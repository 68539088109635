import React, { useState, useEffect } from "react";
import { Icon } from "@iconify/react";
import "../Forms/styles.css";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import moment from "moment";
import {
  addFile,
  getPACUNursingNotes,
  updatePACUNursingNotes,
} from "../../../utils/api";
import SignatureButton from "../../../components/SignatureButton";

const PACUNursingNotes = () => {
  const navigate = useNavigate();
  const { form_id } = useParams();
  const [loading, setLoading] = useState(true);
  const [individualSignature, setIndividualSignature] = useState("");

  const [formData, setFormData] = useState({
    color_in: "",
    color_out: "",
    post_in: "",
    post_out: "",
    pain_in: "",
    pain_out: "",
    Text1: "",
    blood_glucose: "",
    ref_range: "",
    extremities_two: false,
    extremities_one: false,
    extremities_zero: false,
    breath_two: false,
    breath_one: false,
    breath_zero: false,
    preanesthesia_two: false,
    preanesthesia_one: false,
    preanesthesia_zero: false,
    circulation_two: false,
    circulation_one: false,
    circulation_zero: false,
    airway_oral: false,
    airway_nasal: false,
    iv_or: "",
    iv_pacu: "",
    iv_secondary: "",
    discharge_alert: false,
    discharge_able: false,
    discharge_goodcapillary: false,
    ambulatory: false,
    released_to: false,
    time_admitted: "",
    admitted_condition: "",
    temp: "",
    BP: "",
    P: "",
    R: "",
    time_one: "",
    BP_one: "",
    resp_one: "",
    temp_one: "",
    otwosat_one: "",
    hr_one: "",
    pr_one: "",
    time_two: "",

    BP_two: "",
    resp_two: "",
    temp_two: "",
    otwosat_two: "",
    time_one: "",
    BP_one: "",
    resp_one: "",
    temp_one: "",
    otwosat_one: "",
    hr_one: "",
    time_two: "",
    BP_two: "",
    resp_two: "",
    temp_two: "",
    otwosat_two: "",
    hr_two: "",

    time_three: "",
    BP_three: "",
    resp_three: "",
    temp_three: "",
    otwosat_three: "",
    hr_three: "",
    time_four: "",
    BP_four: "",
    resp_four: "",
    temp_four: "",
    otwosat_four: "",
    hr_four: "",
    time_five: "",
    BP_five: "",
    resp_five: "",
    temp_five: "",
    otwosat_five: "",

    hr_five: "",
    time_one: "",
    BP_one: "",
    resp_one: "",
    temp_one: "",
    otwosat_one: "",
    hr_one: "",
    time_two: "",
    BP_two: "",
    resp_two: "",
    temp_two: "",
    otwosat_two: "",
    hr_two: "",
    pr_two: "",
    time_three: "",
    BP_three: "",
    resp_three: "",
    temp_three: "",
    otwosat_three: "",
    hr_three: "",
    pr_three: "",
    time_four: "",
    BP_four: "",
    resp_four: "",
    temp_four: "",
    otwosat_four: "",
    hr_four: "",
    pr_four: "",
    time_five: "",
    BP_five: "",
    resp_five: "",
    temp_five: "",
    otwosat_five: "",
    hr_five: "",
    pr_five: "",
    time_six: "",
    BP_six: "",
    resp_six: "",
    temp_six: "",
    otwosat_six: "",
    hr_six: "",
    pr_six: "",
    time_seven: "",
    BP_seven: "",
    resp_seven: "",
    temp_seven: "",
    discharge_time_one: "",
    otwosat_seven: "",
    hr_seven: "",
    pr_seven: "",
    time_eight: "",
    BP_eight: "",
    resp_eight: "",
    temp_eight: "",
    otwosat_eight: "",
    hr_eight: "",
    pr_eight: "",
    time_nine: "",
    BP_nine: "",
    resp_nine: "",
    temp_nine: "",
    otwosat_nine: "",
    hr_nine: "",
    pr_nine: "",
    time_ten: "",
    BP_ten: "",
    resp_ten: "",
    temp_ten: "",
    otwosat_ten: "",
    hr_ten: "",
    pr_ten: "",
    notes_time_one: "",
    notes_one: "",
    notes_time_two: "",
    notes_two: "",
    notes_time_three: "",
    notes_three: "",
    notes_time_four: "",
    notes_four: "",
    notes_time_five: "",
    notes_five: "",
    notes_time_six: "",
    notes_six: "",
    notes_time_seven: "",
    notes_seven: "",
    notes_time_eight: "",
    notes_eight: "",
    notes_time_nine: "",
    notes_nine: "",
    notes_time_ten: "",
    notes_ten: "",
    accompanying_adult: "",
    relationship_to: "",
    home_via: "",
    received_by: "",
    valuables: "",
    comments: "",
    discharge_time: "",
    iv_fluid: "",
    iv_btl: "",
    nv_total: "",
    activity_in: "",
    activity_out: "",
    respiration_in: "",
    respiration_out: "",
    circulation_in: "",
    circulation_out: "",
    consciousness_in: "",
    consciousness_out: "",
    skin_two: false,
    skin_one: false,
    skin_zero: false,
    color_pink: false,
    color_pale: false,
    color_dusky: false,
    color_ruddy: false,
    dressing_drainage: false,
    dressing_na: false,
    dressing_dry: false,
    iv_healthy: false,
    iv_infiltrated: false,
    iv_reddened: false,
    iv_cold: false,
    consciousness_drowsy: false,
    consciousness_reactive: false,
    consciousness_restless: false,
    iv_warm: false,
    consciousness_awake: false,
    nv_present: false,
    nv_na: false,
    respiratory_nebulizer: false,
    respiratory_tcdb: false,
    respiratory_incentive: false,
    total_fluid: "",
    discharge_stable: false,
    discharge_nodizzy: false,
    postop_confirmed: false,
    discharge_voided: false,
    discharge_na: false,
    discharge_nowound: false,
    discharge_md: false,
    wheelchair: false,
    site_clear_yes: false,
    site_clear_no: false,
    otwotime: "",
    otwotime_via: "",
    otwotime_at: "",
    otwodiscontinued_of: "",
    otwodiscontinued_at: "",
    airway_removed: "",
    IV_discontinued: "",
    comment: "",
    instructions_reviewed: false,
    ordering_physician_one: "",
    drug_ordered_one: "",
    dose_one: "",
    route_one: "",
    time_one_initial: "",
    result_one: "",

    ordering_physician_two: "",
    drug_ordered_two: "",
    dose_two: "",
    route_two: "",
    time_two_initial: "",
    result_two: "",

    ordering_physician_three: "",
    drug_ordered_three: "",
    dose_three: "",
    route_three: "",
    time_three_initial: "",
    result_three: "",

    ordering_physician_four: "",
    drug_ordered_four: "",
    dose_four: "",
    route_four: "",
    time_four_initial: "",
    result_four: "",

    ordering_physician_five: "",
    drug_ordered_five: "",
    dose_five: "",
    route_five: "",
    time_five_initial: "",
    result_five: "",
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    let fileName;

    // Fetch 1: Upload Signature
    if (individualSignature !== "") {
      const response = await addFile(individualSignature);

      const data = await response.json();
      fileName = await data.fileName;
    }
    const payload = {
      ...formData,
      nurse_signature: fileName,
      date: moment().format("MM/DD/YYYY"),
      time: moment().format("h:mm a"),
      status: "In Progress",
    };

    console.log("Payload Sent to Backend:", payload);

    // Fetch 2: Create/Update the Disclosure of Ownerhsip Model

    updatePACUNursingNotes(form_id, payload)
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        console.log("complete");
        navigate(-1);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getPACUNursingNotes(form_id)
      .then((res) => res.json())
      .then((data) => {
        console.log(data);

        if (data.status !== "Not Started") {
          setFormData({
            airway_oral: data.airway_oral || false,
            airway_nasal: data.airway_nasal || false,
            iv_or: data.iv_or || "",
            iv_pacu: data.iv_pacu || "",
            iv_secondary: data.iv_secondary || "",
            discharge_alert: data.discharge_alert || false,
            discharge_able: data.discharge_able || false,
            discharge_goodcapillary: data.discharge_goodcapillary || false,
            ambulatory: data.ambulatory || false,
            released_to: data.released_to || false,
            time_admitted: data.time_admitted || "",
            admitted_condition: data.admitted_condition || "",
            temp: data.temp || "",
            BP: data.BP || "",
            P: data.P || "",
            R: data.R || "",
            time_one: data.time_one || "",
            BP_one: data.BP_one || "",
            resp_one: data.resp_one || "",
            temp_one: data.temp_one || "",
            otwosat_one: data.otwosat_one || "",
            hr_one: data.hr_one || "",
            pr_one: data.pr_one || "",
            time_two: data.time_two || "",
            BP_two: data.BP_two || "",
            resp_two: data.resp_two || "",
            temp_two: data.temp_two || "",
            otwosat_two: data.otwosat_two || "",
            time_one: data.time_one || "",
            BP_one: data.BP_one || "",
            resp_one: data.resp_one || "",
            temp_one: data.temp_one || "",
            otwosat_one: data.otwosat_one || "",
            hr_one: data.hr_one || "",
            Text1: data.Text1 || "",
            blood_glucose: data.blood_glucose || "",
            ref_range: data.ref_range || "",
            time_two: data.time_two || "",
            BP_two: data.BP_two || "",
            resp_two: data.resp_two || "",
            temp_two: data.temp_two || "",
            otwosat_two: data.otwosat_two || "",
            hr_two: data.hr_two || "",

            time_three: data.time_three || "",
            BP_three: data.BP_three || "",
            resp_three: data.resp_three || "",
            temp_three: data.temp_three || "",
            otwosat_three: data.otwosat_three || "",
            hr_three: data.hr_three || "",

            time_four: data.time_four || "",
            BP_four: data.BP_four || "",
            resp_four: data.resp_four || "",
            temp_four: data.temp_four || "",
            otwosat_four: data.otwosat_four || "",
            hr_four: data.hr_four || "",
            ordering_physician_one: data.ordering_physician_one || "",
            drug_ordered_one: data.drug_ordered_one || "",
            dose_one: data.dose_one || "",
            route_one: data.route_one || "",
            time_one_initial: data.time_one_initial || "",
            result_one: data.result_one || "",

            ordering_physician_two: data.ordering_physician_two || "",
            drug_ordered_two: data.drug_ordered_two || "",
            dose_two: data.dose_two || "",
            route_two: data.route_two || "",
            time_two_initial: data.time_two_initial || "",
            result_two: data.result_two || "",

            ordering_physician_three: data.ordering_physician_three || "",
            drug_ordered_three: data.drug_ordered_three || "",
            dose_three: data.dose_three || "",
            route_three: data.route_three || "",
            time_three_initial: data.time_three_initial || "",
            result_three: data.result_three || "",

            ordering_physician_four: data.ordering_physician_four || "",
            drug_ordered_four: data.drug_ordered_four || "",
            dose_four: data.dose_four || "",
            route_four: data.route_four || "",
            time_four_initial: data.time_four_initial || "",
            result_four: data.result_four || "",

            ordering_physician_five: data.ordering_physician_five || "",
            drug_ordered_five: data.drug_ordered_five || "",
            dose_five: data.dose_five || "",
            route_five: data.route_five || "",
            time_five_initial: data.time_five_initial || "",
            result_five: data.result_five || "",
            time_five: data.time_five || "",
            BP_five: data.BP_five || "",
            resp_five: data.resp_five || "",
            temp_five: data.temp_five || "",
            otwosat_five: data.otwosat_five || "",
            hr_five: data.hr_five || "",
            time_one: data.time_one || "",
            BP_one: data.BP_one || "",
            resp_one: data.resp_one || "",
            temp_one: data.temp_one || "",
            otwosat_one: data.otwosat_one || "",
            hr_one: data.hr_one || "",
            time_two: data.time_two || "",
            BP_two: data.BP_two || "",
            resp_two: data.resp_two || "",
            temp_two: data.temp_two || "",
            otwosat_two: data.otwosat_two || "",
            hr_two: data.hr_two || "",
            pr_two: data.pr_two || "",
            time_three: data.time_three || "",
            BP_three: data.BP_three || "",
            resp_three: data.resp_three || "",
            temp_three: data.temp_three || "",
            otwosat_three: data.otwosat_three || "",
            hr_three: data.hr_three || "",
            pr_three: data.pr_three || "",
            time_four: data.time_four || "",
            BP_four: data.BP_four || "",
            resp_four: data.resp_four || "",
            temp_four: data.temp_four || "",
            otwosat_four: data.otwosat_four || "",
            hr_four: data.hr_four || "",
            pr_four: data.pr_four || "",
            time_five: data.time_five || "",
            BP_five: data.BP_five || "",
            resp_five: data.resp_five || "",
            temp_five: data.temp_five || "",
            otwosat_five: data.otwosat_five || "",
            pr_five: data.pr_five || "",
            time_six: data.time_six || "",
            discharge_time_one: data.discharge_time_one || "",
            BP_six: data.BP_six || "",
            resp_six: data.resp_six || "",
            temp_six: data.temp_six || "",
            otwosat_six: data.otwosat_six || "",
            hr_six: data.hr_six || "",
            pr_six: data.pr_six || "",
            time_seven: data.time_seven || "",
            BP_seven: data.BP_seven || "",
            resp_seven: data.resp_seven || "",
            temp_seven: data.temp_seven || "",
            otwosat_seven: data.otwosat_seven || "",
            hr_seven: data.hr_seven || "",
            pr_seven: data.pr_seven || "",
            time_eight: data.time_eight || "",
            BP_eight: data.BP_eight || "",
            resp_eight: data.resp_eight || "",
            temp_eight: data.temp_eight || "",
            otwosat_eight: data.otwosat_eight || "",
            hr_eight: data.hr_eight || "",
            pr_eight: data.pr_eight || "",
            time_nine: data.time_nine || "",
            BP_nine: data.BP_nine || "",
            resp_nine: data.resp_nine || "",
            temp_nine: data.temp_nine || "",
            otwosat_nine: data.otwosat_nine || "",
            hr_nine: data.hr_nine || "",
            pr_nine: data.pr_nine || "",
            time_ten: data.time_ten || "",
            BP_ten: data.BP_ten || "",
            resp_ten: data.resp_ten || "",
            temp_ten: data.temp_ten || "",
            otwosat_ten: data.otwosat_ten || "",
            hr_ten: data.hr_ten || "",
            pr_ten: data.pr_ten || "",
            notes_time_one: data.notes_time_one || "",
            notes_one: data.notes_one || "",
            notes_time_two: data.notes_time_two || "",
            notes_two: data.notes_two || "",
            notes_time_three: data.notes_time_three || "",
            notes_three: data.notes_three || "",
            notes_time_four: data.notes_time_four || "",
            notes_four: data.notes_four || "",
            notes_time_five: data.notes_time_five || "",
            notes_five: data.notes_five || "",
            notes_time_six: data.notes_time_six || "",
            notes_six: data.notes_six || "",
            notes_time_seven: data.notes_time_seven || "",
            notes_seven: data.notes_seven || "",
            notes_time_eight: data.notes_time_eight || "",
            notes_eight: data.notes_eight || "",
            notes_time_nine: data.notes_time_nine || "",
            notes_nine: data.notes_nine || "",
            notes_time_ten: data.notes_time_ten || "",
            notes_ten: data.notes_ten || "",
            accompanying_adult: data.accompanying_adult || "",
            relationship_to: data.relationship_to || "",
            home_via: data.home_via || "",
            received_by: data.received_by || "",
            valuables: data.valuables || "",
            comments: data.comments || "",
            discharge_time: data.discharge_time || "",
            iv_fluid: data.iv_fluid || "",
            iv_btl: data.iv_btl || "",
            nv_total: data.nv_total || "",
            activity_in: data.activity_in || "",
            activity_out: data.activity_out || "",
            respiration_in: data.respiration_in || "",
            respiration_out: data.respiration_out || "",
            circulation_in: data.circulation_in || "",
            circulation_out: data.circulation_out || "",
            consciousness_in: data.consciousness_in || "",
            consciousness_out: data.consciousness_out || "",
            color_in: data.color_in || "",
            color_out: data.color_out || "",
            post_in: data.post_in || "",
            post_out: data.post_out || "",
            pain_in: data.pain_in || "",
            pain_out: data.pain_out || "",
            extremities_two: data.extremities_two || false,
            extremities_one: data.extremities_one || false,
            extremities_zero: data.extremities_zero || false,
            breath_two: data.breath_two || false,
            breath_one: data.breath_one || false,
            breath_zero: data.breath_zero || false,
            preanesthesia_two: data.preanesthesia_two || false,
            preanesthesia_one: data.preanesthesia_one || false,
            preanesthesia_zero: data.preanesthesia_zero || false,
            circulation_two: data.circulation_two || false,
            circulation_one: data.circulation_one || false,
            circulation_zero: data.circulation_zero || false,
            skin_two: data.skin_two || false,
            skin_one: data.skin_one || false,
            skin_zero: data.skin_zero || false,
            color_pink: data.color_pink || false,
            color_pale: data.color_pale || false,
            color_dusky: data.color_dusky || false,
            color_ruddy: data.color_ruddy || false,
            dressing_drainage: data.dressing_drainage || false,
            dressing_na: data.dressing_na || false,
            dressing_dry: data.dressing_dry || false,
            iv_healthy: data.iv_healthy || false,
            iv_infiltrated: data.iv_infiltrated || false,
            iv_reddened: data.iv_reddened || false,
            iv_cold: data.iv_cold || false,
            consciousness_drowsy: data.consciousness_drowsy || false,
            consciousness_reactive: data.consciousness_reactive || false,
            consciousness_restless: data.consciousness_restless || false,
            iv_warm: data.iv_warm || false,
            consciousness_awake: data.consciousness_awake || false,
            nv_present: data.nv_present || false,
            nv_na: data.nv_na || false,
            respiratory_nebulizer: data.respiratory_nebulizer || false,
            respiratory_tcdb: data.respiratory_tcdb || false,
            respiratory_incentive: data.respiratory_incentive || false,
            total_fluid: data.total_fluid || "",
            discharge_stable: data.discharge_stable || false,
            discharge_nodizzy: data.discharge_nodizzy || false,
            postop_confirmed: data.postop_confirmed || false,
            discharge_voided: data.discharge_voided || false,
            discharge_na: data.discharge_na || false,
            discharge_nowound: data.discharge_nowound || false,
            discharge_md: data.discharge_md || false,
            wheelchair: data.wheelchair || false,
            site_clear_yes: data.site_clear_yes || false,
            site_clear_no: data.site_clear_no || false,
            otwotime: data.otwotime || "",
            otwotime_via: data.otwotime_via || "",
            otwotime_at: data.otwotime_at || "",
            otwodiscontinued_of: data.otwodiscontinued_of || "",
            otwodiscontinued_at: data.otwodiscontinued_at || "",
            airway_removed: data.airway_removed || "",
            IV_discontinued: data.IV_discontinued || "",
            comment: data.comment || "",
            instructions_reviewed: data.instructions_reviewed || false,
          });
        }
        setLoading(false);
      })
      .catch((err) => console.log(err));
  }, []);
  if (loading) return <p>Loading...</p>;

  return (
    <>
      <div
        className="arrow-container"
        onClick={() => navigate(-1)}
        style={{ cursor: "pointer" }}
      >
        <p className="back-arrow">
          <Icon icon="ic:twotone-arrow-back" className="icon" /> Back to Forms
        </p>
      </div>
      <h3 className="form-title">PACU Nursing Notes</h3>
      <div className="form-content">
        <form onSubmit={handleSubmit}>
          <div className="label-indent">
            <label>TIME ADMITTED TO RECOVERY:</label>
            <input
              type="text"
              className="call-input"
              name="time_admitted"
              value={formData.time_admitted}
              onChange={(e) =>
                setFormData({ ...formData, time_admitted: e.target.value })
              }
            />
          </div>
          <div className="label-indent mobile">
            <label>
              ADMIT CONDITION:
              <input
                type="text"
                className="call-input"
                name="admitted_condition"
                value={formData.admitted_condition}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    admitted_condition: e.target.value,
                  })
                }
              />
            </label>
            <label>
              TEMP:
              <input
                type="text"
                className="call-input"
                name="temp"
                value={formData.temp}
                onChange={(e) =>
                  setFormData({ ...formData, temp: e.target.value })
                }
              />
            </label>
            <label>
              BP:
              <input
                type="text"
                className="call-input"
                name="BP"
                value={formData.BP}
                onChange={(e) =>
                  setFormData({ ...formData, BP: e.target.value })
                }
              />
            </label>
            <br />{" "}
            <label>
              P:
              <input
                type="text"
                className="call-input"
                name="P"
                value={formData.P}
                onChange={(e) =>
                  setFormData({ ...formData, P: e.target.value })
                }
              />
            </label>
            <label>
              R:
              <input
                type="text"
                className="call-input"
                name="R"
                value={formData.R}
                onChange={(e) =>
                  setFormData({ ...formData, R: e.target.value })
                }
              />
            </label>
          </div>
          <div className="label-indent bj-mobile-margin">
            {/* <div className="label-indent"> */}
            <div className="pacu-container">
              <div className="pacu-row">
                <div className="pacu-cell">
                  <b>TIME</b>
                </div>
                <div className="pacu-cell">
                  <b>BP</b>
                </div>
                <div className="pacu-cell">
                  <b>RESP</b>
                </div>
                <div className="pacu-cell">
                  <b>TEMP</b>
                </div>
                <div className="pacu-cell">
                  <b>O2 STAT</b>
                </div>
                <div className="pacu-cell">
                  <b>HR</b>
                </div>
                <div className="pacu-cell">
                  <b>PR</b>
                </div>
              </div>

              <div className="pacu-row">
                <div className="pacu-cell">
                  <input
                    className="pacu-input"
                    name="time_one"
                    value={formData.time_one}
                    onChange={(e) =>
                      setFormData({ ...formData, time_one: e.target.value })
                    }
                  />
                </div>
                <div className="pacu-cell">
                  <input
                    className="pacu-input"
                    name="BP_one"
                    value={formData.BP_one}
                    onChange={(e) =>
                      setFormData({ ...formData, BP_one: e.target.value })
                    }
                  />
                </div>
                <div className="pacu-cell">
                  <input
                    className="pacu-input"
                    name="resp_one"
                    value={formData.resp_one}
                    onChange={(e) =>
                      setFormData({ ...formData, resp_one: e.target.value })
                    }
                  />
                </div>
                <div className="pacu-cell">
                  <input
                    className="pacu-input"
                    name="temp_one"
                    value={formData.temp_one}
                    onChange={(e) =>
                      setFormData({ ...formData, temp_one: e.target.value })
                    }
                  />
                </div>
                <div className="pacu-cell">
                  <input
                    className="pacu-input"
                    name="otwosat_one"
                    value={formData.otwosat_one}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        otwosat_one: e.target.value,
                      })
                    }
                  />
                </div>
                <div className="pacu-cell">
                  <input
                    className="pacu-input"
                    name="hr_one"
                    value={formData.hr_one}
                    onChange={(e) =>
                      setFormData({ ...formData, hr_one: e.target.value })
                    }
                  />
                </div>
                <div className="pacu-cell">
                  <input
                    className="pacu-input"
                    name="pr_one"
                    value={formData.pr_one}
                    onChange={(e) =>
                      setFormData({ ...formData, pr_one: e.target.value })
                    }
                  />
                </div>
              </div>
              <div className="pacu-row">
                <div className="pacu-cell">
                  <input
                    className="pacu-input"
                    name="time_two"
                    value={formData.time_two}
                    onChange={(e) =>
                      setFormData({ ...formData, time_two: e.target.value })
                    }
                  />
                </div>
                <div className="pacu-cell">
                  <input
                    className="pacu-input"
                    name="BP_two"
                    value={formData.BP_two}
                    onChange={(e) =>
                      setFormData({ ...formData, BP_two: e.target.value })
                    }
                  />
                </div>
                <div className="pacu-cell">
                  <input
                    className="pacu-input"
                    name="resp_two"
                    value={formData.resp_two}
                    onChange={(e) =>
                      setFormData({ ...formData, resp_two: e.target.value })
                    }
                  />
                </div>
                <div className="pacu-cell">
                  <input
                    className="pacu-input"
                    name="temp_two"
                    value={formData.temp_two}
                    onChange={(e) =>
                      setFormData({ ...formData, temp_two: e.target.value })
                    }
                  />
                </div>
                <div className="pacu-cell">
                  <input
                    className="pacu-input"
                    name="otwosat_two"
                    value={formData.otwosat_two}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        otwosat_two: e.target.value,
                      })
                    }
                  />
                </div>
                <div className="pacu-cell">
                  <input
                    className="pacu-input"
                    name="hr_two"
                    value={formData.hr_two}
                    onChange={(e) =>
                      setFormData({ ...formData, hr_two: e.target.value })
                    }
                  />
                </div>
                <div className="pacu-cell">
                  <input
                    className="pacu-input"
                    name="pr_two"
                    value={formData.pr_two}
                    onChange={(e) =>
                      setFormData({ ...formData, pr_two: e.target.value })
                    }
                  />
                </div>
              </div>

              <div className="pacu-row">
                <div className="pacu-cell">
                  <input
                    className="pacu-input"
                    name="time_three"
                    value={formData.time_three}
                    onChange={(e) =>
                      setFormData({ ...formData, time_three: e.target.value })
                    }
                  />
                </div>
                <div className="pacu-cell">
                  <input
                    className="pacu-input"
                    name="BP_three"
                    value={formData.BP_three}
                    onChange={(e) =>
                      setFormData({ ...formData, BP_three: e.target.value })
                    }
                  />
                </div>
                <div className="pacu-cell">
                  <input
                    className="pacu-input"
                    name="resp_three"
                    value={formData.resp_three}
                    onChange={(e) =>
                      setFormData({ ...formData, resp_three: e.target.value })
                    }
                  />
                </div>
                <div className="pacu-cell">
                  <input
                    className="pacu-input"
                    name="temp_three"
                    value={formData.temp_three}
                    onChange={(e) =>
                      setFormData({ ...formData, temp_three: e.target.value })
                    }
                  />
                </div>
                <div className="pacu-cell">
                  <input
                    className="pacu-input"
                    name="otwosat_three"
                    value={formData.otwosat_three}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        otwosat_three: e.target.value,
                      })
                    }
                  />
                </div>
                <div className="pacu-cell">
                  <input
                    className="pacu-input"
                    name="hr_three"
                    value={formData.hr_three}
                    onChange={(e) =>
                      setFormData({ ...formData, hr_three: e.target.value })
                    }
                  />
                </div>
                <div className="pacu-cell">
                  <input
                    className="pacu-input"
                    name="pr_three"
                    value={formData.pr_three}
                    onChange={(e) =>
                      setFormData({ ...formData, pr_three: e.target.value })
                    }
                  />
                </div>
              </div>
              <div className="pacu-row">
                <div className="pacu-cell">
                  <input
                    className="pacu-input"
                    name="time_four"
                    value={formData.time_four}
                    onChange={(e) =>
                      setFormData({ ...formData, time_four: e.target.value })
                    }
                  />
                </div>
                <div className="pacu-cell">
                  <input
                    className="pacu-input"
                    name="BP_four"
                    value={formData.BP_four}
                    onChange={(e) =>
                      setFormData({ ...formData, BP_four: e.target.value })
                    }
                  />
                </div>
                <div className="pacu-cell">
                  <input
                    className="pacu-input"
                    name="resp_four"
                    value={formData.resp_four}
                    onChange={(e) =>
                      setFormData({ ...formData, resp_four: e.target.value })
                    }
                  />
                </div>
                <div className="pacu-cell">
                  <input
                    className="pacu-input"
                    name="temp_four"
                    value={formData.temp_four}
                    onChange={(e) =>
                      setFormData({ ...formData, temp_four: e.target.value })
                    }
                  />
                </div>
                <div className="pacu-cell">
                  <input
                    className="pacu-input"
                    name="otwosat_four"
                    value={formData.otwosat_four}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        otwosat_four: e.target.value,
                      })
                    }
                  />
                </div>
                <div className="pacu-cell">
                  <input
                    className="pacu-input"
                    name="hr_four"
                    value={formData.hr_four}
                    onChange={(e) =>
                      setFormData({ ...formData, hr_four: e.target.value })
                    }
                  />
                </div>
                <div className="pacu-cell">
                  <input
                    className="pacu-input"
                    name="pr_four"
                    value={formData.pr_four}
                    onChange={(e) =>
                      setFormData({ ...formData, pr_four: e.target.value })
                    }
                  />
                </div>
              </div>

              <div className="pacu-row">
                <div className="pacu-cell">
                  <input
                    className="pacu-input"
                    name="time_five"
                    value={formData.time_five}
                    onChange={(e) =>
                      setFormData({ ...formData, time_five: e.target.value })
                    }
                  />
                </div>
                <div className="pacu-cell">
                  <input
                    className="pacu-input"
                    name="BP_five"
                    value={formData.BP_five}
                    onChange={(e) =>
                      setFormData({ ...formData, BP_five: e.target.value })
                    }
                  />
                </div>
                <div className="pacu-cell">
                  <input
                    className="pacu-input"
                    name="resp_five"
                    value={formData.resp_five}
                    onChange={(e) =>
                      setFormData({ ...formData, resp_five: e.target.value })
                    }
                  />
                </div>
                <div className="pacu-cell">
                  <input
                    className="pacu-input"
                    name="temp_five"
                    value={formData.temp_five}
                    onChange={(e) =>
                      setFormData({ ...formData, temp_five: e.target.value })
                    }
                  />
                </div>
                <div className="pacu-cell">
                  <input
                    className="pacu-input"
                    name="otwosat_five"
                    value={formData.otwosat_five}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        otwosat_five: e.target.value,
                      })
                    }
                  />
                </div>
                <div className="pacu-cell">
                  <input
                    className="pacu-input"
                    name="hr_five"
                    value={formData.hr_five}
                    onChange={(e) =>
                      setFormData({ ...formData, hr_five: e.target.value })
                    }
                  />
                </div>
                <div className="pacu-cell">
                  <input
                    className="pacu-input"
                    name="pr_five"
                    value={formData.pr_five}
                    onChange={(e) =>
                      setFormData({ ...formData, pr_five: e.target.value })
                    }
                  />
                </div>
              </div>

              <div className="pacu-row">
                <div className="pacu-cell">
                  <input
                    className="pacu-input"
                    name="time_six"
                    value={formData.time_six}
                    onChange={(e) =>
                      setFormData({ ...formData, time_six: e.target.value })
                    }
                  />
                </div>
                <div className="pacu-cell">
                  <input
                    className="pacu-input"
                    name="BP_six"
                    value={formData.BP_six}
                    onChange={(e) =>
                      setFormData({ ...formData, BP_six: e.target.value })
                    }
                  />
                </div>
                <div className="pacu-cell">
                  <input
                    className="pacu-input"
                    name="resp_six"
                    value={formData.resp_six}
                    onChange={(e) =>
                      setFormData({ ...formData, resp_six: e.target.value })
                    }
                  />
                </div>
                <div className="pacu-cell">
                  <input
                    className="pacu-input"
                    name="temp_six"
                    value={formData.temp_six}
                    onChange={(e) =>
                      setFormData({ ...formData, temp_six: e.target.value })
                    }
                  />
                </div>
                <div className="pacu-cell">
                  <input
                    className="pacu-input"
                    name="otwosat_six"
                    value={formData.otwosat_six}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        otwosat_six: e.target.value,
                      })
                    }
                  />
                </div>
                <div className="pacu-cell">
                  <input
                    className="pacu-input"
                    name="hr_six"
                    value={formData.hr_six}
                    onChange={(e) =>
                      setFormData({ ...formData, hr_six: e.target.value })
                    }
                  />
                </div>
                <div className="pacu-cell">
                  <input
                    className="pacu-input"
                    name="pr_six"
                    value={formData.pr_six}
                    onChange={(e) =>
                      setFormData({ ...formData, pr_six: e.target.value })
                    }
                  />
                </div>
              </div>

              <div className="pacu-row">
                <div className="pacu-cell">
                  <input
                    className="pacu-input"
                    name="time_seven"
                    value={formData.time_seven}
                    onChange={(e) =>
                      setFormData({ ...formData, time_seven: e.target.value })
                    }
                  />
                </div>
                <div className="pacu-cell">
                  <input
                    className="pacu-input"
                    name="BP_seven"
                    value={formData.BP_seven}
                    onChange={(e) =>
                      setFormData({ ...formData, BP_seven: e.target.value })
                    }
                  />
                </div>
                <div className="pacu-cell">
                  <input
                    className="pacu-input"
                    name="resp_seven"
                    value={formData.resp_seven}
                    onChange={(e) =>
                      setFormData({ ...formData, resp_seven: e.target.value })
                    }
                  />
                </div>
                <div className="pacu-cell">
                  <input
                    className="pacu-input"
                    name="temp_seven"
                    value={formData.temp_seven}
                    onChange={(e) =>
                      setFormData({ ...formData, temp_seven: e.target.value })
                    }
                  />
                </div>
                <div className="pacu-cell">
                  <input
                    className="pacu-input"
                    name="otwosat_seven"
                    value={formData.otwosat_seven}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        otwosat_seven: e.target.value,
                      })
                    }
                  />
                </div>
                <div className="pacu-cell">
                  <input
                    className="pacu-input"
                    name="hr_seven"
                    value={formData.hr_seven}
                    onChange={(e) =>
                      setFormData({ ...formData, hr_seven: e.target.value })
                    }
                  />
                </div>
                <div className="pacu-cell">
                  <input
                    className="pacu-input"
                    name="pr_seven"
                    value={formData.pr_seven}
                    onChange={(e) =>
                      setFormData({ ...formData, pr_seven: e.target.value })
                    }
                  />
                </div>
              </div>
              <div className="pacu-row">
                <div className="pacu-cell">
                  <input
                    className="pacu-input"
                    name="time_eight"
                    value={formData.time_eight}
                    onChange={(e) =>
                      setFormData({ ...formData, time_eight: e.target.value })
                    }
                  />
                </div>
                <div className="pacu-cell">
                  <input
                    className="pacu-input"
                    name="BP_eight"
                    value={formData.BP_eight}
                    onChange={(e) =>
                      setFormData({ ...formData, BP_eight: e.target.value })
                    }
                  />
                </div>
                <div className="pacu-cell">
                  <input
                    className="pacu-input"
                    name="resp_eight"
                    value={formData.resp_eight}
                    onChange={(e) =>
                      setFormData({ ...formData, resp_eight: e.target.value })
                    }
                  />
                </div>
                <div className="pacu-cell">
                  <input
                    className="pacu-input"
                    name="temp_eight"
                    value={formData.temp_eight}
                    onChange={(e) =>
                      setFormData({ ...formData, temp_eight: e.target.value })
                    }
                  />
                </div>
                <div className="pacu-cell">
                  <input
                    className="pacu-input"
                    name="otwosat_eight"
                    value={formData.otwosat_eight}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        otwosat_eight: e.target.value,
                      })
                    }
                  />
                </div>
                <div className="pacu-cell">
                  <input
                    className="pacu-input"
                    name="hr_eight"
                    value={formData.hr_eight}
                    onChange={(e) =>
                      setFormData({ ...formData, hr_eight: e.target.value })
                    }
                  />
                </div>
                <div className="pacu-cell">
                  <input
                    className="pacu-input"
                    name="pr_eight"
                    value={formData.pr_eight}
                    onChange={(e) =>
                      setFormData({ ...formData, pr_eight: e.target.value })
                    }
                  />
                </div>
              </div>

              <div className="pacu-row">
                <div className="pacu-cell">
                  <input
                    className="pacu-input"
                    name="time_nine"
                    value={formData.time_nine}
                    onChange={(e) =>
                      setFormData({ ...formData, time_nine: e.target.value })
                    }
                  />
                </div>
                <div className="pacu-cell">
                  <input
                    className="pacu-input"
                    name="BP_nine"
                    value={formData.BP_nine}
                    onChange={(e) =>
                      setFormData({ ...formData, BP_nine: e.target.value })
                    }
                  />
                </div>
                <div className="pacu-cell">
                  <input
                    className="pacu-input"
                    name="resp_nine"
                    value={formData.resp_nine}
                    onChange={(e) =>
                      setFormData({ ...formData, resp_nine: e.target.value })
                    }
                  />
                </div>
                <div className="pacu-cell">
                  <input
                    className="pacu-input"
                    name="temp_nine"
                    value={formData.temp_nine}
                    onChange={(e) =>
                      setFormData({ ...formData, temp_nine: e.target.value })
                    }
                  />
                </div>
                <div className="pacu-cell">
                  <input
                    className="pacu-input"
                    name="otwosat_nine"
                    value={formData.otwosat_nine}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        otwosat_nine: e.target.value,
                      })
                    }
                  />
                </div>
                <div className="pacu-cell">
                  <input
                    className="pacu-input"
                    name="hr_nine"
                    value={formData.hr_nine}
                    onChange={(e) =>
                      setFormData({ ...formData, hr_nine: e.target.value })
                    }
                  />
                </div>
                <div className="pacu-cell">
                  <input
                    className="pacu-input"
                    name="pr_nine"
                    value={formData.pr_nine}
                    onChange={(e) =>
                      setFormData({ ...formData, pr_nine: e.target.value })
                    }
                  />
                </div>
              </div>

              <div className="pacu-row">
                <div className="pacu-cell">
                  <input
                    className="pacu-input"
                    name="time_ten"
                    value={formData.time_ten}
                    onChange={(e) =>
                      setFormData({ ...formData, time_ten: e.target.value })
                    }
                  />
                </div>
                <div className="pacu-cell">
                  <input
                    className="pacu-input"
                    name="BP_ten"
                    value={formData.BP_ten}
                    onChange={(e) =>
                      setFormData({ ...formData, BP_ten: e.target.value })
                    }
                  />
                </div>
                <div className="pacu-cell">
                  <input
                    className="pacu-input"
                    name="resp_ten"
                    value={formData.resp_ten}
                    onChange={(e) =>
                      setFormData({ ...formData, resp_ten: e.target.value })
                    }
                  />
                </div>
                <div className="pacu-cell">
                  <input
                    className="pacu-input"
                    name="temp_ten"
                    value={formData.temp_ten}
                    onChange={(e) =>
                      setFormData({ ...formData, temp_ten: e.target.value })
                    }
                  />
                </div>
                <div className="pacu-cell">
                  <input
                    className="pacu-input"
                    name="otwosat_ten"
                    value={formData.otwosat_ten}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        otwosat_ten: e.target.value,
                      })
                    }
                  />
                </div>
                <div className="pacu-cell">
                  <input
                    className="pacu-input"
                    name="hr_ten"
                    value={formData.hr_ten}
                    onChange={(e) =>
                      setFormData({ ...formData, hr_ten: e.target.value })
                    }
                  />
                </div>
                <div className="pacu-cell">
                  <input
                    className="pacu-input"
                    name="pr_ten"
                    value={formData.pr_ten}
                    onChange={(e) =>
                      setFormData({ ...formData, pr_ten: e.target.value })
                    }
                  />
                </div>
              </div>
            </div>
          </div>
          {/* </div> */}
          <div className="label-indent">
            <p className="text-center">
              <b>ALDRETE EVALUATION/SCORE</b>
            </p>
          </div>
          <div className="label-indent">
            <div className="arrow-container">
              <div>
                <input
                  type="checkbox"
                  name="extremities_two"
                  checked={formData.extremities_two}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      extremities_two: e.target.checked,
                    })
                  }
                />
                <label>Able to move 4 extremities 2</label>
                <br />
                <input
                  type="checkbox"
                  name="extremities_one"
                  checked={formData.extremities_one}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      extremities_one: e.target.checked,
                    })
                  }
                />{" "}
                <label>Able to move 2 extremities 1</label>
                <br />
                <input
                  type="checkbox"
                  name="extremities_zero"
                  checked={formData.extremities_zero}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      extremities_zero: e.target.checked,
                    })
                  }
                />{" "}
                <label>Able to move 0 extremities 0</label>
                <br />
              </div>
              <div>
                <label>ACTIVITY</label>
              </div>
              <div>
                <input
                  type="text"
                  name="activity_in"
                  className="short-input"
                  value={formData.activity_in}
                  onChange={(e) =>
                    setFormData({ ...formData, activity_in: e.target.value })
                  }
                />
                <label>IN</label>
                <input
                  type="text"
                  name="activity_out"
                  className="short-input"
                  value={formData.activity_out}
                  onChange={(e) =>
                    setFormData({ ...formData, activity_out: e.target.value })
                  }
                />
                <label>OUT</label>
              </div>
            </div>
            <div className="form-divider"></div>
            <div className="arrow-container">
              <div>
                <input
                  type="checkbox"
                  name="breath_two"
                  checked={formData.breath_two}
                  onChange={(e) =>
                    setFormData({ ...formData, breath_two: e.target.checked })
                  }
                />{" "}
                <label>Able to breath deep & cough 2</label>
                <br />
                <input
                  type="checkbox"
                  name="breath_one"
                  checked={formData.breath_one}
                  onChange={(e) =>
                    setFormData({ ...formData, breath_one: e.target.checked })
                  }
                />{" "}
                <label>Limited breathing & good airway 1</label>
                <br />
                <input
                  type="checkbox"
                  name="breath_zero"
                  checked={formData.breath_zero}
                  onChange={(e) =>
                    setFormData({ ...formData, breath_zero: e.target.checked })
                  }
                />{" "}
                <label>Apneic or obstructed airway 0</label>
                <br />
              </div>
              <div>
                <label>RESPIRATION</label>
              </div>
              <div>
                <input
                  type="text"
                  name="respiration_in"
                  className="short-input"
                  value={formData.respiration_in}
                  onChange={(e) =>
                    setFormData({ ...formData, respiration_in: e.target.value })
                  }
                />
                <label>IN</label>
                <input
                  type="text"
                  name="respiration_out"
                  className="short-input"
                  value={formData.respiration_out}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      respiration_out: e.target.value,
                    })
                  }
                />
                <label>OUT</label>
              </div>
            </div>
            <div className="form-divider"></div>
            <div className="arrow-container">
              <div>
                <input
                  type="checkbox"
                  name="preanesthesia_two"
                  checked={formData.preanesthesia_two}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      preanesthesia_two: e.target.checked,
                    })
                  }
                />{" "}
                <label>BP +/- 25% Preanesthesia level 2</label>
                <br />
                <input
                  type="checkbox"
                  name="preanesthesia_one"
                  checked={formData.preanesthesia_one}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      preanesthesia_one: e.target.checked,
                    })
                  }
                />{" "}
                <label>BP +/- 25-50% Preanesthesia level 1</label>
                <br />
                <input
                  type="checkbox"
                  name="preanesthesia_zero"
                  checked={formData.preanesthesia_zero}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      preanesthesia_zero: e.target.checked,
                    })
                  }
                />{" "}
                <label>BP +/- 50% Preanesthesia level 0</label>
                <br />
              </div>
              <div>
                <label>CIRCULATION</label>
              </div>
              <div>
                <input
                  type="text"
                  name="circulation_in"
                  className="short-input"
                  value={formData.circulation_in}
                  onChange={(e) =>
                    setFormData({ ...formData, circulation_in: e.target.value })
                  }
                />
                <label>IN</label>
                <input
                  type="text"
                  name="circulation_out"
                  className="short-input"
                  value={formData.circulation_out}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      circulation_out: e.target.value,
                    })
                  }
                />
                <label>OUT</label>
              </div>
            </div>
            <div className="form-divider"></div>
            <div className="arrow-container">
              <div>
                <input
                  type="checkbox"
                  name="circulation_two"
                  checked={formData.circulation_two}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      circulation_two: e.target.checked,
                    })
                  }
                />{" "}
                <label>Awake & oriented 2</label>
                <br />
                <input
                  type="checkbox"
                  name="circulation_one"
                  checked={formData.circulation_one}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      circulation_one: e.target.checked,
                    })
                  }
                />{" "}
                <label>Arousable on calling 1</label>
                <br />
                <input
                  type="checkbox"
                  name="circulation_zero"
                  checked={formData.circulation_zero}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      circulation_zero: e.target.checked,
                    })
                  }
                />{" "}
                <label>Not responding 0</label>
                <br />
              </div>
              <div>
                <label>CONSCIOUSNESS</label>
              </div>
              <div>
                <input
                  type="text"
                  name="consciousness_in"
                  className="short-input"
                  value={formData.consciousness_in}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      consciousness_in: e.target.value,
                    })
                  }
                />
                <label>IN</label>
                <input
                  type="text"
                  name="consciousness_out"
                  className="short-input"
                  value={formData.consciousness_out}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      consciousness_out: e.target.value,
                    })
                  }
                />
                <label>OUT</label>
              </div>
            </div>
            <div className="form-divider"></div>
            <div className="arrow-container">
              <div>
                <input
                  type="checkbox"
                  name="skin_two"
                  checked={formData.skin_two}
                  onChange={(e) =>
                    setFormData({ ...formData, skin_two: e.target.checked })
                  }
                />{" "}
                <label>Normal for race 2</label>
                <br />
                <input
                  type="checkbox"
                  name="skin_one"
                  checked={formData.skin_one}
                  onChange={(e) =>
                    setFormData({ ...formData, skin_one: e.target.checked })
                  }
                />{" "}
                <label>Pale, dusky, blotchy,jaundice, etc 1</label>
                <br />
                <input
                  type="checkbox"
                  name="skin_zero"
                  checked={formData.skin_zero}
                  onChange={(e) =>
                    setFormData({ ...formData, skin_zero: e.target.checked })
                  }
                />{" "}
                <label>Cyanotic 0 </label>
                <br />
              </div>
              <div>
                <label>COLOR</label>
              </div>
              <div>
                <input
                  type="text"
                  name="color_in"
                  className="short-input"
                  value={formData.color_in}
                  onChange={(e) =>
                    setFormData({ ...formData, color_in: e.target.value })
                  }
                />
                <label>IN</label>
                <input
                  type="text"
                  className="short-input"
                  name="color_out"
                  value={formData.color_out}
                  onChange={(e) =>
                    setFormData({ ...formData, color_out: e.target.value })
                  }
                />
                <label>OUT</label>
              </div>
            </div>
            <div className="form-divider"></div>
            <div className="arrow-container">
              <div>
                <label>POST ANESTHESIA RECOVERY SCORE TOTAL:</label>
                <br />
                <label>POLICY: Score must be ≥ Pre-Op to discharge</label>
                <br />
              </div>
              <div></div>
              <div>
                <input
                  type="text"
                  name="post_in"
                  className="short-input"
                  value={formData.post_in}
                  onChange={(e) =>
                    setFormData({ ...formData, post_in: e.target.value })
                  }
                />
                <label>IN</label>
                <input
                  type="text"
                  className="short-input"
                  name="post_out"
                  value={formData.post_out}
                  onChange={(e) =>
                    setFormData({ ...formData, post_out: e.target.value })
                  }
                />
                <label>OUT</label>
              </div>
            </div>
            <div className="form-divider"></div>
            <div className="arrow-container">
              <div>
                <label>
                  PAIN ASSESSMENT - <br />
                  No pain = 0 to Severe Pain = 10 or 1 to 5 on Picture Scale.
                </label>
                <br />
              </div>
              <div></div>
              <div>
                <input
                  type="text"
                  name="pain_in"
                  className="short-input"
                  value={formData.pain_in}
                  onChange={(e) =>
                    setFormData({ ...formData, pain_in: e.target.value })
                  }
                />
                <label>IN</label>
                <input
                  type="text"
                  name="pain_out"
                  className="short-input"
                  value={formData.pain_out}
                  onChange={(e) =>
                    setFormData({ ...formData, pain_out: e.target.value })
                  }
                />
                <label>OUT</label>
              </div>
            </div>
          </div>
          <div className="label-indent">
            <div className="form-divider"></div>
            <label>Blood Glucose Results:</label>{" "}
            <input
              type="text"
              className="call-input"
              name="blood_glucose"
              value={formData.blood_glucose}
              onChange={(e) =>
                setFormData({ ...formData, blood_glucose: e.target.value })
              }
            />
            <label>Reference Range:</label>{" "}
            <input
              type="text"
              className="call-input"
              name="ref_range"
              value={formData.ref_range}
              onChange={(e) =>
                setFormData({ ...formData, ref_range: e.target.value })
              }
            />
            <label>O2 SAT</label>
            <input
              type="text"
              className="call-input"
              name="Text1"
              value={formData.Text1}
              onChange={(e) =>
                setFormData({ ...formData, Text1: e.target.value })
              }
            />
            <br />
            <label>O2 TIME</label>
            <input
              type="text"
              className="call-input"
              name="otwotime"
              value={formData.otwotime}
              onChange={(e) =>
                setFormData({ ...formData, otwotime: e.target.value })
              }
            />
            <label>VIA</label>
            <input
              type="text"
              className="call-input"
              name="otwotime_via"
              value={formData.otwotime_via}
              onChange={(e) =>
                setFormData({ ...formData, otwotime_via: e.target.value })
              }
            />
            <label>@</label>
            <input
              type="text"
              className="call-input"
              name="otwotime_at"
              value={formData.otwotime_at}
              onChange={(e) =>
                setFormData({ ...formData, otwotime_at: e.target.value })
              }
            />
            <label>L/M</label> <br />
            <label>O2 DISCONTINUED @</label>
            <input
              type="text"
              className="call-input"
              name="otwodiscontinued_at"
              value={formData.otwodiscontinued_at}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  otwodiscontinued_at: e.target.value,
                })
              }
            />
            <label>(Time)&nbsp;</label>
            <label>&nbsp;OF</label>
            <input
              type="text"
              className="call-input"
              name="otwodiscontinued_of"
              value={formData.otwodiscontinued_of}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  otwodiscontinued_of: e.target.value,
                })
              }
            />
            <label>%</label>
            <br />
            <label>MIST</label>
            <br />
            <label>AIRWAY: ORAL</label>
            <input
              type="checkbox"
              name="airway_oral"
              checked={formData.airway_oral}
              onChange={(e) =>
                setFormData({ ...formData, airway_oral: e.target.checked })
              }
            />
            <label>NASAL</label>
            <input
              type="checkbox"
              name="airway_nasal"
              checked={formData.airway_nasal}
              onChange={(e) =>
                setFormData({ ...formData, airway_nasal: e.target.checked })
              }
            />
            <label>REMOVED @</label>
            <input
              type="text"
              className="call-input"
              name="airway_removed"
              value={formData.airway_removed}
              onChange={(e) =>
                setFormData({ ...formData, airway_removed: e.target.value })
              }
            />
            <label>(Time)</label> <br />
            <label>IV DISCONTINUED @</label>
            <input
              type="text"
              className="call-input"
              name="IV_discontinued"
              value={formData.IV_discontinued}
              onChange={(e) =>
                setFormData({ ...formData, IV_discontinued: e.target.value })
              }
            />
            <label>(Time)&nbsp;</label>
            <label>&nbsp;SITE CLEAR:</label>
            <input
              type="checkbox"
              name="site_clear_yes"
              checked={formData.site_clear_yes}
              onChange={(e) =>
                setFormData({ ...formData, site_clear_yes: e.target.checked })
              }
            />
            <label>YES</label>
            <input
              type="checkbox"
              name="site_clear_no"
              checked={formData.site_clear_no}
              onChange={(e) =>
                setFormData({ ...formData, site_clear_no: e.target.checked })
              }
            />
            <label>NO**</label>
            <br />
            <label>**COMMENT/EXPLAIN</label>
            <input
              type="text"
              className="call-input"
              name="comment"
              value={formData.comment}
              onChange={(e) =>
                setFormData({ ...formData, comment: e.target.value })
              }
            />
            <div className="form-divider"></div>
            <p className="text-center">
              <b>INITIAL ASSESSMENT</b>
            </p>
          </div>
          <div className="label-indent">
            {" "}
            <label>Color</label>
            <input
              type="checkbox"
              name="color_pink"
              checked={formData.color_pink}
              onChange={(e) =>
                setFormData({ ...formData, color_pink: e.target.checked })
              }
            />
            <label>Pink</label>
            <input
              type="checkbox"
              name="color_pale"
              checked={formData.color_pale}
              onChange={(e) =>
                setFormData({ ...formData, color_pale: e.target.checked })
              }
            />
            <label>Pale</label>
            <input
              type="checkbox"
              name="color_dusky"
              checked={formData.color_dusky}
              onChange={(e) =>
                setFormData({ ...formData, color_dusky: e.target.checked })
              }
            />
            <label>Dusky</label>
            <input
              type="checkbox"
              name="color_ruddy"
              checked={formData.color_ruddy}
              onChange={(e) =>
                setFormData({ ...formData, color_ruddy: e.target.checked })
              }
            />
            <label>Ruddy</label>
            <br />
            <label>Dressing:</label>
            <input
              type="checkbox"
              name="dressing_dry"
              checked={formData.dressing_dry}
              onChange={(e) =>
                setFormData({ ...formData, dressing_dry: e.target.checked })
              }
            />
            <label>Dry/Intact</label>
            <input
              type="checkbox"
              name="dressing_na"
              checked={formData.dressing_na}
              onChange={(e) =>
                setFormData({ ...formData, dressing_na: e.target.checked })
              }
            />
            <label>N/A</label>
            <input
              type="checkbox"
              name="dressing_drainage"
              checked={formData.dressing_drainage}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  dressing_drainage: e.target.checked,
                })
              }
            />
            <label>Drainage</label>
            <br />
            <label>IV Site:</label>
            <input
              type="checkbox"
              name="iv_healthy"
              checked={formData.iv_healthy}
              onChange={(e) =>
                setFormData({ ...formData, iv_healthy: e.target.checked })
              }
            />
            <label>Healthy/No Redness</label>
            <input
              type="checkbox"
              name="iv_infiltrated"
              checked={formData.iv_infiltrated}
              onChange={(e) =>
                setFormData({ ...formData, iv_infiltrated: e.target.checked })
              }
            />
            <label>Infiltrated</label>
            <input
              type="checkbox"
              name="iv_reddened"
              checked={formData.iv_reddened}
              onChange={(e) =>
                setFormData({ ...formData, iv_reddened: e.target.checked })
              }
            />
            <label>Reddened</label>
            <br />
            <label>Skin:</label>
            <input
              type="checkbox"
              name="iv_warm"
              checked={formData.iv_warm}
              onChange={(e) =>
                setFormData({ ...formData, iv_warm: e.target.checked })
              }
            />
            <label>Warm/Dry</label>
            <input
              type="checkbox"
              name="iv_cold"
              checked={formData.iv_cold}
              onChange={(e) =>
                setFormData({ ...formData, iv_cold: e.target.checked })
              }
            />
            <label>Cold/Clammy</label>
            <br />
            <label>Level of Consciousness:</label>
            <input
              type="checkbox"
              name="consciousness_drowsy"
              checked={formData.consciousness_drowsy}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  consciousness_drowsy: e.target.checked,
                })
              }
            />
            <label>Drowsy</label>
            <input
              type="checkbox"
              name="consciousness_reactive"
              checked={formData.consciousness_reactive}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  consciousness_reactive: e.target.checked,
                })
              }
            />
            <label>Reactive</label>
            <input
              type="checkbox"
              name="consciousness_awake"
              checked={formData.consciousness_awake}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  consciousness_awake: e.target.checked,
                })
              }
            />
            <label>Awake</label>
            <input
              type="checkbox"
              name="consciousness_restless"
              checked={formData.consciousness_restless}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  consciousness_restless: e.target.checked,
                })
              }
            />
            <label>Restless</label>
            <br />
            <label>N/V:</label>
            <input
              type="checkbox"
              name="nv_present"
              checked={formData.nv_present}
              onChange={(e) =>
                setFormData({ ...formData, nv_present: e.target.checked })
              }
            />
            <label>Present</label>
            <input
              type="checkbox"
              name="nv_na"
              checked={formData.nv_na}
              onChange={(e) =>
                setFormData({ ...formData, nv_na: e.target.checked })
              }
            />
            <label>N/A</label>
            <label>Total Emesis PACU</label>
            <input
              type="text"
              className="call-input input-field"
              name="nv_total"
              value={formData.nv_total}
              onChange={(e) =>
                setFormData({ ...formData, nv_total: e.target.value })
              }
            />
            <label>cc</label>
            <br />
            <label>Respiratory Rx:</label>
            <input
              type="checkbox"
              name="respiratory_nebulizer"
              checked={formData.respiratory_nebulizer}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  respiratory_nebulizer: e.target.checked,
                })
              }
            />
            <label>Nebulizer</label>
            <input
              type="checkbox"
              name="respiratory_incentive"
              checked={formData.respiratory_incentive}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  respiratory_incentive: e.target.checked,
                })
              }
            />
            <label>Incentive Inspirometer</label>
            <input
              type="checkbox"
              name="respiratory_tcdb"
              checked={formData.respiratory_tcdb}
              onChange={(e) =>
                setFormData({ ...formData, respiratory_tcdb: e.target.checked })
              }
            />
            <label>TCDB</label>
          </div>
          <div className="label-indent">
            <p className="text-center">
              <b>IV THERAPY</b>
            </p>
            <label>Fluid:</label>
            <input
              type="text"
              ame="iv_fluid"
              value={formData.iv_fluid}
              onChange={(e) =>
                setFormData({ ...formData, iv_fluid: e.target.value })
              }
            />
            <label>BTL#</label>
            <input
              type="text"
              className="call-input"
              name="iv_btl"
              value={formData.iv_btl}
              onChange={(e) =>
                setFormData({ ...formData, iv_btl: e.target.value })
              }
            />{" "}
            <br />
            <label>Total IV Intake: OR</label>
            <input
              type="text"
              className="call-input"
              name="iv_or"
              value={formData.iv_or}
              onChange={(e) =>
                setFormData({ ...formData, iv_or: e.target.value })
              }
            />
            <label>cc &nbsp; PACU</label>
            <input
              type="text"
              className="call-input"
              name="iv_pacu"
              value={formData.iv_pacu}
              onChange={(e) =>
                setFormData({ ...formData, iv_pacu: e.target.value })
              }
            />
            <label>cc Secondary TC</label>
            <input
              type="text"
              className="call-input"
              name="iv_secondary"
              value={formData.iv_secondary}
              onChange={(e) =>
                setFormData({ ...formData, iv_secondary: e.target.value })
              }
            />
            <label>cc</label>
            <br />
            <label>Total Fluid Intake:</label>
            <input
              type="text"
              className="call-input"
              name="total_fluid"
              value={formData.total_fluid}
              onChange={(e) =>
                setFormData({ ...formData, total_fluid: e.target.value })
              }
            />
          </div>
          <div className="label-indent table-center bj-mobile-margin">
            <div className="pacu-container">
              <div className="pacu-row">
                <div className="pacu-cell">
                  <b>
                    ORDERING
                    <br />
                    PHYSICIAN
                  </b>
                </div>
                <div className="pacu-cell">
                  <b>
                    DRUG
                    <br />
                    ORDERED
                  </b>
                </div>
                <div className="pacu-cell">
                  <b>DOSE</b>
                </div>
                <div className="pacu-cell">
                  <b>ROUTE</b>
                </div>
                <div className="pacu-cell">
                  <b>
                    TIME w/
                    <br />
                    RN INITIALS
                  </b>
                </div>
                <div className="pacu-cell">
                  <b>RESULT*</b>
                </div>
              </div>

              <div className="pacu-row">
                <div className="pacu-cell">
                  <input
                    className="pacu-input"
                    name="ordering_physician_one"
                    value={formData.ordering_physician_one}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        ordering_physician_one: e.target.value,
                      })
                    }
                  />
                </div>
                <div className="pacu-cell">
                  <input
                    className="pacu-input"
                    name="drug_ordered_one"
                    value={formData.drug_ordered_one}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        drug_ordered_one: e.target.value,
                      })
                    }
                  />
                </div>
                <div className="pacu-cell">
                  <input
                    className="pacu-input"
                    name="dose_one"
                    value={formData.dose_one}
                    onChange={(e) =>
                      setFormData({ ...formData, dose_one: e.target.value })
                    }
                  />
                </div>
                <div className="pacu-cell">
                  <input
                    className="pacu-input"
                    name="route_one"
                    value={formData.route_one}
                    onChange={(e) =>
                      setFormData({ ...formData, route_one: e.target.value })
                    }
                  />
                </div>
                <div className="pacu-cell">
                  <input
                    className="pacu-input"
                    name="time_one_initial"
                    value={formData.time_one_initial}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        time_one_initial: e.target.value,
                      })
                    }
                  />
                </div>
                <div className="pacu-cell">
                  <input
                    className="pacu-input"
                    name="result_one"
                    value={formData.result_one}
                    onChange={(e) =>
                      setFormData({ ...formData, result_one: e.target.value })
                    }
                  />
                </div>
              </div>

              <div className="pacu-row">
                <div className="pacu-cell">
                  <input
                    className="pacu-input"
                    name="ordering_physician_two"
                    value={formData.ordering_physician_two}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        ordering_physician_two: e.target.value,
                      })
                    }
                  />
                </div>
                <div className="pacu-cell">
                  <input
                    className="pacu-input"
                    name="drug_ordered_two"
                    value={formData.drug_ordered_two}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        drug_ordered_two: e.target.value,
                      })
                    }
                  />
                </div>
                <div className="pacu-cell">
                  <input
                    className="pacu-input"
                    name="dose_two"
                    value={formData.dose_two}
                    onChange={(e) =>
                      setFormData({ ...formData, dose_two: e.target.value })
                    }
                  />
                </div>
                <div className="pacu-cell">
                  <input
                    className="pacu-input"
                    name="route_two"
                    value={formData.route_two}
                    onChange={(e) =>
                      setFormData({ ...formData, route_two: e.target.value })
                    }
                  />
                </div>
                <div className="pacu-cell">
                  <input
                    className="pacu-input"
                    name="time_two_initial"
                    value={formData.time_two_initial}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        time_two_initial: e.target.value,
                      })
                    }
                  />
                </div>
                <div className="pacu-cell">
                  <input
                    className="pacu-input"
                    name="result_two"
                    value={formData.result_two}
                    onChange={(e) =>
                      setFormData({ ...formData, result_two: e.target.value })
                    }
                  />
                </div>
              </div>

              <div className="pacu-row">
                <div className="pacu-cell">
                  <input
                    className="pacu-input"
                    name="ordering_physician_three"
                    value={formData.ordering_physician_three}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        ordering_physician_three: e.target.value,
                      })
                    }
                  />
                </div>
                <div className="pacu-cell">
                  <input
                    className="pacu-input"
                    name="drug_ordered_three"
                    value={formData.drug_ordered_three}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        drug_ordered_three: e.target.value,
                      })
                    }
                  />
                </div>
                <div className="pacu-cell">
                  <input
                    className="pacu-input"
                    name="dose_three"
                    value={formData.dose_three}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        dose_three: e.target.value,
                      })
                    }
                  />
                </div>
                <div className="pacu-cell">
                  <input
                    className="pacu-input"
                    name="route_three"
                    value={formData.route_three}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        route_three: e.target.value,
                      })
                    }
                  />
                </div>
                <div className="pacu-cell">
                  <input
                    className="pacu-input"
                    name="time_three_initial"
                    value={formData.time_three_initial}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        time_three_initial: e.target.value,
                      })
                    }
                  />
                </div>
                <div className="pacu-cell">
                  <input
                    className="pacu-input"
                    name="result_three"
                    value={formData.result_three}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        result_three: e.target.value,
                      })
                    }
                  />
                </div>
              </div>

              <div className="pacu-row">
                <div className="pacu-cell">
                  <input
                    className="pacu-input"
                    name="ordering_physician_four"
                    value={formData.ordering_physician_four}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        ordering_physician_four: e.target.value,
                      })
                    }
                  />
                </div>
                <div className="pacu-cell">
                  <input
                    className="pacu-input"
                    name="drug_ordered_four"
                    value={formData.drug_ordered_four}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        drug_ordered_four: e.target.value,
                      })
                    }
                  />
                </div>
                <div className="pacu-cell">
                  <input
                    className="pacu-input"
                    name="dose_four"
                    value={formData.dose_four}
                    onChange={(e) =>
                      setFormData({ ...formData, dose_four: e.target.value })
                    }
                  />
                </div>
                <div className="pacu-cell">
                  <input
                    className="pacu-input"
                    name="route_four"
                    value={formData.route_four}
                    onChange={(e) =>
                      setFormData({ ...formData, route_four: e.target.value })
                    }
                  />
                </div>
                <div className="pacu-cell">
                  <input
                    className="pacu-input"
                    name="time_four_initial"
                    value={formData.time_four_initial}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        time_four_initial: e.target.value,
                      })
                    }
                  />
                </div>
                <div className="pacu-cell">
                  <input
                    className="pacu-input"
                    name="result_four"
                    value={formData.result_four}
                    onChange={(e) =>
                      setFormData({ ...formData, result_four: e.target.value })
                    }
                  />
                </div>
              </div>

              <div className="pacu-row">
                <div className="pacu-cell">
                  <input
                    className="pacu-input"
                    name="ordering_physician_five"
                    value={formData.ordering_physician_five}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        ordering_physician_five: e.target.value,
                      })
                    }
                  />
                </div>
                <div className="pacu-cell">
                  <input
                    className="pacu-input"
                    name="drug_ordered_five"
                    value={formData.drug_ordered_five}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        drug_ordered_five: e.target.value,
                      })
                    }
                  />
                </div>
                <div className="pacu-cell">
                  <input
                    className="pacu-input"
                    name="dose_five"
                    value={formData.dose_five}
                    onChange={(e) =>
                      setFormData({ ...formData, dose_five: e.target.value })
                    }
                  />
                </div>
                <div className="pacu-cell">
                  <input
                    className="pacu-input"
                    name="route_five"
                    value={formData.route_five}
                    onChange={(e) =>
                      setFormData({ ...formData, route_five: e.target.value })
                    }
                  />
                </div>
                <div className="pacu-cell">
                  <input
                    className="pacu-input"
                    name="time_five_initial"
                    value={formData.time_five_initial}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        time_five_initial: e.target.value,
                      })
                    }
                  />
                </div>
                <div className="pacu-cell">
                  <input
                    className="pacu-input"
                    name="result_five"
                    value={formData.result_five}
                    onChange={(e) =>
                      setFormData({ ...formData, result_five: e.target.value })
                    }
                  />
                </div>
              </div>

              <label>
                **If other than normal anticipated result, give details under
                Nurses notes
              </label>
            </div>
          </div>
          <div className="form-divider"></div>

          <div className="label-indent">
            <label>
              <b>TIME</b>
            </label>
            <input
              type="text"
              className="call-input"
              name="notes_time_one"
              value={formData.notes_time_one}
              onChange={(e) =>
                setFormData({ ...formData, notes_time_one: e.target.value })
              }
            />
            <label>
              <b>NURSES NOTES</b>
            </label>
            <input
              type="text"
              className="call-input"
              name="notes_one"
              value={formData.notes_one}
              onChange={(e) =>
                setFormData({ ...formData, notes_one: e.target.value })
              }
            />
            <br />

            <label>
              <b>TIME</b>
            </label>
            <input
              type="text"
              className="call-input"
              name="notes_time_two"
              value={formData.notes_time_two}
              onChange={(e) =>
                setFormData({ ...formData, notes_time_two: e.target.value })
              }
            />
            <label>
              <b>NURSES NOTES</b>
            </label>
            <input
              type="text"
              className="call-input"
              name="notes_two"
              value={formData.notes_two}
              onChange={(e) =>
                setFormData({ ...formData, notes_two: e.target.value })
              }
            />
            <br />

            <label>
              <b>TIME</b>
            </label>
            <input
              type="text"
              className="call-input"
              name="notes_time_three"
              value={formData.notes_time_three}
              onChange={(e) =>
                setFormData({ ...formData, notes_time_three: e.target.value })
              }
            />
            <label>
              <b>NURSES NOTES</b>
            </label>
            <input
              type="text"
              className="call-input"
              name="notes_three"
              value={formData.notes_three}
              onChange={(e) =>
                setFormData({ ...formData, notes_three: e.target.value })
              }
            />
            <br />

            <label>
              <b>TIME</b>
            </label>
            <input
              type="text"
              className="call-input"
              name="notes_time_four"
              value={formData.notes_time_four}
              onChange={(e) =>
                setFormData({ ...formData, notes_time_four: e.target.value })
              }
            />
            <label>
              <b>NURSES NOTES</b>
            </label>
            <input
              type="text"
              className="call-input"
              name="notes_four"
              value={formData.notes_four}
              onChange={(e) =>
                setFormData({ ...formData, notes_four: e.target.value })
              }
            />
            <br />

            <label>
              <b>TIME</b>
            </label>
            <input
              type="text"
              className="call-input"
              name="notes_time_five"
              value={formData.notes_time_five}
              onChange={(e) =>
                setFormData({ ...formData, notes_time_five: e.target.value })
              }
            />
            <label>
              <b>NURSES NOTES</b>
            </label>
            <input
              type="text"
              className="call-input"
              name="notes_five"
              value={formData.notes_five}
              onChange={(e) =>
                setFormData({ ...formData, notes_five: e.target.value })
              }
            />
            <br />

            <label>
              <b>TIME</b>
            </label>
            <input
              type="text"
              className="call-input"
              name="notes_time_six"
              value={formData.notes_time_six}
              onChange={(e) =>
                setFormData({ ...formData, notes_time_six: e.target.value })
              }
            />
            <label>
              <b>NURSES NOTES</b>
            </label>
            <input
              type="text"
              className="call-input"
              name="notes_six"
              value={formData.notes_six}
              onChange={(e) =>
                setFormData({ ...formData, notes_six: e.target.value })
              }
            />
            <br />

            <label>
              <b>TIME</b>
            </label>
            <input
              type="text"
              className="call-input"
              name="notes_time_seven"
              value={formData.notes_time_seven}
              onChange={(e) =>
                setFormData({ ...formData, notes_time_seven: e.target.value })
              }
            />
            <label>
              <b>NURSES NOTES</b>
            </label>
            <input
              type="text"
              className="call-input"
              name="notes_seven"
              value={formData.notes_seven}
              onChange={(e) =>
                setFormData({ ...formData, notes_seven: e.target.value })
              }
            />
            <br />

            <label>
              <b>TIME</b>
            </label>
            <input
              type="text"
              className="call-input"
              name="notes_time_eight"
              value={formData.notes_time_eight}
              onChange={(e) =>
                setFormData({ ...formData, notes_time_eight: e.target.value })
              }
            />
            <label>
              <b>NURSES NOTES</b>
            </label>
            <input
              type="text"
              className="call-input"
              name="notes_eight"
              value={formData.notes_eight}
              onChange={(e) =>
                setFormData({ ...formData, notes_eight: e.target.value })
              }
            />
            <br />

            <label>
              <b>TIME</b>
            </label>
            <input
              type="text"
              className="call-input"
              name="notes_time_nine"
              value={formData.notes_time_nine}
              onChange={(e) =>
                setFormData({ ...formData, notes_time_nine: e.target.value })
              }
            />
            <label>
              <b>NURSES NOTES</b>
            </label>
            <input
              type="text"
              className="call-input"
              name="notes_nine"
              value={formData.notes_nine}
              onChange={(e) =>
                setFormData({ ...formData, notes_nine: e.target.value })
              }
            />
            <br />

            <label>
              <b>TIME</b>
            </label>
            <input
              type="text"
              className="call-input"
              name="notes_time_ten"
              value={formData.notes_time_ten}
              onChange={(e) =>
                setFormData({ ...formData, notes_time_ten: e.target.value })
              }
            />
            <label>
              <b>NURSES NOTES</b>
            </label>
            <input
              type="text"
              className="call-input"
              name="notes_ten"
              value={formData.notes_ten}
              onChange={(e) =>
                setFormData({ ...formData, notes_ten: e.target.value })
              }
            />
          </div>

          <div className="form-divider"></div>
          <div className="label-indent">
            <p className="text-center">
              <b>DISCHARGE</b>
            </p>
            <input
              type="checkbox"
              name="discharge_stable"
              checked={formData.discharge_stable}
              onChange={(e) =>
                setFormData({ ...formData, discharge_stable: e.target.checked })
              }
            />
            <label>Vitals Stable</label>
            <input
              type="checkbox"
              name="discharge_alert"
              checked={formData.discharge_alert}
              onChange={(e) =>
                setFormData({ ...formData, discharge_alert: e.target.checked })
              }
            />
            <label>Pt. Alert/Orient</label>
            <input
              type="checkbox"
              name="discharge_nodizzy"
              checked={formData.discharge_nodizzy}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  discharge_nodizzy: e.target.checked,
                })
              }
            />
            <label>No Dizzy/Active Vomitting</label>
            <input
              type="checkbox"
              name="discharge_voided"
              checked={formData.discharge_voided}
              onChange={(e) =>
                setFormData({ ...formData, discharge_voided: e.target.checked })
              }
            />
            <label>Patient Voided</label>
            <input
              type="checkbox"
              name="discharge_na"
              checked={formData.discharge_na}
              onChange={(e) =>
                setFormData({ ...formData, discharge_na: e.target.checked })
              }
            />
            <label>N/A</label>
            <input
              type="checkbox"
              name="discharge_nowound"
              checked={formData.discharge_nowound}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  discharge_nowound: e.target.checked,
                })
              }
            />
            <label>No wound compromise</label>
            <input
              type="checkbox"
              name="discharge_md"
              checked={formData.discharge_md}
              onChange={(e) =>
                setFormData({ ...formData, discharge_md: e.target.checked })
              }
            />
            <label>M.D. PostOp orders given</label>
            <input
              type="checkbox"
              name="discharge_able"
              checked={formData.discharge_able}
              onChange={(e) =>
                setFormData({ ...formData, discharge_able: e.target.checked })
              }
            />
            <label>Able to ambulate</label>
            <br />
            <label>ORTHOPEDIC patients:</label>
            <input
              type="checkbox"
              name="discharge_goodcapillary"
              checked={formData.discharge_goodcapillary}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  discharge_goodcapillary: e.target.checked,
                })
              }
            />
            <label>Good capillary refill/pinkish in color</label>
          </div>
          <div className="form-divider"></div>
          <div className="label-indent">
            <label>Patient discharged in satisfactory condition at:</label>
            <input
              type="text"
              className="call-input"
              name="discharge_time_one"
              value={formData.discharge_time_one}
              onChange={(e) =>
                setFormData({ ...formData, discharge_time_one: e.target.value })
              }
            />
            <label>AM/PM Ambulatory</label>
            <input
              type="checkbox"
              name="ambulatory"
              checked={formData.ambulatory}
              onChange={(e) =>
                setFormData({ ...formData, ambulatory: e.target.checked })
              }
            />
            <label>Wheelchair</label>
            <input
              type="checkbox"
              name="wheelchair"
              checked={formData.wheelchair}
              onChange={(e) =>
                setFormData({ ...formData, wheelchair: e.target.checked })
              }
            />
            <label>Patient released to:</label>
            <input
              type="checkbox"
              name="released_to"
              checked={formData.released_to}
              onChange={(e) =>
                setFormData({ ...formData, released_to: e.target.checked })
              }
            />
            <label>Home: Via:</label>
            <input
              type="text"
              className="call-input"
              name="home_via"
              value={formData.home_via}
              onChange={(e) =>
                setFormData({ ...formData, home_via: e.target.value })
              }
            />
            <br />
            <input
              type="checkbox"
              name="postop_confirmed"
              checked={formData.postop_confirmed}
              onChange={(e) =>
                setFormData({ ...formData, postop_confirmed: e.target.checked })
              }
            />
            <label>
              Post-op follow-up appt. confirmed &nbsp; &nbsp; Name of
              accompanying adult:
            </label>
            <input
              type="text"
              className="call-input"
              name="accompanying_adult"
              value={formData.accompanying_adult}
              onChange={(e) =>
                setFormData({ ...formData, accompanying_adult: e.target.value })
              }
            />
            <label>Relationship to patient:</label>
            <input
              type="text"
              className="call-input"
              name="relationship_to"
              value={formData.relationship_to}
              onChange={(e) =>
                setFormData({ ...formData, relationship_to: e.target.value })
              }
            />
            <br />
            <label>Valuables given to/received by:</label>
            <input
              type="text"
              className="call-input"
              name="valuables"
              value={formData.valuables}
              onChange={(e) =>
                setFormData({ ...formData, valuables: e.target.value })
              }
            />
            <label>Written instruction reviewed</label>
            <input
              type="checkbox"
              name="instructions_reviewed"
              checked={formData.instructions_reviewed}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  instructions_reviewed: e.target.checked,
                })
              }
            />
            <label>Received by:</label>
            <input
              type="text"
              className="call-input"
              name="received_by"
              value={formData.received_by}
              onChange={(e) =>
                setFormData({ ...formData, received_by: e.target.value })
              }
            />
            <br />
            <label>Comments:</label>
            <input
              type="text"
              className="call-input"
              name="comments"
              value={formData.comments}
              onChange={(e) =>
                setFormData({ ...formData, comments: e.target.value })
              }
            />
          </div>
          <div className="grievance-container mt-50">
            <div className="gc-item">
              {" "}
              <SignatureButton
                individualSignature={individualSignature}
                setIndividualSignature={setIndividualSignature}
              />
              <hr className="signature-line"></hr>
              <p>Discharge Nurse Signature</p>
            </div>
            <div className="gc-item mt-50">
              {" "}
              <label htmlFor="name" className="hidden">
                Reviewed By:
              </label>
              <input
                type="time"
                name="discharge_time"
                value={formData.discharge_time}
                onChange={(e) =>
                  setFormData({ ...formData, discharge_time: e.target.value })
                }
              />
              <p>Time</p>
            </div>
          </div>
          <div className="center mt-50">
            <button className="btn-primary hover-pointer" type="submit">
              Submit
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default PACUNursingNotes;
