import React, { useState, useEffect } from "react";
import { Icon } from "@iconify/react";
import "../Forms/styles.css";
import { useNavigate } from "react-router-dom";
import {
  getSurgicalSafetyChecklist,
  updateSurgicalSafetyChecklist,
} from "../../../utils/api";
import { useParams } from "react-router-dom";
import moment from "moment";

const SurgicalSafetyChecklist = () => {
  const navigate = useNavigate();
  const { form_id } = useParams();
  const [formData, setFormData] = useState({
    confirmed_identity: false,
    hp_present: false,
    preanesthesia_assessment: false,
    site_marked: false,
    machine_checked: false,
    reconciliation_reviewed: false,
    oximeter_functioning: false,
    allergy_yes: false,
    allergy_no: false,
    aspiration_risk_no: false,
    aspiration_risk_yes: false,
    aspiration_risk_special: false,
    test_results: false,
    antibiotic_prophylaxis: false,
    name_procedure: false,
    correct_counts: false,
    universal_protocol: false,
    speciments_identified: false,
    timeout_conducted: false,
    sterility_indicator: false,
    equipment_problems: false,
    images_labeled: false,
    site_marked_na: false,
    machine_checked_na: false,
    aspiration_risk_na: false,
    test_results_na: false,
    antibiotic_prophylaxis_na: false,
    images_labeled_na: false,
    speciments_identified_na: false,
    equipment_problems_na: false,
    name_two: "",
    birth_date: "",
    timeout_time: "",
    completed_by: "",
    patiend_name: "",
  });
  const [loading, setLoading] = useState(true);

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: checked,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("Form Data Before Submission:", formData);

    const payload = {
      ...formData,
      date: moment().format("MM/DD/YYYY"),
      time: moment().format("h:mm a"),
      status: "In Progress",
    };
    console.log("Payload sent to backend:", payload);

    console.log(payload);

    // Fetch 2: ≈ Disclosure of Ownerhsip Model
    updateSurgicalSafetyChecklist(form_id, payload)
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        console.log("complete");
        navigate(-1);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getSurgicalSafetyChecklist(form_id)
      .then((res) => res.json())
      .then((data) => {
        console.log(data);

        if (data.status !== "Not Started") {
          setFormData({
            confirmed_identity: data.confirmed_identity || false,
            hp_present: data.hp_present || false,
            preanesthesia_assessment: data.preanesthesia_assessment || false,
            site_marked: data.site_marked || false,
            machine_checked: data.machine_checked || false,
            reconciliation_reviewed: data.reconciliation_reviewed || false,
            oximeter_functioning: data.oximeter_functioning || false,
            allergy_yes: data.allergy_yes || false,
            allergy_no: data.allergy_no || false,
            aspiration_risk_no: data.aspiration_risk_no || false,
            aspiration_risk_yes: data.aspiration_risk_yes || false,
            aspiration_risk_special: data.aspiration_risk_special || false,
            test_results: data.test_results || false,
            antibiotic_prophylaxis: data.antibiotic_prophylaxis || false,
            name_procedure: data.name_procedure || false,
            correct_counts: data.correct_counts || false,
            universal_protocol: data.universal_protocol || false,
            speciments_identified: data.speciments_identified || false,
            timeout_conducted: data.timeout_conducted || false,
            sterility_indicator: data.sterility_indicator || false,
            equipment_problems: data.equipment_problems || false,
            images_labeled: data.images_labeled || false,
            site_marked_na: data.site_marked_na || false,
            machine_checked_na: data.machine_checked_na || false,
            aspiration_risk_na: data.aspiration_risk_na || false,
            test_results_na: data.test_results_na || false,
            antibiotic_prophylaxis_na: data.antibiotic_prophylaxis_na || false,
            images_labeled_na: data.images_labeled_na || false,
            speciments_identified_na: data.speciments_identified_na || false,
            equipment_problems_na: data.equipment_problems_na || false,
            name_two: data.name_two || "",
            birth_date: data.birth_date || "",
            timeout_time: data.timeout_time || "",
            completed_by: data.completed_by || "",
            patiend_name: data.patiend_name || "",
          });
        }

        setLoading(false);
      })
      .catch((err) => console.log(err));
  }, []);

  if (loading) return <p>Loading...</p>;
  return (
    <>
      <div
        className="arrow-container"
        onClick={() => navigate(-1)}
        style={{ cursor: "pointer" }}
      >
        <p className="back-arrow">
          <Icon icon="ic:twotone-arrow-back" className="icon" /> Back to Forms
        </p>
      </div>{" "}
      <div className="form-content">
        <form onSubmit={handleSubmit}>
          <h2 className="form-title">Surgical Safety Checklist</h2>
          <div className="ssc-input">
            <p>Patient Name</p>
            <input
              className="form-title-input"
              name="name_two"
              value={formData.name_two}
              onChange={(e) =>
                setFormData({ ...formData, name_two: e.target.value })
              }
            />
            <p>Birth Date</p>
            <input
              className="form-title-input"
              name="birth_date"
              value={formData.birth_date}
              onChange={(e) =>
                setFormData({ ...formData, birth_date: e.target.value })
              }
            />
          </div>
          <div className="nurse-grid-container">
            <div className="nurse-grid-item">
              {/* COLUMN 1 */}
              <h3 className="ssc-text">
                1 <Icon icon="bitcoin-icons:arrow-right-outline" />
              </h3>
              <div>
                <p className="ssc-text">
                  Before Inducion of Anesthesia (with at least nurse and
                  anesthetist){" "}
                </p>
              </div>
              <div className="checkbox-container">
                <input
                  type="checkbox"
                  name="confirmed_identity"
                  checked={formData.confirmed_identity}
                  onChange={handleCheckboxChange}
                />
                <label>
                  Has the patient confirmed his/her identity, Site, procedure,
                  and consent?
                </label>
              </div>
              <div className="checkbox-container">
                <input
                  type="checkbox"
                  name="hp_present"
                  checked={formData.hp_present}
                  onChange={handleCheckboxChange}
                />

                <label>Is H & P Present</label>
              </div>
              <div className="checkbox-container">
                <input
                  type="checkbox"
                  name="preanesthesia_assessment"
                  checked={formData.preanesthesia_assessment}
                  onChange={handleCheckboxChange}
                />
                <label>Pre-Anesthesia assessment Completed</label>
              </div>
              <div className="checkbox-container">
                <input
                  type="checkbox"
                  name="site_marked"
                  checked={formData.site_marked}
                  onChange={handleCheckboxChange}
                />
                <label>Is the site marked?</label>
              </div>
              <div className="checkbox-container">
                <input
                  type="checkbox"
                  name="site_marked_na"
                  checked={formData.site_marked_na}
                  onChange={handleCheckboxChange}
                />
                <label>NA</label>
              </div>
              <div className="checkbox-container">
                <input
                  type="checkbox"
                  name="machine_checked"
                  checked={formData.machine_checked}
                  onChange={handleCheckboxChange}
                />
                <label>Has the anesthesia machine been checked?</label>
              </div>
              <div className="checkbox-container">
                <input
                  type="checkbox"
                  name="machine_checked_na"
                  checked={formData.machine_checked_na}
                  onChange={handleCheckboxChange}
                />
                <label>NA if local</label>
              </div>
              <div className="checkbox-container">
                <input
                  type="checkbox"
                  name="reconciliation_reviewed"
                  checked={formData.reconciliation_reviewed}
                  onChange={handleCheckboxChange}
                />
                <label>Has Medication Reconciliation been reviewed?</label>
              </div>
              <div className="checkbox-container">
                <input
                  type="checkbox"
                  name="oximeter_functioning"
                  checked={formData.oximeter_functioning}
                  onChange={handleCheckboxChange}
                />
                <label>
                  Is the pulse oximeter on the patient and functioning?
                </label>
              </div>
              <div className="checkbox-container">
                <input
                  type="checkbox"
                  name="allergy_yes"
                  checked={formData.allergy_yes}
                  onChange={handleCheckboxChange}
                />
                Yes{" "}
                <input
                  type="checkbox"
                  name="allergy_no"
                  checked={formData.allergy_no}
                  onChange={handleCheckboxChange}
                />
                No
                <label>Does the patient have any known allergy?</label>
              </div>
              <p>Difficult airway or aspiration risk? </p>
              <div className="checkbox-container">
                <input
                  type="checkbox"
                  name="aspiration_risk_no"
                  checked={formData.aspiration_risk_no}
                  onChange={handleCheckboxChange}
                />
                <label>No</label>
              </div>
              <div className="checkbox-container">
                <input
                  type="checkbox"
                  name="aspiration_risk_yes"
                  checked={formData.aspiration_risk_yes}
                  onChange={handleCheckboxChange}
                />
                <label>Yes, and equipment/assistance available</label>
              </div>
              <div className="checkbox-container">
                <input
                  type="checkbox"
                  name="aspiration_risk_special"
                  checked={formData.aspiration_risk_special}
                  onChange={handleCheckboxChange}
                />
                <label>Special equipment devices, implants are present</label>
              </div>
              <div className="checkbox-container">
                <input
                  type="checkbox"
                  name="aspiration_risk_na"
                  checked={formData.aspiration_risk_na}
                  onChange={handleCheckboxChange}
                />
                <label>NA</label>
              </div>
              <div className="checkbox-container">
                <input
                  type="checkbox"
                  name="test_results"
                  checked={formData.test_results}
                  onChange={handleCheckboxChange}
                />
                <label>Diagnostic/radiologic test results present</label>
              </div>
              <div className="checkbox-container">
                <input
                  type="checkbox"
                  name="test_results_na"
                  checked={formData.test_results_na}
                  onChange={handleCheckboxChange}
                />
                <label>NA</label>
              </div>
              <div className="checkbox-container">
                <input type="checkbox" />
                <label>
                  Has the patient confirmed his/her identity, Site, procedure,
                  and consent?
                </label>
              </div>
            </div>
            {/* COLUMN 2 */}
            <div className="nurse-grid-item">
              <h3 className="ssc-text">
                2 <Icon icon="bitcoin-icons:arrow-right-outline" />
              </h3>

              <div className="ssc-text">
                Before Skin Incision (with nurse, anesthetist and surgeon)
              </div>
              <div className="checkbox-container">
                <input
                  type="checkbox"
                  name="antibiotic_prophylaxis"
                  checked={formData.antibiotic_prophylaxis}
                  onChange={handleCheckboxChange}
                />
                <label>
                  Has antibiotic prophylaxis have been given within guidelines
                  established by manufacturer?
                </label>
              </div>
              <div className="checkbox-container">
                <input
                  type="checkbox"
                  name="antibiotic_prophylaxis_na"
                  checked={formData.antibiotic_prophylaxis_na}
                  onChange={handleCheckboxChange}
                />
                <label>NA</label>
              </div>
              <div className="checkbox-container">
                <input
                  type="checkbox"
                  name="universal_protocol"
                  checked={formData.universal_protocol}
                  onChange={handleCheckboxChange}
                />
                <label>
                  Univeral Protocol/time out taken correct patient, site,
                  procedur and position:
                </label>
              </div>
              <div className="checkbox-container">
                <input
                  type="checkbox"
                  name="sterility_indicator"
                  checked={formData.sterility_indicator}
                  onChange={handleCheckboxChange}
                />
                <label>DSterility indicator results have been confirmed.</label>
              </div>
              <div className="checkbox-container">
                <input
                  type="checkbox"
                  name="images_labeled"
                  checked={formData.images_labeled}
                  onChange={handleCheckboxChange}
                />
                <label>Relevant Images labeled and displayed</label>
              </div>
              <div className="checkbox-container">
                <input
                  type="checkbox"
                  name="images_labeled_na"
                  checked={formData.images_labeled_na}
                  onChange={handleCheckboxChange}
                />
                <label>NA</label>
              </div>
              <div className="checkbox-container">
                <input
                  type="checkbox"
                  name="timeout_conducted"
                  checked={formData.timeout_conducted}
                  onChange={handleCheckboxChange}
                />
                <label>
                  Time-out conducted @{" "}
                  <input
                    type="time"
                    className="di-input"
                    name="timeout_time"
                    value={formData.timeout_time}
                    onChange={(e) =>
                      setFormData({ ...formData, timeout_time: e.target.value })
                    }
                  />
                  with 100% surgical team participation
                </label>
              </div>
            </div>
            {/* COLUMN 3 */}
            <div className="nurse-grid-item">
              <h3 className="ssc-text">3</h3>
              <div className="ssc-text">
                Before Patient leaves Operating Room (with nurse, anesthetist
                and surgeon)0{" "}
              </div>
              <p>Circulator Verbally confirm:</p>
              <div className="checkbox-container">
                <input
                  type="checkbox"
                  name="name_procedure"
                  checked={formData.name_procedure}
                  onChange={handleCheckboxChange}
                />
                <label>Name of procedure</label>
              </div>
              <div className="checkbox-container">
                <input
                  type="checkbox"
                  name="correct_counts"
                  checked={formData.correct_counts}
                  onChange={handleCheckboxChange}
                />
                <label>Correct Instrument, sponge and needle counts</label>
              </div>
              <div className="checkbox-container">
                <input
                  type="checkbox"
                  name="speciments_identified"
                  checked={formData.speciments_identified}
                  onChange={handleCheckboxChange}
                />
                <label>
                  Specimens identified and labeled in presence of patient
                </label>
              </div>
              <div className="checkbox-container">
                <input
                  type="checkbox"
                  name="specimens_identified_na"
                  checked={formData.specimens_identified_na}
                  onChange={handleCheckboxChange}
                />
                <label>NA</label>
              </div>
              <div className="checkbox-container">
                <input
                  type="checkbox"
                  name="equipment_problems"
                  checked={formData.equipment_problems}
                  onChange={handleCheckboxChange}
                />
                <label>Any equipment problems to be addressed?</label>
              </div>
              <div className="checkbox-container">
                <input
                  type="checkbox"
                  name="equipment_problems_na"
                  checked={formData.equipment_problems_na}
                  onChange={handleCheckboxChange}
                />
                <label>NA</label>
              </div>
            </div>
          </div>
          <div className="center mt-50">
            <button className="btn-primary hover-pointer" type="submit">
              Submit
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default SurgicalSafetyChecklist;
