import React, { useState } from "react";
import { Icon } from "@iconify/react";
import "../Forms/styles.css";
import { useNavigate } from "react-router-dom";
import SignatureButton from "../../../components/SignatureButton";

const AnesthesiaRecord = () => {
  const navigate = useNavigate();

  return (
    <>
      <div
        className="arrow-container"
        onClick={() => navigate(-1)}
        style={{ cursor: "pointer" }}
      >
        <p className="back-arrow">
          <Icon icon="ic:twotone-arrow-back" className="icon" /> Back to Forms
        </p>
      </div>{" "}
      <div className="form-content">
        <form>
          {" "}
          <h2 className="form-title">Anesthesia Record</h2>
          <div className="label-indent">
            <label className="ar-label">Page</label>
            <input type="text" className="di-input" />

            <label className="ar-label">of</label>
            <input type="text" className="di-input" />
          </div>
          <div className="label-indent">
            <label className="ar-label">Assistant Surgeon:</label>
            <input type="text" className="di-input" />

            <input type="checkbox" />
            <label className="ar-label">None</label>
          </div>
          <div className="form-divider"></div>
          <div className="label-indent">
            <label className="ar-label">
              <b>ANESTHESIA</b>
            </label>
            <br />
            <label className="ar-label">START</label>
            <input type="text" className="di-input" />

            <label className="ar-label">STOP</label>
            <input type="text" className="di-input" />
          </div>
          <div className="label-indent">
            <label className="ar-label">
              <b>PROCEDURE</b>
            </label>{" "}
            <br />
            <label className="ar-label">START</label>
            <input type="text" className="di-input" />
            <label className="ar-label">STOP</label>
            <input type="text" className="di-input" />
          </div>
          <div className="label-indent">
            {" "}
            <label className="ar-label">
              <b>OR No.</b>
            </label>
            <input type="text" className="di-input" />
          </div>
          <div className="form-divider"></div>
          <div className="label-indent">
            <label className="ar-label">
              <b>PATIENT INFORMATION</b>
            </label>
            <div>
              {" "}
              <label className="ar-label">Age</label>
              <input type="text" className="di-input" />
              <label className="ar-label">Sex</label>
              <input type="text" className="di-input" />
              <label className="ar-label">Height</label>
              <input type="text" className="di-input" />
              <label className="ar-label">Wt</label>
              <input type="text" className="di-input" />
            </div>
            <div>
              {" "}
              <label className="ar-label">Allergies</label>
              <input type="text" className="di-input" />
            </div>
            <div>
              {" "}
              <label className="ar-label">Medications</label>
              <input type="text" className="di-input" />
            </div>
            <div>
              {" "}
              <label className="ar-label">Positive Hx/Med. Problems</label>
              <input type="text" className="di-input" />
            </div>
            <div>
              {" "}
              <label className="ar-label">ASA</label>
              <input type="checkbox" />
              <label className="ar-label">1</label>
              <input type="checkbox" />
              <label className="ar-label">2</label>
              <input type="checkbox" />
              <label className="ar-label">3</label>
            </div>
            <div>
              {" "}
              <label className="ar-label">Pre-Op Meds</label>
              <input type="text" className="di-input" />
            </div>
          </div>
          <div className="form-divider"></div>
          <div className="label-indent">
            <label className="ar-label">
              <b>MONITORS & EQUIPMENT</b>
            </label>
            <br />
            <input type="checkbox" />
            <label className="ar-label">Stethoscope</label>
            <input type="checkbox" />
            <label className="ar-label">Precordial</label>
            <input type="checkbox" />
            <label className="ar-label">Esophogeal</label>
            <input type="checkbox" />
            <label className="ar-label">Other</label>
            <input type="checkbox" />
            <label className="ar-label">Noninvasive B/P</label>
            <br />
            <input type="checkbox" />
            <label className="ar-label">L</label>
            <input type="checkbox" />
            <label className="ar-label">R</label>
            <input type="checkbox" />
            <label className="ar-label">Continuous EKG</label>
            <input type="checkbox" />
            <label className="ar-label">Temp</label>
            <input type="text" className="di-input" />
            <input type="checkbox" />
            <label className="ar-label">Pulse Oximeter</label>
            <br /> <input type="checkbox" />
            <label className="ar-label">NG/OG Tube</label>
            <input type="checkbox" />
            <label className="ar-label">End Tidal CO2</label>
            <input type="checkbox" />
            <label className="ar-label">Fluid Warmer</label>
            <input type="checkbox" />
            <label className="ar-label">V Lead EKG</label>
            <input type="checkbox" />
            <label className="ar-label">Gas Analyzer</label>
            <br /> <input type="checkbox" />
            <label className="ar-label">Nerve Simulator</label>
            <input type="checkbox" />
            <label className="ar-label">Foley Catheter</label>
            <input type="checkbox" />
            <label className="ar-label">Airway Humidifier</label>
            <br /> <input type="checkbox" />
            <label className="ar-label">IV(s)</label>
            <input type="text" className="di-input" />
            <label className="ar-label">Site</label>
            <input type="text" className="di-input" />
            <br /> <label className="ar-label">Gauge</label>
            <input type="text" className="di-input" />
            <input type="checkbox" />
            <input type="text" className="di-input" />
          </div>
          <div className="form-divider"></div>
          <div className="label-indent">
            <label className="ar-label">
              <b>ANESTHETIC TECHNIQUE</b>
            </label>
            <div>
              <label className="ar-label">GENERAL:</label>
              <br />
              <input type="checkbox" />{" "}
              <label className="ar-label">Intravenous</label>
              <input type="checkbox" />
              <label className="ar-label">Inhalation</label>
              <input type="checkbox" />
              <label className="ar-label">Intramuscular</label>
              <input type="checkbox" />
              <label className="ar-label">Pre-Oxygenation</label>
              <input type="checkbox" />
              <label className="ar-label">LTA</label>
              <br /> <input type="checkbox" />
              <label className="ar-label">Rapid Sequence</label>
              <input type="checkbox" />
              <label className="ar-label">Rectal</label>
              <input type="checkbox" />
              <label className="ar-label">Cricoid Pressure</label>
            </div>
            <div>
              <label className="ar-label">REGIONAL:</label>
              <br />
              <input type="checkbox" />
              <label className="ar-label">Epidural</label>
              <input type="checkbox" />
              <label className="ar-label">Axillary</label>
              <input type="checkbox" />
              <label className="ar-label">Bier Block</label>
              <input type="checkbox" />
              <label className="ar-label">Ankle Block</label>
              <input type="checkbox" />
              <label className="ar-label">Postition</label>
              <input type="text" className="di-input" />
              <br /> <input type="checkbox" />
              <label className="ar-label">Local</label>
              <input type="text" className="di-input" />
              <input type="checkbox" />
              <label className="ar-label">Drug(s)</label>
              <input type="text" className="di-input" />
              <br />
              <input type="checkbox" />
              <label className="ar-label">Dose</label>
              <input type="text" className="di-input" />
              <input type="checkbox" />
              <label className="ar-label">Needle</label>
              <input type="text" className="di-input" />
              <br />
              <input type="checkbox" />
              <label className="ar-label">Site</label>
              <input type="text" className="di-input" />
              <label className="ar-label">Attempts x</label>
              <input type="text" className="di-input" />
              <br />
              <label className="ar-label">OTHER:</label>
              <input type="checkbox" />
              <label className="ar-label">MAC</label>
              <input type="text" className="di-input" />
            </div>
          </div>
          <div className="form-divider"></div>
          <div className="label-indent">
            <label className="ar-label">
              <b>AIRWAY MANAGEMENT</b>
            </label>
            <div>
              <label className="ar-label">INTUBATION:</label>
              <br /> <input type="checkbox" />
              <label className="ar-label">Oral</label>
              <input type="checkbox" />
              <label className="ar-label">Nasal</label>
              <input type="checkbox" />
              <label className="ar-label">Direct</label>
              <input type="checkbox" />
              <label className="ar-label">Stylet Used</label>
              <input type="checkbox" />
              <label className="ar-label">Blind</label>
              <input type="checkbox" />
              <label className="ar-label">Tube & Size</label>
              <input type="text" className="di-input" />
              <br /> <input type="checkbox" />
              <label className="ar-label">Blade</label>
              <input type="text" className="di-input" />
              <label className="ar-label">Laser</label>
              <input type="checkbox" />
              <label className="ar-label">Attempts x </label>
              <input type="text" className="di-input" />
              <br />
              <label className="ar-label">Secured at</label>
              <input type="text" className="di-input" />
              <label className="ar-label">cm</label>
              <input type="checkbox" />
              <label className="ar-label">Breath Sounds</label>
              <input type="text" className="di-input" />
              <br /> <input type="checkbox" />
              <label className="ar-label">Uncuffed, leaks at</label>
              <input type="text" className="di-input" />
              <label className="ar-label">cm H2O</label>
              <input type="checkbox" />
              <label className="ar-label">Cuffed</label>
              <input type="checkbox" />
              <label className="ar-label">Min. occlusive pressure</label>
            </div>
            <div>
              {" "}
              <label className="ar-label">AIRWAY:</label>
              <br />
              <input type="checkbox" />
              <label className="ar-label">Oral</label>
              <input type="checkbox" />
              <label className="ar-label">Nasal</label>
              <input type="checkbox" />
            </div>

            <div>
              {" "}
              <label className="ar-label">CIRCUIT:</label>
              <br />
              <input type="checkbox" />
              <label className="ar-label">Circle</label>
              <input type="checkbox" />
              <label className="ar-label">NRB</label>
              <input type="checkbox" />
              <label className="ar-label">Difficult See Remarks</label>
            </div>
            <div>
              {" "}
              <input type="checkbox" />
              <label className="ar-label">Mask Case</label>
              <input type="checkbox" />
              <label className="ar-label">Via LMA</label>
              <input type="checkbox" />
              <label className="ar-label">Nasal Cannula</label>
              <input type="checkbox" />
              <label className="ar-label">Simple O2 Mask</label>
            </div>
          </div>
          <div className="form-divider"></div>
          <div className="label-indent">
            <label className="ar-label">
              <b>PRE-PROCEDURE</b>
            </label>
            <br />
            <input type="checkbox" />
            <label className="ar-label">Pt Identified</label>
            <input type="checkbox" />
            <label className="ar-label">Chart Reviewed</label>
            <input type="checkbox" />
            <label className="ar-label">Consent</label>
            <input type="checkbox" />
            <label className="ar-label">NPO since</label>
            <input type="checkbox" />
            <label className="ar-label">Signed</label>
            <input type="text" className="di-input" />

            <label className="ar-label">Pre-Anesthesia State:</label>
            <input type="checkbox" />
            <label className="ar-label">Calm</label>
            <input type="checkbox" />
            <label className="ar-label">Awake</label>
            <input type="checkbox" />
            <label className="ar-label">Asleep</label>
            <input type="checkbox" />
            <label className="ar-label">Apprehensive</label>
            <input type="checkbox" />
            <label className="ar-label">Confused</label>
            <input type="checkbox" />
            <label className="ar-label">Uncooperative</label>
            <input type="checkbox" />
            <label className="ar-label">Unresponsive</label>
          </div>
          <div className="form-divider"></div>
          <div className="label-indent">
            <label className="ar-label">
              <b>PATIENT SAFETLY</b>
            </label>
            <br />
            <input type="checkbox" />
            <label className="ar-label">Anesthesia machine checked</label>
            <input type="checkbox" />
            <label className="ar-label">Pressure points checked & padded</label>
            <input type="checkbox" />
            <label className="ar-label">Safety Belt on</label>
            <br /> <input type="checkbox" />
            <label className="ar-label">Axillary roll</label>
            <input type="checkbox" />
            <label className="ar-label">Arms tucked</label>
            <input type="checkbox" />
            <label className="ar-label">Restraints</label>
            <br />
            <label className="ar-label">EYE CARE:</label>
            <br />
            <input type="checkbox" />
            <label className="ar-label">Ointment</label>
            <input type="checkbox" />
            <label className="ar-label">Saline</label>
            <input type="checkbox" />
            <label className="ar-label">Taped</label>
            <input type="checkbox" />
            <label className="ar-label">Goggles</label>
            <input type="checkbox" />
            <label className="ar-label">Pads</label>
          </div>
          <div className="form-divider"></div>
          <div className="ar-flex">
            <div>
              <label className="ar-label">
                <b>AGENTS</b>
              </label>
            </div>
            <div>
              <label className="ar-label">
                <b>TOTALS</b>
              </label>
            </div>
          </div>
          <div>
            <div className="ar-grid-container">
              <div className="ar-grid-item">Oxygen (L/min)</div>{" "}
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>{" "}
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>{" "}
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>{" "}
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>{" "}
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>{" "}
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>{" "}
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>{" "}
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>{" "}
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>{" "}
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>{" "}
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>{" "}
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>
              <div className="ar-grid-item">N2O Air (L/min)</div>{" "}
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>{" "}
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>{" "}
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>{" "}
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>{" "}
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>{" "}
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>{" "}
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>{" "}
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>{" "}
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>{" "}
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>{" "}
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>{" "}
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>
              <div className="ar-grid-item">(%)</div>{" "}
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>{" "}
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>{" "}
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>{" "}
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>{" "}
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>{" "}
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>{" "}
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>{" "}
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>{" "}
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>{" "}
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>{" "}
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>{" "}
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>
              <div className="ar-grid-item">Propofol(mg)</div>{" "}
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>{" "}
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>{" "}
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>{" "}
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>{" "}
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>{" "}
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>{" "}
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>{" "}
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>{" "}
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>{" "}
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>{" "}
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>{" "}
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>
              <div className="ar-grid-item">Versed(mg)</div>{" "}
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>{" "}
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>{" "}
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>{" "}
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>{" "}
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>{" "}
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>{" "}
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>{" "}
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>{" "}
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>{" "}
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>{" "}
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>{" "}
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>
              <div className="ar-grid-item">Fentanyl(mcg)</div>{" "}
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>{" "}
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>{" "}
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>{" "}
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>{" "}
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>{" "}
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>{" "}
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>{" "}
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>{" "}
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>{" "}
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>{" "}
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>{" "}
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>
              <div className="ar-grid-item">Lidocaine(mg)</div>{" "}
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>{" "}
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>{" "}
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>{" "}
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>{" "}
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>{" "}
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>{" "}
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>{" "}
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>{" "}
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>{" "}
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>{" "}
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>{" "}
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>
              <div className="ar-grid-item">Ancef(g)</div>{" "}
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>{" "}
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>{" "}
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>{" "}
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>{" "}
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>{" "}
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>{" "}
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>{" "}
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>{" "}
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>{" "}
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>{" "}
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>{" "}
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>
              <div className="ar-grid-item"></div>{" "}
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>{" "}
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>{" "}
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>{" "}
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>{" "}
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>{" "}
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>{" "}
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>{" "}
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>{" "}
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>{" "}
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>{" "}
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>{" "}
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>
              <div className="ar-grid-item"></div>{" "}
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>{" "}
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>{" "}
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>{" "}
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>{" "}
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>{" "}
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>{" "}
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>{" "}
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>{" "}
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>{" "}
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>{" "}
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>{" "}
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>
              <div className="ar-grid-item"></div>{" "}
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>{" "}
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>{" "}
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>{" "}
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>{" "}
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>{" "}
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>{" "}
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>{" "}
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>{" "}
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>{" "}
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>{" "}
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>{" "}
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>
              <div className="ar-grid-item"></div>{" "}
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>{" "}
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>{" "}
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>{" "}
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>{" "}
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>{" "}
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>{" "}
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>{" "}
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>{" "}
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>{" "}
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>{" "}
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>{" "}
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>
              <div className="ar-grid-item"></div>{" "}
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>{" "}
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>{" "}
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>{" "}
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>{" "}
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>{" "}
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>{" "}
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>{" "}
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>{" "}
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>{" "}
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>{" "}
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>{" "}
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>
              <div className="ar-grid-item"></div>{" "}
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>{" "}
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>{" "}
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>{" "}
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>{" "}
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>{" "}
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>{" "}
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>{" "}
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>{" "}
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>{" "}
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>{" "}
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>{" "}
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>
              <div className="ar-grid-item">
                <b>MONITORS</b>
              </div>{" "}
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>{" "}
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>{" "}
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>{" "}
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>{" "}
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>{" "}
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>{" "}
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>{" "}
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>{" "}
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>{" "}
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>{" "}
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>{" "}
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>
              <div className="ar-grid-item">EKG</div>{" "}
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>{" "}
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>{" "}
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>{" "}
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>{" "}
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>{" "}
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>{" "}
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>{" "}
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>{" "}
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>{" "}
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>{" "}
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>{" "}
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>
              <div className="ar-grid-item">O2 Saturation</div>{" "}
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>{" "}
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>{" "}
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>{" "}
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>{" "}
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>{" "}
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>{" "}
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>{" "}
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>{" "}
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>{" "}
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>{" "}
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>{" "}
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>
              <div className="ar-grid-item">End Tidal CO2</div>{" "}
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>{" "}
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>{" "}
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>{" "}
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>{" "}
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>{" "}
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>{" "}
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>{" "}
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>{" "}
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>{" "}
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>{" "}
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>{" "}
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>
              <div className="ar-grid-item">Temp: °C °F</div>{" "}
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>{" "}
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>{" "}
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>{" "}
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>{" "}
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>{" "}
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>{" "}
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>{" "}
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>{" "}
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>{" "}
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>{" "}
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>{" "}
              <div className="ar-grid-item">
                {" "}
                <input type="text" className="ar-input" />
              </div>
            </div>
          </div>
          <div className="form-divider"></div>
          <div className="label-indent">
            <label className="ar-label">
              <b>VITAL SIGNS</b>
            </label>
            <div className="label-indent">
              <label className="ar-label">BASELINE VALUES</label>
              <br /> <label className="ar-label">B/P</label>
              <input type="text" className="di-input" />
              <label className="ar-label">P</label>
              <input type="text" className="di-input" />
              <br /> <label className="ar-label">R</label>
              <input type="text" className="di-input" />
              <label className="ar-label">T</label>
              <input type="text" className="di-input" />
            </div>
          </div>
          <div className="form-divider"></div>
          <div className="ar-flex-2">
            <label className="ar-label">200-</label>
            <textarea />
          </div>
          <div className="ar-flex-2">
            <label className="ar-label">180-</label>
            <textarea />
          </div>
          <div className="ar-flex-2">
            <label className="ar-label">160-</label>
            <textarea />
          </div>
          <div className="ar-flex-2">
            <label className="ar-label">140-</label>
            <textarea />
          </div>
          <div className="ar-flex-2">
            <label className="ar-label">120-</label>
            <textarea />
          </div>
          <div className="ar-flex-2">
            <label className="ar-label">100-</label>
            <textarea />
          </div>
          <div className="ar-flex-2">
            <label className="ar-label">80-</label>
            <textarea />
          </div>
          <div className="ar-flex-2">
            <label className="ar-label">60-</label>
            <textarea />
          </div>
          <div className="ar-flex-2">
            <label className="ar-label">40-</label>
            <textarea />
          </div>
          <div className="ar-flex-2">
            <label className="ar-label">20-</label>
            <textarea />
          </div>
          <div className="form-divider"></div>
          <div className="ar-flex">
            <div>
              <label className="ar-label">
                <b>FLUIDS</b>
              </label>
            </div>
            <div>
              <input type="text" className="di-input" />

              <label className="ar-label">
                <b> FLUID TOTALS</b>
              </label>
            </div>
          </div>
          <div className="ar-grid-container">
            <div className="ar-grid-item">Patient Position</div>{" "}
            <div className="ar-grid-item">
              {" "}
              <input type="text" className="ar-input" />
            </div>{" "}
            <div className="ar-grid-item">
              {" "}
              <input type="text" className="ar-input" />
            </div>{" "}
            <div className="ar-grid-item">
              {" "}
              <input type="text" className="ar-input" />
            </div>
            <div className="ar-grid-item">
              {" "}
              <input type="text" className="ar-input" />
            </div>{" "}
            <div className="ar-grid-item">
              {" "}
              <input type="text" className="ar-input" />
            </div>{" "}
            <div className="ar-grid-item">
              {" "}
              <input type="text" className="ar-input" />
            </div>{" "}
            <div className="ar-grid-item">
              {" "}
              <input type="text" className="ar-input" />
            </div>
            <div className="ar-grid-item">
              {" "}
              <input type="text" className="ar-input" />
            </div>{" "}
            <div className="ar-grid-item">
              {" "}
              <input type="text" className="ar-input" />
            </div>{" "}
            <div className="ar-grid-item">
              {" "}
              <input type="text" className="ar-input" />
            </div>{" "}
            <div className="ar-grid-item">
              {" "}
              <input type="text" className="ar-input" />
            </div>
            <div className="ar-grid-item">
              {" "}
              <input type="text" className="ar-input" />
            </div>{" "}
            <div className="ar-grid-item">
              {" "}
              <input type="text" className="ar-input" />
            </div>{" "}
            <div className="ar-grid-item">
              {" "}
              <input type="text" className="ar-input" />
            </div>{" "}
            <div className="ar-grid-item">
              {" "}
              <input type="text" className="ar-input" />
            </div>
            <div className="ar-grid-item">Urine</div>{" "}
            <div className="ar-grid-item">
              {" "}
              <input type="text" className="ar-input" />
            </div>{" "}
            <div className="ar-grid-item">
              {" "}
              <input type="text" className="ar-input" />
            </div>{" "}
            <div className="ar-grid-item">
              {" "}
              <input type="text" className="ar-input" />
            </div>
            <div className="ar-grid-item">
              {" "}
              <input type="text" className="ar-input" />
            </div>{" "}
            <div className="ar-grid-item">
              {" "}
              <input type="text" className="ar-input" />
            </div>{" "}
            <div className="ar-grid-item">
              {" "}
              <input type="text" className="ar-input" />
            </div>{" "}
            <div className="ar-grid-item">
              {" "}
              <input type="text" className="ar-input" />
            </div>
            <div className="ar-grid-item">
              {" "}
              <input type="text" className="ar-input" />
            </div>{" "}
            <div className="ar-grid-item">
              {" "}
              <input type="text" className="ar-input" />
            </div>{" "}
            <div className="ar-grid-item">
              {" "}
              <input type="text" className="ar-input" />
            </div>{" "}
            <div className="ar-grid-item">
              {" "}
              <input type="text" className="ar-input" />
            </div>
            <div className="ar-grid-item">
              {" "}
              <input type="text" className="ar-input" />
            </div>{" "}
            <div className="ar-grid-item">
              {" "}
              <input type="text" className="ar-input" />
            </div>{" "}
            <div className="ar-grid-item">
              {" "}
              <input type="text" className="ar-input" />
            </div>{" "}
            <div className="ar-grid-item">
              {" "}
              <input type="text" className="ar-input" />
            </div>
            <div className="ar-grid-item">EBL</div>{" "}
            <div className="ar-grid-item">
              {" "}
              <input type="text" className="ar-input" />
            </div>{" "}
            <div className="ar-grid-item">
              {" "}
              <input type="text" className="ar-input" />
            </div>{" "}
            <div className="ar-grid-item">
              {" "}
              <input type="text" className="ar-input" />
            </div>
            <div className="ar-grid-item">
              {" "}
              <input type="text" className="ar-input" />
            </div>{" "}
            <div className="ar-grid-item">
              {" "}
              <input type="text" className="ar-input" />
            </div>{" "}
            <div className="ar-grid-item">
              {" "}
              <input type="text" className="ar-input" />
            </div>{" "}
            <div className="ar-grid-item">
              {" "}
              <input type="text" className="ar-input" />
            </div>
            <div className="ar-grid-item">
              {" "}
              <input type="text" className="ar-input" />
            </div>{" "}
            <div className="ar-grid-item">
              {" "}
              <input type="text" className="ar-input" />
            </div>{" "}
            <div className="ar-grid-item">
              {" "}
              <input type="text" className="ar-input" />
            </div>{" "}
            <div className="ar-grid-item">
              {" "}
              <input type="text" className="ar-input" />
            </div>
            <div className="ar-grid-item">
              {" "}
              <input type="text" className="ar-input" />
            </div>{" "}
            <div className="ar-grid-item">
              {" "}
              <input type="text" className="ar-input" />
            </div>{" "}
            <div className="ar-grid-item">
              {" "}
              <input type="text" className="ar-input" />
            </div>{" "}
            <div className="ar-grid-item">
              {" "}
              <input type="text" className="ar-input" />
            </div>
            <div className="ar-grid-item">IV</div>{" "}
            <div className="ar-grid-item">
              {" "}
              <input type="text" className="ar-input" />
            </div>{" "}
            <div className="ar-grid-item">
              {" "}
              <input type="text" className="ar-input" />
            </div>{" "}
            <div className="ar-grid-item">
              {" "}
              <input type="text" className="ar-input" />
            </div>
            <div className="ar-grid-item">
              {" "}
              <input type="text" className="ar-input" />
            </div>{" "}
            <div className="ar-grid-item">
              {" "}
              <input type="text" className="ar-input" />
            </div>{" "}
            <div className="ar-grid-item">
              {" "}
              <input type="text" className="ar-input" />
            </div>{" "}
            <div className="ar-grid-item">
              {" "}
              <input type="text" className="ar-input" />
            </div>
            <div className="ar-grid-item">
              {" "}
              <input type="text" className="ar-input" />
            </div>{" "}
            <div className="ar-grid-item">
              {" "}
              <input type="text" className="ar-input" />
            </div>{" "}
            <div className="ar-grid-item">
              {" "}
              <input type="text" className="ar-input" />
            </div>{" "}
            <div className="ar-grid-item">
              {" "}
              <input type="text" className="ar-input" />
            </div>
            <div className="ar-grid-item">
              {" "}
              <input type="text" className="ar-input" />
            </div>{" "}
            <div className="ar-grid-item">
              {" "}
              <input type="text" className="ar-input" />
            </div>{" "}
            <div className="ar-grid-item">
              {" "}
              <input type="text" className="ar-input" />
            </div>{" "}
            <div className="ar-grid-item">
              {" "}
              <input type="text" className="ar-input" />
            </div>
            <div className="ar-grid-item"></div>{" "}
            <div className="ar-grid-item">
              {" "}
              <input type="text" className="ar-input" />
            </div>{" "}
            <div className="ar-grid-item">
              {" "}
              <input type="text" className="ar-input" />
            </div>{" "}
            <div className="ar-grid-item">
              {" "}
              <input type="text" className="ar-input" />
            </div>
            <div className="ar-grid-item">
              {" "}
              <input type="text" className="ar-input" />
            </div>{" "}
            <div className="ar-grid-item">
              {" "}
              <input type="text" className="ar-input" />
            </div>{" "}
            <div className="ar-grid-item">
              {" "}
              <input type="text" className="ar-input" />
            </div>{" "}
            <div className="ar-grid-item">
              {" "}
              <input type="text" className="ar-input" />
            </div>
            <div className="ar-grid-item">
              {" "}
              <input type="text" className="ar-input" />
            </div>{" "}
            <div className="ar-grid-item">
              {" "}
              <input type="text" className="ar-input" />
            </div>{" "}
            <div className="ar-grid-item">
              {" "}
              <input type="text" className="ar-input" />
            </div>{" "}
            <div className="ar-grid-item">
              {" "}
              <input type="text" className="ar-input" />
            </div>
            <div className="ar-grid-item">
              {" "}
              <input type="text" className="ar-input" />
            </div>{" "}
            <div className="ar-grid-item">
              {" "}
              <input type="text" className="ar-input" />
            </div>{" "}
            <div className="ar-grid-item">
              {" "}
              <input type="text" className="ar-input" />
            </div>{" "}
            <div className="ar-grid-item">
              {" "}
              <input type="text" className="ar-input" />
            </div>
          </div>
          <div className="form-divider"></div>
          <div>
            <label className="ar-label">Intra-Operative Remarks</label>
            <textarea />
          </div>
          <div>
            <label className="ar-label">Handoff to PACU RN BP:</label>
            <input type="text" className="di-input" />

            <label className="ar-label">O2:</label>
            <input type="text" className="di-input" />

            <label className="ar-label">Temp:</label>
            <input type="text" className="di-record" />
            <label className="ar-label">Time:</label>
          </div>
          <div className="grievance-container">
            <div className="gc-item mt-50">
              {" "}
              <label htmlFor="name" className="hidden">
                Anesthesia Provider Print Name
              </label>
              <input
                type="text"
                id="name"
                name="name"
                className="bottom-border-input"
              />
              <p> Anesthesia Provider Print Name</p>
            </div>
            <div className="gc-item mt-50">
              {" "}
              <label htmlFor="name" className="hidden">
                Date{" "}
              </label>
              <input
                type="date"
                id="name"
                name="date_3"
                className="bottom-border-input"
                
              />
              <p>Date</p>
            </div>
            <div className="gc-item mt-50">
              {" "}
              <label htmlFor="name" className="hidden">
                Time
              </label>
              <input
                type="time"
                id="time"
                name="time_2"
                className="bottom-border-input"
                
              />
              <p>Time</p>
            </div>
            <div className="gc-item mt-50">
              {" "}
              <SignatureButton />
              <hr className="signature-line"></hr>
              <p>Anesthesia Provider Signature</p>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default AnesthesiaRecord;
