// ------------- User Fetch Requests -------------
// Signup Page
export const createUser = (userData) => {
  return fetch("/api/users", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(userData),
  });
};

// Login Page
export const loginUser = (userData) => {
  return fetch("/api/users/login", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(userData),
  });
};

// User Profile Pages
export const udpateUser = (userData, user_id) => {
  return fetch(`/api/users/${user_id}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(userData),
  });
};
export const userRole = (user_id) => {
  return fetch(`/api/users/${user_id}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};
export const getUserProfile = (user_id) => {
  return fetch(`/api/users/${user_id}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const updateTokenImage = (user_id) => {
  return fetch(`/api/users/newImage/${user_id}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
  });
};
export const addFile = (formData) => {
  return fetch("/api/uploads/create", {
    method: "POST",
    body: formData,
  });
};
// Admin Pages
export const getUsersbyEmail = (searchTerm) => {
  return fetch(`/api/users/email/${searchTerm}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const getUsersbyName = (searchTerm) => {
  return fetch(`/api/users/name/${searchTerm}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const getPendingUsers = () => {
  return fetch(`/api/users/pending`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const getRecentUsers = () => {
  return fetch(`/api/users/recent`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};
//password

export const forgotPassword = async (payload) => {
  return fetch("/api/users/forgot-password", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  });
};

export const updatePassword = ({ email, otp, newPassword }) => {
  console.log({ email, otp, newPassword });
  return fetch("/api/users/update-password", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      email,
      otp,
      newPassword,
    }),
  });
};
export const validateOTP = async (payload) => {
  return fetch("/api/users/check-otp", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  });
};
// Patient Search Page
export const getRecentPatients = () => {
  return fetch(`/api/patients`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};
export const getRecentSurgeries = () => {
  return fetch(`/api/surgery`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};
export const getUsersbyDOS = (searchTerm) => {
  return fetch(`/api/patients/dos?dos=${encodeURIComponent(searchTerm)}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};
export const getPatientsbyDOB = (searchTerm) => {
  return fetch(`/api/patients/dob?dob=${encodeURIComponent(searchTerm)}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};
export const searchPatients = (searchType, searchTerm) => {
  return fetch(
    `/api/patients/search?searchType=${searchType}&searchTerm=${encodeURIComponent(
      searchTerm
    )}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
};
// Patient Folder
export const getPatientById = (user_id) => {
  return fetch(`/api/patients/${user_id}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};
// New Surgery Page
export const getSurgerybyId = (surgery_id) => {
  return fetch(`/api/surgery/${surgery_id}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};
// Patient Info
export const getDoctors = () => {
  return fetch(`/api/users/doctor`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};
export const postPatientInfo = (newPatient) => {
  return fetch("/api/patients", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(newPatient),
  });
};
export const createPatientInfoRecord = (payload) => {
  return fetch("/api/patient-info", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  });
};
export const createSurgeryInformation = (payload) => {
  return fetch("/api/surgery/surgery-information", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  });
};
// Patient Information
export const getPatientInformation = (id) => {
  return fetch(`/api/patient-info/${id}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};
export const updateMedHistory = (id, payload) => {
  return fetch(`/api/patient-info/med-history/${id}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  });
};

export const addMedicine = (payload) => {
  return fetch(`/api/patient-info/medications`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  });
};
export const addMedAllergy = (payload) => {
  return fetch(`/api/patient-info/med-allergies`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  });
};
export const addFoodAllergy = (payload) => {
  return fetch(`/api/patient-info/food-allergies`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  });
};
export const addPriorSurgery = (payload) => {
  return fetch(`/api/patient-info/prior-surgeries`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  });
};
export const addPharmacy = (payload) => {
  return fetch(`/api/patient-info/pharmacy`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  });
};
export const updateHistory = (id, payload) => {
  return fetch(`/api/patient-info/med-history/${id}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  });
};
export const udpateMedications = (id, payload) => {
  return fetch(`/api/patient-info/medications/${id}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  });
};
export const deleteMedications = (id) => {
  return fetch(`/api/patient-info/medications/${id}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
    },
  });
};
export const udpateMedAllergy = (id, payload) => {
  return fetch(`/api/patient-info/med-allergies/${id}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  });
};
export const deleteMedAllergy = (id) => {
  return fetch(`/api/patient-info/med-allergies/${id}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
    },
  });
};
export const udpatePharmacy = (id, payload) => {
  return fetch(`/api/patient-info/pharmacy/${id}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  });
};
export const deletePharmacy = (id) => {
  return fetch(`/api/patient-info/pharmacy/${id}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
    },
  });
};
export const udpateFoodAllergies = (id, payload) => {
  return fetch(`/api/patient-info/food-allergies/${id}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  });
};
export const deleteFoodAllergies = (id) => {
  return fetch(`/api/patient-info/food-allergies/${id}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
    },
  });
};
export const udpateSurgery = (id, payload) => {
  return fetch(`/api/patient-info/prior-surgeries/${id}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  });
};
export const deleteSurgery = (id) => {
  return fetch(`/api/patient-info/prior-surgeries/${id}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
    },
  });
};
export const createBilling = (payload) => {
  return fetch(`/api/patient-info/billing`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },

    body: JSON.stringify(payload),
  });
};
export const createInsurance = (payload) => {
  return fetch(`/api/patient-info/insurance`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  });
};
export const createCommercial = (payload) => {
  return fetch(`/api/patient-info/commercial-insurance`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  });
};
export const createComp = (payload) => {
  return fetch(`/api/patient-info/comp`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  });
};
export const createOop = (payload) => {
  return fetch(`/api/patient-info/oop`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  });
};
export const createPip = (payload) => {
  return fetch(`/api/patient-info/pip`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  });
};
export const udpateInsurance = (id, payload) => {
  return fetch(`/api/patient-info/insurance/${id}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  });
};
export const udpateOop = (id, payload) => {
  return fetch(`/api/patient-info/oop/${id}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  });
};
export const udpateCommercial = (id, payload) => {
  return fetch(`/api/patient-info/commercial-insurance/${id}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  });
};
export const udpateComp = (id, payload) => {
  return fetch(`/api/patient-info/comp/${id}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  });
};
export const udpatePip = (id, payload) => {
  return fetch(`/api/patient-info/pip/${id}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  });
};
export const deleteBilling = (id) => {
  return fetch(`/api/patient-info/billing/${id}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
    },
  });
};
export const getBilling = (id) => {
  return fetch(`/api/patient-info/billing/${id}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};
// New Surgery Page
export const createSurgery = (payload) => {
  return fetch(`/api/surgery`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  });
};
// Nursing Qustionnaire
export const createCondition = (payload) => {
  return fetch(`/api/patient-info/condition`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  });
};
export const updateCondition = (condition_id, payload) => {
  return fetch(`/api/patient-info/condition/${condition_id}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  });
};
export const updatePersonalInfo = (payload) => {
  return fetch(`/api/nursing-quest/`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  });
};
export const getSurgeryInfoById = (surgeryInfo_id) => {
  return fetch(`/api/surgery/surgery-information/${surgeryInfo_id}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};
export const updateSurgeryInfoById = (surgeryInfo_id, payload) => {
  return fetch(`/api/surgery/surgery-information/${surgeryInfo_id}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  });
};
export const getNQInformation = (id) => {
  return fetch(`/api/nursing-quest/${id}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};
export const updateNQMedications = (id, payload) => {
  return fetch(`/api/nursing-quest/medications/${id}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  });
};
export const deleteNQMedications = (id) => {
  return fetch(`/api/nursing-quest/medications/${id}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
    },
  });
};
export const addNQFoodAllergies = (payload) => {
  return fetch(`/api/nursing-quest/food-allergies`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  });
};
export const addNQMedicine = (payload) => {
  return fetch(`/api/nursing-quest/medications`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  });
};

export const addNQMedHistory = (payload) => {
  return fetch(`/api/nursing-quest/med-history`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  });
};
export const addNQPriorSurgeries = (payload) => {
  return fetch(`/api/nursing-quest/prior-surgeries`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  });
};

export const addNQMedAllergies = (payload) => {
  return fetch(`/api/nursing-quest/med-allergies`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  });
};

export const udpateNQFoodAllergies = (id, payload) => {
  return fetch(`/api/nursing-quest/food-allergies/${id}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  });
};
export const deleteNQFoodAllergies = (id) => {
  return fetch(`/api/nursing-quest/food-allergies/${id}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const udpateNQMedAllergy = (id, payload) => {
  return fetch(`/api/nursing-quest/med-allergies/${id}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  });
};
export const deleteNQMedAllergy = (id) => {
  return fetch(`/api/nursing-quest/med-allergies/${id}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
    },
  });
};
export const udpateNQSurgery = (id, payload) => {
  return fetch(`/api/nursing-quest/prior-surgeries/${id}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  });
};
export const deleteNQSurgery = (id) => {
  return fetch(`/api/nursing-quest/prior-surgeries/${id}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
    },
  });
};
export const udpateNurseQues = (id, payload) => {
  return fetch(`/api/nursing-quest/${id}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  });
};
export const updateNQHistory = (id, payload) => {
  return fetch(`/api/nursing-quest/${id}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  });
};
export const updateNQMedHistory = (id, payload) => {
  return fetch(`/api/nursing-quest/med-history/${id}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  });
};

export const getNQMedHistory = (id) => {
  return fetch(`/api/nursing-quest/med-history/${id}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};
export const udpatePatient = (id, payload) => {
  return fetch(`/api/patients/${id}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  });
};
// Patient Forms View
export const getPatientFormsById = (patient_id) => {
  return fetch(`/api/patients/patient-forms/${patient_id}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};
export const getDisclosureofOwnership = (form_id) => {
  return fetch(`/api/surgery/disclosure-ownership/${form_id}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};
export const updateDisclosureOfOwnership = (form_id, payload) => {
  return fetch(`/api/surgery/disclosure-ownership/${form_id}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  });
};
export const getSurgeryFormsById = (surgery_id) => {
  return fetch(`/api/surgery/getForms/${surgery_id}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};
export const createPatientSatisfaction = (payload) => {
  return fetch(`/api/surgery/patient-satisfaction`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  });
};
export const getPatientSatisfaction = (form_id) => {
  return fetch(`/api/surgery/patient-satisfaction/${form_id}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};
export const updatePatientSatisfaction = (form_id, payload) => {
  return fetch(`/api/surgery/patient-satisfaction/${form_id}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  });
};
export const createAssignmentOfBenefits = (payload) => {
  return fetch(`/api/surgery/assignemnt-benefits`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  });
};
export const getAssignmentOfBenefits = (form_id) => {
  return fetch(`/api/surgery/assignemnt-benefits/${form_id}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};
export const updateAssignmentOfBenefits = (form_id, payload) => {
  return fetch(`/api/surgery/assignemnt-benefits/${form_id}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  });
};
export const createDischargeInstructions = (payload) => {
  return fetch(`/api/surgery/discharge-instructions`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  });
};
export const getDischargeInstructions = (form_id) => {
  return fetch(`/api/surgery/discharge-instructions/${form_id}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};
export const updateDischargeInstructions = (form_id, payload) => {
  return fetch(`/api/surgery/discharge-instructions/${form_id}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  });
};
// Print PDFS
export const printDisclosureOfOwnership = (surgery_id, patient_id) => {
  return fetch(`/pdf/disclosureOfOwnership/${surgery_id}/${patient_id}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
  });
};
export const printDischargeInstructions = (surgery_id, patient_id) => {
  return fetch(`/pdf/dischargeInst/${surgery_id}/${patient_id}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const printPatientSatisfaction = (surgery_id, patient_id) => {
  return fetch(`/pdf/patientSatisfaction/${surgery_id}/${patient_id}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const printConsentToProcedure = (surgery_id, patient_id) => {
  return fetch(`/pdf/consentToProcedure/${surgery_id}/${patient_id}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const printPatientRegistration = (surgery_id, patient_id) => {
  return fetch(`/pdf/patientRegistration/${surgery_id}/${patient_id}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const printAssignmentOfBenefits = (surgery_id, patient_id) => {
  return fetch(`/pdf/assignmentOfBenefits/${surgery_id}/${patient_id}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const printConsentToAnesthesia = (surgery_id, patient_id) => {
  return fetch(`/pdf/consentToAnesthesia/${surgery_id}/${patient_id}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const printNoticeOfPrivacy = (surgery_id, patient_id) => {
  return fetch(`/pdf/noticeOfPrivacy/${surgery_id}/${patient_id}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const printGrievancePolicy = (surgery_id, patient_id) => {
  return fetch(`/pdf/grievancePolicy/${surgery_id}/${patient_id}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const printAnesthesiaQuestionnaire = (surgery_id, patient_id) => {
  return fetch(`/pdf/anesthesiaQuestionaire/${surgery_id}/${patient_id}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const printPatientHistoryPhysical = (surgery_id, patient_id) => {
  return fetch(`/pdf/patientHistory/${surgery_id}/${patient_id}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
  });
};
export const printSurgicalSafetyChecklist = (surgery_id, patient_id) => {
  return fetch(`/pdf/surgicalSafetyChecklist/${surgery_id}/${patient_id}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const updateConsentAnesthesia = (form_id, payload) => {
  return fetch(`/api/surgery/consent-anesthesia/${form_id}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  });
};
export const updatePrivacyPolicy = (form_id, payload) => {
  return fetch(`/api/surgery/notice-privacy/${form_id}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  });
};
export const getConsentAnesthesia = (form_id, payload) => {
  return fetch(`/api/surgery/consent-anesthesia/${form_id}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  });
};
export const getPrivacyPolicy = (form_id, payload) => {
  return fetch(`/api/surgery/notice-privacy/${form_id}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  });
};
export const updateGrievancePolicy = (form_id, payload) => {
  return fetch(`/api/surgery/grievance-policy/${form_id}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  });
};
export const getGrievancePolicy = (form_id, payload) => {
  return fetch(`/api/surgery/grievance-policy/${form_id}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  });
};

export const updateAnesthesiaQues = (form_id, payload) => {
  return fetch(`/api/surgery/anesthesia-questionnaire/${form_id}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  });
};
export const getAnesthesiaQues = (form_id, payload) => {
  return fetch(`/api/surgery/anesthesia-questionnaire/${form_id}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  });
};

export const updatePatientRegistration = (form_id, payload) => {
  return fetch(`/api/surgery/patient-registration/${form_id}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  });
};
export const getPatientRegistration = (form_id, payload) => {
  return fetch(`/api/surgery/patient-registration/${form_id}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const updatePatientHistory = (form_id, payload) => {
  return fetch(`/api/surgery/patient-history/${form_id}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  });
};
export const getPatientHistory = (form_id, payload) => {
  return fetch(`/api/surgery/patient-history/${form_id}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const updatePatientConsentProcedure = (form_id, payload) => {
  return fetch(`/api/surgery/patient-consent-procedure/${form_id}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  });
};
export const getPatientConsentProcedure = (form_id) => {
  return fetch(`/api/surgery/patient-consent-procedure/${form_id}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};
export const updateSurgicalSafetyChecklist = (form_id, payload) => {
  return fetch(`/api/surgery/surgical-safety-checklist/${form_id}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  });
};
export const getSurgicalSafetyChecklist = (form_id) => {
  return fetch(`/api/surgery/surgical-safety-checklist/${form_id}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};
export const getPACUNursingNotes = (form_id) => {
  return fetch(`/api/surgery/pacu-nursing-notes/${form_id}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};
export const updatePACUNursingNotes = (form_id, payload) => {
  return fetch(`/api/surgery/pacu-nursing-notes/${form_id}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  });
};
export const getAdminFinancial = (form_id) => {
  return fetch(`/api/surgery/admin-financial/${form_id}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};
export const updateAdminFinancial = (form_id, payload) => {
  return fetch(`/api/surgery/admin-financial/${form_id}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  });
};

export const printAdminFinancial = (surgery_id, patient_id) => {
  return fetch(`/pdf/adminFinancial/${surgery_id}/${patient_id}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const getChartChecklistAdmin = (form_id) => {
  return fetch(`/api/surgery/chart-checklist-admin/${form_id}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};
export const updateChartChecklistAdmin = (form_id, payload) => {
  return fetch(`/api/surgery/chart-checklist-admin/${form_id}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  });
};
export const printChartChecklistAdmin = (surgery_id, patient_id) => {
  return fetch(`/pdf/chartChecklistAdmin/${surgery_id}/${patient_id}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const getChartChecklist = (form_id) => {
  return fetch(`/api/surgery/chart-checklist/${form_id}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};
export const updateChartChecklist = (form_id, payload) => {
  return fetch(`/api/surgery/chart-checklist/${form_id}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  });
};
export const printChartChecklist = (surgery_id, patient_id) => {
  return fetch(`/pdf/chartChecklist/${surgery_id}/${patient_id}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const getPostOpPatientContact = (form_id) => {
  return fetch(`/api/surgery/postop-patient-contact/${form_id}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};
export const updatePostOpPatientContact = (form_id, payload) => {
  return fetch(`/api/surgery/postop-patient-contact/${form_id}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  });
};
export const printPostOpPatientContact = (surgery_id, patient_id) => {
  return fetch(`/pdf/postOpPatientContact/${surgery_id}/${patient_id}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const getPhysiciansDischargeOrders = (form_id) => {
  return fetch(`/api/surgery/physician-discharge-orders/${form_id}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};
export const updatePhysiciansDischargeOrders = (form_id, payload) => {
  return fetch(`/api/surgery/physician-discharge-orders/${form_id}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  });
};
export const printPhysiciansDischargeOrders = (surgery_id, patient_id) => {
  return fetch(`/pdf/physiciansDischargeOrders/${surgery_id}/${patient_id}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
  });
};
export const getPatientEducation = (form_id) => {
  return fetch(`/api/surgery/patient-education/${form_id}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};
export const updatePatientEducation = (form_id, payload) => {
  return fetch(`/api/surgery/patient-education/${form_id}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  });
};
export const printPatientEducation = (surgery_id, patient_id) => {
  return fetch(`/pdf/patientEducation/${surgery_id}/${patient_id}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const getMedicationReconciliation = (form_id) => {
  return fetch(`/api/surgery/medication-reconciliation/${form_id}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};
export const updateMedicationReconciliation = (form_id, payload) => {
  return fetch(`/api/surgery/medication-reconciliation/${form_id}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  });
};
export const printMedicationReconciliation = (surgery_id, patient_id) => {
  return fetch(`/pdf/medicationReconciliation/${surgery_id}/${patient_id}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const getPreOpPhoneCall = (form_id) => {
  return fetch(`/api/surgery/preop-phonecall/${form_id}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};
export const updatePreOpPhoneCall = (form_id, payload) => {
  return fetch(`/api/surgery/preop-phonecall/${form_id}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  });
};
export const printPreOpPhoneCall = (surgery_id, patient_id) => {
  return fetch(`/pdf/preOpPhoneCall/${surgery_id}/${patient_id}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const getIntraOpNursingRecord = (form_id) => {
  return fetch(`/api/surgery/intraop-nursing-record/${form_id}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};
export const updateIntraOpNursingRecord = (form_id, payload) => {
  return fetch(`/api/surgery/intraop-nursing-record/${form_id}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  });
};

export const printIntraOpNursingRecord = (surgery_id, patient_id) => {
  return fetch(`/pdf/intraOpNursingRecord/${surgery_id}/${patient_id}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
  });
};
export const printNursingPreOpAssessment = (surgery_id, patient_id) => {
  return fetch(`/pdf/nursingPreOpAssessment/${surgery_id}/${patient_id}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
  });
};
export const getPreandPostOpAE = (form_id) => {
  return fetch(`/api/surgery/pre-post-op/${form_id}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};
export const updatePreandPostOpAE = (form_id, payload) => {
  return fetch(`/api/surgery/pre-post-op/${form_id}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  });
};
export const printPreandPostOpAE = (surgery_id, patient_id) => {
  return fetch(`/pdf/prePostOp/${surgery_id}/${patient_id}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const getPostAneCarePO = (form_id) => {
  return fetch(`/api/surgery/post-anesthesia-care/${form_id}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};
export const updatePostAneCarePO = (form_id, payload) => {
  return fetch(`/api/surgery/post-anesthesia-care/${form_id}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  });
};
export const printPostAneCarePO = (surgery_id, patient_id) => {
  return fetch(`/pdf/postAnesthesiaCare/${surgery_id}/${patient_id}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const getIntraoperativeRecord = (form_id) => {
  return fetch(`/api/surgery/intraoperative-record/${form_id}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};
export const updateIntraoperativeRecord = (form_id, payload) => {
  return fetch(`/api/surgery/intraoperative-record/${form_id}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  });
};
export const printIntraoperativeRecord = (surgery_id, patient_id) => {
  return fetch(`/pdf/intraoperativeRecord/${surgery_id}/${patient_id}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const getOperativeNote = (form_id) => {
  return fetch(`/api/surgery/operative-note/${form_id}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};
export const updateOperativeNote = (form_id, payload) => {
  return fetch(`/api/surgery/operative-note/${form_id}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  });
};
export const printOperativeNote = (surgery_id, patient_id) => {
  return fetch(`/pdf/operativeNote/${surgery_id}/${patient_id}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const printPACUNursingNotes = (surgery_id, patient_id) => {
  return fetch(`/pdf/pacuNursingNotes/${surgery_id}/${patient_id}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const getBillingSupply = (form_id) => {
  return fetch(`/api/surgery/billing-supply/${form_id}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};
export const updateBillingSupply = (form_id, payload) => {
  return fetch(`/api/surgery/billing-supply/${form_id}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  });
};
export const printBillingSupply = (surgery_id, patient_id) => {
  return fetch(`/pdf/billingSupply/${surgery_id}/${patient_id}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
  });
};
export const getNursingPreOpAssessment = (form_id) => {
  return fetch(`/api/surgery/nursing-preop-assessment/${form_id}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};
export const updateNursingPreOpAssessment = (form_id, payload) => {
  return fetch(`/api/surgery/nursing-preop-assessment/${form_id}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  });
};
