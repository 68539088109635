import React, { useState, useEffect } from "react";
import { Icon } from "@iconify/react";
import "../Forms/styles.css";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import moment from "moment";
import {
  getChartChecklistAdmin,
  updateChartChecklistAdmin,
} from "../../../utils/api";

const ChartChecklistAdmin = () => {
  const navigate = useNavigate();
  const { form_id } = useParams();
  const [loading, setLoading] = useState(true);

  const [formData, setFormData] = useState({
    left_one: "",
    left_two: "",
    left_three: "",
    left_four: "",
    left_five: "",
    left_six: "",
    left_seven: "",
    left_eight: "",
    hippa_one: "",
    hippa2: "",
    ins_one: "",
    ins_two: "",
    misc_one: "",
    misc_two: "",
    misc_three: "",
  });
  const handleSubmit = async (e) => {
    e.preventDefault();

    const payload = {
      ...formData,
      date: moment().format("MM/DD/YYYY"),
      time: moment().format("h:mm a"),
      status: "In Progress",
    };

    console.log("Payload Sent to Backend:", payload);

    // Fetch 2: Create/Update the Disclosure of Ownerhsip Model

    updateChartChecklistAdmin(form_id, payload)
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        console.log("complete");
         navigate(-1);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getChartChecklistAdmin(form_id)
      .then((res) => res.json())
      .then((data) => {
        console.log(data);

        if (data.status !== "Not Started") {
          setFormData({
            left_one: data.left_one || "",
            left_two: data.left_two || "",
            left_three: data.left_three || "",
            left_four: data.left_four || "",
            left_five: data.left_five || "",
            left_six: data.left_six || "",
            left_seven: data.left_seven || "",
            left_eight: data.left_eight || "",
            hippa_one: data.hippa_one || "",
            hippa2: data.hippa2 || "",
            ins_one: data.ins_one || "",
            ins_two: data.ins_two || "",
            misc_one: data.misc_one || "",
            misc_three: data.misc_three || "",
            misc_two: data.misc_two || "",
          });
        }
        setLoading(false);
      })
      .catch((err) => console.log(err));
  }, []);
  if (loading) return <p>Loading...</p>;

  return (
    <>
      <div
        className="arrow-container"
        onClick={() => navigate(-1)}
        style={{ cursor: "pointer" }}
      >
        <p className="back-arrow">
          <Icon icon="ic:twotone-arrow-back" className="icon" /> Back to Forms
        </p>
      </div>{" "}
      <div className="form-content">
        <form onSubmit={handleSubmit}>
          {" "}
          <h2 className="form-title">Chart Checklist</h2>
          <div className="cc-container">
            <p className="page-column">Page #</p>
            <p className="initials-column ">Initials</p>
            <p className="rs-column">
              <b>Left Side(Front Office)</b>
            </p>
          </div>
          <div className="cc-container">
            <p className="page-column">1</p>
            <input
              className="initials-column short-input"
              name="left_one"
              value={formData.left_one}
              onChange={(e) =>
                setFormData({ ...formData, left_one: e.target.value })
              }
            />
            <p className="rs-column">Chart Checklist (This document)</p>
          </div>
          <div className="cc-container">
            <p className="page-column">2</p>
            <input
              className="initials-column short-input"
              name="left_two"
              value={formData.left_two}
              onChange={(e) =>
                setFormData({ ...formData, left_two: e.target.value })
              }
            />
            <p className="rs-column">Billing Check List</p>
          </div>
          <div className="cc-container">
            <p className="page-column">3</p>
            <input
              className="initials-column short-input"
              name="left_three"
              value={formData.left_three}
              onChange={(e) =>
                setFormData({ ...formData, left_three: e.target.value })
              }
            />
            <p className="rs-column">Billing Supply-OR Supplies</p>
          </div>
          <div className="cc-container">
            <p className="page-column">4</p>
            <input
              className="initials-column short-input"
              name="left_four"
              value={formData.left_four}
              onChange={(e) =>
                setFormData({ ...formData, left_four: e.target.value })
              }
            />
            <p className="rs-column">Arbitration Agreement</p>
          </div>
          <div className="cc-container">
            <p className="page-column">5</p>
            <input
              className="initials-column short-input"
              name="left_five"
              value={formData.left_five}
              onChange={(e) =>
                setFormData({ ...formData, left_five: e.target.value })
              }
            />
            <p className="rs-column">Assignment of Benefits - OCRSC</p>
          </div>
          <div className="cc-container">
            <p className="page-column">6</p>
            <input
              className="initials-column short-input"
              name="left_six"
              value={formData.left_six}
              onChange={(e) =>
                setFormData({ ...formData, left_six: e.target.value })
              }
            />
            <p className="rs-column">Notice of Privacy Practice</p>
          </div>
          <div className="cc-container">
            <p className="page-column">7</p>
            <input
              className="initials-column short-input"
              name="left_seven"
              value={formData.left_seven}
              onChange={(e) =>
                setFormData({ ...formData, left_seven: e.target.value })
              }
            />
            <p className="rs-column">
              Disclosure of Ownership/Patient's Rights
            </p>
          </div>
          <div className="cc-container">
            <p className="page-column">8</p>
            <input
              className="initials-column short-input"
              value={formData.left_eight}
              name="left_eight"
              onChange={(e) =>
                setFormData({ ...formData, left_eight: e.target.value })
              }
            />
            <p className="rs-column">
              Specialty Item Charges(implants, screws,plates,etc. if applicable)
            </p>
          </div>
          <div className="cc-container">
            <p className="page-column"></p>
            <p className="initials-column "></p>
            <p className="rs-column">
              <b>HIPAA Forms</b>
            </p>
          </div>
          <div className="cc-container">
            <p className="page-column">1</p>
            <input
              className="initials-column short-input"
              name="hippa_one"
              value={formData.hippa_one}
              onChange={(e) =>
                setFormData({ ...formData, hippa_one: e.target.value })
              }
            />{" "}
            <p className="rs-column">HIPAA Record of Disclosures</p>
          </div>
          <div className="cc-container">
            <p className="page-column">2</p>
            <input
              className="initials-column short-input"
              name="hippa2"
              value={formData.hippa2}
              onChange={(e) =>
                setFormData({ ...formData, hippa2: e.target.value })
              }
            />{" "}
            <p className="rs-column">
              HIPAA Notice of Privacy Practices Receipt
            </p>
          </div>
          <div className="cc-container">
            <p className="page-column"></p>
            <p className="initials-column "></p>
            <p className="rs-column">
              <b>Insurance</b>
            </p>
          </div>
          <div className="cc-container">
            <p className="page-column">1</p>
            <input
              className="initials-column short-input"
              name="ins_one"
              value={formData.ins_one}
              onChange={(e) =>
                setFormData({ ...formData, ins_one: e.target.value })
              }
            />{" "}
            <p className="rs-column">Insurance Verification</p>
          </div>
          <div className="cc-container">
            <p className="page-column">2</p>
            <input
              className="initials-column short-input"
              name="ins_two"
              value={formData.ins_two}
              onChange={(e) =>
                setFormData({ ...formData, ins_two: e.target.value })
              }
            />{" "}
            <p className="rs-column">Insurance Card</p>
          </div>
          <div className="cc-container">
            <p className="page-column"></p>
            <p className="initials-column "></p>
            <p className="rs-column">
              <b>Misc.</b>
            </p>
          </div>
          <div className="cc-container">
            <p className="page-column">1</p>
            <input
              className="initials-column short-input"
              name="misc_one"
              value={formData.misc_one}
              onChange={(e) =>
                setFormData({ ...formData, misc_one: e.target.value })
              }
            />{" "}
            <p className="rs-column">Patient Information</p>
          </div>
          <div className="cc-container">
            <p className="page-column">2</p>
            <input
              className="initials-column short-input"
              name="misc_two"
              value={formData.misc_two}
              onChange={(e) =>
                setFormData({ ...formData, misc_two: e.target.value })
              }
            />{" "}
            <p className="rs-column">Driver License or ID Card</p>
          </div>
          <div className="cc-container">
            <p className="page-column">3</p>
            <input
              className="initials-column short-input"
              name="misc_three"
              value={formData.misc_three}
              onChange={(e) =>
                setFormData({ ...formData, misc_three: e.target.value })
              }
            />{" "}
            <p className="rs-column">Liens/Bills/EOBs</p>
          </div>
          <div className="center mt-50">
            <button className="btn-primary hover-pointer" type="submit">
              Submit
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default ChartChecklistAdmin;
