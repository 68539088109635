import React, { useState, useEffect } from "react";
import { Icon } from "@iconify/react";
import "../Forms/styles.css";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import moment from "moment";
import {
  getPatientEducation,
  updatePatientEducation,
} from "../../../utils/api";

const PatientEducation = () => {
  const navigate = useNavigate();
  const { form_id } = useParams();
  const [loading, setLoading] = useState(true);

  const [formData, setFormData] = useState({
    one: false,
    two: false,
    three: false,
    four: false,
    five: false,
    six: false,
    seven: false,
    eight: false,
    nine: false,
    ten: false,
    eleven: false,
    twelve: false,
    thirteen: false,
    fourteen: false,
    fifteen: false,
    sixteen: false,
    seventeen: false,
    eighteen: false,
    nineteen: false,
  });
  const handleSubmit = async (e) => {
    e.preventDefault();

    const payload = {
      ...formData,
      date: moment().format("MM/DD/YYYY"),
      time: moment().format("h:mm a"),
      status: "In Progress",
    };

    console.log("Payload Sent to Backend:", payload);

    // Fetch 2: Create/Update the Disclosure of Ownerhsip Model

    updatePatientEducation(form_id, payload)
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        console.log("complete");
        navigate(-1);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getPatientEducation(form_id)
      .then((res) => res.json())
      .then((data) => {
        console.log(data);

        if (data.status !== "Not Started") {
          setFormData({
            one: data.one || false,
            two: data.two || false,
            three: data.three || false,
            four: data.four || false,
            five: data.five || false,
            six: data.six || false,
            seven: data.seven || false,
            eight: data.eight || false,
            nine: data.nine || false,
            ten: data.ten || false,
            eleven: data.eleven || false,
            twelve: data.twelve || false,
            thirteen: data.thirteen || false,
            fourteen: data.fourteen || false,
            fifteen: data.fifteen || false,
            sixteen: data.sixteen || false,
            seventeen: data.seventeen || false,
            eighteen: data.eighteen || false,
            nineteen: data.nineteen || false,
          });
        }
        setLoading(false);
      })
      .catch((err) => console.log(err));
  }, []);
  if (loading) return <p>Loading...</p>;

  return (
    <>
      <div
        className="arrow-container"
        onClick={() => navigate(-1)}
        style={{ cursor: "pointer" }}
      >
        <p className="back-arrow">
          <Icon icon="ic:twotone-arrow-back" className="icon" /> Back to Forms
        </p>
      </div>{" "}
      <div className="form-content">
        <form onSubmit={handleSubmit}>
          {" "}
          <h2 className="form-title">Patient Education</h2>
          <div className="label-indent">
            <p className="text-center">
              <b>PRIOR TO YOUR SURGERY</b>
            </p>
          </div>
          <div className="label-indent">
            <input
              type="checkbox"
              name="one"
              checked={formData.one}
              onChange={(e) =>
                setFormData({ ...formData, one: e.target.checked })
              }
            />{" "}
            <label>
              Your surgeon will discuss your surgery with you and schedule it
              with the surgery center. Our staff should call you the day before
              surgery and give you pre-operative instructions. If you haven't
              been contacted by 3pm on the day prior to your surgery, please
              call the surgery center before 4 pm.
            </label>
          </div>
          <div className="label-indent">
            <input
              type="checkbox"
              name="two"
              checked={formData.two}
              onChange={(e) =>
                setFormData({ ...formData, two: e.target.checked })
              }
            />{" "}
            <label>
              Make sure you gather the following items to bring with you: (1)
              Photo ID (2) Health insurance card (3) List of all medications you
              are taking (including over-the-counter medications and
              supplements) (4) Physician's orders (5) The prescription written
              by your physician
            </label>
          </div>
          <div className="label-indent">
            <input
              type="checkbox"
              name="three"
              checked={formData.three}
              onChange={(e) =>
                setFormData({ ...formData, three: e.target.checked })
              }
            />{" "}
            <label>
              Make sure that you make arrangements to have a friend or family
              member available to drive you home when you are ready to leave the
              recovery room.
            </label>
          </div>
          <div className="label-indent">
            <input
              type="checkbox"
              name="four"
              checked={formData.four}
              onChange={(e) =>
                setFormData({ ...formData, four: e.target.checked })
              }
            />{" "}
            <label>
              If you have a change in physical condition before surgery
              (examples: cold, fever, persistent cough, or rash), please notify
              your surgeon prior to the surgery.
            </label>
          </div>
          <div className="label-indent">
            <p className="text-center">
              <b>THE NIGHT BEFORE YOUR SURGERY</b>
            </p>
            <input
              type="checkbox"
              name="five"
              checked={formData.five}
              onChange={(e) =>
                setFormData({ ...formData, five: e.target.checked })
              }
            />{" "}
            <label>
              Please do not eat or drink anything after midnight, the night
              before your surgery. This includes gum, water, vitamins, or mints,
              unless you have been given special instructions. If you do not
              follow this guideline, your procedure may have to be delayed or
              rescheduled.
            </label>
          </div>
          <div className="label-indent">
            <p className="text-center">
              <b>THE DAY OF SURGERY</b>
            </p>
            <input
              type="checkbox"
              name="six"
              checked={formData.six}
              onChange={(e) =>
                setFormData({ ...formData, six: e.target.checked })
              }
            />{" "}
            <label>
              Remember not to eat or drink ANYTHING, including water, in the
              morning unless you have special instructions.{" "}
            </label>
          </div>
          <div className="label-indent">
            <input
              type="checkbox"
              name="seven"
              checked={formData.seven}
              onChange={(e) =>
                setFormData({ ...formData, seven: e.target.checked })
              }
            />{" "}
            <label>
              Wear comfortable clothing that you can change in and out of easily
              and that will not bind the site of your surgery.
            </label>
          </div>
          <div className="label-indent">
            <input
              type="checkbox"
              name="eight"
              checked={formData.eight}
              onChange={(e) =>
                setFormData({ ...formData, eight: e.target.checked })
              }
            />{" "}
            <label>
              Do not wear contact lenses. Please leave your jewelry and other
              valuables at home.
            </label>
          </div>
          <div className="label-indent">
            <input
              type="checkbox"
              name="nine"
              checked={formData.nine}
              onChange={(e) =>
                setFormData({ ...formData, nine: e.target.checked })
              }
            />{" "}
            <label>
              Be here one hour before your scheduled surgery time to complete
              your admission forms and sign your consent form. If you received
              these forms in the mail prior to your surgery, bring them with you
              on the day of your surgery and arrive 30 minutes before your
              scheduled surgery. Persons under 18 years of age must have a
              parent or legal guardian with them to sign the forms.
            </label>
          </div>
          <div className="label-indent">
            <input
              type="checkbox"
              name="ten"
              checked={formData.ten}
              onChange={(e) =>
                setFormData({ ...formData, ten: e.target.checked })
              }
            />{" "}
            <label>
              The surgery center staff will ask you for a brief medical history.
            </label>
          </div>
          <div className="label-indent">
            <input
              type="checkbox"
              name="eleven"
              checked={formData.eleven}
              onChange={(e) =>
                setFormData({ ...formData, eleven: e.target.checked })
              }
            />{" "}
            <label>
              Your anesthesiologist will meet with you to discuss your
              anesthesia, tell you exactly what will happen before, during, and
              after your surgery, and answer any questions you may have.
            </label>
          </div>
          <div className="label-indent">
            <p className="text-center">
              <b>AFTER SURGERY</b>
            </p>
            <input
              type="checkbox"
              name="twelve"
              checked={formData.twelve}
              onChange={(e) =>
                setFormData({ ...formData, twelve: e.target.checked })
              }
            />{" "}
            <label>
              You will rest in our recovery room under the care of
              specialty-trained nurses. Your anesthesiologist will monitor your
              condition.
            </label>
          </div>
          <div className="label-indent">
            <input
              type="checkbox"
              name="thirteen"
              checked={formData.thirteen}
              onChange={(e) =>
                setFormData({ ...formData, thirteen: e.target.checked })
              }
            />{" "}
            <label>
              Our staff will answer your questions and give you post-operative
              instructions as ordered by your physician. You will receive a
              written copy of these instructions.
            </label>
          </div>
          <div className="label-indent">
            <input
              type="checkbox"
              name="fourteen"
              checked={formData.fourteen}
              onChange={(e) =>
                setFormData({ ...formData, fourteen: e.target.checked })
              }
            />{" "}
            <label>
              You may be dizzy or sleepy after your surgery, even after spending
              time in the recovery room. Do not attempt to drive or even take a
              taxi home alone. Please have a responsible adult with you to drive
              you home.
            </label>
          </div>
          <div className="label-indent">
            <p className="text-center">
              <b>RETURNING HOME</b>
            </p>
            <input
              type="checkbox"
              name="fifteen"
              checked={formData.fifteen}
              onChange={(e) =>
                setFormData({ ...formData, fifteen: e.target.checked })
              }
            />{" "}
            <label>
              Even though you may feel fine, have someone plan to stay at home
              with you through the night. Do not make any important decisions,
              consume alcoholic beverages, take medications not prescribed by
              your surgeon, drive, or operate machinery during the 24-hour
              period following your surgery.
            </label>
          </div>
          <div className="label-indent">
            <input
              type="checkbox"
              name="sixteen"
              checked={formData.sixteen}
              onChange={(e) =>
                setFormData({ ...formData, sixteen: e.target.checked })
              }
            />{" "}
            <label>
              Remember that the recovery process continues when you return home.
              You may experience minor after-effects such as drowsiness, muscle
              aches, a sore throat, and occasional dizziness or headaches.
              Nausea may also be present, but vomiting is less common. You will
              begin to feel better within hours, but it may take several days
              before after-effects are gone completely.
            </label>
          </div>
          <div className="label-indent">
            <input
              type="checkbox"
              name="seventeen"
              checked={formData.seventeen}
              onChange={(e) =>
                setFormData({ ...formData, seventeen: e.target.checked })
              }
            />{" "}
            <label>
              Plan to take it easy for a few days as the majority of patients do
              not feel up to their typical activities right away, usually due to
              general tiredness or surgical discomfort.
            </label>
          </div>
          <div className="label-indent">
            <input
              type="checkbox"
              name="eighteen"
              checked={formData.eighteen}
              onChange={(e) =>
                setFormData({ ...formData, eighteen: e.target.checked })
              }
            />{" "}
            <label>
              Call your surgeon if you have questions about what you can and
              cannot do, when you can return to work, and your regular
              activities.
            </label>
          </div>
          <div className="label-indent">
            <p className="text-center">
              <b>YOUR FEE</b>
            </p>
            <input
              type="checkbox"
              name="nineteen"
              checked={formData.nineteen}
              onChange={(e) =>
                setFormData({ ...formData, nineteen: e.target.checked })
              }
            />{" "}
            <label>
              The surgery center's facility fee includes charges for the
              operating room and the recovery room services which are provided
              by the center. This does not include fees for the surgeon,
              anesthesiologist, pathologist, or radiologist, which will be
              billed separately, if applicable. Contact your insurance company
              in advance if you have any questions.
            </label>
          </div>
          <div className="center mt-50">
            <button className="btn-primary hover-pointer" type="submit">
              Submit
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default PatientEducation;
