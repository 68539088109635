import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import FormBox from "./form-box";
import { Icon } from "@iconify/react";
import {
  printDisclosureOfOwnership,
  printDischargeInstructions,
  printPatientSatisfaction,
  printConsentToProcedure,
  printPatientRegistration,
  printAssignmentOfBenefits,
  printConsentToAnesthesia,
  printNoticeOfPrivacy,
  printGrievancePolicy,
  printAnesthesiaQuestionnaire,
  printPatientHistoryPhysical,
  printAdminFinancial,
  printChartChecklistAdmin,
  printChartChecklist,
  printSurgicalSafetyChecklist,
  printPostOpPatientContact,
  printMedicationReconciliation,
  printPostAneCarePO,
  printPreandPostOpAE,
  printOperativeNote,
  printPhysiciansDischargeOrders,
  printBillingSupply,
  printPACUNursingNotes,
  printIntraOpNursingRecord,
  printIntraoperativeRecord,
  printPatientEducation,
  printPreOpPhoneCall,
  printNursingPreOpAssessment,
} from "../../../utils/api";
import { getCurrentURl } from "../../../utils/helpers";

const FormBoxContainer = ({ dbForms }) => {
  const { id, surgery_id } = useParams();
  const [currentView, setCurrentView] = useState("All");
  const [forms, setForms] = useState([]);
  const [currentForms, setCurrentForms] = useState([]);

  const handlePrint = (e, form) => {
    e.preventDefault();
    const form_name = form.formName;

    switch (form_name) {
      case "Disclosure of Ownership":
        printDisclosureOfOwnership(surgery_id, id)
          .then((res) => res.json())
          .then((data) => {
            if (data.fileName) {
              const url = `${getCurrentURl()}documents/${data.fileName}`;
              window.open(url, "_blank");
            }
          })
          .catch((err) => console.log(err));
        break;

      case "Discharge Instructions":
        printDischargeInstructions(surgery_id, id)
          .then((res) => res.json())
          .then((data) => {
            if (data.fileName) {
              const url = `${getCurrentURl()}documents/${data.fileName}`;
              window.open(url, "_blank");
            }
          })
          .catch((err) => console.log(err));
        break;

      case "Patient Satisfaction Survey":
        printPatientSatisfaction(surgery_id, id)
          .then((res) => res.json())
          .then((data) => {
            if (data.fileName) {
              const url = `${getCurrentURl()}documents/${data.fileName}`;
              window.open(url, "_blank");
            }
          })
          .catch((err) => console.log(err));
        break;

      case "Patient Consent to Procedure":
        printConsentToProcedure(surgery_id, id)
          .then((res) => res.json())
          .then((data) => {
            if (data.fileName) {
              const url = `${getCurrentURl()}documents/${data.fileName}`;
              window.open(url, "_blank");
            }
          })
          .catch((err) => console.log(err));
        break;

      case "Patient Registration":
        printPatientRegistration(surgery_id, id)
          .then((res) => res.json())
          .then((data) => {
            if (data.fileName) {
              const url = `${getCurrentURl()}documents/${data.fileName}`;
              window.open(url, "_blank");
            }
          })
          .catch((err) => console.log(err));
        break;

      case "Assignment of Benefits":
        printAssignmentOfBenefits(surgery_id, id)
          .then((res) => res.json())
          .then((data) => {
            if (data.fileName) {
              const url = `${getCurrentURl()}documents/${data.fileName}`;
              window.open(url, "_blank");
            }
          })
          .catch((err) => console.log(err));
        break;

      case "Consent To Anesthesia":
        printConsentToAnesthesia(surgery_id, id)
          .then((res) => res.json())
          .then((data) => {
            if (data.fileName) {
              const url = `${getCurrentURl()}documents/${data.fileName}`;
              window.open(url, "_blank");
            }
          })
          .catch((err) => console.log(err));
        break;

      case "Notice of Privacy Practice":
        printNoticeOfPrivacy(surgery_id, id)
          .then((res) => res.json())
          .then((data) => {
            if (data.fileName) {
              const url = `${getCurrentURl()}documents/${data.fileName}`;
              window.open(url, "_blank");
            }
          })
          .catch((err) => console.log(err));
        break;

      case "Grievance Policy":
        printGrievancePolicy(surgery_id, id)
          .then((res) => res.json())
          .then((data) => {
            if (data.fileName) {
              const url = `${getCurrentURl()}documents/${data.fileName}`;
              window.open(url, "_blank");
            }
          })
          .catch((err) => console.log(err));
        break;

      case "Anesthesia Questionnaire":
        printAnesthesiaQuestionnaire(surgery_id, id)
          .then((res) => res.json())
          .then((data) => {
            if (data.fileName) {
              const url = `${getCurrentURl()}documents/${data.fileName}`;
              window.open(url, "_blank");
            }
          })
          .catch((err) => console.log(err));
        break;

      case "Patient History":
        printPatientHistoryPhysical(surgery_id, id)
          .then((res) => res.json())
          .then((data) => {
            console.log(data);
            if (data.fileName) {
              const url = `${getCurrentURl()}documents/${data.fileName}`;
              window.open(url, "_blank");
            }
          })
          .catch((err) => console.log(err));
        break;

      case "Admin Financial":
        printAdminFinancial(surgery_id, id)
          .then((res) => res.json())
          .then((data) => {
            console.log(data);
            if (data.fileName) {
              const url = `${getCurrentURl()}documents/${data.fileName}`;
              window.open(url, "_blank");
            }
          })
          .catch((err) => console.log(err));
        break;
      case "Chart Checklist Admin":
        printChartChecklistAdmin(surgery_id, id)
          .then((res) => res.json())
          .then((data) => {
            console.log(data);
            if (data.fileName) {
              const url = `${getCurrentURl()}documents/${data.fileName}`;
              window.open(url, "_blank");
            }
          })
          .catch((err) => console.log(err));
        break;
      case "Chart Checklist":
        printChartChecklist(surgery_id, id)
          .then((res) => res.json())
          .then((data) => {
            console.log(data);
            if (data.fileName) {
              const url = `${getCurrentURl()}documents/${data.fileName}`;
              window.open(url, "_blank");
            }
          })
          .catch((err) => console.log(err));
        break;
      case "Surgical Safety Checklist":
        printSurgicalSafetyChecklist(surgery_id, id)
          .then((res) => res.json())
          .then((data) => {
            console.log(data);
            if (data.fileName) {
              const url = `${getCurrentURl()}documents/${data.fileName}`;
              window.open(url, "_blank");
            }
          })
          .catch((err) => console.log(err));
        break;
      case "Post-Op Patient Contact":
        printPostOpPatientContact(surgery_id, id)
          .then((res) => res.json())
          .then((data) => {
            console.log(data);
            if (data.fileName) {
              const url = `${getCurrentURl()}documents/${data.fileName}`;
              window.open(url, "_blank");
            }
          })
          .catch((err) => console.log(err));
        break;
      case "Medication Reconciliation":
        printMedicationReconciliation(surgery_id, id)
          .then((res) => res.json())
          .then((data) => {
            console.log(data);
            if (data.fileName) {
              const url = `${getCurrentURl()}documents/${data.fileName}`;
              window.open(url, "_blank");
            }
          })
          .catch((err) => console.log(err));
        break;
      case "Post Anesthesia Care":
        printPostAneCarePO(surgery_id, id)
          .then((res) => res.json())
          .then((data) => {
            console.log(data);
            if (data.fileName) {
              const url = `${getCurrentURl()}documents/${data.fileName}`;
              window.open(url, "_blank");
            }
          })
          .catch((err) => console.log(err));
        break;
      case "Pre and Post Anesthetic Evaluation":
        printPreandPostOpAE(surgery_id, id)
          .then((res) => res.json())
          .then((data) => {
            console.log(data);
            if (data.fileName) {
              const url = `${getCurrentURl()}documents/${data.fileName}`;
              window.open(url, "_blank");
            }
          })
          .catch((err) => console.log(err));
        break;
      case "Operative Note":
        printOperativeNote(surgery_id, id)
          .then((res) => res.json())
          .then((data) => {
            console.log(data);
            if (data.fileName) {
              const url = `${getCurrentURl()}documents/${data.fileName}`;
              window.open(url, "_blank");
            }
          })
          .catch((err) => console.log(err));
        break;
      case "Physicians Discharge Instructions":
        printPhysiciansDischargeOrders(surgery_id, id)
          .then((res) => res.json())
          .then((data) => {
            console.log(data);
            if (data.fileName) {
              const url = `${getCurrentURl()}documents/${data.fileName}`;
              window.open(url, "_blank");
            }
          })
          .catch((err) => console.log(err));
        break;
      case "Billing Supply":
        printBillingSupply(surgery_id, id)
          .then((res) => res.json())
          .then((data) => {
            console.log(data);
            if (data.fileName) {
              const url = `${getCurrentURl()}documents/${data.fileName}`;
              window.open(url, "_blank");
            }
          })
          .catch((err) => console.log(err));
        break;
      case "PACU Nursing Notes":
        printPACUNursingNotes(surgery_id, id)
          .then((res) => res.json())
          .then((data) => {
            console.log(data);
            if (data.fileName) {
              const url = `${getCurrentURl()}documents/${data.fileName}`;
              window.open(url, "_blank");
            }
          })
          .catch((err) => console.log(err));
        break;
        case "Intra-Op Nursing Record":
          printIntraOpNursingRecord(surgery_id, id)
            .then((res) => res.json())
            .then((data) => {
              console.log(data);
              if (data.fileName) {
                const url = `${getCurrentURl()}documents/${data.fileName}`;
                window.open(url, "_blank");
              }
            })
            .catch((err) => console.log(err));
          break;
          case "Intraoperative Record":
            printIntraoperativeRecord(surgery_id, id)
              .then((res) => res.json())
              .then((data) => {
                console.log(data);
                if (data.fileName) {
                  const url = `${getCurrentURl()}documents/${data.fileName}`;
                  window.open(url, "_blank");
                }
              })
              .catch((err) => console.log(err));
            break;
            case "Patient Education":
              printPatientEducation(surgery_id, id)
                .then((res) => res.json())
                .then((data) => {
                  console.log(data);
                  if (data.fileName) {
                    const url = `${getCurrentURl()}documents/${data.fileName}`;
                    window.open(url, "_blank");
                  }
                })
                .catch((err) => console.log(err));
              break;
              case "Pre-Op Phone Call":
                printPreOpPhoneCall(surgery_id, id)
                  .then((res) => res.json())
                  .then((data) => {
                    console.log(data);
                    if (data.fileName) {
                      const url = `${getCurrentURl()}documents/${data.fileName}`;
                      window.open(url, "_blank");
                    }
                  })
                  .catch((err) => console.log(err));
                break;
                case "Nursing Pre-Op Assessment":
                  printNursingPreOpAssessment(surgery_id, id)
                    .then((res) => res.json())
                    .then((data) => {
                      console.log(data);
                      if (data.fileName) {
                        const url = `${getCurrentURl()}documents/${data.fileName}`;
                        window.open(url, "_blank");
                      }
                    })
                    .catch((err) => console.log(err));
                  break;
      default:
        console.log(`No handler found for form name: ${form_name}`);
        break;
    }
  };
  useEffect(() => {
    console.log("dbForms from API:", dbForms);
  }, [dbForms]);
  // update forms array data when retrieved from the db
  useEffect(() => {
    // Remove Forms model information (prep for looping thorugh associated forms)
    delete dbForms.isDeleted;
    delete dbForms.id;
    delete dbForms.surgery_id;
    console.log("dbForms: ", dbForms);

    // Turn obj to array & update state
    const newArr = Object.keys(dbForms).map((key) => dbForms[key]);
    setForms(newArr);
  }, [dbForms]);

  // Filter forms for display based on the selected tab
  useEffect(() => {
    switch (currentView) {
      case "All":
        setCurrentForms(forms);
        break;
      case "Patient":
        const patientArr = forms.filter((form) =>
          form.signee.includes("Patient")
        );
        setCurrentForms(patientArr);
        break;
      case "Nurse":
        const nurseArr = forms.filter((form) => form.signee.includes("Nurse"));
        setCurrentForms(nurseArr);
        console.log("Nurse Forms: ", nurseArr);
        break;
      case "Anesthesia":
        const anesArr = forms.filter((form) =>
          form.signee.includes("Anesthesia")
        );
        setCurrentForms(anesArr);
        break;
      case "Surgeon":
        const docArr = forms.filter((form) => form.signee.includes("Doctor"));
        setCurrentForms(docArr);
        break;
      case "Admin":
        const adminArr = forms.filter((form) => form.signee.includes("Admin"));
        setCurrentForms(adminArr);
        break;
      default:
        setCurrentForms(forms);
        break;
    }
  }, [currentView, forms]);

  return (
    <div className="second-column-item-heading patient-heading box-shadow border-none">
      {/* Menu */}
      <div className="forms-menu">
        <p
          className={
            currentView === "All" ? "patient-tabs current" : "patient-tabs"
          }
          onClick={() => setCurrentView("All")}
        >
          All
        </p>
        <p
          className={
            currentView === "Patient" ? "patient-tabs current" : "patient-tabs"
          }
          onClick={() => setCurrentView("Patient")}
        >
          Patient
        </p>
        <p
          className={
            currentView === "Nurse" ? "patient-tabs current" : "patient-tabs"
          }
          onClick={() => setCurrentView("Nurse")}
        >
          Nurse
        </p>
        <p
          className={
            currentView === "Anesthesia"
              ? "patient-tabs current"
              : "patient-tabs"
          }
          onClick={() => setCurrentView("Anesthesia")}
        >
          Anesthesia
        </p>
        <p
          className={
            currentView === "Surgeon" ? "patient-tabs current" : "patient-tabs"
          }
          onClick={() => setCurrentView("Surgeon")}
        >
          Surgeon
        </p>
        <p
          className={
            currentView === "Admin" ? "patient-tabs current" : "patient-tabs"
          }
          onClick={() => setCurrentView("Admin")}
        >
          Admin
        </p>
      </div>

      {/* Form Display */}
      {currentForms.map((form, index) => (
        <div key={index} className="form-box-row">
          <button onClick={(e) => handlePrint(e, form)}>
            <Icon
              icon="material-symbols:print-outline-rounded"
              width="24"
              height="24"
            />
          </button>

          <FormBox form={form} index={index} />
        </div>
      ))}
    </div>
  );
};

export default FormBoxContainer;
