import React, { useState, useEffect } from "react";
import { Icon } from "@iconify/react";
import { useNavigate } from "react-router-dom";
import "../Forms/styles.css";
import SignatureButton from "../../../components/SignatureButton";
import { useParams } from "react-router-dom";
import moment from "moment";
import {
  addFile,
  getPostOpPatientContact,
  updatePostOpPatientContact,
} from "../../../utils/api";

const PostOpPatientContact = () => {
  const navigate = useNavigate();
  const { form_id } = useParams();

  const [formData, setFormData] = useState({
    procedures: "",
    time: "",
    phone_one: "",
    time_two: "",
    phone_two: "",
    meds_desc: "",
    additional_notes: "",
    sign_date: "",
    sign_time: "",
    comments_1: "",
    comments_2: "",
    comments_3: "",
    comments_4: "",
    comments_5: "",
    comments_6: "",
    comments_7: "",
    comments_8: "",
    comments_10: "",
    comments_11: "",
    comments_12: "",
    comments_13: "",
    pain_desc: "",
    pain_level: "",
    date_of_call: "",
    date_of_call_two: "",
    checkbox1: false,
    checkbox2: false,
    checkbox3: false,
    checkbox4: false,
    checkbox5: false,
    checkbox6: false,
    checkbox7: false,
    checkbox8: false,
    checkbox9: false,
    checkbox10: false,
    checkbox11: false,
    checkbox12: false,
    checkbox13: false,
    checkbox14: false,
    checkbox15: false,
    checkbox16: false,
    checkbox17: false,
    checkbox18: false,
    checkbox19: false,
    checkbox20: false,
    checkbox21: false,
    checkbox22: false,
    checkbox23: false,
    checkbox24: false,
  });
  const [loading, setLoading] = useState(true);

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: checked,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("Form Data Before Submission:", formData);

    const payload = {
      ...formData,
      date_of_call: moment().format("MM/DD/YYYY"),
      date_of_call_two: moment().format("MM/DD/YYYY"),
      sign_date: moment().format("MM/DD/YYYY"),
      sign_time: moment().format("h:mm a"),
      time_two: moment().format("h:mm a"),
      date: moment().format("MM/DD/YYYY"),
      time: moment().format("h:mm a"),
      status: "In Progress",
    };
    console.log("Payload sent to backend:", payload);

    console.log(payload);

    // Fetch 2: ≈ Disclosure of Ownerhsip Model
    updatePostOpPatientContact(form_id, payload)
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        console.log("complete");
        navigate(-1);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getPostOpPatientContact(form_id)
      .then((res) => res.json())
      .then((data) => {
        console.log(data);

        if (data.status !== "Not Started") {
          setFormData({
            procedures: data.procedures || "",
            time: data.time || "",
            phone_one: data.phone_one || "",
            time_two: data.time_two || "",
            phone_two: data.phone_two || "",
            meds_desc: data.meds_desc || "",
            additional_notes: data.additional_notes || "",
            sign_date: data.sign_date || "",
            sign_time: data.sign_time || "",
            comments_1: data.comments_1 || "",
            comments_2: data.comments_2 || "",
            comments_3: data.comments_3 || "",
            comments_4: data.comments_4 || "",
            comments_5: data.comments_5 || "",
            comments_6: data.comments_6 || "",
            comments_7: data.comments_7 || "",
            comments_8: data.comments_8 || "",
            comments_10: data.comments_10 || "",
            comments_11: data.comments_11 || "",
            comments_12: data.comments_12 || "",
            comments_13: data.comments_13 || "",
            pain_desc: data.pain_desc || "",
            pain_level: data.pain_level || "",
            date_of_call: data.date_of_call || "",
            date_of_call_two: data.date_of_call_two || "",
            checkbox1: data.checkbox1 || false,
            checkbox2: data.checkbox2 || false,
            checkbox3: data.checkbox3 || false,
            checkbox4: data.checkbox4 || false,
            checkbox5: data.checkbox5 || false,
            checkbox6: data.checkbox6 || false,
            checkbox7: data.checkbox7 || false,
            checkbox8: data.checkbox8 || false,
            checkbox9: data.checkbox9 || false,
            checkbox10: data.checkbox10 || false,
            checkbox11: data.checkbox11 || false,
            checkbox12: data.checkbox12 || false,
            checkbox13: data.checkbox13 || false,
            checkbox14: data.checkbox14 || false,
            checkbox15: data.checkbox15 || false,
            checkbox16: data.checkbox16 || false,
            checkbox17: data.checkbox17 || false,
            checkbox18: data.checkbox18 || false,
            checkbox19: data.checkbox19 || false,
            checkbox20: data.checkbox20 || false,
            checkbox21: data.checkbox21 || false,
            checkbox22: data.checkbox22 || false,
            checkbox23: data.checkbox23 || false,
            checkbox24: data.checkbox24 || false,
          });
        }

        setLoading(false);
      })
      .catch((err) => console.log(err));
  }, []);

  if (loading) return <p>Loading...</p>;

  return (
    <>
      <div
        className="arrow-container"
        onClick={() => navigate(-1)}
        style={{ cursor: "pointer" }}
      >
        <p className="back-arrow">
          <Icon icon="ic:twotone-arrow-back" className="icon" /> Back to Forms
        </p>
      </div>
      <h3 className="form-title">Post Operative Patient Contact</h3>
      <div className="form-content">
        <form onSubmit={handleSubmit}>
          {" "}
          <div className="procedure-container">
            <div className="procedure-row">
              <label>PROCEDURE(S):</label>
              <input
                type="text"
                name="procedures"
                value={formData.procedures}
                onChange={(e) =>
                  setFormData({ ...formData, procedures: e.target.value })
                }
              />
            </div>
            <div className="call-log">
              <div className="call-row">
                <label>Date of Call 1:</label>
                <input
                  type="date"
                  className="call-input short"
                  name="date_of_call"
                  value={formData.date_of_call}
                  onChange={(e) =>
                    setFormData({ ...formData, date_of_call: e.target.value })
                  }
                />
                <label>Time:</label>
                <input
                  type="time"
                  className="call-input"
                  name="time"
                  value={formData.time}
                  onChange={(e) =>
                    setFormData({ ...formData, time: e.target.value })
                  }
                />
                <label>Phone call made by:</label>
                <input
                  type="text"
                  className="call-input"
                  name="phone_one"
                  value={formData.phone_one}
                  onChange={(e) =>
                    setFormData({ ...formData, phone_one: e.target.value })
                  }
                />
              </div>
              <div className="call-row">
                <label>Date of Call 2:</label>
                <input
                  type="date"
                  className="call-input short"
                  name="date_of_call_two"
                  value={formData.date_of_call_two}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      date_of_call_two: e.target.value,
                    })
                  }
                />
                <label>Time:</label>
                <input
                  type="time"
                  className="call-input"
                  name="time_two"
                  value={formData.time_two}
                  onChange={(e) =>
                    setFormData({ ...formData, time_two: e.target.value })
                  }
                />
                <label>Phone call made by:</label>
                <input
                  type="text"
                  className="call-input"
                  name="phone_two"
                  value={formData.phone_two}
                  onChange={(e) =>
                    setFormData({ ...formData, phone_two: e.target.value })
                  }
                />
              </div>

              <div className="pop-form-row">
                <span className="symptom">PATIENT EXPERIENCING:</span>
                <p className="yes-no">YES</p>
                <p className="yes-no">NO</p>
                <p className="comments no-border">Comments by patient:</p>
              </div>

              <div className="pop-form-row">
                <span className="symptom">Nausea</span>
                <input
                  className="yes-no"
                  type="checkbox"
                  name="checkbox1"
                  checked={formData.checkbox1}
                  onChange={handleCheckboxChange}
                />
                <input
                  className="yes-no"
                  type="checkbox"
                  name="checkbox13"
                  checked={formData.checkbox13}
                  onChange={handleCheckboxChange}
                />
                <input
                  className="comments"
                  name="comments_1"
                  value={formData.comments_1}
                  onChange={(e) =>
                    setFormData({ ...formData, comments_1: e.target.value })
                  }
                />
              </div>

              <div className="pop-form-row">
                <span className="symptom">Fever</span>
                <input
                  className="yes-no"
                  type="checkbox"
                  name="checkbox2"
                  checked={formData.checkbox2}
                  onChange={handleCheckboxChange}
                />
                <input
                  className="yes-no"
                  type="checkbox"
                  name="checkbox14"
                  checked={formData.checkbox14}
                  onChange={handleCheckboxChange}
                />
                <input
                  className="comments"
                  name="comments_2"
                  value={formData.comments_2}
                  onChange={(e) =>
                    setFormData({ ...formData, comments_2: e.target.value })
                  }
                />
              </div>

              <div className="pop-form-row">
                <span className="symptom">Swelling</span>
                <input
                  className="yes-no"
                  type="checkbox"
                  name="checkbox3"
                  checked={formData.checkbox3}
                  onChange={handleCheckboxChange}
                />
                <input
                  className="yes-no"
                  type="checkbox"
                  name="checkbox15"
                  checked={formData.checkbox15}
                  onChange={handleCheckboxChange}
                />
                <input
                  className="comments"
                  name="comments_3"
                  value={formData.comments_3}
                  onChange={(e) =>
                    setFormData({ ...formData, comments_3: e.target.value })
                  }
                />
              </div>

              <div className="pop-form-row">
                <span className="symptom">Bleeding</span>
                <input
                  className="yes-no"
                  type="checkbox"
                  name="checkbox4"
                  checked={formData.checkbox4}
                  onChange={handleCheckboxChange}
                />
                <input
                  className="yes-no"
                  type="checkbox"
                  name="checkbox16"
                  checked={formData.checkbox16}
                  onChange={handleCheckboxChange}
                />
                <input
                  className="comments"
                  name="comments_4"
                  value={formData.comments_4}
                  onChange={(e) =>
                    setFormData({ ...formData, comments_4: e.target.value })
                  }
                />
              </div>

              <div className="pop-form-row">
                <span className="symptom">Unusual or Excessive Pain</span>
                <input
                  className="yes-no"
                  type="checkbox"
                  name="checkbox5"
                  checked={formData.checkbox5}
                  onChange={handleCheckboxChange}
                />
                <input
                  className="yes-no"
                  type="checkbox"
                  name="checkbox17"
                  checked={formData.checkbox17}
                  onChange={handleCheckboxChange}
                />
                <input
                  className="comments"
                  name="comments_5"
                  value={formData.comments_5}
                  onChange={(e) =>
                    setFormData({ ...formData, comments_5: e.target.value })
                  }
                />
              </div>

              <div className="pop-form-row">
                <span className="symptom">Drainage</span>
                <input
                  className="yes-no"
                  type="checkbox"
                  name="checkbox6"
                  checked={formData.checkbox6}
                  onChange={handleCheckboxChange}
                />
                <input
                  className="yes-no"
                  type="checkbox"
                  name="checkbox18"
                  checked={formData.checkbox18}
                  onChange={handleCheckboxChange}
                />
                <input
                  className="comments"
                  name="comments_6"
                  value={formData.comments_6}
                  onChange={(e) =>
                    setFormData({ ...formData, comments_6: e.target.value })
                  }
                />
              </div>

              <div className="pop-form-row">
                <span className="symptom">Sore throat</span>
                <input
                  className="yes-no"
                  type="checkbox"
                  name="checkbox7"
                  checked={formData.checkbox7}
                  onChange={handleCheckboxChange}
                />
                <input
                  className="yes-no"
                  type="checkbox"
                  name="checkbox19"
                  checked={formData.checkbox19}
                  onChange={handleCheckboxChange}
                />
                <input
                  className="comments"
                  name="comments_7"
                  value={formData.comments_7}
                  onChange={(e) =>
                    setFormData({ ...formData, comments_7: e.target.value })
                  }
                />
              </div>
              <div className="pop-form-row">
                <span className="symptom"></span>
                <input className="yes-no hidden" type="checkbox" />
                <input className="yes-no hidden" type="checkbox" />
                <span className="comments no-border">Comments by caller:</span>
              </div>
              <div className="pop-form-row">
                <span className="symptom">Needlepoint redness</span>
                <input
                  className="yes-no"
                  type="checkbox"
                  name="checkbox8"
                  checked={formData.checkbox8}
                  onChange={handleCheckboxChange}
                />
                <input
                  className="yes-no"
                  type="checkbox"
                  name="checkbox20"
                  checked={formData.checkbox20}
                  onChange={handleCheckboxChange}
                />
                <input
                  className="comments"
                  name="comments_8"
                  value={formData.comments_8}
                  onChange={(e) =>
                    setFormData({ ...formData, comments_8: e.target.value })
                  }
                />
              </div>

              <div className="pop-form-row">
                <span className="symptom">Rapid heartbeat</span>
                <input
                  className="yes-no"
                  type="checkbox"
                  name="checkbox9"
                  checked={formData.checkbox9}
                  onChange={handleCheckboxChange}
                />
                <input
                  className="yes-no"
                  type="checkbox"
                  name="checkbox21"
                  checked={formData.checkbox21}
                  onChange={handleCheckboxChange}
                />
                <input
                  className="comments"
                  name="comments_10"
                  value={formData.comments_10}
                  onChange={(e) =>
                    setFormData({ ...formData, comments_10: e.target.value })
                  }
                />
              </div>

              <div className="pop-form-row">
                <span className="symptom">
                  Is patient drinking plenty of fluids?
                </span>
                <input
                  className="yes-no"
                  type="checkbox"
                  name="checkbox10"
                  checked={formData.checkbox10}
                  onChange={handleCheckboxChange}
                />
                <input
                  className="yes-no"
                  type="checkbox"
                  name="checkbox22"
                  checked={formData.checkbox22}
                  onChange={handleCheckboxChange}
                />
                <input
                  className="comments"
                  name="comments_11"
                  value={formData.comments_11}
                  onChange={(e) =>
                    setFormData({ ...formData, comments_11: e.target.value })
                  }
                />
              </div>

              <div className="pop-form-row">
                <span className="symptom">
                  Has patient eaten since surgery?
                </span>
                <input
                  className="yes-no"
                  type="checkbox"
                  name="checkbox11"
                  checked={formData.checkbox11}
                  onChange={handleCheckboxChange}
                />
                <input
                  className="yes-no"
                  type="checkbox"
                  name="checkbox23"
                  checked={formData.checkbox23}
                  onChange={handleCheckboxChange}
                />
                <input
                  className="comments"
                  name="comments_12"
                  value={formData.comments_12}
                  onChange={(e) =>
                    setFormData({ ...formData, comments_12: e.target.value })
                  }
                />
              </div>

              <div className="pop-form-row">
                <span className="symptom">
                  Is patient taking pain medication?
                </span>
                <input
                  className="yes-no"
                  type="checkbox"
                  name="checkbox12"
                  checked={formData.checkbox12}
                  onChange={handleCheckboxChange}
                />
                <input
                  className="yes-no"
                  type="checkbox"
                  name="checkbox24"
                  checked={formData.checkbox24}
                  onChange={handleCheckboxChange}
                />
                <input
                  className="comments"
                  name="comments_13"
                  value={formData.comments_13}
                  onChange={(e) =>
                    setFormData({ ...formData, comments_13: e.target.value })
                  }
                />
              </div>
              <div className="pop-form-row">
                <span className="symptom">If yes, what</span>

                <input
                  className="comments"
                  name="meds_desc"
                  value={formData.meds_desc}
                  onChange={(e) =>
                    setFormData({ ...formData, meds_desc: e.target.value })
                  }
                />
              </div>
              <div className="pop-form-row mobile mobile-two">
                <span className="symptom">Pain Level:</span>
                <div className="bj-flex">
                  <p className="yes-no">no pain</p>
                  <p className="yes-no">1-10 </p>
                  <p className="yes-no">severe pain</p>
                </div>
                <label>
                  {" "}
                  <input
                    className="comments"
                    name="pain_level"
                    value={formData.pain_level}
                    onChange={(e) =>
                      setFormData({ ...formData, pain_level: e.target.value })
                    }
                  />{" "}
                  /10
                </label>
                <input
                  className="comments"
                  name="pain_desc"
                  value={formData.pain_desc}
                  onChange={(e) =>
                    setFormData({ ...formData, pain_desc: e.target.value })
                  }
                />
              </div>
              <p>Additional Notes:</p>
              <textarea
                name="additional_notes"
                value={formData.additional_notes}
                onChange={(e) =>
                  setFormData({ ...formData, additional_notes: e.target.value })
                }
              ></textarea>
              <div className="mobile grievance-container">
                <label>R.N. Signature</label>
                <input type="text" />
                <label className="sig-date">Date</label>
                <input
                  type="date"
                  name="sign_date"
                  value={formData.sign_date}
                  onChange={(e) =>
                    setFormData({ ...formData, sign_date: e.target.value })
                  }
                />
                <label className="sig-date">Time</label>
                <input
                  type="time"
                  name="sign_time"
                  value={formData.sign_time}
                  onChange={(e) =>
                    setFormData({ ...formData, sign_time: e.target.value })
                  }
                />
              </div>
            </div>
          </div>
          <div className="center mt-50">
            <button className="btn-primary hover-pointer" type="submit">
              Submit
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default PostOpPatientContact;
