import React, { useState, useEffect } from "react";
import { Icon } from "@iconify/react";
import "../Forms/styles.css";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import moment from "moment";
import {
  addFile,
  getNursingPreOpAssessment,
  updateNursingPreOpAssessment,
} from "../../../utils/api";
import SignatureButton from "../../../components/SignatureButton";

const NursingPreOpAssessment = () => {
  const navigate = useNavigate();
  const { form_id } = useParams();
  const [loading, setLoading] = useState(true);
  const [individualSignature, setIndividualSignature] = useState("");
  const [formData, setFormData] = useState({
    age_two: "",
    sex_male: false,
    sex_female: false,
    ID_verbal: false,
    ID_chart: false,
    ID_armband: false,
    ID_ID: false,
    procedure: "",
    diagnosis: "",

    bp: "",
    hr: "",
    temp: "",
    resp: "",
    spotwo: "",
    wt: "",
    ht: "",
    pain: "",
    allergies_notes: "",
    current_meds: "",
    meds_taken: "",
    skin_other_notes: "",
    abdom_other_notes: "",
    limitations_other_notes: "",
    LOC_other_notes: "",
    cog_other_notes: "",
    skin_dry: false,
    skin_warm: false,
    LOC_alert: false,
    abdom_flat: false,
    limitations_none: false,
    LOC_calm: false,
    skin_pink: false,
    abdom_soft: false,
    limitations_visual: false,
    LOC_oriented: false,
    skin_pale: false,
    abdom_firm: false,
    limitations_mobility: false,
    LOC_confused: false,
    skin_cyanotic: false,
    abdom_tender: false,
    limitations_auditory: false,
    LOC_nonverbal: false,
    skin_diaphoretic: false,
    abdom_distended: false,
    limitations_language: false,
    LOC_agitated: false,
    skin_other: false,
    abdom_other: false,
    limitations_other: false,
    LOC_other: false,
    cog_other: false,
    cog_confused: false,
    cog_aware: false,
    iv_ns: false,
    iv_fluid: false,
    iv_other: "",
    iv_cath: "",
    iv_time: "",
    neuro_yes: false,
    bleeding_yes: false,
    fainting_yes: false,
    neuro_no: false,
    bleeding_no: false,
    fainting_no: false,
    diabetes_yes: false,
    hepatitis_yes: false,
    smoking_yes: false,
    diabetes_no: false,
    hepatitis_no: false,
    smoking_no: false,
    hypertension_yes: false,
    asthma_yes: false,
    heart_yes: false,
    hypertension_no: false,
    asthma_no: false,
    heart_no: false,
    comments: "",
    prior: "",
    describe: "",
    reactions: false,
    preop_meds: "",
    preop_meds_time: "",
    preop_meds_dose: "",
    preop_meds_route: "",
    preop_meds_effect: "",
    preop_meds_initial: "",
    preop_meds_two: "",
    preop_meds_time_two: "",
    preop_meds_dose_two: "",
    preop_meds_route_two: "",
    preop_meds_effect_two: "",
    preop_meds_initial_two: "",
    consent_yes: false,
    consent_no: false,
    history_yes: false,
    history_no: false,
    labs_yes: false,
    labs_no: false,
    glucose_yes: false,
    glucose_no: false,
    notified_yes: false,
    notified_no: false,
    pregnancy_yes: false,
    pregnancy_no: false,
    physical_yes: false,
    physical_no: false,
    voided_yes: false,
    voided_no: false,
    discharge_yes: false,
    discharge_no: false,
    eat_yes: false,
    eat_no: false,
    advdir_yes: false,
    advdir_no: false,
    labs_na: false,
    glucose_na: false,
    notified_na: false,
    pregnancy_na: false,
    physical_na: false,
    voided_na: false,
    eat_na: false,
    advdir_na: false,
    glucose_results: "",
    glucose_ref: "",
    pregnancy_negative: false,
    pregnancy_positive: false,
    valuables_none: false,
    valuables_removed: false,
    valuables_retained: false,
    dentures_none: false,
    dentures_removed: false,
    dentures_retained: false,
    contacts_none: false,
    contacts_removed: false,
    contacts_retained: false,
    hearing_none: false,
    hearing_removed: false,
    hearing_retained: false,
    belongings_none_one: false,
    belongings_none: false,
    belongings_retained: false,
    belongings_notes: "",
    eat_since: "",
    eat_am: false,
    eat_pm: false,
    eat_date: "",
    advdir_policy_yes: false,
    advdir_policy_no: false,
    phone: "",
    date_two: "",
    time_two: "",
    companion: "",
    allergies_NKA: false,
    iv_site: "",
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    let fileName;

    // Fetch 1: Upload Signature
    if (individualSignature !== "") {
      const response = await addFile(individualSignature);

      const data = await response.json();
      fileName = await data.fileName;
    }
    const payload = {
      rn_signature: fileName,
      ...formData,
      time_three_sig: moment().format("h:mm a"),
      date_one: moment().format("MM/DD/YYYY"),
      eat_since: moment().format("h:mm a"),

      date: moment().format("MM/DD/YYYY"),
      time: moment().format("h:mm a"),
      date_two: moment().format("MM/DD/YYYY"),
      time_two: moment().format("h:mm a"),
      status: "In Progress",
    };

    console.log("Payload Sent to Backend:", payload);

    // Fetch 2: Create/Update the Disclosure of Ownerhsip Model

    updateNursingPreOpAssessment(form_id, payload)
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        console.log("complete");
        navigate(-1);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getNursingPreOpAssessment(form_id)
      .then((res) => res.json())
      .then((data) => {
        console.log(data);

        if (data.status !== "Not Started") {
          setFormData({
            age_two: data.age_two || "",
            sex_male: data.sex_male || false,
            sex_female: data.sex_female || false,
            ID_verbal: data.ID_verbal || false,
            ID_chart: data.ID_chart || false,
            ID_armband: data.ID_armband || false,
            ID_ID: data.ID_ID || false,
            diagnosis: data.diagnosis || "",
            procedure: data.procedure || "",

            bp: data.bp || "",
            hr: data.hr || "",
            temp: data.temp || "",
            resp: data.resp || "",
            spotwo: data.spotwo || "",
            wt: data.wt || "",
            ht: data.ht || "",
            pain: data.pain || "",
            allergies_notes: data.allergies_notes || "",
            current_meds: data.current_meds || "",
            meds_taken: data.meds_taken || "",
            skin_other_notes: data.skin_other_notes || "",
            abdom_other_notes: data.abdom_other_notes || "",
            limitations_other_notes: data.limitations_other_notes || "",
            LOC_other_notes: data.LOC_other_notes || "",
            cog_other_notes: data.cog_other_notes || "",
            skin_dry: data.skin_dry || false,
            skin_warm: data.skin_warm || false,
            LOC_alert: data.LOC_alert || false,
            abdom_flat: data.abdom_flat || false,
            limitations_none: data.limitations_none || false,
            LOC_calm: data.LOC_calm || false,
            skin_pink: data.skin_pink || false,
            abdom_soft: data.abdom_soft || false,
            limitations_visual: data.limitations_visual || false,
            LOC_oriented: data.LOC_oriented || false,
            skin_pale: data.skin_pale || false,
            abdom_firm: data.abdom_firm || false,
            limitations_mobility: data.limitations_mobility || false,
            LOC_confused: data.LOC_confused || false,
            skin_cyanotic: data.skin_cyanotic || false,
            abdom_tender: data.abdom_tender || false,
            limitations_auditory: data.limitations_auditory || false,
            LOC_nonverbal: data.LOC_nonverbal || false,
            skin_diaphoretic: data.skin_diaphoretic || false,
            abdom_distended: data.abdom_distended || false,
            limitations_language: data.limitations_language || false,
            LOC_agitated: data.LOC_agitated || false,
            skin_other: data.skin_other || false,
            abdom_other: data.abdom_other || false,
            limitations_other: data.limitations_other || false,
            LOC_other: data.LOC_other || false,
            cog_other: data.cog_other || false,
            cog_confused: data.cog_confused || false,
            cog_aware: data.cog_aware || false,
            iv_ns: data.iv_ns || false,
            iv_fluid: data.iv_fluid || false,
            iv_other: data.iv_other || "",
            iv_cath: data.iv_cath || "",
            iv_time: data.iv_time || "",
            neuro_yes: data.neuro_yes || false,
            bleeding_yes: data.bleeding_yes || false,
            fainting_yes: data.fainting_yes || false,
            neuro_no: data.neuro_no || false,
            bleeding_no: data.bleeding_no || false,
            fainting_no: data.fainting_no || false,
            diabetes_yes: data.diabetes_yes || false,
            hepatitis_yes: data.hepatitis_yes || false,
            smoking_yes: data.smoking_yes || false,
            diabetes_no: data.diabetes_no || false,
            hepatitis_no: data.hepatitis_no || false,
            smoking_no: data.smoking_no || false,
            hypertension_yes: data.hypertension_yes || false,
            asthma_yes: data.asthma_yes || false,
            heart_yes: data.heart_yes || false,
            hypertension_no: data.hypertension_no || false,
            asthma_no: data.asthma_no || false,
            heart_no: data.heart_no || false,
            comments: data.comments || "",
            prior: data.prior || "",
            describe: data.describe || "",
            reactions: data.reactions || false,
            preop_meds: data.preop_meds || "",
            preop_meds_time: data.preop_meds_time || "",
            preop_meds_dose: data.preop_meds_dose || "",
            preop_meds_route: data.preop_meds_route || "",
            preop_meds_effect: data.preop_meds_effect || "",
            preop_meds_initial: data.preop_meds_initial || "",
            preop_meds_two: data.preop_meds_two || "",
            preop_meds_time_two: data.preop_meds_time_two || "",
            preop_meds_dose_two: data.preop_meds_dose_two || "",
            preop_meds_route_two: data.preop_meds_route_two || "",
            preop_meds_effect_two: data.preop_meds_effect_two || "",
            preop_meds_initial_two: data.preop_meds_initial_two || "",
            consent_yes: data.consent_yes || false,
            consent_no: data.consent_no || false,
            history_yes: data.history_yes || false,
            history_no: data.history_no || false,
            labs_yes: data.labs_yes || false,
            labs_no: data.labs_no || false,
            glucose_yes: data.glucose_yes || false,
            glucose_no: data.glucose_no || false,
            notified_yes: data.notified_yes || false,
            notified_no: data.notified_no || false,
            pregnancy_yes: data.pregnancy_yes || false,
            pregnancy_no: data.pregnancy_no || false,
            physical_yes: data.physical_yes || false,
            physical_no: data.physical_no || false,
            voided_yes: data.voided_yes || false,
            voided_no: data.voided_no || false,
            discharge_yes: data.discharge_yes || false,
            discharge_no: data.discharge_no || false,
            eat_yes: data.eat_yes || false,
            eat_no: data.eat_no || false,
            advdir_yes: data.advdir_yes || false,
            advdir_no: data.advdir_no || false,
            labs_na: data.labs_na || false,
            glucose_na: data.glucose_na || false,
            notified_na: data.notified_na || false,
            pregnancy_na: data.pregnancy_na || false,
            physical_na: data.physical_na || false,
            voided_na: data.voided_na || false,
            eat_na: data.eat_na || false,
            advdir_na: data.advdir_na || false,
            glucose_results: data.glucose_results || "",
            glucose_ref: data.glucose_ref || "",
            pregnancy_negative: data.pregnancy_negative || false,
            pregnancy_positive: data.pregnancy_positive || false,
            valuables_none: data.valuables_none || false,
            valuables_removed: data.valuables_removed || false,
            valuables_retained: data.valuables_retained || false,
            dentures_none: data.dentures_none || false,
            dentures_removed: data.dentures_removed || false,
            dentures_retained: data.dentures_retained || false,
            contacts_none: data.contacts_none || false,
            contacts_removed: data.contacts_removed || false,
            contacts_retained: data.contacts_retained || false,
            hearing_none: data.hearing_none || false,
            hearing_removed: data.hearing_removed || false,
            hearing_retained: data.hearing_retained || false,
            belongings_none_one: data.belongings_none_one || false,
            belongings_none: data.belongings_none || false,
            belongings_retained: data.belongings_retained || false,
            belongings_notes: data.belongings_notes || "",
            eat_since: data.eat_since || "",
            eat_am: data.eat_am || false,
            eat_pm: data.eat_pm || false,
            eat_date: data.eat_date || "",
            advdir_policy_yes: data.advdir_policy_yes || false,
            advdir_policy_no: data.advdir_policy_no || false,
            phone: data.phone || "",
            date_two: data.date || "",
            time_two: data.time || "",
            companion: data.companion || "",
            allergies_NKA: data.allergies_NKA || false,
            iv_site: data.iv_site || "",
          });
          setIndividualSignature(data.rn_signature);
        }
        setLoading(false);
      })
      .catch((err) => console.log(err));
  }, []);
  if (loading) return <p>Loading...</p>;
  return (
    <>
      <div
        className="arrow-container"
        onClick={() => navigate(-1)}
        style={{ cursor: "pointer" }}
      >
        <p className="back-arrow">
          <Icon icon="ic:twotone-arrow-back" className="icon" /> Back to Forms
        </p>
      </div>{" "}
      <div className="form-content">
        <form onSubmit={handleSubmit}>
          {" "}
          <h2 className="form-title">Nursing Pre-Op Assessment</h2>
          <div className="label-indent">
            <label>Age:</label>
            <input
              type="text"
              className="di-input"
              name="age_two"
              value={formData.age_two}
              onChange={(e) =>
                setFormData({ ...formData, age_two: e.target.value })
              }
            />
            <label>Sex:</label>
            <input
              type="checkbox"
              name="sex_male"
              checked={formData.sex_male}
              onChange={(e) =>
                setFormData({ ...formData, sex_male: e.target.checked })
              }
            />
            <label>Male</label>
            <input
              type="checkbox"
              name="sex_female"
              checked={formData.sex_female}
              onChange={(e) =>
                setFormData({ ...formData, sex_female: e.target.checked })
              }
            />
            <label>Female</label>
          </div>
          <div className="label-indent">
            <label>Patient Identification</label>
            <input
              type="checkbox"
              name="ID_verbal"
              checked={formData.ID_verbal}
              onChange={(e) =>
                setFormData({ ...formData, ID_verbal: e.target.checked })
              }
            />
            <label>Verbal</label>
            <input
              type="checkbox"
              name="ID_chart"
              checked={formData.ID_chart}
              onChange={(e) =>
                setFormData({ ...formData, ID_chart: e.target.checked })
              }
            />
            <label>Chart</label>
            <input
              type="checkbox"
              name="ID_armband"
              checked={formData.ID_armband}
              onChange={(e) =>
                setFormData({ ...formData, ID_armband: e.target.checked })
              }
            />
            <label>Armband</label>
            <input
              type="checkbox"
              name="ID_ID"
              checked={formData.ID_ID}
              onChange={(e) =>
                setFormData({ ...formData, ID_ID: e.target.checked })
              }
            />
            <label>ID</label>
          </div>
          <div className="label-indent">
            <label>Diagnosis:</label>
            <input
              type="text"
              className="long-input"
              name="diagnosis"
              value={formData.diagnosis}
              onChange={(e) =>
                setFormData({ ...formData, diagnosis: e.target.value })
              }
            />
          </div>
          <div className="label-indent">
            <label>Procedure:</label>
            <input
              type="text"
              className="long-input"
              name="procedure"
              value={formData.procedure}
              onChange={(e) =>
                setFormData({ ...formData, procedure: e.target.value })
              }
            />
          </div>
          <div className="label-indent">
            <label>BP:</label>
            <input
              type="text"
              className="short-input"
              name="bp"
              value={formData.bp}
              onChange={(e) => setFormData({ ...formData, bp: e.target.value })}
            />
            <label>HR:</label>
            <input
              type="text"
              className="short-input"
              name="hr"
              value={formData.hr}
              onChange={(e) => setFormData({ ...formData, hr: e.target.value })}
            />
            <label>Temp:</label>
            <input
              type="text"
              className="short-input"
              name="temp"
              value={formData.temp}
              onChange={(e) =>
                setFormData({ ...formData, temp: e.target.value })
              }
            />
            <label>Resp:</label>
            <input
              type="text"
              className="short-input"
              name="resp"
              value={formData.resp}
              onChange={(e) =>
                setFormData({ ...formData, resp: e.target.value })
              }
            />
            <label>SpO2:</label>
            <input
              type="text"
              className="short-input"
              name="spotwo"
              value={formData.spotwo}
              onChange={(e) =>
                setFormData({ ...formData, spotwo: e.target.value })
              }
            />
            <label>Wt:</label>
            <input
              type="text"
              className="short-input"
              name="wt"
              value={formData.wt}
              onChange={(e) => setFormData({ ...formData, wt: e.target.value })}
            />
            <label>Ht:</label>
            <input
              type="text"
              className="short-input"
              name="ht"
              value={formData.ht}
              onChange={(e) => setFormData({ ...formData, ht: e.target.value })}
            />
            <label>Pain level:</label>
            <input
              type="text"
              className="short-input"
              name="pain"
              value={formData.pain}
              onChange={(e) =>
                setFormData({ ...formData, pain: e.target.value })
              }
            />
            <label>/10</label>
          </div>
          <div className="label-indent">
            <b>Allergies</b>
            <input
              type="checkbox"
              name="allergies_NKA"
              checked={formData.allergies_NKA}
              onChange={(e) =>
                setFormData({ ...formData, allergies_NKA: e.target.checked })
              }
            />
            <label>NKA or</label>
            <input
              type="text"
              className="di-input"
              name="allergies_notes"
              value={formData.allergies_notes}
              onChange={(e) =>
                setFormData({ ...formData, allergies_notes: e.target.value })
              }
            />
          </div>
          <div className="label-indent">
            <label>
              Current Medications (give name, dose, route and frequency)
            </label>
            <input
              type="text"
              className="di-input"
              name="current_meds"
              value={formData.current_meds}
              onChange={(e) =>
                setFormData({ ...formData, current_meds: e.target.value })
              }
            />
          </div>
          <div className="label-indent">
            <label>Medications taken to today prior to arrival: </label>
            <input
              type="text"
              className="di-input"
              name="meds_taken"
              value={formData.meds_taken}
              onChange={(e) =>
                setFormData({ ...formData, meds_taken: e.target.value })
              }
            />
          </div>
          <div className="label-indent">
            <label>
              <i>Skin Condition:</i>
            </label>
            <input
              type="checkbox"
              name="skin_dry"
              checked={formData.skin_dry}
              onChange={(e) =>
                setFormData({ ...formData, skin_dry: e.target.checked })
              }
            />

            <label>Dry</label>
            <input
              type="checkbox"
              name="skin_warm"
              checked={formData.skin_warm}
              onChange={(e) =>
                setFormData({ ...formData, skin_warm: e.target.checked })
              }
            />
            <label>Warm</label>
            <input
              type="checkbox"
              name="skin_pink"
              checked={formData.skin_pink}
              onChange={(e) =>
                setFormData({ ...formData, skin_pink: e.target.checked })
              }
            />
            <label>Pink</label>
            <input
              type="checkbox"
              name="skin_pale"
              checked={formData.skin_pale}
              onChange={(e) =>
                setFormData({ ...formData, skin_pale: e.target.checked })
              }
            />
            <label>Pale</label>
            <input
              type="checkbox"
              name="skin_cyanotic"
              checked={formData.skin_cyanotic}
              onChange={(e) =>
                setFormData({ ...formData, skin_cyanotic: e.target.checked })
              }
            />
            <label>Cyanotic</label>
            <input
              type="checkbox"
              name="skin_diaphoretic"
              checked={formData.skin_diaphoretic}
              onChange={(e) =>
                setFormData({ ...formData, skin_diaphoretic: e.target.checked })
              }
            />
            <label>Diaphoretic</label>
            <input
              type="checkbox"
              name="skin_other"
              checked={formData.skin_other}
              onChange={(e) =>
                setFormData({ ...formData, skin_other: e.target.checked })
              }
            />
            <label>Other:</label>
            <input
              type="text"
              className="di-input"
              name="skin_other_notes"
              value={formData.skin_other_notes}
              onChange={(e) =>
                setFormData({ ...formData, skin_other_notes: e.target.value })
              }
            />
          </div>
          <div className="label-indent">
            <label>
              <i>Abdominal Assessment:</i>
            </label>
            <input
              type="checkbox"
              name="abdom_flat"
              checked={formData.abdom_flat}
              onChange={(e) =>
                setFormData({ ...formData, abdom_flat: e.target.checked })
              }
            />

            <label>Flat</label>
            <input
              type="checkbox"
              name="abdom_soft"
              checked={formData.abdom_soft}
              onChange={(e) =>
                setFormData({ ...formData, abdom_soft: e.target.checked })
              }
            />
            <label>Soft</label>
            <input
              type="checkbox"
              name="abdom_firm"
              checked={formData.abdom_firm}
              onChange={(e) =>
                setFormData({ ...formData, abdom_firm: e.target.checked })
              }
            />
            <label>Firm</label>
            <input
              type="checkbox"
              name="abdom_tender"
              checked={formData.abdom_tender}
              onChange={(e) =>
                setFormData({ ...formData, abdom_tender: e.target.checked })
              }
            />
            <label>Tender</label>
            <input
              type="checkbox"
              name="abdom_distended"
              checked={formData.abdom_distended}
              onChange={(e) =>
                setFormData({ ...formData, abdom_distended: e.target.checked })
              }
            />
            <label>Distended</label>
            <input
              type="checkbox"
              name="abdom_other"
              checked={formData.abdom_other}
              onChange={(e) =>
                setFormData({ ...formData, abdom_other: e.target.checked })
              }
            />
            <label>Other:</label>
            <input
              type="text"
              className="di-input"
              name="abdom_other_notes"
              value={formData.abdom_other_notes}
              onChange={(e) =>
                setFormData({ ...formData, abdom_other_notes: e.target.value })
              }
            />
          </div>
          <div className="label-indent">
            <label>
              <i>Physical Limitations:</i>
            </label>
            <input
              type="checkbox"
              name="limitations_none"
              checked={formData.limitations_none}
              onChange={(e) =>
                setFormData({ ...formData, limitations_none: e.target.checked })
              }
            />

            <label>None</label>
            <input
              type="checkbox"
              name="limitations_visual"
              checked={formData.limitations_visual}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  limitations_visual: e.target.checked,
                })
              }
            />
            <label>Visual</label>
            <input
              type="checkbox"
              name="limitations_mobility"
              checked={formData.limitations_mobility}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  limitations_mobility: e.target.checked,
                })
              }
            />
            <label>Mobility</label>
            <input
              type="checkbox"
              name="limitations_auditory"
              checked={formData.limitations_auditory}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  limitations_auditory: e.target.checked,
                })
              }
            />
            <label>Auditory</label>
            <input
              type="checkbox"
              name="limitations_language"
              checked={formData.limitations_language}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  limitations_language: e.target.checked,
                })
              }
            />
            <label>Language</label>

            <input
              type="checkbox"
              name="limitations_other"
              checked={formData.limitations_other}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  limitations_other: e.target.checked,
                })
              }
            />
            <label>Other:</label>

            <input
              type="text"
              className="di-input"
              name="limitations_other_notes"
              value={formData.limitations_other_notes}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  limitations_other_notes: e.target.value,
                })
              }
            />
          </div>
          <div className="label-indent">
            <label>
              <i>LOC:</i>
            </label>
            <input
              type="checkbox"
              name="LOC_alert"
              checked={formData.LOC_alert}
              onChange={(e) =>
                setFormData({ ...formData, LOC_alert: e.target.checked })
              }
            />

            <label>Alert</label>
            <input
              type="checkbox"
              name="LOC_calm"
              checked={formData.LOC_calm}
              onChange={(e) =>
                setFormData({ ...formData, LOC_calm: e.target.checked })
              }
            />
            <label>Calm</label>
            <input
              type="checkbox"
              name="LOC_oriented"
              checked={formData.LOC_oriented}
              onChange={(e) =>
                setFormData({ ...formData, LOC_oriented: e.target.checked })
              }
            />
            <label>Oriented</label>
            <input
              type="checkbox"
              name="LOC_confused"
              checked={formData.LOC_confused}
              onChange={(e) =>
                setFormData({ ...formData, LOC_confused: e.target.checked })
              }
            />
            <label>Confused</label>
            <input
              type="checkbox"
              name="LOC_nonverbal"
              checked={formData.LOC_nonverbal}
              onChange={(e) =>
                setFormData({ ...formData, LOC_nonverbal: e.target.checked })
              }
            />
            <label>Nonverbal</label>
            <input
              type="checkbox"
              name="LOC_agitated"
              checked={formData.LOC_agitated}
              onChange={(e) =>
                setFormData({ ...formData, LOC_agitated: e.target.checked })
              }
            />
            <label>Agitated</label>
            <input
              type="checkbox"
              name="LOC_other"
              checked={formData.LOC_other}
              onChange={(e) =>
                setFormData({ ...formData, LOC_other: e.target.checked })
              }
            />
            <label>Other:</label>
            <input
              type="text"
              className="di-input"
              name="LOC_other_notes"
              value={formData.LOC_other_notes}
              onChange={(e) =>
                setFormData({ ...formData, LOC_other_notes: e.target.value })
              }
            />
          </div>
          <div className="label-indent">
            <label>
              <i>Cognitive Evaluation:</i>
            </label>
            <input
              type="checkbox"
              name="cog_aware"
              checked={formData.cog_aware}
              onChange={(e) =>
                setFormData({ ...formData, cog_aware: e.target.checked })
              }
            />

            <label>Cognizant/aware</label>
            <input
              type="checkbox"
              name="cog_confused"
              checked={formData.cog_confused}
              onChange={(e) =>
                setFormData({ ...formData, cog_confused: e.target.checked })
              }
            />
            <label>Confused/unaware</label>
            <input
              type="checkbox"
              name="cog_other"
              checked={formData.cog_other}
              onChange={(e) =>
                setFormData({ ...formData, cog_other: e.target.checked })
              }
            />
            <label>Other:</label>
            <input
              type="text"
              className="di-input"
              name="cog_other_notes"
              value={formData.cog_other_notes}
              onChange={(e) =>
                setFormData({ ...formData, cog_other_notes: e.target.value })
              }
            />
          </div>
          <div className="label-indent">
            <label>
              <i>I.V. Site:</i>
            </label>{" "}
            <input
              type="text"
              name="iv_site"
              value={formData.iv_site}
              onChange={(e) =>
                setFormData({ ...formData, iv_site: e.target.value })
              }
            />
            <label>Fluid:</label>
            <input
              type="checkbox"
              name="iv_fluid"
              checked={formData.iv_fluid}
              onChange={(e) =>
                setFormData({ ...formData, iv_fluid: e.target.checked })
              }
            />
            <label>LR 1000</label>
            <input
              type="checkbox"
              name="iv_ns"
              checked={formData.iv_ns}
              onChange={(e) =>
                setFormData({ ...formData, iv_ns: e.target.checked })
              }
            />
            <label>NS 1000</label>
            <label>Other:</label>
            <input
              type="text"
              className="di-input"
              name="iv_other"
              value={formData.iv_other}
              onChange={(e) =>
                setFormData({ ...formData, iv_other: e.target.value })
              }
            />
            <label>Cath Size:</label>
            <input
              type="text"
              className="di-input"
              name="iv_cath"
              value={formData.iv_cath}
              onChange={(e) =>
                setFormData({ ...formData, iv_cath: e.target.value })
              }
            />
            <label>Time:</label>
            <input
              type="text"
              className="di-input"
              name="iv_time"
              value={formData.iv_time}
              onChange={(e) =>
                setFormData({ ...formData, iv_time: e.target.value })
              }
            />
          </div>
          <div className="label-indent">
            <label>Neurological Problems</label>
            <input
              type="checkbox"
              name="neuro_yes"
              checked={formData.neuro_yes}
              onChange={(e) =>
                setFormData({ ...formData, neuro_yes: e.target.checked })
              }
            />
            <label>Yes</label>
            <input
              type="checkbox"
              name="neuro_no"
              checked={formData.neuro_no}
              onChange={(e) =>
                setFormData({ ...formData, neuro_no: e.target.checked })
              }
            />
            <label>No</label>
            <label>Diabetes</label>
            <input
              type="checkbox"
              name="diabetes_yes"
              checked={formData.diabetes_yes}
              onChange={(e) =>
                setFormData({ ...formData, diabetes_yes: e.target.checked })
              }
            />
            <label>Yes</label>
            <input
              type="checkbox"
              name="diabetes_no"
              checked={formData.diabetes_no}
              onChange={(e) =>
                setFormData({ ...formData, diabetes_no: e.target.checked })
              }
            />
            <label>No</label>
            <label>Hypertension</label>
            <input
              type="checkbox"
              name="hypertension_yes"
              checked={formData.hypertension_yes}
              onChange={(e) =>
                setFormData({ ...formData, hypertension_yes: e.target.checked })
              }
            />
            <label>Yes</label>
            <input
              type="checkbox"
              name="hypertension_no"
              checked={formData.hypertension_no}
              onChange={(e) =>
                setFormData({ ...formData, hypertension_no: e.target.checked })
              }
            />
            <label>No</label>
          </div>
          <div className="label-indent">
            <label>Bleeding Problems</label>
            <input
              type="checkbox"
              name="bleeding_yes"
              checked={formData.bleeding_yes}
              onChange={(e) =>
                setFormData({ ...formData, bleeding_yes: e.target.checked })
              }
            />
            <label>Yes</label>
            <input
              type="checkbox"
              name="bleeding_no"
              checked={formData.bleeding_no}
              onChange={(e) =>
                setFormData({ ...formData, bleeding_no: e.target.checked })
              }
            />
            <label>No</label>
            <label>Hepatitis</label>
            <input
              type="checkbox"
              name="hepatitis_yes"
              checked={formData.hepatitis_yes}
              onChange={(e) =>
                setFormData({ ...formData, hepatitis_yes: e.target.checked })
              }
            />
            <label>Yes</label>
            <input
              type="checkbox"
              name="hepatitis_no"
              checked={formData.hepatitis_no}
              onChange={(e) =>
                setFormData({ ...formData, hepatitis_no: e.target.checked })
              }
            />
            <label>No</label>
            <label>Asthma</label>
            <input
              type="checkbox"
              name="asthma_yes"
              checked={formData.asthma_yes}
              onChange={(e) =>
                setFormData({ ...formData, asthma_yes: e.target.checked })
              }
            />
            <label>Yes</label>
            <input
              type="checkbox"
              name="asthma_no"
              checked={formData.asthma_no}
              onChange={(e) =>
                setFormData({ ...formData, asthma_no: e.target.checked })
              }
            />
            <label>No</label>
          </div>
          <div className="label-indent">
            <label>Fainting/Dizziness</label>
            <input
              type="checkbox"
              name="fainting_yes"
              checked={formData.fainting_yes}
              onChange={(e) =>
                setFormData({ ...formData, fainting_yes: e.target.checked })
              }
            />
            <label>Yes</label>
            <input
              type="checkbox"
              name="fainting_no"
              checked={formData.fainting_no}
              onChange={(e) =>
                setFormData({ ...formData, fainting_no: e.target.checked })
              }
            />
            <label>No</label>
            <label>Smoking</label>
            <input
              type="checkbox"
              name="smoking_yes"
              checked={formData.smoking_yes}
              onChange={(e) =>
                setFormData({ ...formData, smoking_yes: e.target.checked })
              }
            />
            <label>Yes</label>
            <input
              type="checkbox"
              name="smoking_no"
              checked={formData.smoking_no}
              onChange={(e) =>
                setFormData({ ...formData, smoking_no: e.target.checked })
              }
            />
            <label>No</label>
            <label>Heart Disease</label>
            <input
              type="checkbox"
              name="heart_yes"
              checked={formData.heart_yes}
              onChange={(e) =>
                setFormData({ ...formData, heart_yes: e.target.checked })
              }
            />
            <label>Yes</label>
            <input
              type="checkbox"
              name="heart_no"
              checked={formData.heart_no}
              onChange={(e) =>
                setFormData({ ...formData, heart_no: e.target.checked })
              }
            />
            <label>No</label>
          </div>
          <div className="label-indent">
            <label>Comments:</label>
            <input
              type="text"
              className="di-input"
              name="comments"
              value={formData.comments}
              onChange={(e) =>
                setFormData({ ...formData, comments: e.target.value })
              }
            />
          </div>
          <div className="label-indent">
            <label>Prior Surgeries:</label>
            <input
              type="text"
              className="di-input"
              name="prior"
              value={formData.prior}
              onChange={(e) =>
                setFormData({ ...formData, prior: e.target.value })
              }
            />
          </div>
          <div className="label-indent">
            <label>Reactions to anethesia:</label>
            <input
              type="checkbox"
              name="reactions"
              checked={formData.reactions}
              onChange={(e) =>
                setFormData({ ...formData, reactions: e.target.checked })
              }
            />
            <label>None or describe:</label>
            <input
              type="text"
              className="di-input"
              name="describe"
              value={formData.describe}
              onChange={(e) =>
                setFormData({ ...formData, describe: e.target.value })
              }
            />
          </div>
          <div className="preop-meds-container">
            <div className="preop-meds-row">
              <div className="preop-meds-column">Pre-Op Meds Given</div>
              <div className="preop-meds-column">Time</div>
              <div className="preop-meds-column">Dose</div>
              <div className="preop-meds-column">Route</div>
              <div className="preop-meds-column">Effect</div>
              <div className="preop-meds-column">Initial</div>
            </div>

            <div className="preop-meds-row">
              <div className="preop-meds-column">
                <input
                  className="ir-no-border"
                  type="text"
                  name="preop_meds"
                  value={formData.preop_meds}
                  onChange={(e) =>
                    setFormData({ ...formData, preop_meds: e.target.value })
                  }
                />
              </div>
              <div className="preop-meds-column">
                {" "}
                <input
                  className="ir-no-border"
                  type="text"
                  name="preop_meds_time"
                  value={formData.preop_meds_time}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      preop_meds_time: e.target.value,
                    })
                  }
                />
              </div>
              <div className="preop-meds-column">
                {" "}
                <input
                  className="ir-no-border"
                  type="text"
                  name="preop_meds_dose"
                  value={formData.preop_meds_dose}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      preop_meds_dose: e.target.value,
                    })
                  }
                />
              </div>
              <div className="preop-meds-column">
                {" "}
                <input
                  className="ir-no-border"
                  type="text"
                  name="preop_meds_route"
                  value={formData.preop_meds_route}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      preop_meds_route: e.target.value,
                    })
                  }
                />
              </div>
              <div className="preop-meds-column">
                {" "}
                <input
                  className="ir-no-border"
                  type="text"
                  name="preop_meds_effect"
                  value={formData.preop_meds_effect}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      preop_meds_effect: e.target.value,
                    })
                  }
                />
              </div>
              <div className="preop-meds-column">
                {" "}
                <input
                  className="ir-no-border"
                  type="text"
                  name="preop_meds_initial"
                  value={formData.preop_meds_initial}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      preop_meds_initial: e.target.value,
                    })
                  }
                />
              </div>
            </div>

            <div className="preop-meds-row">
              <div className="preop-meds-column">
                {" "}
                <input
                  className="ir-no-border"
                  type="text"
                  name="preop_meds_two"
                  value={formData.preop_meds_two}
                  onChange={(e) =>
                    setFormData({ ...formData, preop_meds_two: e.target.value })
                  }
                />
              </div>
              <div className="preop-meds-column">
                {" "}
                <input
                  className="ir-no-border"
                  type="text"
                  name="preop_meds_time_two"
                  value={formData.preop_meds_time_two}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      preop_meds_time_two: e.target.value,
                    })
                  }
                />
              </div>
              <div className="preop-meds-column">
                {" "}
                <input
                  className="ir-no-border"
                  type="text"
                  name="preop_meds_dose_two"
                  value={formData.preop_meds_dose_two}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      preop_meds_dose_two: e.target.value,
                    })
                  }
                />
              </div>
              <div className="preop-meds-column">
                {" "}
                <input
                  className="ir-no-border"
                  type="text"
                  name="preop_meds_route_two"
                  value={formData.preop_meds_route_two}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      preop_meds_route_two: e.target.value,
                    })
                  }
                />
              </div>
              <div className="preop-meds-column">
                {" "}
                <input
                  className="ir-no-border"
                  type="text"
                  name="preop_meds_effect_two"
                  value={formData.preop_meds_effect_two}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      preop_meds_effect_two: e.target.value,
                    })
                  }
                />
              </div>
              <div className="preop-meds-column">
                {" "}
                <input
                  className="ir-no-border"
                  type="text"
                  name="preop_meds_initial_two"
                  value={formData.preop_meds_initial_two}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      preop_meds_initial_two: e.target.value,
                    })
                  }
                />
              </div>
            </div>
          </div>
          <div className="label-indent">
            <input
              type="checkbox"
              name="consent_yes"
              checked={formData.consent_yes}
              onChange={(e) =>
                setFormData({ ...formData, consent_yes: e.target.checked })
              }
            />
            <label>Yes</label>
            <input
              type="checkbox"
              name="consent_no"
              checked={formData.consent_no}
              onChange={(e) =>
                setFormData({ ...formData, consent_no: e.target.checked })
              }
            />
            <label>No</label>
            <input type="checkbox" />
            <label>N/A&nbsp;&nbsp;</label>
            <label>1. Consents signed.</label>
          </div>
          <div className="label-indent">
            <input
              type="checkbox"
              name="history_yes"
              checked={formData.history_yes}
              onChange={(e) =>
                setFormData({ ...formData, history_yes: e.target.checked })
              }
            />
            <label>Yes</label>
            <input
              type="checkbox"
              name="history_no"
              checked={formData.history_no}
              onChange={(e) =>
                setFormData({ ...formData, history_no: e.target.checked })
              }
            />
            <label>No</label>
            <input type="checkbox" />
            <label>N/A&nbsp;&nbsp;</label>
            <label>2. History & Physical complete.</label>
          </div>
          <div className="label-indent">
            <input
              type="checkbox"
              name="labs_yes"
              checked={formData.labs_yes}
              onChange={(e) =>
                setFormData({ ...formData, labs_yes: e.target.checked })
              }
            />
            <label>Yes</label>
            <input
              type="checkbox"
              name="labs_no"
              checked={formData.labs_no}
              onChange={(e) =>
                setFormData({ ...formData, labs_no: e.target.checked })
              }
            />
            <label>No</label>
            <input
              type="checkbox"
              name="labs_na"
              checked={formData.labs_na}
              onChange={(e) =>
                setFormData({ ...formData, labs_na: e.target.checked })
              }
            />
            <label>N/A&nbsp;&nbsp;</label>
            <label>3. Labs completed as ordered by physician. </label>
          </div>
          <div className="label-indent">
            <input
              type="checkbox"
              name="glucose_yes"
              checked={formData.glucose_yes}
              onChange={(e) =>
                setFormData({ ...formData, glucose_yes: e.target.checked })
              }
            />
            <label>Yes</label>
            <input
              type="checkbox"
              name="glucose_no"
              checked={formData.glucose_no}
              onChange={(e) =>
                setFormData({ ...formData, glucose_no: e.target.checked })
              }
            />
            <label>No</label>
            <input
              type="checkbox"
              name="glucose_na"
              checked={formData.glucose_na}
              onChange={(e) =>
                setFormData({ ...formData, glucose_na: e.target.checked })
              }
            />
            <label>N/A&nbsp;&nbsp;</label>
            <label>4. Blood glucose test</label>
            <label>Results:</label>
            <input
              type="text"
              className="short-input"
              name="glucose_results"
              value={formData.glucose_results}
              onChange={(e) =>
                setFormData({ ...formData, glucose_results: e.target.value })
              }
            />
            <label>Reference Range:</label>
            <input
              type="text"
              className="short-input"
              name="glucose_ref"
              value={formData.glucose_ref}
              onChange={(e) =>
                setFormData({ ...formData, glucose_ref: e.target.value })
              }
            />
          </div>
          <div className="label-indent">
            <input
              type="checkbox"
              name="notified_yes"
              checked={formData.notified_yes}
              onChange={(e) =>
                setFormData({ ...formData, notified_yes: e.target.checked })
              }
            />
            <label>Yes</label>
            <input
              type="checkbox"
              name="notified_no"
              checked={formData.notified_no}
              onChange={(e) =>
                setFormData({ ...formData, notified_no: e.target.checked })
              }
            />
            <label>No</label>
            <input
              type="checkbox"
              name="notified_na"
              checked={formData.notified_na}
              onChange={(e) =>
                setFormData({ ...formData, notified_na: e.target.checked })
              }
            />
            <label>N/A&nbsp;&nbsp;</label>
            <label>5. Physician notified of abnormal results</label>
          </div>
          <div className="label-indent">
            <input
              type="checkbox"
              name="pregnancy_yes"
              checked={formData.pregnancy_yes}
              onChange={(e) =>
                setFormData({ ...formData, pregnancy_yes: e.target.checked })
              }
            />
            <label>Yes</label>
            <input
              type="checkbox"
              name="pregnancy_no"
              checked={formData.pregnancy_no}
              onChange={(e) =>
                setFormData({ ...formData, pregnancy_no: e.target.checked })
              }
            />
            <label>No</label>
            <input
              type="checkbox"
              name="pregnancy_na"
              checked={formData.pregnancy_na}
              onChange={(e) =>
                setFormData({ ...formData, pregnancy_na: e.target.checked })
              }
            />
            <label>N/A&nbsp;&nbsp;</label>
            <label>6. Pregnancy test if ordered </label>
            <input
              type="checkbox"
              name="pregnancy_positive"
              checked={formData.pregnancy_positive}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  pregnancy_positive: e.target.checked,
                })
              }
            />
            <label>Yes</label>
            <input
              type="checkbox"
              name="pregnancy_negative"
              checked={formData.pregnancy_negative}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  pregnancy_negative: e.target.checked,
                })
              }
            />
            <label>No</label>
          </div>
          <div className="label-indent">
            <input
              type="checkbox"
              name="physical_yes"
              checked={formData.physical_yes}
              onChange={(e) =>
                setFormData({ ...formData, physical_yes: e.target.checked })
              }
            />
            <label>Yes</label>
            <input
              type="checkbox"
              name="physical_no"
              checked={formData.physical_no}
              onChange={(e) =>
                setFormData({ ...formData, physical_no: e.target.checked })
              }
            />
            <label>No</label>
            <input
              type="checkbox"
              name="physical_na"
              checked={formData.physical_na}
              onChange={(e) =>
                setFormData({ ...formData, physical_na: e.target.checked })
              }
            />
            <label>N/A &nbsp;&nbsp;</label>
            <label>
              7. Physical assessment is unchanged since pre-admission evaluation
            </label>
          </div>
          <div className="label-indent">
            <label>8. Valuables (clothing and jewelry)</label>
            <input
              type="checkbox"
              name="valuables_none"
              checked={formData.valuables_none}
              onChange={(e) =>
                setFormData({ ...formData, valuables_none: e.target.checked })
              }
            />
            <label>None</label>
            <input
              type="checkbox"
              name="valuables_removed"
              checked={formData.valuables_removed}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  valuables_removed: e.target.checked,
                })
              }
            />
            <label>Removed</label>
            <input
              type="checkbox"
              name="valuables_retained"
              checked={formData.valuables_retained}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  valuables_retained: e.target.checked,
                })
              }
            />
            <label>Retained</label>
          </div>
          <div className="label-indent">
            <label>9. Dentures/partial plates</label>
            <input
              type="checkbox"
              name="dentures_none"
              checked={formData.dentures_none}
              onChange={(e) =>
                setFormData({ ...formData, dentures_none: e.target.checked })
              }
            />
            <label>None</label>
            <input
              type="checkbox"
              name="dentures_removed"
              checked={formData.dentures_removed}
              onChange={(e) =>
                setFormData({ ...formData, dentures_removed: e.target.checked })
              }
            />
            <label>Removed</label>
            <input
              type="checkbox"
              name="dentures_retained"
              checked={formData.dentures_retained}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  dentures_retained: e.target.checked,
                })
              }
            />
            <label>Retained</label>
          </div>
          <div className="label-indent">
            <label>10. Contact lenses/glasses</label>
            <input
              type="checkbox"
              name="contacts_none"
              checked={formData.contacts_none}
              onChange={(e) =>
                setFormData({ ...formData, contacts_none: e.target.checked })
              }
            />
            <label>None</label>
            <input
              type="checkbox"
              name="contacts_removed"
              checked={formData.contacts_removed}
              onChange={(e) =>
                setFormData({ ...formData, contacts_removed: e.target.checked })
              }
            />
            <label>Removed</label>
            <input
              type="checkbox"
              name="contacts_retained"
              checked={formData.contacts_retained}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  contacts_retained: e.target.checked,
                })
              }
            />
            <label>Retained</label>
          </div>
          <div className="label-indent">
            <label>11. Hearing Aid</label>
            <input
              type="checkbox"
              name="hearing_none"
              checked={formData.hearing_none}
              onChange={(e) =>
                setFormData({ ...formData, hearing_none: e.target.checked })
              }
            />
            <label>None</label>
            <input
              type="checkbox"
              name="hearing_removed"
              checked={formData.hearing_removed}
              onChange={(e) =>
                setFormData({ ...formData, hearing_removed: e.target.checked })
              }
            />
            <label>Removed</label>
            <input
              type="checkbox"
              name="hearing_retained"
              checked={formData.hearing_retained}
              onChange={(e) =>
                setFormData({ ...formData, hearing_retained: e.target.checked })
              }
            />
            <label>Retained</label>
          </div>
          <div className="label-indent">
            <label>12. Other belongings:</label>
            <input
              type="text"
              className="di-input"
              name="belongings_notes"
              value={formData.belongings_notes}
              onChange={(e) =>
                setFormData({ ...formData, belongings_notes: e.target.value })
              }
            />
            <input
              type="checkbox"
              name="belongings_none_one"
              checked={formData.belongings_none_one}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  belongings_none_one: e.target.checked,
                })
              }
            />
            <label>None</label>
            <input
              type="checkbox"
              name="belongings_none"
              checked={formData.belongings_none}
              onChange={(e) =>
                setFormData({ ...formData, belongings_none: e.target.checked })
              }
            />
            <label>Removed</label>
            <input
              type="checkbox"
              name="belongings_retained"
              checked={formData.belongings_retained}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  belongings_retained: e.target.checked,
                })
              }
            />
            <label>Retained</label>
          </div>
          <div className="label-indent">
            <input
              type="checkbox"
              name="voided_yes"
              checked={formData.voided_yes}
              onChange={(e) =>
                setFormData({ ...formData, voided_yes: e.target.checked })
              }
            />
            <label>Yes</label>
            <input
              type="checkbox"
              name="voided_no"
              checked={formData.voided_no}
              onChange={(e) =>
                setFormData({ ...formData, voided_no: e.target.checked })
              }
            />
            <label>No</label>
            <input
              type="checkbox"
              name="voided_na"
              checked={formData.voided_na}
              onChange={(e) =>
                setFormData({ ...formData, voided_na: e.target.checked })
              }
            />
            <label>N/A&nbsp;&nbsp;</label>
            <label>13. Voided</label>
          </div>
          <div className="label-indent">
            <input
              type="checkbox"
              name="discharge_yes"
              checked={formData.discharge_yes}
              onChange={(e) =>
                setFormData({ ...formData, discharge_yes: e.target.checked })
              }
            />
            <label>Yes</label>
            <input
              type="checkbox"
              name="discharge_no"
              checked={formData.discharge_no}
              onChange={(e) =>
                setFormData({ ...formData, discharge_no: e.target.checked })
              }
            />
            <label>No&nbsp;&nbsp;</label>
            <label>14. Discharge Instructions reviewed with patient</label>
          </div>
          <div className="label-indent">
            <input
              type="checkbox"
              name="eat_yes"
              checked={formData.eat_yes}
              onChange={(e) =>
                setFormData({ ...formData, eat_yes: e.target.checked })
              }
            />
            <label>Yes</label>
            <input
              type="checkbox"
              name="eat_no"
              checked={formData.eat_no}
              onChange={(e) =>
                setFormData({ ...formData, eat_no: e.target.checked })
              }
            />
            <label>No</label>
            <input
              type="checkbox"
              name="eat_na"
              checked={formData.eat_na}
              onChange={(e) =>
                setFormData({ ...formData, eat_na: e.target.checked })
              }
            />
            <label>N/A&nbsp;&nbsp;</label>
            <label>
              15. Patient reports nothing to eat or drink(including water)
              since:
            </label>
            <input
              type="time"
              className="di-input"
              name="eat_since"
              value={formData.eat_since}
              onChange={(e) =>
                setFormData({ ...formData, eat_since: e.target.value })
              }
            />
            <label>am/pm</label>
            <br />
            <label>Date:</label>
            <input
              type="date"
              className="di-input"
              name="eat_date"
              value={formData.eat_date}
              onChange={(e) =>
                setFormData({ ...formData, eat_date: e.target.value })
              }
            />
          </div>
          <div className="label-indent">
            <input
              type="checkbox"
              name="advdir_yes"
              checked={formData.advdir_yes}
              onChange={(e) =>
                setFormData({ ...formData, advdir_yes: e.target.checked })
              }
            />
            <label>Yes</label>
            <input
              type="checkbox"
              name="advdir_no"
              checked={formData.advdir_no}
              onChange={(e) =>
                setFormData({ ...formData, advdir_no: e.target.checked })
              }
            />
            <label>No</label>
            <input
              type="checkbox"
              name="advdir_na"
              checked={formData.advdir_na}
              onChange={(e) =>
                setFormData({ ...formData, advdir_na: e.target.checked })
              }
            />
            <label>N/A&nbsp;&nbsp;</label>
            <label>
              16. Advanced Directive: If yes, Center policy explained to patient
            </label>
            <input
              type="checkbox"
              name="advdir_policy_yes"
              checked={formData.advdir_policy_yes}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  advdir_policy_yes: e.target.checked,
                })
              }
            />
            <label>Yes</label>
            <input
              type="checkbox"
              name="advdir_policy_no"
              checked={formData.advdir_policy_no}
              onChange={(e) =>
                setFormData({ ...formData, advdir_policy_no: e.target.checked })
              }
            />
            <label>No</label>
          </div>
          <div className="label-indent">
            <label>
              I have arranged to have responsible adult companion drive home.
              Contact phone#
            </label>
            <input
              type="text"
              className="di-input"
              name="phone"
              value={formData.phone}
              onChange={(e) =>
                setFormData({ ...formData, phone: e.target.value })
              }
            />
          </div>
          <div className="grievance-container mt-50 mobile">
            <div className="gc-item">
              <SignatureButton
                individualSignature={individualSignature}
                setIndividualSignature={setIndividualSignature}
              />
              <hr className="signature-line"></hr>
              <p>RN Signature</p>
            </div>
            <div className="gc-item mt-50">
              {" "}
              <label htmlFor="name" className="hidden">
                RN Signature
              </label>
              <input
                type="date"
                id="name"
                name="date_two"
                value={formData.date_two}
                onChange={(e) =>
                  setFormData({ ...formData, date_two: e.target.value })
                }
                className="bottom-border-input"
              />
              <p>Date</p>
            </div>
            <div className="gc-item mt-50">
              {" "}
              <label htmlFor="name" className="hidden">
                Time
              </label>
              <input
                type="time"
                id="time"
                name="time_two"
                value={formData.time_two}
                onChange={(e) =>
                  setFormData({ ...formData, time_two: e.target.value })
                }
                className="bottom-border-input"
              />
              <p>Time</p>
            </div>
            <div className="gc-item">
              {" "}
              <input
                className="name-input"
                name="companion"
                value={formData.companion}
                onChange={(e) =>
                  setFormData({ ...formData, companion: e.target.value })
                }
              />
              <hr className="signature-line"></hr>
              <p>Responsible Adult/Companion Name</p>
            </div>
          </div>
          <div className="center mt-50">
            <button className="btn-primary hover-pointer" type="submit">
              Submit
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default NursingPreOpAssessment;
