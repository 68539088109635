import React, { useState, useEffect } from "react";
import { Icon } from "@iconify/react";
import "../Forms/styles.css";
import { useNavigate } from "react-router-dom";
import { getAdminFinancial, updateAdminFinancial } from "../../../utils/api";
import { useParams } from "react-router-dom";
import moment from "moment";

const AdminFinancial = () => {
  const navigate = useNavigate();
  const { form_id } = useParams();
  const [formData, setFormData] = useState({
    one: false,
    two: false,
    three: false,
    four: false,
  });
  const [loading, setLoading] = useState(true);
  // Handle checkbox change
  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: checked,
    }));
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    const payload = {
      ...formData,
      date: moment().format("MM/DD/YYYY"),
      time: moment().format("h:mm a"),
      status: "In Progress",
    };

    console.log(payload);

    // Fetch 2: ≈ Disclosure of Ownerhsip Model
    updateAdminFinancial(form_id, payload)
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        console.log("complete");
        navigate(-1);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getAdminFinancial(form_id)
      .then((res) => res.json())
      .then((data) => {
        console.log(data);

        if (data.status !== "Not Started") {
          setFormData({
            one: data.one || false,
            two: data.two || false,
            three: data.three || false,
            four: data.four || false,
          });
        }

        setLoading(false);
      })
      .catch((err) => console.log(err));
  }, []);

  if (loading) return <p>Loading...</p>;
  return (
    <>
      <div
        className="arrow-container"
        onClick={() => navigate(-1)}
        style={{ cursor: "pointer" }}
      >
        <p className="back-arrow">
          <Icon icon="ic:twotone-arrow-back" className="icon" /> Back to Forms
        </p>
      </div>{" "}
      <div className="form-content">
        <form onSubmit={handleSubmit}>
          {" "}
          <h2 className="form-title">Admin Financial</h2>
          <h2 className="form-title">
            THERE WILL BE 4 SEPARATE CHARGES ASSOCIATED WITH YOUR SURGERY
          </h2>
          <div className="label-indent">
            <input
              type="checkbox"
              name="one"
              checked={formData.one}
              onChange={handleCheckboxChange}
            />{" "}
            <label>
              1.{" "}
              <u>
                <b>Your Surgeon:</b>
              </u>
              <br /> This bill will cover your surgeon’s surgical fees and
              post-operative care following surgery for the time arranged with
              your surgeon.{" "}
            </label>
          </div>
          <div className="label-indent">
            <input
              type="checkbox"
              name="two"
              checked={formData.two}
              onChange={handleCheckboxChange}
            />{" "}
            <label>
              2.{" "}
              <u>
                <b>Cornerstone Surgery Center:</b>
              </u>
              <br /> This is a state licensed surgical facility whose fees are
              separate and unrelated to the physician’s fees.{" "}
            </label>
          </div>
          <div className="label-indent">
            <input
              type="checkbox"
              name="three"
              checked={formData.three}
              onChange={handleCheckboxChange}
            />{" "}
            <label>
              3.{" "}
              <u>
                <b>Anesthesiologist (if applicable):</b>
              </u>
              <br /> This bill will be from the doctor who will administer the
              anesthesia during your surgery, and is also unrelated to the
              physician or facility fees.{" "}
            </label>
          </div>
          <div className="label-indent">
            <input
              type="checkbox"
              name="four"
              checked={formData.four}
              onChange={handleCheckboxChange}
            />{" "}
            <label>
              4.{" "}
              <u>
                <b>Laboratory/Radiology/EKG:</b>
              </u>
              <br /> This bill will be from the Laboratory/Radiology Department
              and/or facility where your physician may send you for
              pre-operative blood work, x-rays, EKG, and/or pathologic diagnosis
              from specimens collected at the time of surgery.
            </label>
          </div>
          <div className="center mt-50">
            <button className="btn-primary hover-pointer" type="submit">
              Submit
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default AdminFinancial;
