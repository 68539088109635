import React, { useState } from "react";
import { Icon } from "@iconify/react";
import "../Forms/styles.css";
import { useNavigate } from "react-router-dom";
import SignatureButton from "../../../components/SignatureButton";

const PatientSurveyBenchmark = () => {
  const navigate = useNavigate();

  return (
    <>
      <div
        className="arrow-container"
        onClick={() => navigate(-1)}
        style={{ cursor: "pointer" }}
      >
        <p className="back-arrow">
          <Icon icon="ic:twotone-arrow-back" className="icon" /> Back to Forms
        </p>
      </div>
      <div className="form-content">
        <div>
          <form>
            <h2>Patient Survey Benchmark (Quarterly Report)</h2>
            <div className="label-indent">
              <label>
                <b>
                  TOTALS(
                  <input type="text" />
                  QUARTER)
                </b>
              </label>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default PatientSurveyBenchmark;
