import React, { useState, useEffect } from "react";
import { Icon } from "@iconify/react";
import "../Forms/styles.css";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import moment from "moment";
import {
  addFile,
  getIntraoperativeRecord,
  updateIntraoperativeRecord,
} from "../../../utils/api";
import SignatureButton from "../../../components/SignatureButton";

const IntraoperativeRecord = () => {
  const navigate = useNavigate();
  const { form_id } = useParams();
  const [loading, setLoading] = useState(true);
  const [individualSignature, setIndividualSignature] = useState("");
  const [formData, setFormData] = useState({
    procedure: "",
    procedure_date: "",
    ox_rate: "",
    ox_timeon: "",
    ox_timeoff: "",
    local: "",
    AMT: "",
    time: "",
    notes: "",
    LOC: "",
    resp_crackles: false,
    resp_labored: false,
    pstn_prone: false,
    pstn_supine: false,
    pstn_left: false,
    pstn_right: false,
    pstn_head: false,
    pstn_tren: false,
    ox_nasal: false,
    ox_mask: false,
    ox_yes: false,
    ox_no: false,
    resp_regular: false,
    resp_clear: false,
    resp_short: false,
    resp_diminish: false,
    resp_wheezing: false,
    bp_one: "",
    hr_one: "",
    rr_one: "",
    date_one: "",

    time_one: "",
    otwo_two: "",
    otwo_one: "",
    skin_one: "",
    pain_one: "",
    LOC_one: "",
    time_two: "",
    bp_two: "",
    hr_two: "",
    rr_two: "",
    otwo_three: "",
    skin_two: "",
    pain_two: "",
    LOC_two: "",
    time_three: "",
    bp_three: "",
    hr_three: "",
    rr_three: "",
    otwo_four: "",
    skin_three: "",
    pain_three: "",
    LOC_three: "",
    time_four: "",
    bp_four: "",
    hr_four: "",
    rr_four: "",
    otwo_five: "",
    skin_four: "",
    pain_four: "",
    LOC_four: "",
    time_five: "",
    bp_five: "",
    hr_five: "",
    rr_five: "",
    otwo_six: "",
    skin_five: "",
    pain_five: "",
    LOC_five: "",
    time_six: "",
    bp_six: "",
    hr_six: "",
    rr_six: "",
    otwo_seven: "",
    skin_six: "",
    pain_six: "",
    LOC_six: "",
    time_seven: "",
    bp_seven: "",
    hr_seven: "",
    rr_seven: "",
    otwo_eight: "",
    skin_seven: "",
    pain_seven: "",
    LOC_seven: "",
    time_eight: "",
    bp_eight: "",
    hr_eight: "",
    rr_eight: "",
    otwo_nine: "",
    skin_eight: "",
    pain_eight: "",
    LOC_eight: "",
    time_nine: "",
    bp_nine: "",
    hr_nine: "",
    rr_nine: "",
    otwo_ten: "",
    skin_nine: "",
    pain_nine: "",
    LOC_nine: "",
    time_ten: "",
    bp_ten: "",
    hr_ten: "",
    rr_ten: "",
    otwo_eleven: "",
    skin_ten: "",
    pain_ten: "",
    LOC_ten: "",
    time_eleven: "",
    bp_eleven: "",
    hr_eleven: "",
    rr_eleven: "",
    otwo_twelve: "",
    skin_eleven: "",
    pain_eleven: "",
    LOC_eleven: "",
    time_twelve: "",
    bp_twelve: "",
    time_three_sig: "",
    hr_twelve: "",
    rr_twelve: "",
    otwo_thirteen: "",
    skin_twelve: "",
    pain_twelve: "",
    LOC_twelve: "",
    time_thirteen: "",
    bp_thirteen: "",
    hr_thirteen: "",
    rr_thirteen: "",
    skin_thirteen: "",
    pain_thirteen: "",
    LOC_thirteen: "",
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    let fileName;

    // Fetch 1: Upload Signature
    if (individualSignature !== "") {
      const response = await addFile(individualSignature);

      const data = await response.json();
      fileName = await data.fileName;
    }
    const payload = {
      rn_signature: fileName,
      ...formData,
      time_three_sig: moment().format("h:mm a"),
      date_one: moment().format("MM/DD/YYYY"),

      date: moment().format("MM/DD/YYYY"),
      time: moment().format("h:mm a"),
      status: "In Progress",
    };

    console.log("Payload Sent to Backend:", payload);

    // Fetch 2: Create/Update the Disclosure of Ownerhsip Model

    updateIntraoperativeRecord(form_id, payload)
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        console.log("complete");
        navigate(-1);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getIntraoperativeRecord(form_id)
      .then((res) => res.json())
      .then((data) => {
        console.log(data);

        if (data.status !== "Not Started") {
          setFormData({
            procedure: data.procedure || "",
            procedure_date: data.procedure_date || "",
            ox_rate: data.ox_rate || "",
            ox_timeon: data.ox_timeon || "",
            ox_timeoff: data.ox_timeoff || "",
            local: data.local || "",
            AMT: data.AMT || "",
            time: data.time || "",
            notes: data.notes || "",
            LOC: data.LOC || "",
            pstn_prone: data.pstn_prone || false,
            pstn_supine: data.pstn_supine || false,
            pstn_left: data.pstn_left || false,
            pstn_right: data.pstn_right || false,
            pstn_head: data.pstn_head || false,
            pstn_tren: data.pstn_tren || false,
            ox_nasal: data.ox_nasal || false,
            ox_mask: data.ox_mask || false,
            ox_yes: data.ox_yes || false,
            ox_no: data.ox_no || false,
            resp_regular: data.resp_regular || false,
            resp_clear: data.resp_clear || false,
            resp_short: data.resp_short || false,
            resp_diminish: data.resp_diminish || false,
            resp_wheezing: data.resp_wheezing || false,
            bp_one: data.bp_one || "",
            time_three_sig: data.time_three_sig || "",
            hr_one: data.hr_one || "",
            rr_one: data.rr_one || "",
            otwo_one: data.otwo_one || "",
            resp_crackles: data.resp_crackles || false,
            resp_labored: data.resp_labored || false,
            time_one: data.time_one || "",
            otwo_two: data.otwo_two || "",
            skin_one: data.skin_one || "",
            pain_one: data.pain_one || "",
            LOC_one: data.LOC_one || "",
            time_two: data.time_two || "",
            date_one: data.date_one || "",

            bp_two: data.bp_two || "",
            hr_two: data.hr_two || "",
            rr_two: data.rr_two || "",
            otwo_three: data.otwo_three || "",
            skin_two: data.skin_two || "",
            pain_two: data.pain_two || "",
            LOC_two: data.LOC_two || "",
            time_three: data.time_three || "",
            bp_three: data.bp_three || "",
            hr_three: data.hr_three || "",
            rr_three: data.rr_three || "",
            otwo_four: data.otwo_four || "",
            skin_three: data.skin_three || "",
            pain_three: data.pain_three || "",
            LOC_three: data.LOC_three || "",
            time_four: data.time_four || "",
            bp_four: data.bp_four || "",
            hr_four: data.hr_four || "",
            rr_four: data.rr_four || "",
            otwo_five: data.otwo_five || "",
            skin_four: data.skin_four || "",
            pain_four: data.pain_four || "",
            LOC_four: data.LOC_four || "",
            time_five: data.time_five || "",
            bp_five: data.bp_five || "",
            hr_five: data.hr_five || "",
            rr_five: data.rr_five || "",
            otwo_six: data.otwo_six || "",
            skin_five: data.skin_five || "",
            pain_five: data.pain_five || "",
            LOC_five: data.LOC_five || "",
            time_six: data.time_six || "",
            bp_six: data.bp_six || "",
            hr_six: data.hr_six || "",
            rr_six: data.rr_six || "",
            otwo_seven: data.otwo_seven || "",
            skin_six: data.skin_six || "",
            pain_six: data.pain_six || "",
            LOC_six: data.LOC_six || "",
            time_seven: data.time_seven || "",
            bp_seven: data.bp_seven || "",
            hr_seven: data.hr_seven || "",
            rr_seven: data.rr_seven || "",
            otwo_eight: data.otwo_eight || "",
            skin_seven: data.skin_seven || "",
            pain_seven: data.pain_seven || "",
            LOC_seven: data.LOC_seven || "",
            time_eight: data.time_eight || "",
            bp_eight: data.bp_eight || "",
            hr_eight: data.hr_eight || "",
            rr_eight: data.rr_eight || "",
            otwo_nine: data.otwo_nine || "",
            skin_eight: data.skin_eight || "",
            pain_eight: data.pain_eight || "",
            LOC_eight: data.LOC_eight || "",
            time_nine: data.time_nine || "",
            bp_nine: data.bp_nine || "",
            hr_nine: data.hr_nine || "",
            rr_nine: data.rr_nine || "",
            otwo_ten: data.otwo_ten || "",
            skin_nine: data.skin_nine || "",
            pain_nine: data.pain_nine || "",
            LOC_nine: data.LOC_nine || "",
            time_ten: data.time_ten || "",
            bp_ten: data.bp_ten || "",
            hr_ten: data.hr_ten || "",
            rr_ten: data.rr_ten || "",
            otwo_eleven: data.otwo_eleven || "",
            skin_ten: data.skin_ten || "",
            pain_ten: data.pain_ten || "",
            LOC_ten: data.LOC_ten || "",
            time_eleven: data.time_eleven || "",
            bp_eleven: data.bp_eleven || "",
            hr_eleven: data.hr_eleven || "",
            rr_eleven: data.rr_eleven || "",
            otwo_twelve: data.otwo_twelve || "",
            skin_eleven: data.skin_eleven || "",
            pain_eleven: data.pain_eleven || "",
            LOC_eleven: data.LOC_eleven || "",
            time_twelve: data.time_twelve || "",
            bp_twelve: data.bp_twelve || "",
            hr_twelve: data.hr_twelve || "",
            rr_twelve: data.rr_twelve || "",
            otwo_thirteen: data.otwo_thirteen || "",
            skin_twelve: data.skin_twelve || "",
            pain_twelve: data.pain_twelve || "",
            LOC_twelve: data.LOC_twelve || "",
            time_thirteen: data.time_thirteen || "",
            bp_thirteen: data.bp_thirteen || "",
            hr_thirteen: data.hr_thirteen || "",
            rr_thirteen: data.rr_thirteen || "",
            skin_thirteen: data.skin_thirteen || "",
            pain_thirteen: data.pain_thirteen || "",
            LOC_thirteen: data.LOC_thirteen || "",
          });
        }
        setLoading(false);
      })
      .catch((err) => console.log(err));
  }, []);
  if (loading) return <p>Loading...</p>;

  return (
    <>
      <div
        className="arrow-container"
        onClick={() => navigate(-1)}
        style={{ cursor: "pointer" }}
      >
        <p className="back-arrow">
          <Icon icon="ic:twotone-arrow-back" className="icon" /> Back to Forms
        </p>
      </div>{" "}
      <div className="form-content">
        <form onSubmit={handleSubmit}>
          {" "}
          <h2 className="form-title">Intraoperative Record</h2>
          <div className="iof-form-section">
            <b>
              {" "}
              <label>Procedure:</label>
            </b>
            <input
              type="text"
              className="sig-input procedure-input"
              name="procedure"
              value={formData.procedure}
              onChange={(e) =>
                setFormData({ ...formData, procedure: e.target.value })
              }
            />
            <label>Date</label>
            <input
              type="date"
              className="sig-input"
              name="procedure_date"
              value={formData.procedure_date}
              onChange={(e) =>
                setFormData({ ...formData, procedure_date: e.target.value })
              }
            />
          </div>
          <div className="loc-container iof-form-section">
            <label className="loc-label">LOC:</label>
            <input
              type="text"
              className="form-input-short sig-input"
              name="LOC"
              value={formData.LOC}
              onChange={(e) =>
                setFormData({ ...formData, LOC: e.target.value })
              }
            />
            <br />
            <label>1 = Awake&nbsp;&nbsp;</label>

            <label>2 = Occasionally Drowsy&nbsp;&nbsp;</label>

            <label>3 = Frequently Drowsy&nbsp;&nbsp;</label>

            <label>4 = Asleep, able to arouse&nbsp;&nbsp;</label>

            <label>5 = Somnolent&nbsp;&nbsp;</label>
          </div>
          <div className="iof-form-container">
            {/* POSITION Section */}
            <div className="iof-form-section">
              <label className="iof-form-label">POSITION:</label>
              <label className="iof-form-checkbox">
                <input
                  type="checkbox"
                  name="pstn_prone"
                  checked={formData.pstn_prone}
                  onChange={(e) =>
                    setFormData({ ...formData, pstn_prone: e.target.checked })
                  }
                />{" "}
                Prone
              </label>
              <label className="iof-form-checkbox">
                <input
                  type="checkbox"
                  name="pstn_supine"
                  checked={formData.pstn_supine}
                  onChange={(e) =>
                    setFormData({ ...formData, pstn_supine: e.target.checked })
                  }
                />{" "}
                Supine
              </label>
              <label className="iof-form-checkbox">
                <input
                  type="checkbox"
                  name="pstn_left"
                  checked={formData.pstn_left}
                  onChange={(e) =>
                    setFormData({ ...formData, pstn_left: e.target.checked })
                  }
                />{" "}
                Left Lateral
              </label>
              <label className="iof-form-checkbox">
                <input
                  type="checkbox"
                  name="pstn_right"
                  checked={formData.pstn_right}
                  onChange={(e) =>
                    setFormData({ ...formData, pstn_right: e.target.checked })
                  }
                />{" "}
                Right Lateral
              </label>
              <label className="iof-form-checkbox">
                <input
                  type="checkbox"
                  name="pstn_head"
                  checked={formData.pstn_head}
                  onChange={(e) =>
                    setFormData({ ...formData, pstn_head: e.target.checked })
                  }
                />{" "}
                Head of bed elevated
              </label>
              <label className="iof-form-checkbox">
                <input
                  type="checkbox"
                  name="pstn_tren"
                  checked={formData.pstn_tren}
                  onChange={(e) =>
                    setFormData({ ...formData, pstn_tren: e.target.checked })
                  }
                />{" "}
                Trendelenburg
              </label>
            </div>

            {/* OXYGEN Section */}
            <div className="iof-form-section">
              <label className="iof-form-label">OXYGEN:</label>
              <label className="iof-form-checkbox">
                <input
                  type="checkbox"
                  name="ox_no"
                  checked={formData.ox_no}
                  onChange={(e) =>
                    setFormData({ ...formData, ox_no: e.target.checked })
                  }
                />{" "}
                No
              </label>
              <label className="iof-form-checkbox">
                <input
                  type="checkbox"
                  name="ox_yes"
                  checked={formData.ox_yes}
                  onChange={(e) =>
                    setFormData({ ...formData, ox_yes: e.target.checked })
                  }
                />{" "}
                Yes / rate{" "}
                <input
                  type="text"
                  className="form-input-short sig-input"
                  name="ox_rate"
                  value={formData.ox_rate}
                  onChange={(e) =>
                    setFormData({ ...formData, ox_rate: e.target.value })
                  }
                />
                l/min
              </label>
              <label className="iof-form-checkbox">
                <input
                  type="checkbox"
                  name="ox_nasal"
                  checked={formData.ox_nasal}
                  onChange={(e) =>
                    setFormData({ ...formData, ox_nasal: e.target.checked })
                  }
                />{" "}
                Nasal cannula
              </label>
              <label className="iof-form-checkbox">
                <input
                  type="checkbox"
                  name="ox_mask"
                  checked={formData.ox_mask}
                  onChange={(e) =>
                    setFormData({ ...formData, ox_mask: e.target.checked })
                  }
                />{" "}
                Mask
              </label>
              <div className="inline-inputs">
                Time on{" "}
                <input
                  type="text"
                  className="form-input-short sig-input"
                  name="ox_timeon"
                  value={formData.ox_timeon}
                  onChange={(e) =>
                    setFormData({ ...formData, ox_timeon: e.target.value })
                  }
                />{" "}
                Time off{" "}
                <input
                  type="text"
                  className="form-input-short sig-input"
                  name="ox_timeoff"
                  value={formData.ox_timeoff}
                  onChange={(e) =>
                    setFormData({ ...formData, ox_timeoff: e.target.value })
                  }
                />
              </div>
            </div>

            {/* RESPIRATORY Section */}
            <div className="iof-form-section">
              <label className="iof-form-label">RESPIRATORY:</label>
              <label className="iof-form-checkbox">
                <input
                  type="checkbox"
                  name="resp_regular"
                  checked={formData.resp_regular}
                  onChange={(e) =>
                    setFormData({ ...formData, resp_regular: e.target.checked })
                  }
                />{" "}
                Regular and even
              </label>
              <label className="iof-form-checkbox">
                <input
                  type="checkbox"
                  name="resp_clear"
                  checked={formData.resp_clear}
                  onChange={(e) =>
                    setFormData({ ...formData, resp_clear: e.target.checked })
                  }
                />{" "}
                Breath sounds clear
              </label>
              <label className="iof-form-checkbox">
                <input
                  type="checkbox"
                  name="resp_short"
                  checked={formData.resp_short}
                  onChange={(e) =>
                    setFormData({ ...formData, resp_short: e.target.checked })
                  }
                />{" "}
                Shortness of breath
              </label>
              <label className="iof-form-checkbox">
                <input
                  type="checkbox"
                  name="resp_labored"
                  checked={formData.resp_labored}
                  onChange={(e) =>
                    setFormData({ ...formData, resp_labored: e.target.checked })
                  }
                />{" "}
                Labored
              </label>
              <label className="iof-form-checkbox">
                <input
                  type="checkbox"
                  name="resp_crackles"
                  checked={formData.resp_crackles}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      resp_crackles: e.target.checked,
                    })
                  }
                />{" "}
                Crackles
              </label>
              <label className="iof-form-checkbox">
                <input
                  type="checkbox"
                  name="resp_wheezing"
                  checked={formData.resp_wheezing}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      resp_wheezing: e.target.checked,
                    })
                  }
                />{" "}
                Wheezing
              </label>
              <label className="iof-form-checkbox">
                <input
                  type="checkbox"
                  name="resp_diminish"
                  checked={formData.resp_diminish}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      resp_diminish: e.target.checked,
                    })
                  }
                />{" "}
                Diminished lung sounds bilateral
              </label>
            </div>
          </div>
          <div className="vital-sign-record">
            {/* Header Row */}
            <div className="row header">
              <div className="cell">TIME</div>
              <div className="cell">B / P</div>
              <div className="cell">HR</div>
              <div className="cell">RR</div>
              <div className="cell">O2 SAT</div>
              <div className="cell">SKIN COLOR / TEMP</div>
              <div className="cell">PAIN</div>
              <div className="cell">LOC</div>
            </div>

            <div className="row header">
              <div className="cell">
                <input
                  className="ir-no-border"
                  placeholder="Baseline"
                  name="time_one"
                  value={formData.time_one}
                  onChange={(e) =>
                    setFormData({ ...formData, time_one: e.target.value })
                  }
                />
              </div>
              <div className="cell">
                <input
                  className="ir-no-border"
                  name="bp_one"
                  value={formData.bp_one}
                  onChange={(e) =>
                    setFormData({ ...formData, bp_one: e.target.value })
                  }
                />
              </div>
              <div className="cell">
                <input
                  className="ir-no-border"
                  name="hr_one"
                  value={formData.hr_one}
                  onChange={(e) =>
                    setFormData({ ...formData, hr_one: e.target.value })
                  }
                />
              </div>
              <div className="cell">
                <input
                  className="ir-no-border"
                  name="rr_one"
                  value={formData.rr_one}
                  onChange={(e) =>
                    setFormData({ ...formData, rr_one: e.target.value })
                  }
                />
              </div>
              <div className="cell">
                <input
                  className="ir-no-border"
                  name="otwo_one"
                  value={formData.otwo_one}
                  onChange={(e) =>
                    setFormData({ ...formData, otwo_one: e.target.value })
                  }
                />
              </div>
              <div className="cell">
                <input
                  className="ir-no-border"
                  name="skin_one"
                  value={formData.skin_one}
                  onChange={(e) =>
                    setFormData({ ...formData, skin_one: e.target.value })
                  }
                />
              </div>
              <div className="cell">
                <input
                  className="ir-no-border"
                  name="pain_one"
                  value={formData.pain_one}
                  onChange={(e) =>
                    setFormData({ ...formData, pain_one: e.target.value })
                  }
                />
              </div>
              <div className="cell">
                <input
                  className="ir-no-border"
                  name="LOC_one"
                  value={formData.LOC_one}
                  onChange={(e) =>
                    setFormData({ ...formData, LOC_one: e.target.value })
                  }
                />
              </div>
            </div>
            <div className="row header">
              <div className="cell">
                <input
                  className="ir-no-border"
                  name="time_two"
                  value={formData.time_two}
                  onChange={(e) =>
                    setFormData({ ...formData, time_two: e.target.value })
                  }
                />
              </div>
              <div className="cell">
                <input
                  className="ir-no-border"
                  name="bp_two"
                  value={formData.bp_two}
                  onChange={(e) =>
                    setFormData({ ...formData, bp_two: e.target.value })
                  }
                />
              </div>
              <div className="cell">
                <input
                  className="ir-no-border"
                  name="hr_two"
                  value={formData.hr_two}
                  onChange={(e) =>
                    setFormData({ ...formData, hr_two: e.target.value })
                  }
                />
              </div>
              <div className="cell">
                <input
                  className="ir-no-border"
                  name="rr_two"
                  value={formData.rr_two}
                  onChange={(e) =>
                    setFormData({ ...formData, rr_two: e.target.value })
                  }
                />
              </div>
              <div className="cell">
                <input
                  className="ir-no-border"
                  name="otwo_two"
                  value={formData.otwo_two}
                  onChange={(e) =>
                    setFormData({ ...formData, otwo_two: e.target.value })
                  }
                />
              </div>
              <div className="cell">
                <input
                  className="ir-no-border"
                  name="skin_two"
                  value={formData.skin_two}
                  onChange={(e) =>
                    setFormData({ ...formData, skin_two: e.target.value })
                  }
                />
              </div>
              <div className="cell">
                <input
                  className="ir-no-border"
                  name="pain_two"
                  value={formData.pain_two}
                  onChange={(e) =>
                    setFormData({ ...formData, pain_two: e.target.value })
                  }
                />
              </div>
              <div className="cell">
                <input
                  className="ir-no-border"
                  name="LOC_two"
                  value={formData.LOC_two}
                  onChange={(e) =>
                    setFormData({ ...formData, LOC_two: e.target.value })
                  }
                />
              </div>
            </div>
            <div className="row header">
              <div className="cell">
                <input
                  className="ir-no-border"
                  name="time_three"
                  value={formData.time_three}
                  onChange={(e) =>
                    setFormData({ ...formData, time_three: e.target.value })
                  }
                />
              </div>
              <div className="cell">
                <input
                  className="ir-no-border"
                  name="bp_three"
                  value={formData.bp_three}
                  onChange={(e) =>
                    setFormData({ ...formData, bp_three: e.target.value })
                  }
                />
              </div>
              <div className="cell">
                <input
                  className="ir-no-border"
                  name="hr_three"
                  value={formData.hr_three}
                  onChange={(e) =>
                    setFormData({ ...formData, hr_three: e.target.value })
                  }
                />
              </div>
              <div className="cell">
                <input
                  className="ir-no-border"
                  name="rr_three"
                  value={formData.rr_three}
                  onChange={(e) =>
                    setFormData({ ...formData, rr_three: e.target.value })
                  }
                />
              </div>
              <div className="cell">
                <input
                  className="ir-no-border"
                  name="otwo_three"
                  value={formData.otwo_three}
                  onChange={(e) =>
                    setFormData({ ...formData, otwo_three: e.target.value })
                  }
                />
              </div>
              <div className="cell">
                <input
                  className="ir-no-border"
                  name="skin_three"
                  value={formData.skin_three}
                  onChange={(e) =>
                    setFormData({ ...formData, skin_three: e.target.value })
                  }
                />
              </div>
              <div className="cell">
                <input
                  className="ir-no-border"
                  name="pain_three"
                  value={formData.pain_three}
                  onChange={(e) =>
                    setFormData({ ...formData, pain_three: e.target.value })
                  }
                />
              </div>
              <div className="cell">
                <input
                  className="ir-no-border"
                  name="LOC_three"
                  value={formData.LOC_three}
                  onChange={(e) =>
                    setFormData({ ...formData, LOC_three: e.target.value })
                  }
                />
              </div>
            </div>
            <div className="row header">
              <div className="cell">
                <input
                  className="ir-no-border"
                  name="time_four"
                  value={formData.time_four}
                  onChange={(e) =>
                    setFormData({ ...formData, time_four: e.target.value })
                  }
                />
              </div>
              <div className="cell">
                <input
                  className="ir-no-border"
                  name="bp_four"
                  value={formData.bp_four}
                  onChange={(e) =>
                    setFormData({ ...formData, bp_four: e.target.value })
                  }
                />
              </div>
              <div className="cell">
                <input
                  className="ir-no-border"
                  name="hr_four"
                  value={formData.hr_four}
                  onChange={(e) =>
                    setFormData({ ...formData, hr_four: e.target.value })
                  }
                />
              </div>
              <div className="cell">
                <input
                  className="ir-no-border"
                  name="rr_four"
                  value={formData.rr_four}
                  onChange={(e) =>
                    setFormData({ ...formData, rr_four: e.target.value })
                  }
                />
              </div>
              <div className="cell">
                <input
                  className="ir-no-border"
                  name="otwo_four"
                  value={formData.otwo_four}
                  onChange={(e) =>
                    setFormData({ ...formData, otwo_four: e.target.value })
                  }
                />
              </div>
              <div className="cell">
                <input
                  className="ir-no-border"
                  name="skin_four"
                  value={formData.skin_four}
                  onChange={(e) =>
                    setFormData({ ...formData, skin_four: e.target.value })
                  }
                />
              </div>
              <div className="cell">
                <input
                  className="ir-no-border"
                  name="pain_four"
                  value={formData.pain_four}
                  onChange={(e) =>
                    setFormData({ ...formData, pain_four: e.target.value })
                  }
                />
              </div>
              <div className="cell">
                <input
                  className="ir-no-border"
                  name="LOC_four"
                  value={formData.LOC_four}
                  onChange={(e) =>
                    setFormData({ ...formData, LOC_four: e.target.value })
                  }
                />
              </div>
            </div>
            <div className="row header">
              <div className="cell">
                <input
                  className="ir-no-border"
                  name="time_five"
                  value={formData.time_five}
                  onChange={(e) =>
                    setFormData({ ...formData, time_five: e.target.value })
                  }
                />
              </div>
              <div className="cell">
                <input
                  className="ir-no-border"
                  name="bp_five"
                  value={formData.bp_five}
                  onChange={(e) =>
                    setFormData({ ...formData, bp_five: e.target.value })
                  }
                />
              </div>
              <div className="cell">
                <input
                  className="ir-no-border"
                  name="hr_five"
                  value={formData.hr_five}
                  onChange={(e) =>
                    setFormData({ ...formData, hr_five: e.target.value })
                  }
                />
              </div>
              <div className="cell">
                <input
                  className="ir-no-border"
                  name="rr_five"
                  value={formData.rr_five}
                  onChange={(e) =>
                    setFormData({ ...formData, rr_five: e.target.value })
                  }
                />
              </div>
              <div className="cell">
                <input
                  className="ir-no-border"
                  name="otwo_five"
                  value={formData.otwo_five}
                  onChange={(e) =>
                    setFormData({ ...formData, otwo_five: e.target.value })
                  }
                />
              </div>
              <div className="cell">
                <input
                  className="ir-no-border"
                  name="skin_five"
                  value={formData.skin_five}
                  onChange={(e) =>
                    setFormData({ ...formData, skin_five: e.target.value })
                  }
                />
              </div>
              <div className="cell">
                <input
                  className="ir-no-border"
                  name="pain_five"
                  value={formData.pain_five}
                  onChange={(e) =>
                    setFormData({ ...formData, pain_five: e.target.value })
                  }
                />
              </div>
              <div className="cell">
                <input
                  className="ir-no-border"
                  name="LOC_five"
                  value={formData.LOC_five}
                  onChange={(e) =>
                    setFormData({ ...formData, LOC_five: e.target.value })
                  }
                />
              </div>
            </div>
            <div className="row header">
              <div className="cell">
                <input
                  className="ir-no-border"
                  name="time_six"
                  value={formData.time_six}
                  onChange={(e) =>
                    setFormData({ ...formData, time_six: e.target.value })
                  }
                />
              </div>
              <div className="cell">
                <input
                  className="ir-no-border"
                  name="bp_six"
                  value={formData.bp_six}
                  onChange={(e) =>
                    setFormData({ ...formData, bp_six: e.target.value })
                  }
                />
              </div>
              <div className="cell">
                <input
                  className="ir-no-border"
                  name="hr_six"
                  value={formData.hr_six}
                  onChange={(e) =>
                    setFormData({ ...formData, hr_six: e.target.value })
                  }
                />
              </div>
              <div className="cell">
                <input
                  className="ir-no-border"
                  name="rr_six"
                  value={formData.rr_six}
                  onChange={(e) =>
                    setFormData({ ...formData, rr_six: e.target.value })
                  }
                />
              </div>
              <div className="cell">
                <input
                  className="ir-no-border"
                  name="otwo_six"
                  value={formData.otwo_six}
                  onChange={(e) =>
                    setFormData({ ...formData, otwo_six: e.target.value })
                  }
                />
              </div>
              <div className="cell">
                <input
                  className="ir-no-border"
                  name="skin_six"
                  value={formData.skin_six}
                  onChange={(e) =>
                    setFormData({ ...formData, skin_six: e.target.value })
                  }
                />
              </div>
              <div className="cell">
                <input
                  className="ir-no-border"
                  name="pain_six"
                  value={formData.pain_six}
                  onChange={(e) =>
                    setFormData({ ...formData, pain_six: e.target.value })
                  }
                />
              </div>
              <div className="cell">
                <input
                  className="ir-no-border"
                  name="LOC_six"
                  value={formData.LOC_six}
                  onChange={(e) =>
                    setFormData({ ...formData, LOC_six: e.target.value })
                  }
                />
              </div>
            </div>
            <div className="row header">
              <div className="cell">
                <input
                  className="ir-no-border"
                  name="time_seven"
                  value={formData.time_seven}
                  onChange={(e) =>
                    setFormData({ ...formData, time_seven: e.target.value })
                  }
                />
              </div>
              <div className="cell">
                <input
                  className="ir-no-border"
                  name="bp_seven"
                  value={formData.bp_seven}
                  onChange={(e) =>
                    setFormData({ ...formData, bp_seven: e.target.value })
                  }
                />
              </div>
              <div className="cell">
                <input
                  className="ir-no-border"
                  name="hr_seven"
                  value={formData.hr_seven}
                  onChange={(e) =>
                    setFormData({ ...formData, hr_seven: e.target.value })
                  }
                />
              </div>
              <div className="cell">
                <input
                  className="ir-no-border"
                  name="rr_seven"
                  value={formData.rr_seven}
                  onChange={(e) =>
                    setFormData({ ...formData, rr_seven: e.target.value })
                  }
                />
              </div>
              <div className="cell">
                <input
                  className="ir-no-border"
                  name="otwo_seven"
                  value={formData.otwo_seven}
                  onChange={(e) =>
                    setFormData({ ...formData, otwo_seven: e.target.value })
                  }
                />
              </div>
              <div className="cell">
                <input
                  className="ir-no-border"
                  name="skin_seven"
                  value={formData.skin_seven}
                  onChange={(e) =>
                    setFormData({ ...formData, skin_seven: e.target.value })
                  }
                />
              </div>
              <div className="cell">
                <input
                  className="ir-no-border"
                  name="pain_seven"
                  value={formData.pain_seven}
                  onChange={(e) =>
                    setFormData({ ...formData, pain_seven: e.target.value })
                  }
                />
              </div>
              <div className="cell">
                <input
                  className="ir-no-border"
                  name="LOC_seven"
                  value={formData.LOC_seven}
                  onChange={(e) =>
                    setFormData({ ...formData, LOC_seven: e.target.value })
                  }
                />
              </div>
            </div>
            <div className="row header">
              <div className="cell">
                <input
                  className="ir-no-border"
                  name="time_eight"
                  value={formData.time_eight}
                  onChange={(e) =>
                    setFormData({ ...formData, time_eight: e.target.value })
                  }
                />
              </div>
              <div className="cell">
                <input
                  className="ir-no-border"
                  name="bp_eight"
                  value={formData.bp_eight}
                  onChange={(e) =>
                    setFormData({ ...formData, bp_eight: e.target.value })
                  }
                />
              </div>
              <div className="cell">
                <input
                  className="ir-no-border"
                  name="hr_eight"
                  value={formData.hr_eight}
                  onChange={(e) =>
                    setFormData({ ...formData, hr_eight: e.target.value })
                  }
                />
              </div>
              <div className="cell">
                <input
                  className="ir-no-border"
                  name="rr_eight"
                  value={formData.rr_eight}
                  onChange={(e) =>
                    setFormData({ ...formData, rr_eight: e.target.value })
                  }
                />
              </div>
              <div className="cell">
                <input
                  className="ir-no-border"
                  name="otwo_eight"
                  value={formData.otwo_eight}
                  onChange={(e) =>
                    setFormData({ ...formData, otwo_eight: e.target.value })
                  }
                />
              </div>
              <div className="cell">
                <input
                  className="ir-no-border"
                  name="skin_eight"
                  value={formData.skin_eight}
                  onChange={(e) =>
                    setFormData({ ...formData, skin_eight: e.target.value })
                  }
                />
              </div>
              <div className="cell">
                <input
                  className="ir-no-border"
                  name="pain_eight"
                  value={formData.pain_eight}
                  onChange={(e) =>
                    setFormData({ ...formData, pain_eight: e.target.value })
                  }
                />
              </div>
              <div className="cell">
                <input
                  className="ir-no-border"
                  name="LOC_eight"
                  value={formData.LOC_eight}
                  onChange={(e) =>
                    setFormData({ ...formData, LOC_eight: e.target.value })
                  }
                />
              </div>
            </div>
            <div className="row header">
              <div className="cell">
                <input
                  className="ir-no-border"
                  name="time_nine"
                  value={formData.time_nine}
                  onChange={(e) =>
                    setFormData({ ...formData, time_nine: e.target.value })
                  }
                />
              </div>
              <div className="cell">
                <input
                  className="ir-no-border"
                  name="bp_nine"
                  value={formData.bp_nine}
                  onChange={(e) =>
                    setFormData({ ...formData, bp_nine: e.target.value })
                  }
                />
              </div>
              <div className="cell">
                <input
                  className="ir-no-border"
                  name="hr_nine"
                  value={formData.hr_nine}
                  onChange={(e) =>
                    setFormData({ ...formData, hr_nine: e.target.value })
                  }
                />
              </div>
              <div className="cell">
                <input
                  className="ir-no-border"
                  name="rr_nine"
                  value={formData.rr_nine}
                  onChange={(e) =>
                    setFormData({ ...formData, rr_nine: e.target.value })
                  }
                />
              </div>
              <div className="cell">
                <input
                  className="ir-no-border"
                  name="otwo_nine"
                  value={formData.otwo_nine}
                  onChange={(e) =>
                    setFormData({ ...formData, otwo_nine: e.target.value })
                  }
                />
              </div>
              <div className="cell">
                <input
                  className="ir-no-border"
                  name="skin_nine"
                  value={formData.skin_nine}
                  onChange={(e) =>
                    setFormData({ ...formData, skin_nine: e.target.value })
                  }
                />
              </div>
              <div className="cell">
                <input
                  className="ir-no-border"
                  name="pain_nine"
                  value={formData.pain_nine}
                  onChange={(e) =>
                    setFormData({ ...formData, pain_nine: e.target.value })
                  }
                />
              </div>
              <div className="cell">
                <input
                  className="ir-no-border"
                  name="LOC_nine"
                  value={formData.LOC_nine}
                  onChange={(e) =>
                    setFormData({ ...formData, LOC_nine: e.target.value })
                  }
                />
              </div>
            </div>
            <div className="row header">
              <div className="cell">
                <input
                  className="ir-no-border"
                  name="time_ten"
                  value={formData.time_ten}
                  onChange={(e) =>
                    setFormData({ ...formData, time_ten: e.target.value })
                  }
                />
              </div>
              <div className="cell">
                <input
                  className="ir-no-border"
                  name="bp_ten"
                  value={formData.bp_ten}
                  onChange={(e) =>
                    setFormData({ ...formData, bp_ten: e.target.value })
                  }
                />
              </div>
              <div className="cell">
                <input
                  className="ir-no-border"
                  name="hr_ten"
                  value={formData.hr_ten}
                  onChange={(e) =>
                    setFormData({ ...formData, hr_ten: e.target.value })
                  }
                />
              </div>
              <div className="cell">
                <input
                  className="ir-no-border"
                  name="rr_ten"
                  value={formData.rr_ten}
                  onChange={(e) =>
                    setFormData({ ...formData, rr_ten: e.target.value })
                  }
                />
              </div>
              <div className="cell">
                <input
                  className="ir-no-border"
                  name="otwo_ten"
                  value={formData.otwo_ten}
                  onChange={(e) =>
                    setFormData({ ...formData, otwo_ten: e.target.value })
                  }
                />
              </div>
              <div className="cell">
                <input
                  className="ir-no-border"
                  name="skin_ten"
                  value={formData.skin_ten}
                  onChange={(e) =>
                    setFormData({ ...formData, skin_ten: e.target.value })
                  }
                />
              </div>
              <div className="cell">
                <input
                  className="ir-no-border"
                  name="pain_ten"
                  value={formData.pain_ten}
                  onChange={(e) =>
                    setFormData({ ...formData, pain_ten: e.target.value })
                  }
                />
              </div>
              <div className="cell">
                <input
                  className="ir-no-border"
                  name="LOC_ten"
                  value={formData.LOC_ten}
                  onChange={(e) =>
                    setFormData({ ...formData, LOC_ten: e.target.value })
                  }
                />
              </div>
            </div>
            <div className="row header">
              <div className="cell">
                <input
                  className="ir-no-border"
                  name="time_eleven"
                  value={formData.time_eleven}
                  onChange={(e) =>
                    setFormData({ ...formData, time_eleven: e.target.value })
                  }
                />
              </div>
              <div className="cell">
                <input
                  className="ir-no-border"
                  name="bp_eleven"
                  value={formData.bp_eleven}
                  onChange={(e) =>
                    setFormData({ ...formData, bp_eleven: e.target.value })
                  }
                />
              </div>
              <div className="cell">
                <input
                  className="ir-no-border"
                  name="hr_eleven"
                  value={formData.hr_eleven}
                  onChange={(e) =>
                    setFormData({ ...formData, hr_eleven: e.target.value })
                  }
                />
              </div>
              <div className="cell">
                <input
                  className="ir-no-border"
                  name="rr_eleven"
                  value={formData.rr_eleven}
                  onChange={(e) =>
                    setFormData({ ...formData, rr_eleven: e.target.value })
                  }
                />
              </div>
              <div className="cell">
                <input
                  className="ir-no-border"
                  name="otwo_eleven"
                  value={formData.otwo_eleven}
                  onChange={(e) =>
                    setFormData({ ...formData, otwo_eleven: e.target.value })
                  }
                />
              </div>
              <div className="cell">
                <input
                  className="ir-no-border"
                  name="skin_eleven"
                  value={formData.skin_eleven}
                  onChange={(e) =>
                    setFormData({ ...formData, skin_eleven: e.target.value })
                  }
                />
              </div>
              <div className="cell">
                <input
                  className="ir-no-border"
                  name="pain_eleven"
                  value={formData.pain_eleven}
                  onChange={(e) =>
                    setFormData({ ...formData, pain_eleven: e.target.value })
                  }
                />
              </div>
              <div className="cell">
                <input
                  className="ir-no-border"
                  name="LOC_eleven"
                  value={formData.LOC_eleven}
                  onChange={(e) =>
                    setFormData({ ...formData, LOC_eleven: e.target.value })
                  }
                />
              </div>
            </div>
            <div className="row header">
              <div className="cell">
                <input
                  className="ir-no-border"
                  name="time_twelve"
                  value={formData.time_twelve}
                  onChange={(e) =>
                    setFormData({ ...formData, time_twelve: e.target.value })
                  }
                />
              </div>
              <div className="cell">
                <input
                  className="ir-no-border"
                  name="bp_twelve"
                  value={formData.bp_twelve}
                  onChange={(e) =>
                    setFormData({ ...formData, bp_twelve: e.target.value })
                  }
                />
              </div>
              <div className="cell">
                <input
                  className="ir-no-border"
                  name="hr_twelve"
                  value={formData.hr_twelve}
                  onChange={(e) =>
                    setFormData({ ...formData, hr_twelve: e.target.value })
                  }
                />
              </div>
              <div className="cell">
                <input
                  className="ir-no-border"
                  name="rr_twelve"
                  value={formData.rr_twelve}
                  onChange={(e) =>
                    setFormData({ ...formData, rr_twelve: e.target.value })
                  }
                />
              </div>
              <div className="cell">
                <input
                  className="ir-no-border"
                  name="otwo_twelve"
                  value={formData.otwo_twelve}
                  onChange={(e) =>
                    setFormData({ ...formData, otwo_twelve: e.target.value })
                  }
                />
              </div>
              <div className="cell">
                <input
                  className="ir-no-border"
                  name="skin_twelve"
                  value={formData.skin_twelve}
                  onChange={(e) =>
                    setFormData({ ...formData, skin_twelve: e.target.value })
                  }
                />
              </div>
              <div className="cell">
                <input
                  className="ir-no-border"
                  name="pain_twelve"
                  value={formData.pain_twelve}
                  onChange={(e) =>
                    setFormData({ ...formData, pain_twelve: e.target.value })
                  }
                />
              </div>
              <div className="cell">
                <input
                  className="ir-no-border"
                  name="LOC_twelve"
                  value={formData.LOC_twelve}
                  onChange={(e) =>
                    setFormData({ ...formData, LOC_twelve: e.target.value })
                  }
                />
              </div>
            </div>
            <div className="row header">
              <div className="cell">
                <input
                  className="ir-no-border"
                  name="time_thirteen"
                  value={formData.time_thirteen}
                  onChange={(e) =>
                    setFormData({ ...formData, time_thirteen: e.target.value })
                  }
                />
              </div>
              <div className="cell">
                <input
                  className="ir-no-border"
                  name="bp_thirteen"
                  value={formData.bp_thirteen}
                  onChange={(e) =>
                    setFormData({ ...formData, bp_thirteen: e.target.value })
                  }
                />
              </div>
              <div className="cell">
                <input
                  className="ir-no-border"
                  name="hr_thirteen"
                  value={formData.hr_thirteen}
                  onChange={(e) =>
                    setFormData({ ...formData, hr_thirteen: e.target.value })
                  }
                />
              </div>
              <div className="cell">
                <input
                  className="ir-no-border"
                  name="rr_thirteen"
                  value={formData.rr_thirteen}
                  onChange={(e) =>
                    setFormData({ ...formData, rr_thirteen: e.target.value })
                  }
                />
              </div>
              <div className="cell">
                <input
                  className="ir-no-border"
                  name="otwo_thirteen"
                  value={formData.otwo_thirteen}
                  onChange={(e) =>
                    setFormData({ ...formData, otwo_thirteen: e.target.value })
                  }
                />
              </div>
              <div className="cell">
                <input
                  className="ir-no-border"
                  name="skin_thirteen"
                  value={formData.skin_thirteen}
                  onChange={(e) =>
                    setFormData({ ...formData, skin_thirteen: e.target.value })
                  }
                />
              </div>
              <div className="cell">
                <input
                  className="ir-no-border"
                  name="pain_thirteen"
                  value={formData.pain_thirteen}
                  onChange={(e) =>
                    setFormData({ ...formData, pain_thirteen: e.target.value })
                  }
                />
              </div>
              <div className="cell">
                <input
                  className="ir-no-border"
                  name="LOC_thirteen"
                  value={formData.LOC_thirteen}
                  onChange={(e) =>
                    setFormData({ ...formData, LOC_thirteen: e.target.value })
                  }
                />
              </div>
            </div>
          </div>
          <div className="iof-form-section">
            <label>
              LOCAL ANESTHESIA:
              <input
                type="text"
                className="sig-input"
                name="local"
                value={formData.local}
                onChange={(e) =>
                  setFormData({ ...formData, local: e.target.value })
                }
              />
            </label>
            <label>
              AMT:
              <input
                type="text"
                className="sig-input"
                name="AMT"
                value={formData.AMT}
                onChange={(e) =>
                  setFormData({ ...formData, AMT: e.target.value })
                }
              />
            </label>
            <label>
              TIME:
              <input
                type="time"
                className="sig-input"
                name="time"
                value={formData.time}
                onChange={(e) =>
                  setFormData({ ...formData, time: e.target.value })
                }
              />
            </label>
          </div>
          <div className="iof-form-section">
            <label>Notes:</label>
            <textarea
              name="notes"
              value={formData.notes}
              onChange={(e) =>
                setFormData({ ...formData, notes: e.target.value })
              }
            />
          </div>
          <div className="grievance-container mt-50 mobile">
            <div className="gc-item">
              {" "}
              <SignatureButton
                individualSignature={individualSignature}
                setIndividualSignature={setIndividualSignature}
              />
              <hr className="signature-line"></hr>
              <p>R.N. Signature</p>
            </div>
            <div className="gc-item mt-50">
              {" "}
              <label htmlFor="name" className="hidden">
                R.N. Signature
              </label>
              <input
                type="date"
                id="name"
                name="date_one"
                value={formData.date_one}
                onChange={(e) =>
                  setFormData({ ...formData, date_one: e.target.value })
                }
                className="bottom-border-input"
              />
              <p>Date</p>
            </div>
            <div className="gc-item mt-50">
              {" "}
              <label htmlFor="name" className="hidden">
                Time
              </label>
              <input
                type="time"
                id="time"
                name="time_three_sig"
                value={formData.time_three_sig}
                onChange={(e) =>
                  setFormData({ ...formData, time_three_sig: e.target.value })
                }
                className="bottom-border-input"
              />
              <p>Time</p>
            </div>
          </div>
          <div className="center mt-50">
            <button className="btn-primary hover-pointer" type="submit">
              Submit
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default IntraoperativeRecord;
