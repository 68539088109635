import React, { useState, useEffect } from "react";
import { Icon } from "@iconify/react";
import { useNavigate } from "react-router-dom";
import "./styles.css";
import SignatureButton from "../../../components/SignatureButton";
import { useParams } from "react-router-dom";
import moment from "moment";
import {
  addFile,
  getDischargeInstructions,
  updateDischargeInstructions,
} from "../../../utils/api";

const DischargeInstructions = () => {
  const { form_id } = useParams();
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();

  const [date, setDate] = useState();
  const [oneWeek, setOneWeek] = useState(false);
  const [threeWeeks, setThreeWeeks] = useState(false);
  const [callOffice, setCallOffice] = useState(false);
  const [followupText, setFollowupText] = useState("");
  const [phoneNumber, setPhoneNumber] = useState();
  const [homeContactYes, setHomeContactYes] = useState();
  const [workContactYes, setWorkContactYes] = useState();
  const [messageHomeYes, setMessageHomeYes] = useState();
  const [messageWorkYes, setMessageWorkYes] = useState();
  const [homeContactNo, setHomeContactNo] = useState();
  const [workContactNo, setWorkContactNo] = useState();
  const [messageHomeNo, setMessageHomeNo] = useState();
  const [messageWorkNo, setMessageWorkNo] = useState();
  const [nameHome, setNameHome] = useState();
  const [nameWork, setNameWork] = useState();
  const [specialInst, setSpecialInst] = useState();
  const [prescription, setPrescription] = useState();
  const [prescriptionText, setPrescriptionText] = useState("");
  const [individualSignature, setIndividualSignature] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();

    let fileName;

    // Fetch 1: Upload Signature
    if (individualSignature !== "") {
      const response = await addFile(individualSignature);
      const data = await response.json();
      fileName = await data.fileName;
    }

    const payload = {
      one_week: oneWeek,
      three_weeks: threeWeeks,
      follow_up_text: followupText,
      call_office: callOffice,
      phone_number: phoneNumber,
      home_contact_yes: homeContactYes,
      work_contact_yes: workContactYes,
      home_message_yes: messageHomeYes,
      work_message_yes: messageWorkYes,
      home_contact_no: homeContactNo,
      work_contact_no: workContactNo,
      home_message_no: messageHomeNo,
      work_message_no: messageHomeNo,
      home_proxy: nameHome,
      work_proxy: nameWork,
      special_instructions: specialInst,
      prescription: prescription,
      prescription_text: prescriptionText,
      patient_signature: fileName,
      patient_signature_date: moment().format("MM/DD/YYYY"),
      date: moment().format("MM/DD/YYYY"),
      time: moment().format("h:mm a"),
      status: "In Progress",
    };

    console.log("Payload Sent to Backend:", payload);

    // Fetch 2:
    updateDischargeInstructions(form_id, payload)
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        console.log("complete");
        navigate(-1);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getDischargeInstructions(form_id)
      .then((res) => res.json())
      .then((data) => {
        console.log(data);

        if (data.status !== "Not Started") {
          setOneWeek(data.one_week);
          setThreeWeeks(data.three_weeks);
          setCallOffice(data.call_office);

          setHomeContactYes(data.home_contact_yes);
          setHomeContactNo(data.home_contact_no);

          setWorkContactYes(data.work_contact_yes);
          setWorkContactNo(data.work_contact_no);

          setPrescription(data.prescription);

          setMessageHomeYes(data.home_message_yes);
          setMessageHomeNo(data.home_message_no);
          
          setMessageWorkYes(data.work_message_yes);
          setMessageWorkNo(data.work_message_no);
        }

        setFollowupText(data.follow_up_text || "");
        setPhoneNumber(data.phone_number || "");
        setNameHome(data.home_proxy || "");
        setNameWork(data.work_proxy || "");
        setSpecialInst(data.special_instructions || "");
        setPrescriptionText(data.prescription_text || "");
        setDate(data.patient_signature_date || "");

        setLoading(false);
      })
      .catch((err) => console.log(err));
  }, []);

  if (loading) return <p>Loading...</p>;

  return (
    <>
      <div
        className="arrow-container"
        onClick={() => navigate(-1)}
        style={{ cursor: "pointer" }}
      >
        <p className="back-arrow">
          <Icon icon="ic:twotone-arrow-back" className="icon" /> Back to Forms
        </p>
      </div>
      <div className="form-content">
        <h2 className="form-title">Discharge Instructions General</h2>

        <form onSubmit={(e) => handleSubmit(e)}>
          <p>
            1. You were administered general anesthesia during the procedure so
            you will be sleepy for the rest of the day and, maybe, tomorrow. DO
            NOT drive, operate any machinery or do anything requiring
            coordination for the rest of the day. Do not sign any legal
            documents or make any important decisions in the next 24 hours. If
            you are a smoker, you may experience a rise in body temperature
            tomorrow. After general anesthesia, you should breathe deeply and
            cough vigorously each waking hour in order to keep the lungs clear.
            Consult your doctor about when you can resume normal activities.
          </p>
          <p>
            2. <strong>DIET</strong> – For the next 24 hours we recommend a
            clear liquid to light diet today. Avoid milk, spicy or greasy foods.
          </p>
          <p className="form-title">
            {" "}
            You should NOT drink any alcoholic beverages for 24 hours after your
            procedure.
          </p>
          <p>
            3.{" "}
            <strong>
              CALL YOUR DOCTOR: Dr. Jack Shohet at (949) 631 4327 or (562) 270
              4327
            </strong>
          </p>
          <p>a. If you have an increase in pain after the first 48 hours</p>
          <p>
            b. If you have excessive bleeding. A small amount of bleeding may
            appear on the dressing or pad
          </p>
          <p>
            c. If you have persistent, severe pain or pain that is not
            eliminated by Tylenol or your prescription
          </p>
          <p>
            d. If signs of infection are observed (chills or fever, redness,
            swelling or drainage from surgical area)
          </p>
          <p>
            e. If you experience persistent nausea or vomiting. A certain
            percent of patients may experience nausea or vomiting following
            general anesthesia. You may call the anesthesiologist as well.
          </p>
          <p className="form-title">
            <strong>
              If unable to reach your surgeon or anesthesiologist, go to the
              nearest emergency room.
            </strong>
          </p>
          <p>
            Your doctor would like to see him/her in his/her office for a follow
            up visit. Please make the appointment to see him/her in:
          </p>
          <div className="di-followup">
            <div>
              <label>
                <input
                  type="radio"
                  id="one_week"
                  name="follow_up"
                  checked={oneWeek}
                  onChange={() => {
                    setOneWeek(true);
                    setThreeWeeks(false);
                    setCallOffice(false);
                    setFollowupText("");
                  }}
                />
                1 week
              </label>
              <label>
                <input
                  type="radio"
                  id="three_weeks"
                  name="follow_up"
                  checked={threeWeeks}
                  onChange={() => {
                    setThreeWeeks(true);
                    setOneWeek(false);
                    setCallOffice(false);
                    setFollowupText("");
                  }}
                />
                3 weeks
              </label>
              <label>
                Other:
                <input
                  name="follow_up_text"
                  className="di-input"
                  type="text"
                  value={followupText}
                  onChange={(e) => {
                    setFollowupText(e.target.value);
                    setOneWeek(false);
                    setThreeWeeks(false);
                    setCallOffice(false);
                  }}
                />
              </label>
            </div>
            <label>
              <input
                type="radio"
                id="call_office"
                name="follow_up"
                checked={callOffice}
                onChange={() => {
                  setCallOffice(true);
                  setOneWeek(false);
                  setThreeWeeks(false);
                  setFollowupText("");
                }}
              />
              Call your Surgeons Office
            </label>
          </div>

          <p>
            5. <strong>PAIN –</strong> If medication has been prescribed for
            your discomfort, it may not provide total relief. DO NOT take
            aspirin and aspirin products.
          </p>
          <p>
            6. <strong>DO NOT</strong> change your dressing or get it wet unless
            instructed to do so by your doctor. Take a sponge bath until you see
            your doctor at this office unless instructed otherwise.
          </p>
          <p>
            7. You should have a responsible adult be with you for the rest of
            the day and during the night for your protection and safety.
          </p>
          <p>
            8. You may expect a call from a staff member of SEA Surgery Center
            in the next few days. This is a routine call to check on your
            progress. Please confirm the telephone number where we may reach you
            this evening and tomorrow.
            <input
              name="phone_number"
              type="phone"
              className="di-input"
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
            />
          </p>
          <div className="di-container">
            <div className="di-item">
              9. May we contact you at home following the procedure?
            </div>
            <div className="di-item">
              {" "}
              <div className="aq-form-options">
                <label className="aq-option-label">
                  <input
                    type="checkbox"
                    className="checkbox"
                    name="home_contact_yes"
                    onChange={() => {
                      setMessageHomeYes(true);
                      setHomeContactNo(false);
                    }}
                    checked={homeContactYes === true}
                  />{" "}
                  YES
                </label>
                <label className="aq-option-label">
                  <input
                    type="checkbox"
                    className="checkbox"
                    name="home_contact_no"
                    onChange={() => {
                      setHomeContactNo(true);
                      setMessageHomeYes(false);
                    }}
                    checked={homeContactNo === true}
                  />{" "}
                  NO
                </label>
              </div>
            </div>
            <div className="di-item">
              10. May we contact you at work following the procedure?
            </div>
            <div className="di-item">
              {" "}
              <div className="aq-form-options">
                <label className="aq-option-label">
                  <input
                    type="checkbox"
                    className="checkbox"
                    value={true}
                    name="work_contact"
                    onChange={() => setWorkContactYes(true)}
                    checked={workContactYes === true}
                  />{" "}
                  YES
                </label>
                <label className="aq-option-label">
                  <input
                    type="checkbox"
                    className="checkbox"
                    value={true}
                    name="work_contact"
                    onChange={() => setWorkContactNo(true)}
                    checked={workContactNo === true}
                  />{" "}
                  NO
                </label>
              </div>
            </div>
            <div className="di-item">11. May we leave a MESSAGE at home?</div>
            <div className="di-item">
              {" "}
              <div className="aq-form-options">
                <label className="aq-option-label">
                  <input
                    type="checkbox"
                    className="checkbox"
                    value={true}
                    name="home_message"
                    onChange={() => setMessageHomeYes(true)}
                    checked={messageHomeYes === true}
                  />{" "}
                  YES
                </label>
                <label className="aq-option-label">
                  <input
                    type="checkbox"
                    className="checkbox"
                    value={true}
                    name="home_message"
                    onChange={() => setMessageHomeNo(true)}
                    checked={messageHomeNo === true}
                  />{" "}
                  NO
                </label>
              </div>
            </div>
            <div className="di-item">12. May we leave a MESSAGE at work?</div>
            <div className="di-item">
              {" "}
              <div className="aq-form-options">
                <label className="aq-option-label">
                  <input
                    type="checkbox"
                    className="checkbox"
                    value={true}
                    name="work_message"
                    checked={messageWorkYes === true}
                    onChange={() => setMessageWorkYes(true)}
                  />{" "}
                  YES
                </label>
                <label className="aq-option-label">
                  <input
                    type="checkbox"
                    className="checkbox"
                    value={true}
                    name="work_message"
                    checked={messageWorkNo === true}
                    onChange={() => setMessageWorkNo(true)}
                  />{" "}
                  NO
                </label>
              </div>
            </div>
            <div className="di-item">13. Who may we speak with at home?</div>
            <div className="di-item">
              {" "}
              <input
                name="home_proxy"
                type="text"
                className="long-input"
                value={nameHome}
                onChange={(e) => setNameHome(e.target.value)}
              />
            </div>
            <div className="di-item">14. Who may we speak with at work?</div>
            <div className="di-item">
              {" "}
              <input
                name="work_proxy"
                type="text"
                className="long-input"
                value={nameWork}
                onChange={(e) => setNameWork(e.target.value)}
              />
            </div>
          </div>
          <div className="si-container">
            <p className="si-text">15. Special Instructions:</p>
            <input
              name="special_instructions"
              type="text"
              className="long-input"
              value={specialInst}
              onChange={(e) => setSpecialInst(e.target.value)}
            />
          </div>
          <div className="si-container">
            <p className="si-text">Prescription:</p>
            <div className="aq-form-options">
              <label className="aq-option-label">
                <input
                  type="checkbox"
                  className="checkbox"
                  name="prescription"
                  value={true}
                  checked={prescription === true}
                  onChange={() => setPrescription(true)}
                />{" "}
                YES
              </label>
              <label className="aq-option-label">
                <input
                  type="checkbox"
                  className="checkbox"
                  name="prescription"
                  value={false}
                  checked={prescription === false}
                  onChange={() => setPrescription(false)}
                />{" "}
                NO
              </label>
            </div>
            <input
              type="text"
              className="long-input"
              value={prescriptionText}
              onChange={(e) => setPrescriptionText(e.target.value)}
            />
          </div>

          <p className="form-title">
            <strong>
              I have received and understood my discharge instructions:
            </strong>
          </p>
          <div className="grid-2">
            <div className="signature-container mt-50">
              <SignatureButton
                individualSignature={individualSignature}
                setIndividualSignature={setIndividualSignature}
              />
              <hr className="signature-line"></hr>
              <p>Patient/Care Giver/Representative</p>
            </div>

            <div className="mt-50">
              <label htmlFor="name" className="hidden">
                Date
              </label>
              <input
                type="date"
                id="date"
                name="patient_signature_date"
                className="bottom-border-input"
                value={date}
                
                onChange={(e) => setDate(e.target.value)}
              />

              <p>Date</p>
            </div>
          </div>
          {/* <div className="grievance-container mt-50">
            <div className="gc-item">
              {" "}
              <SignatureButton />
              <hr className="signature-line"></hr>
              <p>RN Signature</p>
            </div>
            <div className="gc-item mt-50">
              {" "}
              <label htmlFor="name" className="hidden">
                RN Signature
              </label>
              <input
                type="text"
                id="name"
                name="name"
                className="bottom-border-input"
                required
              />
              <p>Date</p>
            </div>
            <div className="gc-item mt-50">
              {" "}
              <label htmlFor="name" className="hidden">
                Time of Discharge
              </label>
              <input
                type="text"
                id="time"
                name="time"
                className="bottom-border-input"
                required
              />
              <p>Time of Discharge</p>
            </div>
          </div> */}
          <div className="center mt-50">
            <button className="btn-primary hover-pointer" type="submit">
              Submit
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default DischargeInstructions;
