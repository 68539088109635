import React from "react";
import { Icon } from "@iconify/react";
import { useNavigate } from "react-router-dom";
import "../Forms/styles.css";
const PostAnesthesiaPhysicianOrders = () => {
  const navigate = useNavigate();

  return (
    <>
      <div>
        <div
          className="arrow-container"
          onClick={() => navigate(-1)}
          style={{ cursor: "pointer" }}
        >
          <p className="back-arrow">
            <Icon icon="ic:twotone-arrow-back" className="icon" /> Back to Forms
          </p>
        </div>
        <h3 className="form-title">Post Anesthesia Care Physician Orders</h3>
        <div className="form-content">
          <form>
            <div className="papo-container">
              <div className="papo-row">
                <div className="papo-column"></div>
                <div className="papo-column papo-title-text">
                  <p>R.N. Initials</p>
                </div>
                <div className="papo-column papo-title-text">
                  <p>Time Noted</p>
                </div>
              </div>
              <div className="papo-row">
                <div className="papo-column">
                  <b>1. Oxygen</b>
                </div>
                <div className="papo-column">
                  <input type="text" />
                </div>
                <div className="papo-column">
                  <input type="text" />
                </div>
              </div>
              <div className="papo-row">
                <div className="papo-column">
                  <input type="checkbox" />
                  <label>3-10 liters per minute via facemask</label>
                </div>
                <div className="papo-column">
                  <input type="text" />
                </div>
                <div className="papo-column">
                  <input type="text" />
                </div>
              </div>
              <div className="papo-row">
                <div className="papo-column">
                  <input type="checkbox" />
                  <label>2-4 liters per minute via nasal cannula</label>
                </div>
                <div className="papo-column">
                  <input type="text" />
                </div>
                <div className="papo-column">
                  <input type="text" />
                </div>
              </div>
              <div className="papo-row">
                <div className="papo-column">
                  <input type="checkbox" />
                  <label>3-10 liters per inute via LMA</label>
                </div>
                <div className="papo-column">
                  <input type="text" />
                </div>
                <div className="papo-column">
                  <input type="text" />
                </div>
              </div>
              <div className="papo-row">
                <div className="papo-column">
                  <input type="checkbox" />
                  <label>Administer 02 PRN SP02 &gt; 92%</label>
                </div>
                <div className="papo-column">
                  <input type="text" />
                </div>
                <div className="papo-column">
                  <input type="text" />
                </div>
              </div>
              <div className="papo-row">
                <div className="papo-column">
                  <b>2. IV</b>
                </div>
                <div className="papo-column">
                  <input type="text" />
                </div>
                <div className="papo-column">
                  <input type="text" />
                </div>
              </div>
              <div className="papo-row">
                <div className="papo-column">
                  <input type="checkbox" />
                  <label>
                    Run current IV at{" "}
                    <input type="text" className="papo-form-input" />
                    ml/hr
                  </label>
                </div>
                <div className="papo-column">
                  <input type="text" />
                </div>
                <div className="papo-column">
                  <input type="text" />
                </div>
              </div>
              <div className="papo-row">
                <div className="papo-column">
                  <input type="checkbox" />
                  <label>D/C IV prior to discharge</label>
                </div>
                <div className="papo-column">
                  <input type="text" />
                </div>
                <div className="papo-column">
                  <input type="text" />
                </div>
              </div>
              <div className="papo-row">
                <div className="papo-column">
                  <b>3. Pain Management</b>
                </div>
                <div className="papo-column">
                  <input type="text" />
                </div>
                <div className="papo-column">
                  <input type="text" />
                </div>
              </div>
              <div className="papo-row">
                <div className="papo-column">
                  Parenteral, for moderate to severe pain and when unable to
                  take PO
                </div>
                <div className="papo-column">
                  <input type="text" />
                </div>
                <div className="papo-column">
                  <input type="text" />
                </div>
              </div>
              <div className="papo-row">
                <div className="papo-column">
                  <input type="checkbox" />
                  <label>
                    Demerol© (meperidine){" "}
                    <input type="text" className="papo-form-input" />
                    mg IVP, may repeat q
                    <input type="text" className="papo-form-input" />
                    min X <input type="text" className="papo-form-input" />
                  </label>
                </div>
                <div className="papo-column">
                  <input type="text" />
                </div>
                <div className="papo-column">
                  <input type="text" />
                </div>
              </div>
              <div className="papo-row">
                <div className="papo-column">
                  {" "}
                  <input type="checkbox" />
                  <label>
                    Toradol© (ketorolac){" "}
                    <input type="text" className="papo-form-input" />
                    mg <input type="checkbox" />
                    IVP or <input type="checkbox" />
                    IM
                  </label>
                </div>
                <div className="papo-column">
                  <input type="text" />
                </div>
                <div className="papo-column">
                  <input type="text" />
                </div>
              </div>
              <div className="papo-row">
                <div className="papo-column">
                  {" "}
                  <input type="checkbox" />
                  <label>
                    Fentanyl <input type="text" className="papo-form-input" />
                    mcg <input type="checkbox" />
                    IVP, may repeat q<input type="checkbox" />
                    min X <input type="checkbox" /> doses
                  </label>
                </div>
                <div className="papo-column">
                  <input type="text" />
                </div>
                <div className="papo-column">
                  <input type="text" />
                </div>
              </div>
              <div className="papo-row">
                <div className="papo-column">
                  Oral, for mild to moderate pain when tolerating PO
                </div>
                <div className="papo-column">
                  <input type="text" />
                </div>
                <div className="papo-column">
                  <input type="text" />
                </div>
              </div>
              <div className="papo-row">
                <div className="papo-column">
                  <input type="checkbox" />
                  <label>Percocet 5/325mg 1 tab PO may repeat</label>
                </div>
                <div className="papo-column">
                  <input type="text" />
                </div>
                <div className="papo-column">
                  <input type="text" />
                </div>
              </div>
              <div className="papo-row">
                <div className="papo-column">
                  {" "}
                  <input type="checkbox" />
                  <label>
                    Vicodin© (hydrocodone 5/mg/acetaminophen 500mg) 1 tab PO q
                    <input type="text" className="papo-form-input" />
                  </label>
                </div>
                <div className="papo-column">
                  <input type="text" />
                </div>
                <div className="papo-column">
                  <input type="text" />
                </div>
              </div>
              <div className="papo-row">
                <div className="papo-column">
                  {" "}
                  <input type="checkbox" />
                  <label>Other</label>
                </div>
                <div className="papo-column">
                  <input type="text" />
                </div>
                <div className="papo-column">
                  <input type="text" />
                </div>
              </div>
              <div className="papo-row">
                <div className="papo-column">
                  <b>4. Nausea and Vomitting</b>
                </div>
                <div className="papo-column">
                  <input type="text" />
                </div>
                <div className="papo-column">
                  <input type="text" />
                </div>
              </div>
              <div className="papo-row">
                <div className="papo-column">
                  <input type="checkbox" />
                  <label>
                    Reglan© (metoclopramide) 10mg IVP, may repeat X{" "}
                    <input type="text" className="papo-form-input" />
                  </label>
                </div>
                <div className="papo-column">
                  <input type="text" />
                </div>
                <div className="papo-column">
                  <input type="text" />
                </div>
              </div>
              <div className="papo-row">
                <div className="papo-column">
                  <input type="checkbox" />
                  <label>Zofran© 4mg IVPB, for nausea & vomitting</label>
                </div>
                <div className="papo-column">
                  <input type="text" />
                </div>
                <div className="papo-column">
                  <input type="text" />
                </div>
              </div>
              <div className="papo-row">
                <div className="papo-column">
                  <input type="checkbox" />
                  <label>Other</label>
                </div>
                <div className="papo-column">
                  <input type="text" />
                </div>
                <div className="papo-column">
                  <input type="text" />
                </div>
              </div>
              <div className="papo-row">
                <div className="papo-column">
                  <b>5. Additional Orders:</b>{" "}
                  <input type="text" className="papo-form-input-long" />
                </div>
                <div className="papo-column">
                  <input type="text" />
                </div>
                <div className="papo-column">
                  <input type="text" />
                </div>
              </div>

              <div className="papo-row">
                <div className="papo-column">
                  <b>
                    6. <input type="checkbox" />
                    <label>
                      {" "}
                      May discharge patient when alert, stable, and discharge
                    </label>
                    criteria met.
                  </b>
                </div>
                <div className="papo-column">
                  <input type="text" />
                </div>
                <div className="papo-column">
                  <input type="text" />
                </div>
              </div>
              <div className="papo-row">
                <div className="papo-column">
                  <input type="checkbox" />
                  <label>
                    <b>
                      M.D. Evaluation: I have evaluated the patient and patient
                      is ready for discharge.
                    </b>
                  </label>
                </div>
                <div className="papo-column">
                  <input type="text" />
                </div>
                <div className="papo-column">
                  <input type="text" />
                </div>
              </div>
            </div>
            <div className="papo-sig-container">
              <label>
                M.D.
                <input type="text" className="sig-input" />
              </label>
              <label>
                Time
                <input type="time" className="sig-input" />
              </label>
            </div>
            <div className="papo-sig-container">
              <label>
                Anesthesia Provider Signature
                <input type="text" className="sig-input" />
              </label>
              <label>
                Date
                <input type="date" className="sig-input" />
              </label>
              <label>
                Time
                <input type="time" className="sig-input" />
              </label>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default PostAnesthesiaPhysicianOrders;
