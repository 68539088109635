import React, { useState, useEffect } from "react";
import { Icon } from "@iconify/react";
import "../Forms/styles.css";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import moment from "moment";
import { getPreOpPhoneCall, updatePreOpPhoneCall } from "../../../utils/api";


const PreOpPhoneCall = () => {
  const navigate = useNavigate();
  const { form_id } = useParams();
  const [loading, setLoading] = useState(true);

  const [formData, setFormData] = useState({
    patient_name: "",
    patient_phone: "",
    procedure: "",
    date_one: "",
    date_two: "",
    surgeon_two: "",
    anesthesia_type: "",
    surgeon_phone: "",
    time_one: "",
    ride_yes: false,
    ride_no: false,
    ride_na: false,
    advanced_directive: false,
    advanced_directive_yes: false,
    advanced_directive_no: false,
    DNR_yes: false,
    DNR_no: false,
    notes: "",
    interviewed_by: "",
  });
  const handleSubmit = async (e) => {
    e.preventDefault();

    const payload = {
      ...formData,
      date: moment().format("MM/DD/YYYY"),
      time: moment().format("h:mm a"),
      date_one: moment().format("MM/DD/YYYY"),

      date_two: moment().format("MM/DD/YYYY"),
      time_one: moment().format("h:mm a"),
      status: "In Progress",
    };

    console.log("Payload Sent to Backend:", payload);

    // Fetch 2: Create/Update the Disclosure of Ownerhsip Model

    updatePreOpPhoneCall(form_id, payload)
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        console.log("complete");
        navigate(-1);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getPreOpPhoneCall(form_id)
      .then((res) => res.json())
      .then((data) => {
        console.log(data);

        if (data.status !== "Not Started") {
          setFormData({
            patient_name: "",
            patient_phone: "",
            procedure: "",
            date_one: "",
            date_two: "",
            surgeon_two: "",
            anesthesia_type: "",
            surgeon_phone: "",
            time_one: "",
            ride_yes: false,
            ride_no: false,
            ride_na: false,
            advanced_directive: false,
            advanced_directive_yes: false,
            advanced_directive_no: false,
            DNR_yes: false,
            DNR_no: false,
            notes: "",
            interviewed_by: "",
          });
        }
        setLoading(false);
      })
      .catch((err) => console.log(err));
  }, []);
  if (loading) return <p>Loading...</p>;

  return (
    <>
      <div
        className="arrow-container"
        onClick={() => navigate(-1)}
        style={{ cursor: "pointer" }}
      >
        <p className="back-arrow">
          <Icon icon="ic:twotone-arrow-back" className="icon" /> Back to Forms
        </p>
      </div>{" "}
      <div className="form-content">
        <form onSubmit={handleSubmit}>
          {" "}
          <h2 className="form-title">Pre-Op Phone Call</h2>
          {/* first row */}
          <div className="preoppc-container">
            <div className="preoppc-form-group">
              <label>
                Patient Name:
                <input
                  name="patient_name"
                  value={formData.patient_name}
                  onChange={(e) =>
                    setFormData({ ...formData, patient_name: e.target.value })
                  }
                />
              </label>
            </div>
            <div className="preoppc-form-group">
              <label>
                Patient Phone #:
                <input
                  name="patient_phone"
                  value={formData.patient_phone}
                  onChange={(e) =>
                    setFormData({ ...formData, patient_phone: e.target.value })
                  }
                />
              </label>
            </div>
          </div>
          {/* secondrow */}
          <div className="preoppc-container">
            <div className="preoppc-form-group">
              <label>
                Prodedure:
                <input
                  name="procedure"
                  value={formData.procedure}
                  onChange={(e) =>
                    setFormData({ ...formData, procedure: e.target.value })
                  }
                />
              </label>
            </div>
            <div className="preoppc-form-group">
              <label>
                Procedure Date:
                <input
                  type="date"
                  name="date_one"
                  value={formData.date_one}
                  onChange={(e) =>
                    setFormData({ ...formData, date_one: e.target.value })
                  }
                />
              </label>
            </div>
            <div className="preoppc-form-group">
              <label>
                Time:
                <input
                  type="time"
                  name="time_one"
                  value={formData.time_one}
                  onChange={(e) =>
                    setFormData({ ...formData, time_one: e.target.value })
                  }
                />
              </label>
            </div>
          </div>
          {/* third row */}
          <div className="preoppc-container">
            <div className="preoppc-form-group">
              <label>
                Anesthesia Type:
                <input
                  name="anesthesia_type"
                  value={formData.anesthesia_type}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      anesthesia_type: e.target.value,
                    })
                  }
                />
              </label>
            </div>
            <div className="preoppc-form-group">
              <label>
                Surgeon
                <input
                  name="surgeon_two"
                  value={formData.surgeon_two}
                  onChange={(e) =>
                    setFormData({ ...formData, surgeon_two: e.target.value })
                  }
                />
              </label>
            </div>
          </div>
          {/* fourth row */}
          <div className="preoppc-container">
            <div className="preoppc-form-group preoppc-form-group-2">
              <p>Do you have a ride home/aftercare?</p>
              <input
                type="checkbox"
                name="ride_yes"
                checked={formData.ride_yes}
                onChange={(e) =>
                  setFormData({ ...formData, ride_yes: e.target.checked })
                }
              />
              <label>yes</label>
              <input
                type="checkbox"
                name="ride_no"
                checked={formData.ride_no}
                onChange={(e) =>
                  setFormData({ ...formData, ride_no: e.target.checked })
                }
              />
              <label>no</label>
              <label>
                <input
                  type="checkbox"
                  name="ride_na"
                  checked={formData.ride_na}
                  onChange={(e) =>
                    setFormData({ ...formData, ride_na: e.target.checked })
                  }
                />
                N/A (Local Only)
              </label>
            </div>
            <div className="preoppc-form-group">
              <label>
                Contact Phone #:
                <input
                  name="surgeon_phone"
                  value={formData.surgeon_phone}
                  onChange={(e) =>
                    setFormData({ ...formData, surgeon_phone: e.target.value })
                  }
                />
              </label>
            </div>
          </div>
          <div className="satisfaction-container sc-no-margin">
            <div className="survey">
              <div className="pa-question">
                <label className="question-label">
                  <b> Do you have an Advanced Directive?</b>
                </label>
                <div className="options">
                  <label>
                    <input
                      type="checkbox"
                      name="advanced_directive_yes"
                      checked={formData.advanced_directive_yes}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          advanced_directive_yes: e.target.checked,
                        })
                      }
                    />
                    Yes{" "}
                  </label>
                  <label>
                    <input
                      type="checkbox"
                      name="advanced_directive_no"
                      checked={formData.advanced_directive_no}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          advanced_directive_no: e.target.checked,
                        })
                      }
                    />
                    No{" "}
                  </label>
                </div>
              </div>
            </div>

            <div className="survey">
              <div className="pa-question">
                <label className="question-label">
                  Does your Advanced Directive have a{" "}
                  <b>"Do Not Resuscitate"</b>
                  order?
                  <br />
                  If Yes, notify the patient that we do not honor "Do Not
                  Resuscitate" Orders. The patient will be asked to sign consent
                  for resuscitation. If the patient does not agree, inform the
                  patient that the procedure will have to be scheduled in a
                  hospital. Notify the supervisor if the case needs to be
                  canceled.
                </label>
                <div className="options">
                  <label>
                    <input
                      type="checkbox"
                      name="DNR_yes"
                      checked={formData.DNR_yes}
                      onChange={(e) =>
                        setFormData({ ...formData, DNR_yes: e.target.checked })
                      }
                    />
                    Yes{" "}
                  </label>
                  <label>
                    <input
                      type="checkbox"
                      name="DNR_no"
                      checked={formData.DNR_no}
                      onChange={(e) =>
                        setFormData({ ...formData, DNR_no: e.target.checked })
                      }
                    />
                    No{" "}
                  </label>
                </div>
              </div>
            </div>
            <div className="survey">
              <div className="pa-question">
                <label className="question-label">
                  Reviewed
                  <ol>
                    <li> Patient's Rights & Responsibility</li>
                    <li>Ownership Information</li>
                    <li>Grievance Policy</li>
                    <li>Advance Directive Policy</li>
                  </ol>
                </label>
                <div className="options">
                  {/* <label>
                    Yes <input type="checkbox" />
                  </label>
                  <label>
                    No <input type="checkbox" />
                  </label> */}
                </div>
              </div>
            </div>
            <div className="survey">
              <div className="pa-question">
                <label className="question-label">
                  PRESCRIPTIONS: You must{" "}
                  <b>bring your prescriptions with you</b> on the day of
                  surgery, including regular medications taken on a daily basis
                  and post-operatively prescribed medications recommended by
                  your surgeon.
                </label>
                <div className="options">
                  {/* <label>
                    Yes <input type="checkbox" />
                  </label>
                  <label>
                    No <input type="checkbox" />
                  </label> */}
                </div>
              </div>
            </div>
            <div className="survey">
              <div className="pa-question">
                <label className="question-label">
                  CLOTHING: Wear a buttoning shirt, slacks, and flat slip-on or
                  tennis shoes. Do not wear pantyhose, a pull-over top. Contact
                  lens wearers should bring glasses. Please bring a sweater or
                  jacket in case you are cold after surgery. Please remove all
                  hair pins, jewelry, or any metal in your body (piercing).
                </label>
                <div className="options">
                  {/* <label>
                    Yes <input type="checkbox" />
                  </label>
                  <label>
                    No <input type="checkbox" />
                  </label> */}
                </div>
              </div>
            </div>
            <div className="survey">
              <div className="pa-question">
                <label className="question-label">
                  CONTACT AFTER SURGERY: You must provide us with a telephone
                  number where you can be reached for 24 hours after surgery.
                </label>
                <div className="options">
                  {/* <label>
                    Yes <input type="checkbox" />
                  </label>
                  <label>
                    No <input type="checkbox" />
                  </label> */}
                </div>
              </div>
            </div>
            <div className="survey">
              <div className="pa-question">
                <label className="question-label">
                  ARRIVAL TIME: It is of the utmost importance that you arrive
                  on time at:{" "}
                  <input
                    type="time"
                    className="di-input"
                    name="time_two"
                    value={formData.time_two}
                    onChange={(e) =>
                      setFormData({ ...formData, time_two: e.target.value })
                    }
                  />
                </label>
                <div className="options">
                  {/* <label>
                    Yes <input type="checkbox" />
                  </label>
                  <label>
                    No <input type="checkbox" />
                  </label> */}
                </div>
              </div>
            </div>
            <div className="survey">
              <div className="pa-question">
                <label className="question-label">
                  LEAVE ALL VALUABLES AT HOME: Including handbags and jewelry,
                  watches, or anything of value with a responsible adult.
                </label>
                <div className="options">
                  {/* <label>
                    Yes <input type="checkbox" />
                  </label>
                  <label>
                    No <input type="checkbox" />
                  </label> */}
                </div>
              </div>
            </div>
            <div className="survey">
              <div className="pa-question">
                <label className="question-label">
                  DO NOT EAT OR DRINK ANYTHING: If you have had anything to eat
                  or drink after midnight the night before,{" "}
                  <b>your surgery may have to be canceled otherwise.</b>
                </label>
                <div className="options">
                  {/* <label>
                    Yes <input type="checkbox" />
                  </label>
                  <label>
                    No <input type="checkbox" />
                  </label> */}
                </div>
              </div>
            </div>
            <div className="survey">
              <div className="pa-question">
                <label className="question-label">
                  ORAL HYGIENE: It is fine to brush your teeth, but do not
                  swallow the water (local patients exempt).
                </label>
                <div className="options">
                  {/* <label>
                    Yes <input type="checkbox" />
                  </label>
                  <label>
                    No <input type="checkbox" />
                  </label> */}
                </div>
              </div>
            </div>
          </div>
          <div className="preoppc-form-group local">
            <label>
              <b>LOCAL PATIENTS</b>
            </label>
            {/* <textarea /> */}
          </div>
          <div className="preoppc-form-group">
            <label>Notes:</label>
            <textarea
              value={formData.notes}
              onChange={(e) =>
                setFormData({ ...formData, notes: e.target.value })
              }
            />
          </div>
          <div className="grievance-container mt-50 mobile">
            <div className="gc-item">
              {" "}
              <input
              className="bottom-border-input"
                name="interviewed_by"
                value={formData.interviewed_by}
                onChange={(e) =>
                  setFormData({ ...formData, interviewed_by: e.target.value })
                }
              />
              <p>Interviewed By:</p>
            </div>
            <div className="gc-item mt-50">
              {" "}
              <label htmlFor="name" className="hidden">
                Interviewed By:
              </label>
              <input
                type="date"
                id="name"
                className="bottom-border-input"
                name="date_two"
                value={formData.date_two}
                onChange={(e) =>
                  setFormData({ ...formData, date_two: e.target.value })
                }
              />
              <p>Date</p>
            </div>
          </div>
          <div className="center mt-50">
            <button className="btn-primary hover-pointer" type="submit">
              Submit
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default PreOpPhoneCall;
