import React, { useState, useEffect } from "react";
import { Icon } from "@iconify/react";
import "../Forms/styles.css";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import moment from "moment";
import {
  addFile,
  getBillingSupply,
  updateBillingSupply,
} from "../../../utils/api";
import SignatureButton from "../../../components/SignatureButton";

const BillingSupply = () => {
  const navigate = useNavigate();
  const { form_id } = useParams();
  const [loading, setLoading] = useState(true);
  const [individualSignature, setIndividualSignature] = useState("");

  const [formData, setFormData] = useState({
    procedure: "",
    one: "",
    two: "",
    three: "",
    four: "",
    five: "",
    six: "",
    seven: "",
    eight: "",
    nine: "",
    ten: "",
    eleven: "",
    twelve: "",
    thirteen: "",
    fourteen: "",
    fifteen: "",
    sixteen: "",
    seventeen: "",
    eighteen: "",
    nineteen: "",
    twenty: "",
    twenty_one: "",
    twenty_two: "",
    twenty_three: "",
    twenty_four: "",
    twenty_five: "",
    twenty_six: "",
    twenty_seven: "",
    twenty_eight: "",
    twenty_nine: "",
    thirty: "",
    thirty_one: "",
    thirty_two: "",
    thirty_three: "",
    thirty_four: "",
    thirty_five: "",
    thirty_six: "",
    thirty_seven: "",
    thirty_eight: "",
    thirty_nine: "",
    forty: "",
    forty_one: "",
    forty_two: "",
    forty_three: "",
    forty_four: "",
    forty_five: "",
    forty_six: "",
    forty_seven: "",
    forty_eight: "",
    forty_nine: "",
    fifty: "",
    fifty_one: "",
    fifty_two: "",
    fifty_three: "",
    fifty_four: "",
    fifty_five: "",
    fifty_six: "",
    fifty_seven: "",
    fifty_eight: "",
    fifty_nine: "",
    sixty: "",
    sixty_one: "",
    sixty_two: "",
    sixty_three: "",
    sixty_four: "",
    sixty_five: "",
    sixty_six: "",
    sixty_seven: "",
    sixty_eight: "",
    sixty_nine: "",
    seventy: "",
    seventy_one: "",
    seventy_two: "",
    seventy_three: "",
    seventy_four: "",
    seventy_five: "",
    seventy_six: "",
    seventy_seven: "",
    seventy_eight: "",
    seventy_nine: "",
    eighty: "",
    eighty_one: "",
    eighty_two: "",
    eighty_three: "",
    eighty_four: "",
    eighty_five: "",
    eighty_six: "",
    Text1: "",
    implants: "",
    special_two: "",
    meds_one: "",
    meds_two: "",
    anesthesia_one: "",
    anesthesia_two: "",
    special_one: "",
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    let fileName;

    // Fetch 1: Upload Signature
    if (individualSignature !== "") {
      const response = await addFile(individualSignature);

      const data = await response.json();
      fileName = await data.fileName;
    }
    const payload = {
      ...formData,
      date: moment().format("MM/DD/YYYY"),
      time: moment().format("h:mm a"),
      status: "In Progress",
    };

    console.log("Payload Sent to Backend:", payload);

    // Fetch 2: Create/Update the Disclosure of Ownerhsip Model

    updateBillingSupply(form_id, payload)
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        console.log("complete");
        navigate(-1);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getBillingSupply(form_id)
      .then((res) => res.json())
      .then((data) => {
        console.log(data);

        if (data.status !== "Not Started") {
          setFormData({
            procedure: data.procedure || "",
            one: data.one || "",
            two: data.two || "",
            three: data.three || "",
            four: data.four || "",
            five: data.five || "",
            six: data.six || "",
            seven: data.seven || "",
            eight: data.eight || "",
            nine: data.nine || "",
            ten: data.ten || "",
            eleven: data.eleven || "",
            twelve: data.twelve || "",
            thirteen: data.thirteen || "",
            fourteen: data.fourteen || "",
            fifteen: data.fifteen || "",
            sixteen: data.sixteen || "",
            seventeen: data.seventeen || "",
            eighteen: data.eighteen || "",
            nineteen: data.nineteen || "",
            twenty: data.twenty || "",
            twenty_one: data.twenty_one || "",
            twenty_two: data.twenty_two || "",
            twenty_three: data.twenty_three || "",
            twenty_four: data.twenty_four || "",
            twenty_five: data.twenty_five || "",
            twenty_six: data.twenty_six || "",
            twenty_seven: data.twenty_seven || "",
            twenty_eight: data.twenty_eight || "",
            twenty_nine: data.twenty_nine || "",
            thirty: data.thirty || "",
            thirty_one: data.thirty_one || "",
            thirty_two: data.thirty_two || "",
            thirty_three: data.thirty_three || "",
            thirty_four: data.thirty_four || "",
            thirty_five: data.thirty_five || "",
            thirty_six: data.thirty_six || "",
            thirty_seven: data.thirty_seven || "",
            thirty_eight: data.thirty_eight || "",
            thirty_nine: data.thirty_nine || "",
            forty: data.forty || "",
            forty_one: data.forty_one || "",
            forty_two: data.forty_two || "",
            forty_three: data.forty_three || "",
            forty_four: data.forty_four || "",
            forty_five: data.forty_five || "",
            forty_six: data.forty_six || "",
            forty_seven: data.forty_seven || "",
            forty_eight: data.forty_eight || "",
            forty_nine: data.forty_nine || "",
            fifty: data.fifty || "",
            fifty_one: data.fifty_one || "",
            fifty_two: data.fifty_two || "",
            fifty_three: data.fifty_three || "",
            fifty_four: data.fifty_four || "",
            fifty_five: data.fifty_five || "",
            fifty_six: data.fifty_six || "",
            fifty_seven: data.fifty_seven || "",
            fifty_eight: data.fifty_eight || "",
            fifty_nine: data.fifty_nine || "",
            sixty: data.sixty || "",
            sixty_one: data.sixty_one || "",
            sixty_two: data.sixty_two || "",
            sixty_three: data.sixty_three || "",
            sixty_four: data.sixty_four || "",
            sixty_five: data.sixty_five || "",
            sixty_six: data.sixty_six || "",
            sixty_seven: data.sixty_seven || "",
            sixty_eight: data.sixty_eight || "",
            sixty_nine: data.sixty_nine || "",
            seventy: data.seventy || "",
            seventy_one: data.seventy_one || "",
            seventy_two: data.seventy_two || "",
            seventy_three: data.seventy_three || "",
            seventy_four: data.seventy_four || "",
            seventy_five: data.seventy_five || "",
            seventy_six: data.seventy_six || "",
            seventy_seven: data.seventy_seven || "",
            seventy_eight: data.seventy_eight || "",
            seventy_nine: data.seventy_nine || "",
            eighty: data.eighty || "",
            eighty_one: data.eighty_one || "",
            eighty_two: data.eighty_two || "",
            eighty_three: data.eighty_three || "",
            eighty_four: data.eighty_four || "",
            eighty_five: data.eighty_five || "",
            eighty_six: data.eighty_six || "",
            Text1: data.Text1 || "",
            implants: data.implants || "",
            special_two: data.special_two || "",
            meds_one: data.meds_one || "",
            meds_two: data.meds_two || "",
            anesthesia_one: data.anesthesia_one || "",
            anesthesia_two: data.anesthesia_two || "",
            special_one: data.special_one || "",
            name_two: data.name_two || "",
            surgeon_two: data.surgeon_two || "",
            DOS_two: data.DOS_two || "",
            gender_two: data.gender_two || "",
            DOB_two: data.DOB_two || "",
            MRN_two: data.MRN_two || "",
            age_two: data.age_two || "",
          });
        }
        setLoading(false);
      })
      .catch((err) => console.log(err));
  }, []);
  if (loading) return <p>Loading...</p>;

  return (
    <>
      <div
        className="arrow-container"
        onClick={() => navigate(-1)}
        style={{ cursor: "pointer" }}
      >
        <p className="back-arrow">
          <Icon icon="ic:twotone-arrow-back" className="icon" /> Back to Forms
        </p>
      </div>{" "}
      <div className="form-content">
        <form onSubmit={handleSubmit}>
          {" "}
          <h2 className="form-title">Billing Supply</h2>
          <div className="label-input">
            <label>
              <b>PROCEDURE:</b>
            </label>
            <input
              type="text"
              className="di-input"
              name="procedure"
              value={formData.procedure}
              onChange={(e) =>
                setFormData({ ...formData, procedure: e.target.value })
              }
            />
          </div>
          <div className="billing-supply-grid-container">
            <div className="billing-supply-grid-item">
              <label>IV STARTER KIT</label>
            </div>
            <div className="billing-supply-grid-item">
              <input
                name="one"
                value={formData.one}
                onChange={(e) =>
                  setFormData({ ...formData, one: e.target.value })
                }
                type="text"
                className="post-op-input"
                placeholder="X"
              />
            </div>
            <div className="billing-supply-grid-item">
              <label>NASAL CANNULA</label>
            </div>
            <div className="billing-supply-grid-item">
              <input
                type="text"
                name="seventy_two"
                value={formData.seventy_two}
                onChange={(e) =>
                  setFormData({ ...formData, seventy_two: e.target.value })
                }
                className="post-op-input"
                placeholder="X"
              />
            </div>

            <div className="billing-supply-grid-item">
              <label>IV CATHETER</label>
            </div>
            <div className="billing-supply-grid-item">
              <input
                name="two"
                value={formData.two}
                onChange={(e) =>
                  setFormData({ ...formData, two: e.target.value })
                }
                type="text"
                className="post-op-input"
                placeholder="X"
              />
            </div>
            <div className="billing-supply-grid-item">
              <label>OXYGEN MASK</label>
            </div>
            <div className="billing-supply-grid-item">
              <input
                type="text"
                name="seventy_one"
                value={formData.seventy_one}
                onChange={(e) =>
                  setFormData({ ...formData, seventy_one: e.target.value })
                }
                className="post-op-input"
                placeholder="X"
              />
            </div>
            <div className="billing-supply-grid-item">
              <label>
                IV SOLUTION LR{" "}
                <input
                  name="three"
                  value={formData.three}
                  onChange={(e) =>
                    setFormData({ ...formData, three: e.target.value })
                  }
                  type="text"
                  className="short-input"
                />{" "}
                NS{" "}
                <input
                  name="four"
                  value={formData.four}
                  onChange={(e) =>
                    setFormData({ ...formData, four: e.target.value })
                  }
                  type="text"
                  className="short-input"
                />
              </label>
            </div>
            <div className="billing-supply-grid-item">
              <input
                name="five"
                value={formData.five}
                onChange={(e) =>
                  setFormData({ ...formData, five: e.target.value })
                }
                type="text"
                className="post-op-input"
                placeholder="X"
              />
            </div>
            <div className="billing-supply-grid-item">
              <label>XEROFORM GAUZE</label>
            </div>
            <div className="billing-supply-grid-item">
              <input
                type="text"
                name="seventy"
                value={formData.seventy}
                onChange={(e) =>
                  setFormData({ ...formData, seventy: e.target.value })
                }
                className="post-op-input"
                placeholder="X"
              />
            </div>
            <div className="billing-supply-grid-item">
              <label>PATIENT CAP x 1</label>
            </div>
            <div className="billing-supply-grid-item">
              <input
                name="six"
                value={formData.six}
                onChange={(e) =>
                  setFormData({ ...formData, six: e.target.value })
                }
                type="text"
                className="post-op-input"
              />
            </div>
            <div className="billing-supply-grid-item">
              <label>SUCTION TUBING SET UP</label>
            </div>
            <div className="billing-supply-grid-item">
              <input
                type="text"
                name="sixty_nine"
                value={formData.sixty_nine}
                onChange={(e) =>
                  setFormData({ ...formData, sixty_nine: e.target.value })
                }
                className="post-op-input"
                placeholder="X"
              />
            </div>
            <div className="billing-supply-grid-item">
              <label>PATIENT BOOTIES (PAIR) x 1</label>
            </div>
            <div className="billing-supply-grid-item">
              <input
                name="seven"
                value={formData.seven}
                onChange={(e) =>
                  setFormData({ ...formData, seven: e.target.value })
                }
                type="text"
                className="post-op-input"
              />
            </div>
            <div className="billing-supply-grid-item">
              <label>YANKAUER SUCTION TIP</label>
            </div>
            <div className="billing-supply-grid-item">
              <input
                type="text"
                name="sixty_eight"
                value={formData.sixty_eight}
                onChange={(e) =>
                  setFormData({ ...formData, sixty_eight: e.target.value })
                }
                className="post-op-input"
                placeholder="X"
              />
            </div>
            <div className="billing-supply-grid-item">
              <label>STERILE GLOVES</label>
            </div>
            <div className="billing-supply-grid-item">
              <input
                type="text"
                name="eight"
                value={formData.eight}
                onChange={(e) =>
                  setFormData({ ...formData, eight: e.target.value })
                }
                className="post-op-input"
                placeholder="X"
              />
            </div>
            <div className="billing-supply-grid-item">
              <label>KERLIX </label>
            </div>
            <div className="billing-supply-grid-item">
              <input
                type="text"
                name="sixty_seven"
                value={formData.sixty_seven}
                onChange={(e) =>
                  setFormData({ ...formData, sixty_seven: e.target.value })
                }
                className="post-op-input"
                placeholder="X"
              />
            </div>
            <div className="billing-supply-grid-item">
              <label>REGULAR GLOVES</label>
            </div>
            <div className="billing-supply-grid-item">
              <input
                type="text"
                name="nine"
                value={formData.nine}
                onChange={(e) =>
                  setFormData({ ...formData, nine: e.target.value })
                }
                className="post-op-input"
                placeholder="X"
              />
            </div>
            <div className="billing-supply-grid-item">
              <label>
                GELFOAM
                <input
                  type="text"
                  name="eighty_five"
                  value={formData.eighty_five}
                  onChange={(e) =>
                    setFormData({ ...formData, eighty_five: e.target.value })
                  }
                  className="short-input"
                />{" "}
                GELFILM{" "}
                <input
                  type="text"
                  name="eighty_six"
                  value={formData.eighty_six}
                  onChange={(e) =>
                    setFormData({ ...formData, eighty_six: e.target.value })
                  }
                  className="short-input"
                />
              </label>
            </div>
            <div className="billing-supply-grid-item">
              <input
                type="text"
                className="post-op-input"
                placeholder="X"
                name="sixty_six"
                value={formData.sixty_six}
                onChange={(e) =>
                  setFormData({ ...formData, sixty_six: e.target.value })
                }
              />
            </div>
            <div className="billing-supply-grid-item">
              <label>STERILE GOWNS</label>
            </div>
            <div className="billing-supply-grid-item">
              <input
                type="text"
                name="ten"
                value={formData.ten}
                onChange={(e) =>
                  setFormData({ ...formData, ten: e.target.value })
                }
                className="post-op-input"
                placeholder="X"
              />
            </div>
            <div className="billing-supply-grid-item">
              <label>GLASSCOCK EAR DRESSING </label>
            </div>
            <div className="billing-supply-grid-item">
              <input
                type="text"
                name="sixty_five"
                value={formData.sixty_five}
                onChange={(e) =>
                  setFormData({ ...formData, sixty_five: e.target.value })
                }
                className="post-op-input"
                placeholder="X"
              />
            </div>
            <div className="billing-supply-grid-item">
              <label>STERILE SURGICAL PACK x 1</label>
            </div>
            <div className="billing-supply-grid-item">
              <input
                type="text"
                name="eleven"
                value={formData.eleven}
                onChange={(e) =>
                  setFormData({ ...formData, eleven: e.target.value })
                }
                className="post-op-input"
              />
            </div>
            <div className="billing-supply-grid-item">
              <label>ZEISS MICROSCOPE TOWER </label>
            </div>
            <div className="billing-supply-grid-item">
              <input
                type="text"
                name="sixty_four"
                value={formData.sixty_four}
                onChange={(e) =>
                  setFormData({ ...formData, sixty_four: e.target.value })
                }
                className="post-op-input"
                placeholder="X"
              />
            </div>
            <div className="billing-supply-grid-item">
              <label>STERILE SURGICAL TRAY (MINOR)</label>
            </div>
            <div className="billing-supply-grid-item">
              <input
                type="text"
                name="twelve"
                value={formData.twelve}
                onChange={(e) =>
                  setFormData({ ...formData, twelve: e.target.value })
                }
                className="post-op-input"
                placeholder="X"
              />
            </div>
            <div className="billing-supply-grid-item">
              <label>STRYKER CAMERA & LIGHT SOURCE UNIT</label>
            </div>
            <div className="billing-supply-grid-item">
              <input
                type="text"
                className="post-op-input"
                name="sixty_three"
                value={formData.sixty_three}
                onChange={(e) =>
                  setFormData({ ...formData, sixty_three: e.target.value })
                }
                placeholder="X"
              />
            </div>
            <div className="billing-supply-grid-item">
              <label>STERILE SURGICAL TRAY (EAR)</label>
            </div>
            <div className="billing-supply-grid-item">
              <input
                type="text"
                name="thirteen"
                value={formData.thirteen}
                onChange={(e) =>
                  setFormData({ ...formData, thirteen: e.target.value })
                }
                className="post-op-input"
                placeholder="X"
              />
            </div>
            <div className="billing-supply-grid-item">
              <label>MEDTRONIC NIM RESPONSE UNIT </label>
            </div>
            <div className="billing-supply-grid-item">
              <input
                type="text"
                name="sixty_two"
                value={formData.sixty_two}
                onChange={(e) =>
                  setFormData({ ...formData, sixty_two: e.target.value })
                }
                className="post-op-input"
                placeholder="X"
              />
            </div>
            <div className="billing-supply-grid-item">
              <label>STERILE SURGICAL TRAY (MIDAS RX)</label>
            </div>
            <div className="billing-supply-grid-item">
              <input
                type="text"
                name="fourteen"
                value={formData.fourteen}
                onChange={(e) =>
                  setFormData({ ...formData, fourteen: e.target.value })
                }
                className="post-op-input"
                placeholder="X"
              />
            </div>
            <div className="billing-supply-grid-item">
              <label>DORNIER MEDTECH LASER </label>
            </div>
            <div className="billing-supply-grid-item">
              <input
                type="text"
                name="sixty_one"
                value={formData.sixty_one}
                onChange={(e) =>
                  setFormData({ ...formData, sixty_one: e.target.value })
                }
                className="post-op-input"
                placeholder="X"
              />
            </div>
            <div className="billing-supply-grid-item">
              <label>STERILE SURGICAL TRAY (ENVOY)</label>
            </div>
            <div className="billing-supply-grid-item">
              <input
                type="text"
                name="fifteen"
                value={formData.fifteen}
                onChange={(e) =>
                  setFormData({ ...formData, fifteen: e.target.value })
                }
                className="post-op-input"
                placeholder="X"
              />
            </div>
            <div className="billing-supply-grid-item">
              <label>0.9 NS 3000ML BAGS </label>
            </div>
            <div className="billing-supply-grid-item">
              <input
                type="text"
                name="sixty"
                value={formData.sixty}
                onChange={(e) =>
                  setFormData({ ...formData, sixty: e.target.value })
                }
                className="post-op-input"
                placeholder="X"
              />
            </div>
            <div className="billing-supply-grid-item">
              <label>ADULT ANESTHESIA BREATHING CIRCUIT</label>
            </div>
            <div className="billing-supply-grid-item">
              <input
                type="text"
                name="sixteen"
                value={formData.sixteen}
                onChange={(e) =>
                  setFormData({ ...formData, sixteen: e.target.value })
                }
                className="post-op-input"
                placeholder="X"
              />
            </div>
            <div className="billing-supply-grid-item">
              <label>EPINEPHRINE 1:100,000 IU/ML VIALS</label>
            </div>
            <div className="billing-supply-grid-item">
              <input
                type="text"
                name="fifty_nine"
                value={formData.fifty_nine}
                onChange={(e) =>
                  setFormData({ ...formData, fifty_nine: e.target.value })
                }
                className="post-op-input"
                placeholder="X"
              />
            </div>
            <div className="billing-supply-grid-item">
              <label>NIBP MONITORING x 1</label>
            </div>
            <div className="billing-supply-grid-item">
              <input
                type="text"
                name="seventeen"
                value={formData.seventeen}
                onChange={(e) =>
                  setFormData({ ...formData, seventeen: e.target.value })
                }
                className="post-op-input"
              />
            </div>
            <div className="billing-supply-grid-item">
              <label>MEDTRONIC PUMP</label>
            </div>
            <div className="billing-supply-grid-item">
              <input
                type="text"
                name="fifty_eight"
                value={formData.fifty_eight}
                onChange={(e) =>
                  setFormData({ ...formData, fifty_eight: e.target.value })
                }
                className="post-op-input"
                placeholder="X"
              />
            </div>
            <div className="billing-supply-grid-item">
              <label>PSO2 MONITORING x 1</label>
            </div>
            <div className="billing-supply-grid-item">
              <input
                type="text"
                name="eighteen"
                value={formData.eighteen}
                onChange={(e) =>
                  setFormData({ ...formData, eighteen: e.target.value })
                }
                className="post-op-input"
              />
            </div>
            <div className="billing-supply-grid-item">
              <label>MEDTRONIC PUMP TUBING SETUP </label>
            </div>
            <div className="billing-supply-grid-item">
              <input
                type="text"
                name="fifty_seven"
                value={formData.fifty_seven}
                onChange={(e) =>
                  setFormData({ ...formData, fifty_seven: e.target.value })
                }
                className="post-op-input"
                placeholder="X"
              />
            </div>
            <div className="billing-supply-grid-item">
              <label>EKG MONITORING x 1</label>
            </div>
            <div className="billing-supply-grid-item">
              <input
                type="text"
                name="nineteen"
                value={formData.nineteen}
                onChange={(e) =>
                  setFormData({ ...formData, nineteen: e.target.value })
                }
                className="post-op-input"
              />
            </div>
            <div className="billing-supply-grid-item">
              <label>VALLEYLAB CAUTERY UNIT </label>
            </div>
            <div className="billing-supply-grid-item">
              <input
                type="text"
                name="fifty_six"
                value={formData.fifty_six}
                onChange={(e) =>
                  setFormData({ ...formData, fifty_six: e.target.value })
                }
                className="post-op-input"
                placeholder="X"
              />
            </div>
            <div className="billing-supply-grid-item">
              <label>EKG ELECTRODES</label>
            </div>
            <div className="billing-supply-grid-item">
              <input
                type="text"
                name="twenty"
                value={formData.twenty}
                onChange={(e) =>
                  setFormData({ ...formData, twenty: e.target.value })
                }
                className="post-op-input"
                placeholder="X"
              />
            </div>
            <div className="billing-supply-grid-item">
              <label>BAIR HUGGER </label>
            </div>
            <div className="billing-supply-grid-item">
              <input
                type="text"
                name="fifty_five"
                value={formData.fifty_five}
                onChange={(e) =>
                  setFormData({ ...formData, fifty_five: e.target.value })
                }
                className="post-op-input"
                placeholder="X"
              />
            </div>
            <div className="billing-supply-grid-item">
              <label>OXYGEN ADMINISTRATION x 1</label>
            </div>
            <div className="billing-supply-grid-item">
              <input
                type="text"
                name="twenty_three"
                value={formData.twenty_three}
                onChange={(e) =>
                  setFormData({ ...formData, twenty_three: e.target.value })
                }
                className="post-op-input"
              />
            </div>
            <div className="billing-supply-grid-item">
              <label>BAIR HUGGER BLANKET </label>
            </div>
            <div className="billing-supply-grid-item">
              <input
                type="text"
                name="fifty_four"
                value={formData.fifty_four}
                onChange={(e) =>
                  setFormData({ ...formData, fifty_four: e.target.value })
                }
                className="post-op-input"
                placeholder="X"
              />
            </div>
            <div className="billing-supply-grid-item">
              <label>
                ET TUBE{" "}
                <input
                  type="text"
                  className="short-input"
                  name="Text1"
                  value={formData.Text1}
                  onChange={(e) =>
                    setFormData({ ...formData, Text1: e.target.value })
                  }
                />{" "}
                SIZE{" "}
                <input
                  type="text"
                  name="twenty_one"
                  value={formData.twenty_one}
                  onChange={(e) =>
                    setFormData({ ...formData, twenty_one: e.target.value })
                  }
                  className="short-input"
                />{" "}
                LMA{" "}
                <input
                  name="twenty_two"
                  value={formData.twenty_two}
                  onChange={(e) =>
                    setFormData({ ...formData, twenty_two: e.target.value })
                  }
                  type="text"
                  className="short-input"
                />
                SIZE{" "}
                <input
                  type="text"
                  name="eighty"
                  value={formData.eighty}
                  onChange={(e) =>
                    setFormData({ ...formData, eighty: e.target.value })
                  }
                  className="short-input"
                />{" "}
              </label>
            </div>
            <div className="billing-supply-grid-item">
              <input
                type="text"
                name="twenty_four"
                value={formData.twenty_four}
                onChange={(e) =>
                  setFormData({ ...formData, twenty_four: e.target.value })
                }
                className="post-op-input"
                placeholder="X"
              />
            </div>
            <div className="billing-supply-grid-item">
              <label>STERILE TOWELS (5PK) </label>
            </div>
            <div className="billing-supply-grid-item">
              <input
                type="text"
                name="seventy_five"
                value={formData.seventy_five}
                onChange={(e) =>
                  setFormData({ ...formData, seventy_five: e.target.value })
                }
                className="post-op-input"
                placeholder="X"
              />
            </div>

            <div className="billing-supply-grid-item">
              <label>STERILE MICROSCOPE DRAPE (CEILING) </label>
            </div>
            <div className="billing-supply-grid-item">
              <input
                type="text"
                name="seventy_four"
                value={formData.seventy_four}
                onChange={(e) =>
                  setFormData({ ...formData, seventy_four: e.target.value })
                }
                className="post-op-input"
                placeholder="X"
              />
            </div>
            <div className="billing-supply-grid-item">
              <label>4 X 4 RAYTEC SPONGES (10 PK)</label>
            </div>
            <div className="billing-supply-grid-item">
              <input
                type="text"
                name="twenty_five"
                value={formData.twenty_five}
                onChange={(e) =>
                  setFormData({ ...formData, twenty_five: e.target.value })
                }
                className="post-op-input"
                placeholder="X"
              />
            </div>
            <div className="billing-supply-grid-item">
              <label>STERILE MICROSCOPE DRAPE (FLOOR) </label>
            </div>
            <div className="billing-supply-grid-item">
              <input
                type="text"
                name="seventy_three"
                value={formData.seventy_three}
                onChange={(e) =>
                  setFormData({ ...formData, seventy_three: e.target.value })
                }
                className="post-op-input"
                placeholder="X"
              />
            </div>
            <div className="billing-supply-grid-item">
              <label>4 X 4 SPONGES (10 PK)</label>
            </div>
            <div className="billing-supply-grid-item">
              <input
                type="text"
                name="twenty_six"
                value={formData.twenty_six}
                onChange={(e) =>
                  setFormData({ ...formData, twenty_six: e.target.value })
                }
                className="post-op-input"
                placeholder="X"
              />
            </div>
            <div className="billing-supply-grid-item">
              <label>STERILE CAMERA DRAPE </label>
            </div>
            <div className="billing-supply-grid-item">
              <input
                type="text"
                name="fifty_three"
                value={formData.fifty_three}
                onChange={(e) =>
                  setFormData({ ...formData, fifty_three: e.target.value })
                }
                className="post-op-input"
                placeholder="X"
              />
            </div>
            <div className="billing-supply-grid-item">
              <label>LAP SPONGES (5PK)</label>
            </div>
            <div className="billing-supply-grid-item">
              <input
                type="text"
                name="twenty_seven"
                value={formData.twenty_seven}
                onChange={(e) =>
                  setFormData({ ...formData, twenty_seven: e.target.value })
                }
                className="post-op-input"
                placeholder="X"
              />
            </div>
            <div className="billing-supply-grid-item">
              <label>3/4 DRAPE, STERILE </label>
            </div>
            <div className="billing-supply-grid-item">
              <input
                type="text"
                name="fifty_two"
                value={formData.fifty_two}
                onChange={(e) =>
                  setFormData({ ...formData, fifty_two: e.target.value })
                }
                className="post-op-input"
                placeholder="X"
              />
            </div>
            <div className="billing-supply-grid-item">
              <label>HYPODERMIC NEEDLES</label>
            </div>
            <div className="billing-supply-grid-item">
              <input
                type="text"
                name="twenty_eight"
                value={formData.twenty_eight}
                onChange={(e) =>
                  setFormData({ ...formData, twenty_eight: e.target.value })
                }
                className="post-op-input"
                placeholder="X"
              />
            </div>
            <div className="billing-supply-grid-item">
              <label>1010 DRAPE, STERILE </label>
            </div>
            <div className="billing-supply-grid-item">
              <input
                type="text"
                name="fifty_one"
                value={formData.fifty_one}
                onChange={(e) =>
                  setFormData({ ...formData, fifty_one: e.target.value })
                }
                className="post-op-input"
                placeholder="X"
              />
            </div>
            <div className="billing-supply-grid-item">
              <label>SUTURE</label>
            </div>
            <div className="billing-supply-grid-item">
              <input
                type="text"
                name="twenty_nine"
                value={formData.twenty_nine}
                onChange={(e) =>
                  setFormData({ ...formData, twenty_nine: e.target.value })
                }
                className="post-op-input"
                placeholder="X"
              />
            </div>
            <div className="billing-supply-grid-item">
              <label>SPLIT DRAPE, STERILE </label>
            </div>
            <div className="billing-supply-grid-item">
              <input
                type="text"
                name="fifty"
                value={formData.fifty}
                onChange={(e) =>
                  setFormData({ ...formData, fifty: e.target.value })
                }
                className="post-op-input"
                placeholder="X"
              />
            </div>
            <div className="billing-supply-grid-item">
              <label>SURGICAL BLADES</label>
            </div>
            <div className="billing-supply-grid-item">
              <input
                type="text"
                name="thirty"
                value={formData.thirty}
                onChange={(e) =>
                  setFormData({ ...formData, thirty: e.target.value })
                }
                className="post-op-input"
                placeholder="X"
              />
            </div>
            <div className="billing-supply-grid-item">
              <label>GLASSCOCK OTOSURGICAL STERILE DRAPE </label>
            </div>
            <div className="billing-supply-grid-item">
              <input
                type="text"
                name="seventy_eight"
                value={formData.seventy_eight}
                onChange={(e) =>
                  setFormData({ ...formData, seventy_eight: e.target.value })
                }
                className="post-op-input"
                placeholder="X"
              />
            </div>
            <div className="billing-supply-grid-item">
              <label>CAUTERY PENCIL WITH HOLDER</label>
            </div>
            <div className="billing-supply-grid-item">
              <input
                type="text"
                name="thirty_one"
                value={formData.thirty_one}
                onChange={(e) =>
                  setFormData({ ...formData, thirty_one: e.target.value })
                }
                className="post-op-input"
                placeholder="X"
              />
            </div>
            <div className="billing-supply-grid-item">
              <label>STERILE ARM CHAIR COVERS (PAIR) </label>
            </div>
            <div className="billing-supply-grid-item">
              <input
                type="text"
                name="seventy_seven"
                value={formData.seventy_seven}
                onChange={(e) =>
                  setFormData({ ...formData, seventy_seven: e.target.value })
                }
                className="post-op-input"
                placeholder="X"
              />
            </div>
            <div className="billing-supply-grid-item">
              <label>BIPOLAR CAUTERY FORCEPS</label>
            </div>
            <div className="billing-supply-grid-item">
              <input
                type="text"
                name="thirty_two"
                value={formData.thirty_two}
                onChange={(e) =>
                  setFormData({ ...formData, thirty_two: e.target.value })
                }
                className="post-op-input"
                placeholder="X"
              />
            </div>
            <div className="billing-supply-grid-item">
              <label>
                STERILE BOWLS 32OZ
                <input
                  type="text"
                  className="short-input"
                  name="eighty_four"
                  value={formData.eighty_four}
                  onChange={(e) =>
                    setFormData({ ...formData, eighty_four: e.target.value })
                  }
                />{" "}
                16OZ{" "}
                <input
                  type="text"
                  className="short-input"
                  name="eighty_three"
                  value={formData.eighty_three}
                  onChange={(e) =>
                    setFormData({ ...formData, eighty_three: e.target.value })
                  }
                />
              </label>
            </div>
            <div className="billing-supply-grid-item">
              <input
                type="text"
                className="post-op-input"
                placeholder="X"
                name="seventy_six"
                value={formData.seventy_six}
                onChange={(e) =>
                  setFormData({ ...formData, seventy_six: e.target.value })
                }
              />
            </div>
            <div className="billing-supply-grid-item">
              <label>PATIENT GROUNDING PATCH</label>
            </div>
            <div className="billing-supply-grid-item">
              <input
                type="text"
                name="thirty_three"
                value={formData.thirty_three}
                onChange={(e) =>
                  setFormData({ ...formData, thirty_three: e.target.value })
                }
                className="post-op-input"
                placeholder="X"
              />
            </div>
            <div className="billing-supply-grid-item">
              <label>
                INJECTABLE SUBQ LOCAL ANESTHESIA 1% LIDOCAINE W/EPI
                <input
                  type="text"
                  name="eighty_one"
                  value={formData.eighty_one}
                  onChange={(e) =>
                    setFormData({ ...formData, eighty_one: e.target.value })
                  }
                  className="short-input"
                />{" "}
                8.4% <br /> NAHC03
                <input
                  type="text"
                  name="eighty_two"
                  value={formData.eighty_two}
                  onChange={(e) =>
                    setFormData({ ...formData, eighty_two: e.target.value })
                  }
                  className="short-input"
                />{" "}
              </label>
            </div>
            <div className="billing-supply-grid-item">
              <input
                type="text"
                name="forty_nine"
                value={formData.forty_nine}
                onChange={(e) =>
                  setFormData({ ...formData, forty_nine: e.target.value })
                }
                className="post-op-input"
                placeholder="X"
              />
            </div>
            <div className="billing-supply-grid-item">
              <label>
                SURGICAL PREP SOLUTION
                <input
                  type="text"
                  className="short-input"
                  name="seventy_nine"
                  value={formData.seventy_nine}
                  onChange={(e) =>
                    setFormData({ ...formData, seventy_nine: e.target.value })
                  }
                />
              </label>
            </div>
            <div className="billing-supply-grid-item">
              <input
                type="text"
                name="thirty_four"
                value={formData.thirty_four}
                onChange={(e) =>
                  setFormData({ ...formData, thirty_four: e.target.value })
                }
                className="post-op-input"
                placeholder="X"
              />
            </div>
            <div className="billing-supply-grid-item">
              <label>1% LIDOCAINE W/ EPI 8.4% NAHCO3 </label>
            </div>
            <div className="billing-supply-grid-item">
              <input type="text" className="post-op-input" placeholder="X" />
            </div>
            <div className="billing-supply-grid-item">
              <label>STERILE MARKING PEN</label>
            </div>
            <div className="billing-supply-grid-item">
              <input
                type="text"
                name="thirty_five"
                value={formData.thirty_five}
                onChange={(e) =>
                  setFormData({ ...formData, thirty_five: e.target.value })
                }
                className="post-op-input"
                placeholder="X"
              />
            </div>
            <div className="billing-supply-grid-item">
              <label>STERILE LIGHT HANDLE COVERS </label>
            </div>
            <div className="billing-supply-grid-item">
              <input
                type="text"
                name="forty_eight"
                value={formData.forty_eight}
                onChange={(e) =>
                  setFormData({ ...formData, forty_eight: e.target.value })
                }
                className="post-op-input"
                placeholder="X"
              />
            </div>
            <div className="billing-supply-grid-item">
              <label>STERI STRIPS (PKG)</label>
            </div>
            <div className="billing-supply-grid-item">
              <input
                type="text"
                name="thirty_six"
                value={formData.thirty_six}
                onChange={(e) =>
                  setFormData({ ...formData, thirty_six: e.target.value })
                }
                className="post-op-input"
                placeholder="X"
              />
            </div>
            <div className="billing-supply-grid-item">
              <label>ACCUCHECK </label>
            </div>
            <div className="billing-supply-grid-item">
              <input
                type="text"
                name="forty_seven"
                value={formData.forty_seven}
                onChange={(e) =>
                  setFormData({ ...formData, forty_seven: e.target.value })
                }
                className="post-op-input"
                placeholder="X"
              />
            </div>
            <div className="billing-supply-grid-item">
              <label>SYRINGES</label>
            </div>
            <div className="billing-supply-grid-item">
              <input
                type="text"
                name="thirty_seven"
                value={formData.thirty_seven}
                onChange={(e) =>
                  setFormData({ ...formData, thirty_seven: e.target.value })
                }
                className="post-op-input"
                placeholder="X"
              />
            </div>
            <div className="billing-supply-grid-item">
              <label>URINE HCG TEST </label>
            </div>
            <div className="billing-supply-grid-item">
              <input
                type="text"
                name="forty_six"
                value={formData.forty_six}
                onChange={(e) =>
                  setFormData({ ...formData, forty_six: e.target.value })
                }
                className="post-op-input"
                placeholder="X"
              />
            </div>
            <div className="billing-supply-grid-item">
              <label>MASTISOL LIQUID ADHESIVE</label>
            </div>
            <div className="billing-supply-grid-item">
              <input
                type="text"
                name="thirty_eight"
                value={formData.thirty_eight}
                onChange={(e) =>
                  setFormData({ ...formData, thirty_eight: e.target.value })
                }
                className="post-op-input"
                placeholder="X"
              />
            </div>
            <div className="billing-supply-grid-item">
              <label>EGGCRATE MATTRESS PADS </label>
            </div>
            <div className="billing-supply-grid-item">
              <input
                type="text"
                name="forty_five"
                value={formData.forty_five}
                onChange={(e) =>
                  setFormData({ ...formData, forty_five: e.target.value })
                }
                className="post-op-input"
                placeholder="X"
              />
            </div>
            <div className="billing-supply-grid-item">
              <label>ANGIOCATHETER</label>
            </div>
            <div className="billing-supply-grid-item">
              <input
                type="text"
                name="thirty_nine"
                value={formData.thirty_nine}
                onChange={(e) =>
                  setFormData({ ...formData, thirty_nine: e.target.value })
                }
                className="post-op-input"
                placeholder="X"
              />
            </div>
            <div className="billing-supply-grid-item">
              <label>STERILE LUBRICANT</label>
            </div>
            <div className="billing-supply-grid-item">
              <input
                type="text"
                name="forty_four"
                value={formData.forty_four}
                onChange={(e) =>
                  setFormData({ ...formData, forty_four: e.target.value })
                }
                className="post-op-input"
                placeholder="X"
              />
            </div>
            <div className="billing-supply-grid-item">
              <label>Propofol 500mg/50ml</label>
            </div>
            <div className="billing-supply-grid-item">
              <input
                type="text"
                name="forty"
                value={formData.forty}
                onChange={(e) =>
                  setFormData({ ...formData, forty: e.target.value })
                }
                className="post-op-input"
                placeholder="X"
              />
            </div>
            <div className="billing-supply-grid-item">
              <label>Sevoflurane </label>
            </div>
            <div className="billing-supply-grid-item">
              <input
                type="text"
                name="forty_three"
                value={formData.forty_three}
                onChange={(e) =>
                  setFormData({ ...formData, forty_three: e.target.value })
                }
                className="post-op-input"
                placeholder="X"
              />
            </div>
            <div className="billing-supply-grid-item">
              <label>Rocuronium 50mg/5ml</label>
            </div>
            <div className="billing-supply-grid-item">
              <input
                type="text"
                name="forty_one"
                value={formData.forty_one}
                onChange={(e) =>
                  setFormData({ ...formData, forty_one: e.target.value })
                }
                className="post-op-input"
                placeholder="X"
              />
            </div>
            <div className="billing-supply-grid-item">
              <label>Succinylcholine Chloride 200mg/10ml</label>
            </div>
            <div className="billing-supply-grid-item">
              <input
                type="text"
                name="forty_two"
                value={formData.forty_two}
                onChange={(e) =>
                  setFormData({ ...formData, forty_two: e.target.value })
                }
                className="post-op-input"
                placeholder="X"
              />
            </div>
          </div>
          <div className="billing-supply-grid-item">
            <label>Implants:</label>
            <input
              type="text"
              name="implants"
              value={formData.implants}
              onChange={(e) =>
                setFormData({ ...formData, implants: e.target.value })
              }
              className="bottom-border-input"
            />
          </div>
          <div className="billing-supply-grid-item">
            <label>Medications:</label>
            <div className="billing-supply-ta">
              <textarea
                name="meds_one"
                value={formData.meds_one}
                onChange={(e) =>
                  setFormData({ ...formData, meds_one: e.target.value })
                }
              />
              <textarea
                name="meds_two"
                value={formData.meds_two}
                onChange={(e) =>
                  setFormData({ ...formData, meds_two: e.target.value })
                }
              />
            </div>
          </div>
          <div className="billing-supply-grid-item">
            <label>Anesthesia Drugs:</label>
            <div className="billing-supply-ta">
              <textarea
                name="anesthesia_one"
                value={formData.anesthesia_one}
                onChange={(e) =>
                  setFormData({ ...formData, anesthesia_one: e.target.value })
                }
              />
              <textarea
                name="anesthesia_two"
                value={formData.anesthesia_two}
                onChange={(e) =>
                  setFormData({ ...formData, anesthesia_two: e.target.value })
                }
              />
            </div>
          </div>
          <div className="billing-supply-grid-item">
            <label>Specialty Devices:</label>
            <div className="billing-supply-ta">
              <textarea
                name="special_one"
                value={formData.special_one}
                onChange={(e) =>
                  setFormData({ ...formData, special_one: e.target.value })
                }
              />
              <textarea
                name="special_two"
                value={formData.special_two}
                onChange={(e) =>
                  setFormData({ ...formData, special_two: e.target.value })
                }
              />
            </div>
          </div>
          <div className="center mt-50">
            <button className="btn-primary hover-pointer" type="submit">
              Submit
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default BillingSupply;
