import React, { useState, useEffect } from "react";
import { Icon } from "@iconify/react";
import "../Forms/styles.css";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import moment from "moment";
import { getChartChecklist, updateChartChecklist } from "../../../utils/api";

const ChartChecklist = () => {
  const navigate = useNavigate();
  const { form_id } = useParams();
  const [loading, setLoading] = useState(true);

  const [formData, setFormData] = useState({
    right_one: "",
    right_two: "",
    right_three: "",
    right_four: "",
    PACU_one: "",
    PACU_two: "",
    discharge: "",
    op_report: "",
    anesthesia_one: "",
    anesthesia_two: "",
    anesthesia_three: "",
    anesthesia_four: "",
    intra_one: "",
    intra_two: "",
    doctors_one: "",
    doctors_two: "",
    doctors_three: "",
    doctors_four: "",
    labs_one: "",
    labs_two: "",
    chart_one: "",
    chart_two: "",
    chart_three: "",
    chart_four: "",
    chart_five: "",
    chart_six: "",
    chart_seven: "",
    chart_eight: "",
    pathology: "",
  });

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: checked,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const payload = {
      ...formData,
      date: moment().format("MM/DD/YYYY"),
      time: moment().format("h:mm a"),
      status: "In Progress",
    };

    console.log("Payload Sent to Backend:", payload);

    // Fetch 2: Create/Update the Disclosure of Ownerhsip Model

    updateChartChecklist(form_id, payload)
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        console.log("complete");
        navigate(-1);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getChartChecklist(form_id)
      .then((res) => res.json())
      .then((data) => {
        console.log(data);

        if (data.status !== "Not Started") {
          setFormData({
            right_one: data.right_one || "",
            right_two: data.right_two || "",
            right_three: data.right_three || "",
            right_four: data.right_four || "",
            PACU_one: data.PACU_one || "",
            PACU_two: data.PACU_two || "",
            discharge: data.discharge || "",
            op_report: data.op_report || "",
            anesthesia_one: data.anesthesia_one || "",
            anesthesia_two: data.anesthesia_two || "",
            anesthesia_three: data.anesthesia_three || "",
            anesthesia_four: data.anesthesia_four || "",
            intra_one: data.intra_one || "",
            intra_two: data.intra_two || "",
            doctors_one: data.doctors_one || "",
            doctors_two: data.doctors_two || "",
            doctors_three: data.doctors_three || "",
            doctors_four: data.doctors_four || "",
            labs_one: data.labs_one || "",
            labs_two: data.labs_two || "",
            chart_one: data.chart_one || "",
            chart_two: data.chart_two || "",
            chart_three: data.chart_three || "",
            chart_four: data.chart_four || "",
            chart_five: data.chart_five || "",
            chart_six: data.chart_six || "",
            chart_seven: data.chart_seven || "",
            chart_eight: data.chart_eight || "",
            pathology: data.pathology || "",
          });
        }
        setLoading(false);
      })
      .catch((err) => console.log(err));
  }, []);
  if (loading) return <p>Loading...</p>;

  return (
    <>
      <div
        className="arrow-container"
        onClick={() => navigate(-1)}
        style={{ cursor: "pointer" }}
      >
        <p className="back-arrow">
          <Icon icon="ic:twotone-arrow-back" className="icon" /> Back to Forms
        </p>
      </div>{" "}
      <div className="form-content">
        <form onSubmit={handleSubmit}>
          {" "}
          <h2 className="form-title">Chart Checklist</h2>
          <div className="cc-container">
            <p className="page-column">Page #</p>
            <p className="initials-column ">RN Initials</p>
            <p className="rs-column">
              <b>Right Side RN</b>
            </p>
          </div>
          <div className="cc-container">
            <p className="page-column">1</p>
            <input
              className="initials-column short-input"
              name="right_one"
              value={formData.right_one}
              onChange={(e) =>
                setFormData({ ...formData, right_one: e.target.value })
              }
            />
            <p className="rs-column">Chart Checklist (This document)</p>
          </div>
          <div className="cc-container">
            <p className="page-column">2</p>
            <input
              className="initials-column short-input"
              name="right_two"
              value={formData.right_two}
              onChange={(e) =>
                setFormData({ ...formData, right_two: e.target.value })
              }
            />
            <p className="rs-column">HIPAA/Allergies-Visit Form</p>
          </div>
          <div className="cc-container">
            <p className="page-column">3</p>
            <input
              className="initials-column short-input"
              name="right_three"
              value={formData.right_three}
              onChange={(e) =>
                setFormData({ ...formData, right_three: e.target.value })
              }
            />
            <p className="rs-column">
              24 Hour Call Back-Follow up/Post-Op Call
            </p>
          </div>
          <div className="cc-container">
            <p className="page-column">4</p>
            <input
              className="initials-column short-input"
              name="right_four"
              value={formData.right_four}
              onChange={(e) =>
                setFormData({ ...formData, right_four: e.target.value })
              }
            />
            <p className="rs-column">Pre-Op Call</p>
          </div>
          <div className="cc-container">
            <p className="page-column"></p>
            <p className="initials-column "></p>
            <p className="rs-column">
              <b>Op-Report</b>
            </p>
          </div>
          <div className="cc-container">
            <p className="page-column">1</p>
            <input
              className="initials-column short-input"
              name="op_report"
              value={formData.op_report}
              onChange={(e) =>
                setFormData({ ...formData, op_report: e.target.value })
              }
            />{" "}
            <p className="rs-column">Dictated Report</p>
          </div>
          <div className="cc-container">
            <p className="page-column"></p>
            <p className="initials-column "></p>
            <p className="rs-column">
              <b>Discharge</b>
            </p>
          </div>
          <div className="cc-container">
            <p className="page-column">1</p>
            <input
              className="initials-column short-input"
              name="discharge"
              value={formData.discharge}
              onChange={(e) =>
                setFormData({ ...formData, discharge: e.target.value })
              }
            />{" "}
            <p className="rs-column">Discharge Instruction</p>
          </div>
          <div className="cc-container">
            <p className="page-column"></p>
            <p className="initials-column "></p>
            <p className="rs-column">
              <b>PACU</b>
            </p>
          </div>
          <div className="cc-container">
            <p className="page-column">1</p>
            <input
              className="initials-column short-input"
              name="PACU_one"
              value={formData.PACU_one}
              onChange={(e) =>
                setFormData({ ...formData, PACU_one: e.target.value })
              }
            />{" "}
            <p className="rs-column">Recovery Form</p>
          </div>
          <div className="cc-container">
            <p className="page-column">2</p>
            <input
              className="initials-column short-input"
              name="PACU_two"
              value={formData.PACU_two}
              onChange={(e) =>
                setFormData({ ...formData, PACU_two: e.target.value })
              }
            />{" "}
            <p className="rs-column">Pre-Op Admit Form</p>
          </div>
          <div className="cc-container">
            <p className="page-column"></p>
            <p className="initials-column "></p>
            <p className="rs-column">
              <b>Anesthesia</b>
            </p>
          </div>
          <div className="cc-container">
            <p className="page-column">1</p>
            <input
              className="initials-column short-input"
              name="anesthesia_one"
              value={formData.anesthesia_one}
              onChange={(e) =>
                setFormData({ ...formData, anesthesia_one: e.target.value })
              }
            />{" "}
            <p className="rs-column">Anesthesia Record</p>
          </div>
          <div className="cc-container">
            <p className="page-column">2</p>
            <input
              className="initials-column short-input"
              name="anesthesia_two"
              value={formData.anesthesia_two}
              onChange={(e) =>
                setFormData({ ...formData, anesthesia_two: e.target.value })
              }
            />{" "}
            <p className="rs-column">Anesthesia Questionnaire</p>
          </div>
          <div className="cc-container">
            <p className="page-column">3</p>
            <input
              className="initials-column short-input"
              name="anesthesia_three"
              value={formData.anesthesia_three}
              onChange={(e) =>
                setFormData({ ...formData, anesthesia_three: e.target.value })
              }
            />{" "}
            <p className="rs-column">Patient Consent to Anethesia</p>
          </div>
          <div className="cc-container">
            <p className="page-column">4</p>
            <input
              className="initials-column short-input"
              name="anesthesia_four"
              value={formData.anesthesia_four}
              onChange={(e) =>
                setFormData({ ...formData, anesthesia_four: e.target.value })
              }
            />{" "}
            <p className="rs-column">
              Pre and Post Anesthesia Evaluation 1 & 2
            </p>
          </div>
          <div className="cc-container">
            <p className="page-column"></p>
            <p className="initials-column "></p>
            <p className="rs-column">
              <b>Intra-Operative Forms</b>
            </p>
          </div>
          <div className="cc-container">
            <p className="page-column">1</p>
            <input
              className="initials-column short-input"
              name="intra_one"
              value={formData.intra_one}
              onChange={(e) =>
                setFormData({ ...formData, intra_one: e.target.value })
              }
            />{" "}
            <p className="rs-column">Intra-Operative Nursing Record 1 & 2</p>
          </div>
          <div className="cc-container">
            <p className="page-column">2</p>
            <input
              className="initials-column short-input"
              name="intra_two"
              value={formData.intra_two}
              onChange={(e) =>
                setFormData({ ...formData, intra_two: e.target.value })
              }
            />{" "}
            <p className="rs-column">Surgical Safety Checklist</p>
          </div>
          <div className="cc-container">
            <p className="page-column"></p>
            <p className="initials-column "></p>
            <p className="rs-column">
              <b>Doctor's Orders</b>
            </p>
          </div>
          <div className="cc-container">
            <p className="page-column">1</p>
            <input
              className="initials-column short-input"
              name="doctors_one"
              value={formData.doctors_one}
              onChange={(e) =>
                setFormData({ ...formData, doctors_one: e.target.value })
              }
            />{" "}
            <p className="rs-column">Patient Consent to Procedure</p>
          </div>
          <div className="cc-container">
            <p className="page-column">2</p>
            <input
              className="initials-column short-input"
              name="doctors_two"
              value={formData.doctors_two}
              onChange={(e) =>
                setFormData({ ...formData, doctors_two: e.target.value })
              }
            />{" "}
            <p className="rs-column">
              History & Physical with 30 days of procedure{" "}
            </p>
          </div>
          <div className="cc-container">
            <p className="page-column">3</p>
            <input
              className="initials-column short-input"
              name="doctors_three"
              value={formData.doctors_three}
              onChange={(e) =>
                setFormData({ ...formData, doctors_three: e.target.value })
              }
            />{" "}
            <p className="rs-column">Physicians Orders-- Pre and Post</p>
          </div>
          <div className="cc-container">
            <p className="page-column">4</p>
            <input
              className="initials-column short-input"
              name="doctors_four"
              value={formData.doctors_four}
              onChange={(e) =>
                setFormData({ ...formData, doctors_four: e.target.value })
              }
            />{" "}
            <p className="rs-column">Operative Note </p>
          </div>
          <div className="cc-container">
            <p className="page-column"></p>
            <p className="initials-column "></p>
            <p className="rs-column">
              <b>Labs/X-ray</b>
            </p>
          </div>
          <div className="cc-container">
            <p className="page-column">1</p>
            <input
              className="initials-column short-input"
              name="labs_one"
              value={formData.labs_one}
              onChange={(e) =>
                setFormData({ ...formData, labs_one: e.target.value })
              }
            />{" "}
            <p className="rs-column">
              Pathology Report (if applicable){" "}
              <input
                type="checkbox"
                name="pathology"
                checked={formData.pathology}
                onChange={handleCheckboxChange}
              />{" "}
              <label>sent</label>
            </p>
          </div>
          <div className="cc-container">
            <p className="page-column">2</p>
            <input
              className="initials-column short-input"
              name="labs_two"
              value={formData.labs_two}
              onChange={(e) =>
                setFormData({ ...formData, labs_two: e.target.value })
              }
            />{" "}
            <p className="rs-column">
              Lab Tests/Lab Work (Blood, urine,MRI,X-ray,EKG,off-site pregnancy
              test,etc.)
            </p>
          </div>
          <div className="cc-container">
            <p className="page-column"></p>
            <p className="initials-column "></p>
            <p className="rs-column">
              <b>Chart Review (RN)</b>
            </p>
          </div>
          <div className="cc-container">
            <p className="page-column">1</p>
            <input
              className="initials-column short-input"
              name="chart_one"
              value={formData.chart_one}
              onChange={(e) =>
                setFormData({ ...formData, chart_one: e.target.value })
              }
            />{" "}
            <p className="rs-column">No Blanks on the forms - ANY Form!</p>
          </div>
          <div className="cc-container">
            <p className="page-column">2</p>
            <input
              className="initials-column short-input"
              name="chart_two"
              value={formData.chart_two}
              onChange={(e) =>
                setFormData({ ...formData, chart_two: e.target.value })
              }
            />{" "}
            <p className="rs-column">Presence/Absence of Allergies noted </p>
          </div>
          <div className="cc-container">
            <p className="page-column">3</p>
            <input
              className="initials-column short-input"
              name="chart_three"
              value={formData.chart_three}
              onChange={(e) =>
                setFormData({ ...formData, chart_three: e.target.value })
              }
            />{" "}
            <p className="rs-column">
              Consent Complete, no blanks, all signees{" "}
            </p>
          </div>
          <div className="cc-container">
            <p className="page-column">4</p>
            <input
              className="initials-column short-input"
              name="chart_four"
              value={formData.chart_four}
              onChange={(e) =>
                setFormData({ ...formData, chart_four: e.target.value })
              }
            />{" "}
            <p className="rs-column">
              Pre-Op labs initialed by RN if normal, by MD if results outside
              normal range
            </p>
          </div>
          <div className="cc-container">
            <p className="page-column">5</p>
            <input
              className="initials-column short-input"
              name="chart_five"
              value={formData.chart_five}
              onChange={(e) =>
                setFormData({ ...formData, chart_five: e.target.value })
              }
            />{" "}
            <p className="rs-column">BILLING SUPPLY FORM </p>
          </div>
          <div className="cc-container">
            <p className="page-column">6</p>
            <input
              className="initials-column short-input"
              name="chart_six"
              value={formData.chart_six}
              onChange={(e) =>
                setFormData({ ...formData, chart_six: e.target.value })
              }
            />{" "}
            <p className="rs-column">
              Discharge evaluation signed by MD immediately prior to discharge -
              TIMED{" "}
            </p>
          </div>
          <div className="cc-container">
            <p className="page-column">7</p>
            <input
              className="initials-column short-input"
              name="chart_seven"
              value={formData.chart_seven}
              onChange={(e) =>
                setFormData({ ...formData, chart_seven: e.target.value })
              }
            />{" "}
            <p className="rs-column">Orders signed by MD, noted, timed by RN</p>
          </div>
          <div className="cc-container">
            <p className="page-column">8</p>
            <input
              className="initials-column short-input"
              name="chart_eight"
              value={formData.chart_eight}
              onChange={(e) =>
                setFormData({ ...formData, chart_eight: e.target.value })
              }
            />{" "}
            <p className="rs-column">
              Pathology reports signed by MD (if applicable){" "}
            </p>
          </div>
          <div className="center mt-50">
            <button className="btn-primary hover-pointer" type="submit">
              Submit
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default ChartChecklist;
