export const getFormLink = (formName) => {
  switch (formName) {
    case "Anesthesia Questionnaire":
      return "/form-anethesia-ques";
    case "Assignment of Benefits":
      return "/form-assignment-benefits";
    case "Discharge Instructions":
      return "/form-discharge-instructions";
    case "Disclosure of Ownership":
      return "/form-disclosure-ownership";
    case "Grievance Policy":
      return "/form-grievance";
    case "Notice of Privacy Practice":
      return "/form-privacy";
    case "Consent To Anesthesia":
      return "/form-consent-anethesia";
    case "Patient History":
      return "/form-patient-history";
    case "Patient Registration":
      return "/form-registration";
    case "Patient Satisfaction Survey":
      return "/form-satisfaction";
    case "Surgical Safety Checklist":
      return "/form-surgical-safety";
    case "Chart Checklist":
      return "/form-chart-checklist";
    case "Intra-Op Nursing Record":
      return "/form-intraop-nursing-record";
    case "Intraoperative Record":
      return "/form-intraop-record";
    case "Medication Reconciliation":
      return "/form-medication-reconciliation";
    case "Nursing Pre-Op Assessment":
      return "/form-nursing-preop-assessment";
    case "Post-Op Patient Contact":
      return "/form-post-op-patient-contact";
    case "Pre-Op Phone Call":
      return "/form-preop-phone-call";
    case "PACU Nursing Notes":
      return "/form-pacu-nursing-notes";

    case "Patient Education":
      return "/form-patient-education";
    case "Admin Financial":
      return "/form-admin-financial";
    case "Billing Supply":
      return "/form-billing-supply";
    case "Chart Checklist Admin":
      return "/form-chart-checklist-admin";
    case "Physicians Discharge Instructions":
      return "/form-physicians-discharge-orders";
    case "Operative Note":
      return "/form-operative-note";
    case "Anesthesia Record":
      return "/form-anesthesia-record";
    case "Post Anesthesia Care":
      return "/form-post-anesthesia-care";
    case "Pre and Post Anesthetic Evaluation":
      return "/form-pre-and-post-op";
    default:
      return "/form-anethesia-ques";
  }
};
