import React, { useState, useEffect } from "react";
import { Icon } from "@iconify/react";
import { useNavigate } from "react-router-dom";
import "./styles.css";
import SignatureButton from "../../../components/SignatureButton";
import { useParams } from "react-router-dom";
import moment from "moment";
import {
  addFile,
  getPatientConsentProcedure,
  updatePatientConsentProcedure,
} from "../../../utils/api";

const PatientConsentProcedure = () => {
  const navigate = useNavigate();
  const { form_id } = useParams();
  const [individualSignature, setIndividualSignature] = useState("");
  const [notPatientSignature, setNotPatientSignature] = useState("");
  const [loading, setLoading] = useState(true);
  const [surgeonName, setSurgeonName] = useState([""]);
  const [surgeryName, setSurgeryName] = useState([""]);
  const [exceptions, setExeptions] = useState([""]);
  const [noExceptions, setNoExceptions] = useState([""]);
  const [patientDate, setPatientDate] = useState([""]);
  const [patientTime, setPatientTime] = useState([""]);
  const [witnessDate, setWitnessDate] = useState([""]);
  const [witnessTime, setWitnessTime] = useState([""]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    let patientFileName, formCompleterFileName;

    if (notPatientSignature !== "") {
      console.log("Uploading form completer signature:", notPatientSignature);
    }

    // Fetch 1: Upload Signature
    if (individualSignature !== "") {
      const response = await addFile(individualSignature);

      const data = await response.json();
      patientFileName = data.fileName;
    }
    if (notPatientSignature !== "") {
      const response = await addFile(notPatientSignature);
      const data = await response.json();
      console.log("Uploaded form completer signature:", data.fileName);
      formCompleterFileName = data.fileName || null;
    }

    const payload = {
      surgeon_name: surgeonName,
      surgery_name: surgeryName,
      exceptions: exceptions,
      no_exceptions: noExceptions,
      patient_date: moment().format("MM/DD/YYYY"),
      patient_time: moment().format("h:mm a"),
      witness_date: moment().format("MM/DD/YYYY"),
      witness_time: moment().format("h:mm a"),
      patient_signature: patientFileName,
      form_completer_signature: formCompleterFileName,
      date: moment().format("MM/DD/YYYY"),
      time: moment().format("h:mm a"),
      status: "In Progress",
    };

    // console.log("Payload Sent to Backend:", payload);

    // Fetch 2: 

    updatePatientConsentProcedure(form_id, payload)
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        console.log("complete");
        navigate(-1);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getPatientConsentProcedure(form_id)
      .then((res) => res.json())
      .then((data) => {
        console.log(data);

        if (data.status !== "Not Started") {
          setSurgeonName(data.surgeon_name || "");
          setSurgeryName(data.surgery_name || "");
          setExeptions(data.exceptions || "");
          setNoExceptions(data.no_exceptions || false);
          setPatientDate(data.patient_date || moment().format("YYYY-MM-DD"));
          setPatientTime(data.patient_time || moment().format("HH:mm"));
          setWitnessDate(data.witness_date || moment().format("YYYY-MM-DD"));
          setWitnessTime(data.witness_time || moment().format("HH:mm"));
          setIndividualSignature(data.patient_signature || "");
          setNotPatientSignature(data.form_completer_signature || "");
        } else {
          setSurgeonName("");
          setSurgeryName("");
          setExeptions("");
          setNoExceptions(false);
          setPatientDate("");
          setPatientTime("");
          setWitnessDate("");
          setWitnessTime("");
          setIndividualSignature("");
          setNotPatientSignature("");
        }
        setLoading(false);
      })
      .catch((err) => console.log(err));
  }, []);
  if (loading) return <p>Loading...</p>;

  return (
    <>
      <div
        className="arrow-container"
        onClick={() => navigate(-1)}
        style={{ cursor: "pointer" }}
      >
        <p className="back-arrow">
          <Icon icon="ic:twotone-arrow-back" className="icon" /> Back to Forms
        </p>
      </div>
      <div className="form-content">
        <div>
          <form onSubmit={(e) => handleSubmit(e)}>
            <h2 className="form-title">Patient Consent Procedure</h2>
            <p>
              Your physician,{" "}
              <strong>
                <u>
                  <input
                    className="di-input"
                    value={surgeonName}
                    onChange={(e) => setSurgeonName(e.target.value)}
                  />
                </u>
              </strong>
              , has determined that the operation or procedure listed below may
              be beneficial in the diagnosis and/or treatment of your condition.
              All surgical operations and diagnostic and therapeutic procedures
              involve risks of unsuccessful outcomes, complications, injury, or
              even death, from both known and unforeseen causes. No warranties
              or guarantees have been made as to result or cure. Operation or
              procedure to be performed:
            </p>

            <h3 className="text-center">
              <u>
                <input
                  className="di-input"
                  value={surgeryName}
                  onChange={(e) => setSurgeryName(e.target.value)}
                />
              </u>
            </h3>

            <p>
              Our treating physician or professional is an independent
              contractor and not an employee of the SEA Surgery Center
              ("Center"). Anesthetists at the Center are also provided by
              independent contractor physicians.
            </p>

            <p>
              As a patient you have the right to receive as much information as
              you may need in order to give informed consent to or refuse the
              recommended treatment or procedure. Except in emergencies, your
              healthcare provider should describe in language you can understand
              the nature and extent of the nature of the proposed treatment or
              procedure, the material risks and/or dangers involved, the
              alternative courses of treatment or non-treatment, including the
              respective risks of unfortunate consequences associated with the
              treatment or procedure, and the relative probability of success of
              the treatment or procedure. If you have questions, you are
              encouraged to consult with your physician. You have the right to
              give or refuse your consent to such operation or procedure. You
              have the right to consent or refuse any proposed operation or
              procedure prior to its performance.
            </p>

            <p>
              Having read and fully understanding the above, and having received
              and fully understanding the above information from my
              physician/healthcare provider(s), I hereby authorize the
              following:
            </p>

            <ol>
              <li>
                I authorize the above named healthcare provider and any of their
                associates or assistants, including residents within their
                licensed scope of their practice, to perform the above named
                procedure and to provide such additional services as may be
                deemed medically reasonable and necessary, including but not
                limited to:
                <ol type="a">
                  <li>
                    Those resulting from conditions or discoveries which make a
                    change or extension advisable;
                  </li>
                  <li>
                    The administration and maintenance of anesthesia as directed
                    by a healthcare professional;
                  </li>
                  <li>The implant of medical devices;</li>
                  <li>Services involving pathology and radiology;</li>
                  <li>Transfer to a hospital;</li>
                  <li>Peer review of my medical record.</li>
                </ol>
              </li>
              <li>
                I authorize the pathology services to use its discretion in the
                retention or disposal of any severed tissue or member.
                Exemptions:{" "}
                <input
                  type="checkbox"
                  checked={noExceptions} 
                  onChange={(e) => setNoExceptions(e.target.checked)}
                />{" "}
                None{" "}
                <input
                  className="ca-input"
                  type="text"
                  value={exceptions}
                  onChange={(e) => setExeptions(e.target.value)}
                />
              </li>

              <li>
                I understand that, if given other than local anesthesia, I am
                required to have a companion available during and after my
                surgery and that I will be discharged to that person's custody
                and must rely on him or her for my return home.
              </li>
              <li>
                Consent to the photographing, filming, or videotaping of the
                treatment or procedure for diagnostic, documentation or
                educational use. I agree to the extent necessary to determine
                liability for payment of my account and to obtain reimbursement,
                the Center may disclose portions of my financial and medical
                records to any person or entity which is or may be liable for
                all or any portion of the Center's charges, including but not
                limited to insurance companies, health care service plans, or
                worker's compensation carriers, and also to those individuals
                and Governing Body who are required to review the medical record
                for purposes of medical quality assurance/improvement and peer
                review.
              </li>
              <li>
                I authorize disclosure of my Social Security number to device
                manufacturers subject to the Safe Medical Device Act.
              </li>
              <li>
                I understand that this facility respects human life and will not
                allow any advance directive to prevent life or limb to be in
                place.
              </li>
              <li>
                Your treating physician may have an ownership interest in the
                Center and may gain financially by performing the procedure at
                the Center.
                <br />
                You, the patient, have the right to choose where your procedure
                is performed. By signing this consent, you are agreeing to have
                the procedure performed at SEA Surgery Center.
              </li>
            </ol>

            <p className="signature">
              <i>
                I certify that I have read and fully understand the above
                consent statement, that the explanations herein referred to are
                understood by me, that all of my questions have been answered
                satisfactorily, and that all blanks or statements requiring
                insertion or completion were filled in before I affixed my
                signature. I am aware that no guarantees have been made as to
                result or cure, and I also consent to giving up my rights
                freely, voluntarily, and without reservations. I understand that
                I have the right to refuse any surgical and/or medical procedure
                or treatments.
              </i>
            </p>
            <div className="grievance-container mt-50">
              <div className="gc-item">
                {" "}
                <SignatureButton
                  individualSignature={individualSignature}
                  setIndividualSignature={setIndividualSignature}
                />
                <hr className="signature-line"></hr>
                <p>Patient Signature</p>
              </div>
              <div className="gc-item mt-50">
                {" "}
                <label htmlFor="name" className="hidden">
                  Patient Signature
                </label>
                <input
                  type="date"
                  id="name"
                  name="name"
                  className="bottom-border-input"
                  
                  value={patientDate}
                  onChange={(e) => setPatientDate(e.target.value)}
                />
                <p>Date</p>
              </div>
              <div className="gc-item mt-50">
                {" "}
                <label htmlFor="name" className="hidden">
                  Time
                </label>
                <input
                  type="time"
                  id="time"
                  name="time"
                  className="bottom-border-input"
                  
                  value={patientTime}
                  onChange={(e) => setPatientTime(e.target.value)}
                />
                <p>Time</p>
              </div>

              <div className="gc-item">
                {" "}
                <SignatureButton
                  individualSignature={notPatientSignature}
                  setIndividualSignature={(file) => {
                    console.log("Captured signature:", file);
                    setNotPatientSignature(file);
                  }}
                />
                <hr className="signature-line"></hr>
                <p>Witness Signature</p>
              </div>
              <div className="gc-item mt-50">
                {" "}
                <label htmlFor="name" className="hidden">
                  Witness Signature
                </label>
                <input
                  type="date"
                  id="name"
                  name="name"
                  className="bottom-border-input"
                  
                  value={witnessDate}
                  onChange={(e) => setWitnessDate(e.target.value)}
                />
                <p>Date</p>
              </div>
              <div className="gc-item mt-50">
                {" "}
                <label htmlFor="name" className="hidden">
                  Time
                </label>
                <input
                  type="time"
                  id="time"
                  name="time"
                  className="bottom-border-input"
                  
                  value={witnessTime}
                  onChange={(e) => setWitnessTime(e.target.value)}
                />
                <p>Time</p>
              </div>

              {/* <div className="gc-item">
                {" "}
                <SignatureButton />
                <hr className="signature-line"></hr>
                <p>Surgeon Signature</p>
              </div>
              <div className="gc-item mt-50">
                {" "}
                <label htmlFor="name" className="hidden">
                  Surgeon Signature
                </label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  className="bottom-border-input"
                  required
                />
                <p>Date</p>
              </div>
              <div className="gc-item mt-50">
                {" "}
                <label htmlFor="name" className="hidden">
                  Time
                </label>
                <input
                  type="text"
                  id="time"
                  name="time"
                  className="bottom-border-input"
                  required
                />
                <p>Time</p>
              </div> */}
            </div>
            <div className="center mt-50">
              <button className="btn-primary hover-pointer" type="submit">
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};
export default PatientConsentProcedure;
