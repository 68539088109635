import React, { useState, useEffect } from "react";
import { Icon } from "@iconify/react";
import "../Forms/styles.css";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import moment from "moment";
import {
  addFile,
  getMedicationReconciliation,
  updateMedicationReconciliation,
} from "../../../utils/api";
import SignatureButton from "../../../components/SignatureButton";

const MedicationReconciliation = () => {
  const navigate = useNavigate();
  const { form_id } = useParams();
  const [loading, setLoading] = useState(true);
  const [individualSignature, setIndividualSignature] = useState("");

  const [formData, setFormData] = useState({
    allergies: "",
    reaction: "",
    current_med: "",
    dose_one: "",
    med_one: "",
    route_one: "",
    freq_one: "",
    date_one: "",
    dose_two: "",
    med_two: "",
    route_two: "",
    freq_two: "",
    dose_three: "",
    med_three: "",
    route_three: "",
    freq_three: "",
    dose_four: "",
    med_four: "",
    route_four: "",
    freq_four: "",
    dose_five: "",
    med_five: "",
    route_five: "",
    freq_five: "",
    dose_six: "",
    med_six: "",
    route_six: "",
    freq_six: "",
    dose_seven: "",
    med_seven: "",
    route_seven: "",
    freq_seven: "",
    dose_eight_freq: "",
    dose_eight: "",
    route_eight: "",
    freq_eight: "",
    reviewed_by: "",
    res_one: false,
    res_two: false,
    res_three: false,
    res_four: false,
    res_five: false,
    res_six: false,
    res_seven: false,
    res_eight: false,
    dis_one: false,
    dis_two: false,
    dis_three: false,
    dis_four: false,
    dis_five: false,
    dis_six: false,
    dis_seven: false,
    dis_eight: false,
  });

  const handleSubmit = async (e) => {
    e.preventDefault();

    const payload = {
      ...formData,
      date_one: moment().format("MM/DD/YYYY"),
      date: moment().format("MM/DD/YYYY"),

      time: moment().format("h:mm a"),
      status: "In Progress",
    };

    console.log("Payload Sent to Backend:", payload);

    // Fetch 2: Create/Update the Disclosure of Ownerhsip Model

    updateMedicationReconciliation(form_id, payload)
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        console.log("complete");
        navigate(-1);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getMedicationReconciliation(form_id)
      .then((res) => res.json())
      .then((data) => {
        console.log(data);

        if (data.status !== "Not Started") {
          setFormData({
            allergies: data.allergies || "",
            reaction: data.reaction || "",
            current_med: data.current_med || "",
            dose_one: data.dose_one || "",
            med_one: data.med_one || "",
            route_one: data.route_one || "",
            freq_one: data.freq_one || "",
            date_one: data.date_one || "",
            dose_two: data.dose_two || "",
            med_two: data.med_two || "",
            route_two: data.route_two || "",
            freq_two: data.freq_two || "",
            dose_three: data.dose_three || "",
            med_three: data.med_three || "",
            route_three: data.route_three || "",
            freq_three: data.freq_three || "",
            dose_four: data.dose_four || "",
            med_four: data.med_four || "",
            route_four: data.route_four || "",
            freq_four: data.freq_four || "",
            dose_five: data.dose_five || "",
            med_five: data.med_five || "",
            route_five: data.route_five || "",
            freq_five: data.freq_five || "",
            dose_six: data.dose_six || "",
            med_six: data.med_six || "",
            route_six: data.route_six || "",
            freq_six: data.freq_six || "",
            dose_seven: data.dose_seven || "",
            med_seven: data.med_seven || "",
            route_seven: data.route_seven || "",
            freq_seven: data.freq_seven || "",
            dose_eight_freq: data.dose_eight_freq || "",
            dose_eight: data.dose_eight || "",
            route_eight: data.route_eight || "",
            freq_eight: data.freq_eight || "",
            reviewed_by: data.reviewed_by || "",
            res_one: data.res_one || false,
            res_two: data.res_two || false,
            res_three: data.res_three || false,
            res_four: data.res_four || false,
            res_five: data.res_five || false,
            res_six: data.res_six || false,
            res_seven: data.res_seven || false,
            res_eight: data.res_eight || false,
            dis_one: data.dis_one || false,
            dis_two: data.dis_two || false,
            dis_three: data.dis_three || false,
            dis_four: data.dis_four || false,
            dis_five: data.dis_five || false,
            dis_six: data.dis_six || false,
            dis_seven: data.dis_seven || false,
            dis_eight: data.dis_eight || false,
          });
        }
        setLoading(false);
      })
      .catch((err) => console.log(err));
  }, []);
  if (loading) return <p>Loading...</p>;

  return (
    <>
      <div
        className="arrow-container"
        onClick={() => navigate(-1)}
        style={{ cursor: "pointer" }}
      >
        <p className="back-arrow">
          <Icon icon="ic:twotone-arrow-back" className="icon" /> Back to Forms
        </p>
      </div>{" "}
      <div className="form-content">
        <form onSubmit={handleSubmit}>
          {" "}
          <h2 className="form-title">Medication Reconciliation</h2>
          <div className="mc-conatiner">
            <div className="mc-section">
              <label>Allergies:</label>
              <input
                type="text"
                name="allergies"
                className="long-input"
                value={formData.allergies}
                onChange={(e) =>
                  setFormData({ ...formData, allergies: e.target.value })
                }
              />
            </div>
            <div className="mc-section">
              <label>Abnormal Reaction to Medication:</label>
              <input
                type="text"
                name="reaction"
                className="long-input"
                value={formData.reaction}
                onChange={(e) =>
                  setFormData({ ...formData, reaction: e.target.value })
                }
              />
            </div>

            <div className="mc-section">
              <label>
                Current medication including prescription, over the
                counter,dietary ad herbal supplements:
              </label>
              <input
                type="text"
                name="current_med"
                className="long-input"
                value={formData.current_med}
                onChange={(e) =>
                  setFormData({ ...formData, current_med: e.target.value })
                }
              />
            </div>
          </div>
          <div className="mr-section-container">
            <div className="mr-row">
              <div className="mr-cell">Medication</div>
              <div className="mr-cell">Dose (strength)</div>
              <div className="mr-cell">Frequency (How often is it taken)</div>
              <div className="mr-cell">Route (By mouth, IV)</div>
              <div className="mr-cell ">Resume or Discontinue</div>
            </div>
            <div className="mr-row">
              <div className="mr-cell">
                <input
                  type="text"
                  className="ir-no-border"
                  name="dose_one"
                  value={formData.dose_one}
                  onChange={(e) =>
                    setFormData({ ...formData, dose_one: e.target.value })
                  }
                />
              </div>

              <div className="mr-cell">
                <input
                  type="text"
                  className="ir-no-border"
                  name="med_one"
                  value={formData.med_one}
                  onChange={(e) =>
                    setFormData({ ...formData, med_one: e.target.value })
                  }
                />
              </div>

              <div className="mr-cell">
                <input
                  type="text"
                  className="ir-no-border"
                  name="route_one"
                  value={formData.route_one}
                  onChange={(e) =>
                    setFormData({ ...formData, route_one: e.target.value })
                  }
                />
              </div>

              <div className="mr-cell">
                <input
                  type="text"
                  className="ir-no-border"
                  name="freq_one"
                  value={formData.freq_one}
                  onChange={(e) =>
                    setFormData({ ...formData, freq_one: e.target.value })
                  }
                />
              </div>

              <div className="mr-cell cell-flex">
                <label>
                  Resume
                  <input
                    type="checkbox"
                    name="res_one"
                    checked={formData.res_one}
                    onChange={(e) =>
                      setFormData({ ...formData, res_one: e.target.checked })
                    }
                  />
                </label>
                <label>
                  Discontinue
                  <input
                    type="checkbox"
                    name="dis_one"
                    checked={formData.dis_one}
                    onChange={(e) =>
                      setFormData({ ...formData, dis_one: e.target.checked })
                    }
                  />
                </label>
              </div>
            </div>
            <div className="mr-row">
              <div className="mr-cell">
                <input
                  type="text"
                  className="ir-no-border"
                  name="dose_two"
                  value={formData.dose_two}
                  onChange={(e) =>
                    setFormData({ ...formData, dose_two: e.target.value })
                  }
                />
              </div>

              <div className="mr-cell">
                <input
                  type="text"
                  className="ir-no-border"
                  name="med_two"
                  value={formData.med_two}
                  onChange={(e) =>
                    setFormData({ ...formData, med_two: e.target.value })
                  }
                />
              </div>

              <div className="mr-cell">
                <input
                  type="text"
                  className="ir-no-border"
                  name="route_two"
                  value={formData.route_two}
                  onChange={(e) =>
                    setFormData({ ...formData, route_two: e.target.value })
                  }
                />
              </div>

              <div className="mr-cell">
                <input
                  type="text"
                  className="ir-no-border"
                  name="freq_two"
                  value={formData.freq_two}
                  onChange={(e) =>
                    setFormData({ ...formData, freq_two: e.target.value })
                  }
                />
              </div>

              <div className="mr-cell cell-flex">
                <label>
                  Resume
                  <input
                    type="checkbox"
                    name="res_two"
                    checked={formData.res_two}
                    onChange={(e) =>
                      setFormData({ ...formData, res_two: e.target.checked })
                    }
                  />
                </label>
                <label>
                  Discontinue
                  <input
                    type="checkbox"
                    name="dis_two"
                    checked={formData.dis_two}
                    onChange={(e) =>
                      setFormData({ ...formData, dis_two: e.target.checked })
                    }
                  />
                </label>
              </div>
            </div>
            <div className="mr-row">
              <div className="mr-cell">
                <input
                  type="text"
                  className="ir-no-border"
                  name="dose_three"
                  value={formData.dose_three}
                  onChange={(e) =>
                    setFormData({ ...formData, dose_three: e.target.value })
                  }
                />
              </div>

              <div className="mr-cell">
                <input
                  type="text"
                  className="ir-no-border"
                  name="med_three"
                  value={formData.med_three}
                  onChange={(e) =>
                    setFormData({ ...formData, med_three: e.target.value })
                  }
                />
              </div>

              <div className="mr-cell">
                <input
                  type="text"
                  className="ir-no-border"
                  name="route_three"
                  value={formData.route_three}
                  onChange={(e) =>
                    setFormData({ ...formData, route_three: e.target.value })
                  }
                />
              </div>

              <div className="mr-cell">
                <input
                  type="text"
                  className="ir-no-border"
                  name="freq_three"
                  value={formData.freq_three}
                  onChange={(e) =>
                    setFormData({ ...formData, freq_three: e.target.value })
                  }
                />
              </div>

              <div className="mr-cell cell-flex">
                <label>
                  Resume
                  <input
                    type="checkbox"
                    name="res_three"
                    checked={formData.res_three}
                    onChange={(e) =>
                      setFormData({ ...formData, res_three: e.target.checked })
                    }
                  />
                </label>
                <label>
                  Discontinue
                  <input
                    type="checkbox"
                    name="dis_three"
                    checked={formData.dis_three}
                    onChange={(e) =>
                      setFormData({ ...formData, dis_three: e.target.checked })
                    }
                  />
                </label>
              </div>
            </div>
            <div className="mr-row">
              <div className="mr-cell">
                <input
                  type="text"
                  className="ir-no-border"
                  name="dose_four"
                  value={formData.dose_four}
                  onChange={(e) =>
                    setFormData({ ...formData, dose_four: e.target.value })
                  }
                />
              </div>

              <div className="mr-cell">
                <input
                  type="text"
                  className="ir-no-border"
                  name="med_four"
                  value={formData.med_four}
                  onChange={(e) =>
                    setFormData({ ...formData, med_four: e.target.value })
                  }
                />
              </div>

              <div className="mr-cell">
                <input
                  type="text"
                  className="ir-no-border"
                  name="route_four"
                  value={formData.route_four}
                  onChange={(e) =>
                    setFormData({ ...formData, route_four: e.target.value })
                  }
                />
              </div>

              <div className="mr-cell">
                <input
                  type="text"
                  className="ir-no-border"
                  name="freq_four"
                  value={formData.freq_four}
                  onChange={(e) =>
                    setFormData({ ...formData, freq_four: e.target.value })
                  }
                />
              </div>

              <div className="mr-cell cell-flex">
                <label>
                  Resume
                  <input
                    type="checkbox"
                    name="res_four"
                    checked={formData.res_four}
                    onChange={(e) =>
                      setFormData({ ...formData, res_four: e.target.checked })
                    }
                  />
                </label>
                <label>
                  Discontinue
                  <input
                    type="checkbox"
                    name="dis_four"
                    checked={formData.dis_four}
                    onChange={(e) =>
                      setFormData({ ...formData, dis_four: e.target.checked })
                    }
                  />
                </label>
              </div>
            </div>
            <div className="mr-row">
              <div className="mr-cell">
                <input
                  type="text"
                  className="ir-no-border"
                  name="dose_five"
                  value={formData.dose_five}
                  onChange={(e) =>
                    setFormData({ ...formData, dose_five: e.target.value })
                  }
                />
              </div>

              <div className="mr-cell">
                <input
                  type="text"
                  className="ir-no-border"
                  name="med_five"
                  value={formData.med_five}
                  onChange={(e) =>
                    setFormData({ ...formData, med_five: e.target.value })
                  }
                />
              </div>

              <div className="mr-cell">
                <input
                  type="text"
                  className="ir-no-border"
                  name="route_five"
                  value={formData.route_five}
                  onChange={(e) =>
                    setFormData({ ...formData, route_five: e.target.value })
                  }
                />
              </div>

              <div className="mr-cell">
                <input
                  type="text"
                  className="ir-no-border"
                  name="freq_five"
                  value={formData.freq_five}
                  onChange={(e) =>
                    setFormData({ ...formData, freq_five: e.target.value })
                  }
                />
              </div>

              <div className="mr-cell cell-flex">
                <label>
                  Resume
                  <input
                    type="checkbox"
                    name="res_five"
                    checked={formData.res_five}
                    onChange={(e) =>
                      setFormData({ ...formData, res_five: e.target.checked })
                    }
                  />
                </label>
                <label>
                  Discontinue
                  <input
                    type="checkbox"
                    name="dis_five"
                    checked={formData.dis_five}
                    onChange={(e) =>
                      setFormData({ ...formData, dis_five: e.target.checked })
                    }
                  />
                </label>
              </div>
            </div>
            <div className="mr-row">
              <div className="mr-cell">
                <input
                  type="text"
                  className="ir-no-border"
                  name="med_six"
                  value={formData.med_six}
                  onChange={(e) =>
                    setFormData({ ...formData, med_six: e.target.value })
                  }
                />
              </div>

              <div className="mr-cell">
                <input
                  type="text"
                  className="ir-no-border"
                  name="dose_six"
                  value={formData.dose_six}
                  onChange={(e) =>
                    setFormData({ ...formData, dose_six: e.target.value })
                  }
                />
              </div>

              <div className="mr-cell">
                <input
                  type="text"
                  className="ir-no-border"
                  name="freq_six"
                  value={formData.freq_six}
                  onChange={(e) =>
                    setFormData({ ...formData, freq_six: e.target.value })
                  }
                />
              </div>

              <div className="mr-cell">
                <input
                  type="text"
                  className="ir-no-border"
                  name="route_six"
                  value={formData.route_six}
                  onChange={(e) =>
                    setFormData({ ...formData, route_six: e.target.value })
                  }
                />
              </div>

              <div className="mr-cell cell-flex">
                <label>
                  Resume
                  <input
                    type="checkbox"
                    name="res_six"
                    checked={formData.res_six}
                    onChange={(e) =>
                      setFormData({ ...formData, res_six: e.target.checked })
                    }
                  />
                </label>
                <label>
                  Discontinue
                  <input
                    type="checkbox"
                    name="dis_six"
                    checked={formData.dis_six}
                    onChange={(e) =>
                      setFormData({ ...formData, dis_six: e.target.checked })
                    }
                  />
                </label>
              </div>
            </div>
            <div className="mr-row">
              <div className="mr-cell">
                <input
                  type="text"
                  className="ir-no-border"
                  name="med_seven"
                  value={formData.med_seven}
                  onChange={(e) =>
                    setFormData({ ...formData, med_seven: e.target.value })
                  }
                />
              </div>

              <div className="mr-cell">
                <input
                  type="text"
                  className="ir-no-border"
                  name="dose_seven"
                  value={formData.dose_seven}
                  onChange={(e) =>
                    setFormData({ ...formData, dose_seven: e.target.value })
                  }
                />
              </div>

              <div className="mr-cell">
                <input
                  type="text"
                  className="ir-no-border"
                  name="freq_seven"
                  value={formData.freq_seven}
                  onChange={(e) =>
                    setFormData({ ...formData, freq_seven: e.target.value })
                  }
                />
              </div>

              <div className="mr-cell">
                <input
                  type="text"
                  className="ir-no-border"
                  name="route_seven"
                  value={formData.route_seven}
                  onChange={(e) =>
                    setFormData({ ...formData, route_seven: e.target.value })
                  }
                />
              </div>

              <div className="mr-cell cell-flex">
                <label>
                  Resume
                  <input
                    type="checkbox"
                    name="res_seven"
                    checked={formData.res_seven}
                    onChange={(e) =>
                      setFormData({ ...formData, res_seven: e.target.checked })
                    }
                  />
                </label>
                <label>
                  Discontinue
                  <input
                    type="checkbox"
                    name="dis_seven"
                    checked={formData.dis_seven}
                    onChange={(e) =>
                      setFormData({ ...formData, dis_seven: e.target.checked })
                    }
                  />
                </label>
              </div>
            </div>
            <div className="mr-row">
              <div className="mr-cell">
                <input
                  type="text"
                  className="ir-no-border"
                  name="dos_eight"
                  value={formData.dos_eight}
                  onChange={(e) =>
                    setFormData({ ...formData, dos_eight: e.target.value })
                  }
                />
              </div>
              <div className="mr-cell">
                <input
                  type="text"
                  className="ir-no-border"
                  name="dose_eight_freq"
                  value={formData.dose_eight_freq}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      dose_eight_freq: e.target.value,
                    })
                  }
                />
              </div>

              <div className="mr-cell">
                <input
                  type="text"
                  className="ir-no-border"
                  name="freq_eight"
                  value={formData.freq_eight}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      freq_eight: e.target.value,
                    })
                  }
                />
              </div>

              <div className="mr-cell">
                <input
                  type="text"
                  className="ir-no-border"
                  name="route_eight"
                  value={formData.route_eight}
                  onChange={(e) =>
                    setFormData({ ...formData, route_eight: e.target.value })
                  }
                />
              </div>

              <div className="mr-cell cell-flex">
                <label>
                  Resume
                  <input
                    type="checkbox"
                    name="res_eight"
                    checked={formData.res_eight}
                    onChange={(e) =>
                      setFormData({ ...formData, res_eight: e.target.checked })
                    }
                  />
                </label>
                <label>
                  Discontinue
                  <input
                    type="checkbox"
                    name="dis_eight"
                    checked={formData.dis_eight}
                    onChange={(e) =>
                      setFormData({ ...formData, dis_eight: e.target.checked })
                    }
                  />
                </label>
              </div>
            </div>
          </div>
          <div className="grievance-container mt-50 mobile">
            <div className="gc-item">
              {" "}
              <input
                name="reviewed_by"
                type="text"
                value={formData.reviewed_by}
                onChange={(e) =>
                  setFormData({ ...formData, reviewed_by: e.target.value })
                }
                id="name"
                className="bottom-border-input"
              />
              <p>Reviewed By:</p>
            </div>
            <div className="gc-item mt-50">
              {" "}
              <label htmlFor="name" className="hidden">
                Reviewed By:
              </label>
              <input
                name="date_one"
                type="date"
                value={formData.date_one}
                onChange={(e) =>
                  setFormData({ ...formData, date_one: e.target.value })
                }
                id="name"
                className="bottom-border-input"
              />
              <p>Date</p>
            </div>
          </div>
          <div className="center mt-50">
            <button className="btn-primary hover-pointer" type="submit">
              Submit
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default MedicationReconciliation;
