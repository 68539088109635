import React, { useState, useEffect } from "react";
import { Icon } from "@iconify/react";
import "../Forms/styles.css";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import moment from "moment";
import {
  addFile,
  getIntraOpNursingRecord,
  updateIntraOpNursingRecord,
} from "../../../utils/api";

import SignatureButton from "../../../components/SignatureButton";

const IntraOpNursingRecord = () => {
  const navigate = useNavigate();
  const { form_id } = useParams();
  const [loading, setLoading] = useState(true);
  const [individualSignature, setIndividualSignature] = useState("");
  // const [notPatientSignature, setNotPatientSignature] = useState("");

  const [formData, setFormData] = useState({
    Other: "",
    Yes_2: "",
    OtherRow1: "",
    DisorderOtherRow1: "",
    SiteByRow1: "",
    Dressing: "",
    Pad: "",
    Bipolar: "",
    APC: "",
    Other_2: "",
    SafetytrapSite: "",
    CoagSetting: "",
    CutSetting: "",
    PadAppliedBy: "",
    FluoroTime: "",
    text_positioning: "",

    Setting: "",
    by_positioned: "",
    CorrectSponge: "",
    IncorrectSponge: "",
    CorrectSponge_2: "",
    IncorrectSponge_2: "",
    CorrectSponge_3: "",
    IncorrectSponge_3: "",
    CorrectNeedle: "",
    IncorrectNeedle: "",
    CorrectNeedle_2: "",
    IncorrectNeedle_2: "",
    CorrectNeedle_3: "",
    IncorrectNeedle_3: "",
    CorrectInstrument: "",
    IncorrectInstrument: "",
    CorrectInstrument_2: "",
    IncorrectInstrument_2: "",
    CorrectInstrument_3: "",
    IncorrectInstrument_3: "",
    Circulators: "",
    Scrubs: "",
    checkbox_seventy_1: false,

    checkbox_none: false,
    checkbox_one: false,
    checkbox_two: false,
    checkbox_three: false,
    checkbox_four: false,
    checkbox_five: false,
    checkbox_ten: false,
    checkbox_eleven: false,
    checkbox_eight: false,
    checkbox_nine: false,
    checkbox_thirteen: false,
    checkbox_fourteen: false,
    checkbox_fifteen: false,
    checkbox_sixteen: false,
    checkbox_seventeen: false,
    checkbox_eighteen: false,
    checkbox_nineteen: false,
    checkbox_twenty: false,
    checkbox_twenty_one: false,
    checkbox_twenty_two: false,
    checkbox_twenty_three: false,
    checkbox_twenty_four: false,
    checkbox_twenty_five: false,
    checkbox_twenty_six: false,
    checkbox_twenty_seven: false,
    checkbox_twenty_eight: false,
    checkbox_twenty_nine: false,
    checkbox_thirty: false,
    checkbox_thirty_one: false,
    checkbox_thirty_two: false,
    checkbox_thirty_three: false,
    checkbox_thirty_four: false,
    checkbox_thirty_five: false,
    checkbox_thirty_six: false,
    checkbox_thirty_seven: false,
    checkbox_thirty_eight: false,
    checkbox_thirty_nine: false,
    checkbox_forty: false,
    checkbox_forty_one: false,
    Text4: "",
    Text5: "",
    Text6: "",
    checkbox_forty_three: false,
    checkbox_forty_four: false,
    checkbox_forty_five: false,
    checkbox_forty_six: false,
    checkbox_forty_seven: false,
    checkbox_forty_eight: false,
    checkbox_forty_nine: false,
    checkbox_fifty: false,
    checkbox_fifty_one: false,
    checkbox_fifty_two: false,
    checkbox_fifty_three: false,
    checkbox_fifty_four: false,
    checkbox_fifty_five: false,
    checkbox_fifty_six: false,
    checkbox_fifty_seven: false,
    checkbox_fifty_eight: false,
    checkbox_fifty_nine: false,
    checkbox_sixty: false,
    checkbox_sixty_one: false,
    checkbox_sixty_two: false,
    Text7: "",
    Text8: "",

    lJAInstrument: "",
    lJANeedle: "",
    lJASponge: "",

    checkbox_sixty_three: false,
    checkbox_sixty_four: false,
    checkbox_sixty_five: false,
    checkbox_sixty_six: false,
    checkbox_sixty_seven: false,
    checkbox_sixty_eight: false,
    checkbox_sixty_nine: false,
    checkbox_seventy: false,
    Text9: "",
    AirlDII: "",
    checkbox_seventy_one: false,
    checkbox_seventy_two: false,
    checkbox_seventy_three: false,
    Text10: "",
    checkbox_seventy_four: false,
    checkbox_seventy_five: false,
    checkbox_seventy_six: false,
    checkbox_seventy_seven: false,
    checkbox_seventy_eight: false,
    checkbox_seventy_nine: false,
    checkbox_eighty: false,
    checkbox_eighty_one: false,
    checkbox_eighty_two: false,
    checkbox_eighty_three: false,
    Check_Box3_1: false,
    checkbox_eighty_four: false,
    checkbox_eighty_five: false,
    checkbox_eighty_six: false,
    checkbox_eighty_seven: false,
    checkbox_eighty_eight: false,
    checkbox_eighty_nine: false,
    checkbox_ninety: false,
    checkbox_ninety_one: false,
    checkbox_ninety_two: false,
    checkbox_ninety_three: false,
    checkbox_ninety_four: false,
    checkbox_ninety_five: false,
    checkbox_ninety_six: false,
    checkbox_ninety_seven: false,
    checkbox_ninety_eight: false,
    checkbox_ninety_nine: false,
    checkbox_one_hundred: false,
    box_one: false,
    box_two: false,
    box_three: false,
    box_four: false,
    box_five: false,
    box_six: false,
    box_seven: false,
    box_eight: false,
    box_nine: false,
    box_ten: false,
    box_eleven: false,
    box_twelve: false,
    box_thirteen: false,
    box_fourteen: false,
    box_fifteen: false,
    box_sixteen: false,
    box_seventeen: false,
    box_eighteen: false,
    box_nineteen: false,
    box_twenty: false,
    box_twenty_one: false,
    box_twenty_two: false,
    box_twenty_three: false,
    box_twenty_four: false,
    box_twenty_five: false,
    box_twenty_six: false,
    box_twenty_seven: false,
    box_twenty_eight: false,
    box_twenty_nine: false,
    box_thirty: false,
    box_thirty_one: false,
    box_thirty_two: false,
    box_thirty_three: false,
    box_thirty_four: false,
    box_thirty_five: false,
    box_thirty_six: false,
    box_thirty_seven: false,
    box_thirty_eight: false,
    box_thirty_nine: false,
    box_forty: false,
    box_forty_one: false,
    box_forty_two: false,
    box_forty_three: false,
    box_forty_four: false,
    box_forty_five: false,
    box_forty_six: false,
    box_forty_seven: false,
    box_forty_eight: false,
    box_forty_nine: false,
    box_fifty: false,
    box_fifty_one: false,
    box_fifty_two: false,
    Text11: "",
    checkbox_twelve: false,
    Text12: "",
    check3: "",
    Text13: "",
    Text14: "",
    Text15: "",
    Text16: "",
    Text17: "",
    Text18: "",
    Text19: "",
    Text20: "",
    Text21: "",
    Text22: "",
    Text23: "",
    Text24: "",
    Text22: "",
    Text23: "",
    Text24: "",
    Text25: "",
    Text26: "",
    Text27: "",
    Text28: "",
    Text29: "",
    Text30: "",
    Text31: "",
    Text32: "",
    Text33: "",
    Text34: "",
    Text35: "",
    Text36: "",
    checkbox_forty_two: false,
    Text1: "",
    Text2: "",
    Text3: "",
    Text37: "",
    Text38: "",
    Text39: "",
    Text40: "",
    anesthesia_gen: false,
    anesthesia_local: false,
    intubated: false,
    pending_pathology: false,
    masked: false,
    complications_none: false,
    complications_yes: false,
    specimens_none: false,
    specimens_yes: false,
    specimens_fs: false,
    specimens_xray: false,
    cultures_none: false,
    cultures_aerobic: false,
    cultures_anaerobic: false,
    discharged_gurney: false,
    discharged_wheelchair: false,
    time_in: "",
    anesthesia_start: "",
    surgery_start: "",
    surgery_end: "",
    anesthesia_end: "",
    time_out: "",
    preop_diagnosis_text: "",
    procedure: "",
    preop_diagnosis: "",

    block_type: "",
    anesthesia_record: "",
    complications_text: "",
    procedure_one_surgeon: "",
    anesthesiologist: "",
    procedure_one: "",
    procedure_two_surgeon: "",
    others: "",
    procedure_two: "",
    circulating_rn_one: "",
    circulating_rn_two: "",
    circulating_rn_three: "",
    inout_a: "",
    inout_a_one: "",
    inout_a_three: "",
    inout_b_one: "",
    inout_b_two: "",
    inout_b_three: "",
    inout_c_one: "",
    inout_c_two: "",
    inout_c_three: "",
    inout_d_one: "",
    inout_d_two: "",
    inout_d_three: "",
    inout_e_one: "",
    inout_e_two: "",
    inout_e_three: "",
    inout_f_one: "",
    inout_f_two: "",
    inout_f_three: "",
    scrub_rn_one: "",
    scrub_rn_two: "",
    scrub_rn_three: "",
    irrigation_one: "",
    irrigation_two: "",
    irrigation_three: "",
    irrigation_four: "",
    irrigation_five: "",
    amount_one: "",
    amount_two: "",
    amount_three: "",
    amount_four: "",
    amount_five: "",
    method_one: "",
    method_two: "",
    method_three: "",
    method_four: "",
    method_five: "",
    specimens_sent: "",
    discharged_notes: "",
    anesthesia_mac: false,
    anesthesia_lma: false,
    outcomes_one_met: false,
    outcomes_two_met: false,
    outcomes_one_notmet: false,
    outcomes_two_notmet: false,
    outcomes_three_met: false,
    outcomes_three_notmet: false,
    discharged_report_given: "",
    anesthesia_block: false,
  });
  const handleSubmit = async (e) => {
    e.preventDefault();
    let patientFileName, rnSignatureTwoFileName;

    // if (notPatientSignature !== "") {
    //   console.log("Uploading form completer signature:", notPatientSignature);
    // }

    // Fetch 1: Upload Signature
    if (individualSignature !== "") {
      const response = await addFile(individualSignature);

      const data = await response.json();
      patientFileName = data.fileName;
    }
    // if (notPatientSignature !== "") {
    //   const response = await addFile(notPatientSignature);
    //   const data = await response.json();
    //   console.log("Uploaded form completer signature:", data.fileName);
    //   rnSignatureTwoFileName = data.fileName || null;
    // }

    const payload = {
      rn_signature: patientFileName,
      // rn_signature_two: rnSignatureTwoFileName,

      ...formData,
      date: moment().format("MM/DD/YYYY"),
      time: moment().format("h:mm a"),
      Text11: moment().format("h:mm a"),
      Text13: moment().format("h:mm a"),
      status: "In Progress",
    };

    console.log("Payload Sent to Backend:", payload);

    // Fetch 2: Create/Update the Disclosure of Ownerhsip Model

    updateIntraOpNursingRecord(form_id, payload)
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        console.log("complete");
        navigate(-1);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getIntraOpNursingRecord(form_id)
      .then((res) => res.json())
      .then((data) => {
        console.log(data);

        if (data.status !== "Not Started") {
          setFormData({
            ...formData,
          });
        }
        setLoading(false);
      })
      .catch((err) => console.log(err));
  }, []);
  if (loading) return <p>Loading...</p>;

  return (
    <>
      <div
        className="arrow-container"
        onClick={() => navigate(-1)}
        style={{ cursor: "pointer" }}
      >
        <p className="back-arrow">
          <Icon icon="ic:twotone-arrow-back" className="icon" /> Back to Forms
        </p>
      </div>{" "}
      <div className="form-content">
        <form onSubmit={handleSubmit}>
          {" "}
          <h2 className="form-title">Intra-Op Nursing Record</h2>
          <h2 className="form-title">
            <label>TIME OUT:</label>
            <input
              type="time"
              className="di-input"
              name="Text11"
              value={formData.Text11}
              onChange={(e) =>
                setFormData({ ...formData, Text11: e.target.value })
              }
            />
          </h2>
          <div>
            <p className="title-text-h3">REASSESSMENT</p>
          </div>
          <div className="ionr-container">
            <div>
              <h4>
                I. RISK FOR ANXIETY RELATED TO KNOWLEDGE DEFICIT AND STRESS OF
                PROCEDURE
              </h4>
            </div>
            <div>
              <p className="label-heading header">
                IDENTIFICATION/VERIFICATION
              </p>
            </div>
            <div className="label-indent">
              <label>Identified</label>
            </div>
            <div className="label-indent">
              <input
                type="checkbox"
                name="checkbox_one"
                checked={formData.checkbox_one}
                onChange={(e) =>
                  setFormData({ ...formData, checkbox_one: e.target.checked })
                }
              />{" "}
              <label>Patient</label>
              <input
                type="checkbox"
                name="checkbox_three"
                checked={formData.checkbox_three}
                onChange={(e) =>
                  setFormData({ ...formData, checkbox_three: e.target.checked })
                }
              />{" "}
              <label>ID Band</label>
              <input
                type="checkbox"
                name="checkbox_eleven"
                checked={formData.checkbox_eleven}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    checkbox_eleven: e.target.checked,
                  })
                }
              />{" "}
              <label>Surgery/Consent</label>
              <input
                type="checkbox"
                name="checkbox_ten"
                checked={formData.checkbox_ten}
                onChange={(e) =>
                  setFormData({ ...formData, checkbox_ten: e.target.checked })
                }
              />{" "}
              <label>Site</label>
              <input
                type="checkbox"
                name="checkbox_five"
                checked={formData.checkbox_five}
                onChange={(e) =>
                  setFormData({ ...formData, checkbox_five: e.target.checked })
                }
              />{" "}
              <label>Surgeon</label>
            </div>
            <div className="label-indent">
              <label>Verified By</label>
            </div>
            <div className="label-indent">
              <input
                type="checkbox"
                name="checkbox_two"
                checked={formData.checkbox_two}
                onChange={(e) =>
                  setFormData({ ...formData, checkbox_two: e.target.checked })
                }
              />{" "}
              <label>Patient</label>
              <input
                type="checkbox"
                name="checkbox_eight"
                checked={formData.checkbox_eight}
                onChange={(e) =>
                  setFormData({ ...formData, checkbox_eight: e.target.checked })
                }
              />{" "}
              <label>Surgeon</label>
              <input
                type="checkbox"
                name="checkbox_nine"
                checked={formData.checkbox_nine}
                onChange={(e) =>
                  setFormData({ ...formData, checkbox_nine: e.target.checked })
                }
              />{" "}
              <label>Guardian/Parent</label>
              <input
                type="checkbox"
                name="checkbox_four"
                checked={formData.checkbox_four}
                onChange={(e) =>
                  setFormData({ ...formData, checkbox_four: e.target.checked })
                }
              />{" "}
              <label>Medical Record</label>
            </div>
            <div className="form-divider"></div>
            <div>
              <p className="label-heading header">COGNITIVE/EMOTIONAL STATUS</p>
            </div>
            <div className="label-indent">
              <input
                type="checkbox"
                name="checkbox_twenty_nine"
                checked={formData.checkbox_twenty_nine}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    checkbox_twenty_nine: e.target.checked,
                  })
                }
              />{" "}
              <label>Awake</label>
              <input
                type="checkbox"
                name="checkbox_twenty_eight"
                checked={formData.checkbox_twenty_eight}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    checkbox_twenty_eight: e.target.checked,
                  })
                }
              />{" "}
              <label>Agitated</label>
              <input
                type="checkbox"
                name="checkbox_twenty_four"
                checked={formData.checkbox_twenty_four}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    checkbox_twenty_four: e.target.checked,
                  })
                }
              />{" "}
              <label>Anxious</label>
              <input
                type="checkbox"
                name="checkbox_twenty_three"
                checked={formData.checkbox_twenty_three}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    checkbox_twenty_three: e.target.checked,
                  })
                }
              />{" "}
              <label>Calm</label>
              <input
                type="checkbox"
                name="checkbox_nineteen"
                checked={formData.checkbox_nineteen}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    checkbox_nineteen: e.target.checked,
                  })
                }
              />{" "}
              <label>Confused</label>
              <input
                type="checkbox"
                name="checkbox_eighteen"
                checked={formData.checkbox_eighteen}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    checkbox_eighteen: e.target.checked,
                  })
                }
              />{" "}
              <label>Alcohol Abuse History</label>
              <br />{" "}
              <input
                type="checkbox"
                name="checkbox_thirty"
                checked={formData.checkbox_thirty}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    checkbox_thirty: e.target.checked,
                  })
                }
              />{" "}
              <label>Alert</label>
              <input
                type="checkbox"
                name="checkbox_twenty_five"
                checked={formData.checkbox_twenty_five}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    checkbox_twenty_five: e.target.checked,
                  })
                }
              />{" "}
              <label>Unresponsive</label>
              <input
                type="checkbox"
                name="checkbox_twenty"
                checked={formData.checkbox_twenty}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    checkbox_twenty: e.target.checked,
                  })
                }
              />{" "}
              <label>Hostile</label>
              <input
                type="checkbox"
                name="checkbox_fifteen"
                checked={formData.checkbox_fifteen}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    checkbox_fifteen: e.target.checked,
                  })
                }
              />{" "}
              <label>Lethargic</label>
              <input
                type="checkbox"
                name="checkbox_thirty_one"
                checked={formData.checkbox_thirty_one}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    checkbox_thirty_one: e.target.checked,
                  })
                }
              />{" "}
              <label>Responsive</label>
              <input
                type="checkbox"
                name="checkbox_twenty_six"
                checked={formData.checkbox_twenty_six}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    checkbox_twenty_six: e.target.checked,
                  })
                }
              />{" "}
              <label>Oriented</label>
              <br />{" "}
              <input
                type="checkbox"
                name="checkbox_twenty_one"
                checked={formData.checkbox_twenty_one}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    checkbox_twenty_one: e.target.checked,
                  })
                }
              />{" "}
              <label>Drowsy</label>
              <input
                type="checkbox"
                name="checkbox_sixteen"
                checked={formData.checkbox_sixteen}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    checkbox_sixteen: e.target.checked,
                  })
                }
              />{" "}
              <label>Disoriented</label>
              <input
                type="checkbox"
                name="checkbox_twenty_two"
                checked={formData.checkbox_twenty_two}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    checkbox_twenty_two: e.target.checked,
                  })
                }
              />{" "}
              <label>Sedated</label>
              <input
                type="checkbox"
                name="checkbox_twenty_seven"
                checked={formData.checkbox_twenty_seven}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    checkbox_twenty_seven: e.target.checked,
                  })
                }
              />{" "}
              <label>Drug Abuse History</label>
              <br />
              <input
                type="checkbox"
                name="checkbox_seventeen"
                checked={formData.checkbox_seventeen}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    checkbox_seventeen: e.target.checked,
                  })
                }
              />{" "}
              <label>Pain 0-10</label>
              <input
                type="text"
                className="di-input"
                name="Text6"
                value={formData.Text6}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    Text6: e.target.value,
                  })
                }
              />
              <input
                type="checkbox"
                name="checkbox_fourteen"
                checked={formData.checkbox_fourteen}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    checkbox_fourteen: e.target.checked,
                  })
                }
              />{" "}
              <label>Other Factors</label>
              <input
                type="text"
                className="di-input"
                name="Text5"
                value={formData.Text5}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    Text5: e.target.value,
                  })
                }
              />
            </div>
            <div className="form-divider"></div>

            <div>
              <p className="label-heading header">
                SENSORY/COMMUNICATION LIMITS
              </p>
            </div>

            <div className="label-indent">
              <input
                type="checkbox"
                name="checkbox_thirty_nine"
                checked={formData.checkbox_thirty_nine}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    checkbox_thirty_nine: e.target.checked,
                  })
                }
              />{" "}
              <label>No Limitations Noted</label>
              <br />
              <i>Limitations</i>{" "}
              <input
                type="checkbox"
                name="checkbox_thirty_four"
                checked={formData.checkbox_thirty_four}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    checkbox_thirty_four: e.target.checked,
                  })
                }
              />{" "}
              <label>Hearing Deficit</label>
              <input
                type="checkbox"
                name="checkbox_thirty_six"
                checked={formData.checkbox_thirty_six}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    checkbox_thirty_six: e.target.checked,
                  })
                }
              />{" "}
              <label>Visual</label>
              <input
                type="checkbox"
                name="checkbox_thirty_eight"
                checked={formData.checkbox_thirty_eight}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    checkbox_thirty_eight: e.target.checked,
                  })
                }
              />{" "}
              <label>Language</label>
              <input
                type="checkbox"
                name="checkbox_thirty_three"
                checked={formData.checkbox_thirty_three}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    checkbox_thirty_three: e.target.checked,
                  })
                }
              />{" "}
              <label>Memory</label>
              <input
                type="checkbox"
                name="checkbox_forty_one"
                checked={formData.checkbox_forty_one}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    checkbox_forty_one: e.target.checked,
                  })
                }
              />{" "}
              <label>Mobility</label>
              <div>
                <i>Language</i>{" "}
                <input
                  type="checkbox"
                  name="checkbox_forty"
                  checked={formData.checkbox_forty}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      checkbox_forty: e.target.checked,
                    })
                  }
                />{" "}
                <label>English</label>
                <input
                  type="checkbox"
                  name="checkbox_thirty_seven"
                  checked={formData.checkbox_thirty_seven}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      checkbox_thirty_seven: e.target.checked,
                    })
                  }
                />{" "}
                <label>Spanish</label>
                <input
                  type="checkbox"
                  name="checkbox_thirty_five"
                  checked={formData.checkbox_thirty_five}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      checkbox_thirty_five: e.target.checked,
                    })
                  }
                />{" "}
                <label>Chinese</label>
                <input
                  type="checkbox"
                  name="checkbox_thirty_two"
                  checked={formData.checkbox_thirty_two}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      checkbox_thirty_two: e.target.checked,
                    })
                  }
                />{" "}
                <label>Vietnamese</label>
                <label>Other</label>
                <input
                  type="text"
                  className="di-input"
                  name="Other"
                  value={formData.Other}
                  onChange={(e) =>
                    setFormData({ ...formData, Other: e.target.value })
                  }
                />
                <br />{" "}
                <input
                  type="checkbox"
                  name="checkbox_"
                  checked={formData.checkbox_}
                  onChange={(e) =>
                    setFormData({ ...formData, checkbox_: e.target.checked })
                  }
                />
                <label>Translators's Name</label>
                <input
                  type="text"
                  className="di-input"
                  name="Text4"
                  value={formData.Text4}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      Text4: e.target.value,
                    })
                  }
                />
              </div>
            </div>
            <div className="form-divider"></div>
            <div>
              <h4>II. RISK FOR INFECTION RELATED TO INVASION PROCEDURES</h4>
              <div className="label-indent">
                <input
                  type="checkbox"
                  name="checkbox_thirteen"
                  checked={formData.checkbox_thirteen}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      checkbox_thirteen: e.target.checked,
                    })
                  }
                />{" "}
                <label>No factors identified</label>
                <input
                  type="checkbox"
                  name="checkbox_twelve"
                  checked={formData.checkbox_twelve}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      checkbox_twelve: e.target.checked,
                    })
                  }
                />{" "}
                <label>See Pre-Op Assessment</label>
                <input
                  type="checkbox"
                  name="box_forty_eight"
                  checked={formData.box_forty_eight}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      box_forty_eight: e.target.checked,
                    })
                  }
                />{" "}
                <label>Poor Hygiene</label>
                <input
                  type="checkbox"
                  name="box_fifty"
                  checked={formData.box_fifty}
                  onChange={(e) =>
                    setFormData({ ...formData, box_fifty: e.target.checked })
                  }
                />{" "}
                <label>Infection Process</label>
                <input
                  type="checkbox"
                  name="box_fifty_one"
                  checked={formData.box_fifty_one}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      box_fifty_one: e.target.checked,
                    })
                  }
                />{" "}
                <label>Concurrent Disease Process</label>
                <input
                  type="checkbox"
                  name="box_forty_nine"
                  checked={formData.box_forty_nine}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      box_forty_nine: e.target.checked,
                    })
                  }
                />{" "}
                <label>Decreased Immune Response</label>
              </div>
            </div>
            <div>
              <h4>III. RISK FOR INJURY OR IMPAIRMENT</h4>
              <div className="label-indent">
                <label className="header">ALLERGIES</label>
                <input
                  type="checkbox"
                  name="checkbox_none"
                  checked={formData.checkbox_none}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      checkbox_none: e.target.checked,
                    })
                  }
                />{" "}
                <label>None</label>
                <input
                  type="checkbox"
                  name="checkbox_fifty_eight"
                  checked={formData.checkbox_fifty_eight}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      checkbox_fifty_eight: e.target.checked,
                    })
                  }
                />{" "}
                <label>Yes</label>
                <input
                  type="text"
                  className="di-input"
                  name="Yes_2"
                  value={formData.Yes_2}
                  onChange={(e) =>
                    setFormData({ ...formData, Yes_2: e.target.value })
                  }
                />{" "}
                <label>NPO Since</label>
                <input
                  type="text"
                  className="di-input"
                  name="Text8"
                  value={formData.Text8}
                  onChange={(e) =>
                    setFormData({ ...formData, Text8: e.target.value })
                  }
                />{" "}
              </div>
              <div className="label-indent">
                {" "}
                <label>Presents With</label>
                <input
                  type="checkbox"
                  name="checkbox_fifty_seven"
                  checked={formData.checkbox_fifty_seven}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      checkbox_fifty_seven: e.target.checked,
                    })
                  }
                />{" "}
                <label>IV</label>
                <input
                  type="checkbox"
                  name="checkbox_fifty_three"
                  checked={formData.checkbox_fifty_three}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      checkbox_fifty_three: e.target.checked,
                    })
                  }
                />{" "}
                <label>EKG</label>
                <input
                  type="checkbox"
                  name="checkbox_fifty_five"
                  checked={formData.checkbox_fifty_five}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      checkbox_fifty_five: e.target.checked,
                    })
                  }
                />{" "}
                <label>O2</label>
                <input
                  type="checkbox"
                  name="checkbox_fifty"
                  checked={formData.checkbox_fifty}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      checkbox_fifty: e.target.checked,
                    })
                  }
                />{" "}
                <label>Other</label>
                <input
                  type="text"
                  className="di-input"
                  name="Text7"
                  value={formData.Text7}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      Text7: e.target.value,
                    })
                  }
                />{" "}
              </div>
              <div className="label-indent">
                <label>PreOp Skin Condition</label>
                <input
                  type="checkbox"
                  name="checkbox_forty_eight"
                  checked={formData.checkbox_forty_eight}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      checkbox_forty_eight: e.target.checked,
                    })
                  }
                />
                <label>Warm</label>
                <input
                  type="checkbox"
                  name="checkbox_sixty"
                  checked={formData.checkbox_sixty}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      checkbox_sixty: e.target.checked,
                    })
                  }
                />
                <label>Dry</label>
                <input
                  type="checkbox"
                  name="checkbox_sixty_two"
                  checked={formData.checkbox_sixty_two}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      checkbox_sixty_two: e.target.checked,
                    })
                  }
                />
                <label>Intact</label>
                <input
                  type="checkbox"
                  name="checkbox_fifty_one"
                  checked={formData.checkbox_fifty_one}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      checkbox_fifty_one: e.target.checked,
                    })
                  }
                />
                <label>Cool</label>
                <input
                  type="checkbox"
                  name="checkbox_sixty_eight"
                  checked={formData.checkbox_sixty_eight}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      checkbox_sixty_eight: e.target.checked,
                    })
                  }
                />
                <label>Pale</label>
                <input
                  type="checkbox"
                  name="checkbox_sixty_seven"
                  checked={formData.checkbox_sixty_seven}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      checkbox_sixty_seven: e.target.checked,
                    })
                  }
                />
                <label>Diaphoretic</label>
                <input
                  type="checkbox"
                  name="checkbox_sixty_three"
                  checked={formData.checkbox_sixty_three}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      checkbox_sixty_three: e.target.checked,
                    })
                  }
                />
                <label>Other</label>
                <input
                  type="text"
                  className="di-input"
                  name="Text12"
                  value={formData.Text12}
                  onChange={(e) =>
                    setFormData({ ...formData, Text12: e.target.value })
                  }
                />
              </div>
              <div className="label-indent">
                {" "}
                <input
                  type="checkbox"
                  name="checkbox_fifty_two"
                  checked={formData.checkbox_fifty_two}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      checkbox_fifty_two: e.target.checked,
                    })
                  }
                />
                <label>Rash, Lesions, Bruise, Swelling Site/Description</label>
                <input
                  type="text"
                  className="di-input"
                  name="OtherRow1"
                  value={formData.OtherRow1}
                  onChange={(e) =>
                    setFormData({ ...formData, OtherRow1: e.target.value })
                  }
                />
              </div>
              <div className="label-indent">
                {" "}
                <label>Risk Factors</label>
                <input
                  type="checkbox"
                  name="checkbox_forty_seven"
                  checked={formData.checkbox_forty_seven}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      checkbox_forty_seven: e.target.checked,
                    })
                  }
                />{" "}
                <label>None</label>
                <input
                  type="checkbox"
                  name="checkbox_forty_three"
                  checked={formData.checkbox_forty_three}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      checkbox_forty_three: e.target.checked,
                    })
                  }
                />{" "}
                <label>Cardiac</label>
                <input
                  type="checkbox"
                  name="checkbox_forty_four"
                  checked={formData.checkbox_forty_four}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      checkbox_forty_four: e.target.checked,
                    })
                  }
                />{" "}
                <label>HTN</label>
                <input
                  type="checkbox"
                  name="checkbox_forty_five"
                  checked={formData.checkbox_forty_five}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      checkbox_forty_five: e.target.checked,
                    })
                  }
                />{" "}
                <label>COPD</label>
                <input
                  type="checkbox"
                  name="checkbox_fifty_six"
                  checked={formData.checkbox_fifty_six}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      checkbox_fifty_six: e.target.checked,
                    })
                  }
                />{" "}
                <label>Smoker</label>
                <input
                  type="checkbox"
                  name="checkbox_sixty_nine"
                  checked={formData.checkbox_sixty_nine}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      checkbox_sixty_nine: e.target.checked,
                    })
                  }
                />{" "}
                <label>Diabetes</label>
                <input
                  type="checkbox"
                  name="checkbox_sixty_five"
                  checked={formData.checkbox_sixty_five}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      checkbox_sixty_five: e.target.checked,
                    })
                  }
                />{" "}
                <label>Obesity</label>
                <br />{" "}
                <input
                  type="checkbox"
                  name="checkbox_sixty_six"
                  checked={formData.checkbox_sixty_six}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      checkbox_sixty_six: e.target.checked,
                    })
                  }
                />{" "}
                <label>M.I.</label>
                <input
                  type="checkbox"
                  name="checkbox_seventy"
                  checked={formData.checkbox_seventy}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      checkbox_seventy: e.target.checked,
                    })
                  }
                />{" "}
                <label>Seizure Disorder</label>
                <input
                  type="checkbox"
                  name="checkbox_fifty_nine"
                  checked={formData.checkbox_fifty_nine}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      checkbox_fifty_nine: e.target.checked,
                    })
                  }
                />{" "}
                <label>Edema</label>
                <input
                  type="checkbox"
                  name="checkbox_forty_nine"
                  checked={formData.checkbox_forty_nine}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      checkbox_forty_nine: e.target.checked,
                    })
                  }
                />{" "}
                <label>Asthma</label>
                <input
                  type="checkbox"
                  name="checkbox_sixty_one"
                  checked={formData.checkbox_sixty_one}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      checkbox_sixty_one: e.target.checked,
                    })
                  }
                />{" "}
                <label>Renal Disease</label>
                <input
                  type="checkbox"
                  name="checkbox_sixty_four"
                  checked={formData.checkbox_sixty_four}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      checkbox_sixty_four: e.target.checked,
                    })
                  }
                />{" "}
                <label>Other</label>
                <input
                  type="text"
                  className="di-input"
                  name="Text9"
                  value={formData.Text9}
                  onChange={(e) =>
                    setFormData({ ...formData, Text9: e.target.value })
                  }
                />{" "}
              </div>
              <div className="label-indent">
                <label>ROM Limitations</label>
                <input
                  type="checkbox"
                  name="checkbox_fifty_four"
                  checked={formData.checkbox_fifty_four}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      checkbox_fifty_four: e.target.checked,
                    })
                  }
                />{" "}
                <label>None</label>
                <input
                  type="checkbox"
                  name="checkbox_forty_six"
                  checked={formData.checkbox_forty_six}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      checkbox_forty_six: e.target.checked,
                    })
                  }
                />{" "}
                <label>Yes/Describe</label>
                <input
                  type="text"
                  className="di-input"
                  name="DisorderOtherRow1"
                  value={formData.DisorderOtherRow1}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      DisorderOtherRow1: e.target.value,
                    })
                  }
                />
              </div>
              <div className="label-indent">
                <label>Notes</label>
                <input
                  type="text"
                  className="di-input"
                  name="Text36"
                  value={formData.Text36}
                  onChange={(e) =>
                    setFormData({ ...formData, Text36: e.target.value })
                  }
                />
              </div>
              <div className="grid-2">
                <div className="signature-container mt-50">
                  <SignatureButton
                    individualSignature={individualSignature}
                    setIndividualSignature={setIndividualSignature}
                  />
                  <hr className="signature-line"></hr>
                  <p>R.N. Signature</p>
                </div>

                <div className="mt-50">
                  <label htmlFor="name" className="hidden">
                    R.N. Signature
                  </label>
                  <input
                    type="time"
                    id="name"
                    name="Text13"
                    className="bottom-border-input"
                    value={formData.Text13}
                    onChange={(e) =>
                      setFormData({ ...formData, Text13: e.target.value })
                    }
                  />

                  <p>Time</p>
                </div>
              </div>
              <div>
                <p className="title-text-h3">PLAN/IMPLEMENTATION</p>
              </div>
              <div>
                <h4>
                  I. OUTCOME-PATIENT VERBALIZES/INDICATES DECREASED ANXIETY,
                  UNDERSTANDING OF PROCEDURE & SEQUENCE OF EVENTS
                </h4>
                <div className="label-indent">
                  <input
                    type="checkbox"
                    name="checkbox_seventy_one"
                    checked={formData.checkbox_seventy_one}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        checkbox_seventy_one: e.target.checked,
                      })
                    }
                  />{" "}
                  <label>OR Protocol explained to patient</label>
                  <input
                    type="checkbox"
                    name="checkbox_seventy_1"
                    checked={formData.checkbox_seventy_1}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        checkbox_seventy_1: e.target.checked,
                      })
                    }
                  />{" "}
                  <label>
                    Patient encouraged to ask questions and verbalize concerns
                    <br />
                  </label>
                  <input
                    type="checkbox"
                    name="checkbox_seventy_two"
                    checked={formData.checkbox_seventy_two}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        checkbox_seventy_two: e.target.checked,
                      })
                    }
                  />{" "}
                  <label>
                    Conveyed caring supportive attitude; initiated comfort
                    measures
                  </label>
                  <input
                    type="checkbox"
                    name="box_forty_six"
                    checked={formData.box_forty_six}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        box_forty_six: e.target.checked,
                      })
                    }
                  />{" "}
                  <label>Pt acknowledged pre-op teaching</label>
                  <br />{" "}
                  <input
                    type="checkbox"
                    name="box_fifty_two"
                    checked={formData.box_fifty_two}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        box_fifty_two: e.target.checked,
                      })
                    }
                  />{" "}
                  <label>Remained w/pt during induction</label>
                  <input
                    type="checkbox"
                    name="checkbox_seventy_three"
                    checked={formData.checkbox_seventy_three}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        checkbox_seventy_three: e.target.checked,
                      })
                    }
                  />{" "}
                  <label>Other</label>{" "}
                  <input
                    type="text"
                    className="di-input"
                    name="Text10"
                    value={formData.Text10}
                    onChange={(e) =>
                      setFormData({ ...formData, Text10: e.target.value })
                    }
                  />
                </div>
              </div>
              <div>
                <h4>
                  II. OUTCOME-PATIENT'S SURGERY PERFORMED USING ASEPTIC
                  PRACTICES & IN A MANNER TO PREVENT CROSS CONTAMINATION
                </h4>
                <div className="label-indent">
                  {" "}
                  <label>Wound Classification</label>
                  <input
                    type="checkbox"
                    name="check3"
                    checked={formData.check3}
                    onChange={(e) =>
                      setFormData({ ...formData, check3: e.target.checked })
                    }
                  />{" "}
                  <label>I</label>
                  <input
                    type="checkbox"
                    name="checkbox_seventy_eight"
                    checked={formData.checkbox_seventy_eight}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        checkbox_seventy_eight: e.target.checked,
                      })
                    }
                  />{" "}
                  <label>II</label>
                  <input
                    type="checkbox"
                    name="checkbox_seventy_five"
                    checked={formData.checkbox_seventy_five}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        checkbox_seventy_five: e.target.checked,
                      })
                    }
                  />{" "}
                  <label>III</label>
                  <input
                    type="checkbox"
                    name="checkbox_eighty"
                    checked={formData.checkbox_eighty}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        checkbox_eighty: e.target.checked,
                      })
                    }
                  />
                  <label>IV</label>
                </div>
                <div className="label-indent">
                  <label>Shave Prep</label>
                  <input
                    type="checkbox"
                    name="checkbox_seventy_six"
                    checked={formData.checkbox_seventy_six}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        checkbox_seventy_six: e.target.checked,
                      })
                    }
                  />{" "}
                  <label>None</label>
                  <input
                    type="checkbox"
                    name="box_forty_seven"
                    checked={formData.box_forty_seven}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        box_forty_seven: e.target.checked,
                      })
                    }
                  />{" "}
                  <label>Pre-Or</label>
                  <input
                    type="checkbox"
                    name="box_forty_five"
                    checked={formData.box_forty_five}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        box_forty_five: e.target.checked,
                      })
                    }
                  />{" "}
                  <label>In OR by</label>
                  <input
                    type="text"
                    className="di-input"
                    name="Text17"
                    value={formData.Text17}
                    onChange={(e) =>
                      setFormData({ ...formData, Text17: e.target.value })
                    }
                  />
                </div>
                <div className="label-indent">
                  {" "}
                  <label>Skin Prep </label>{" "}
                  <input
                    type="checkbox"
                    name="checkbox_ninety_one"
                    checked={formData.checkbox_ninety_one}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        checkbox_ninety_one: e.target.checked,
                      })
                    }
                  />{" "}
                  <label>None</label>
                  <input
                    type="checkbox"
                    name="checkbox_"
                    checked={formData.checkbox_}
                    onChange={(e) =>
                      setFormData({ ...formData, checkbox_: e.target.checked })
                    }
                  />
                  <label>Betadine</label>
                  <input
                    type="checkbox"
                    name="checkbox_eighty_four"
                    checked={formData.checkbox_eighty_four}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        checkbox_eighty_four: e.target.checked,
                      })
                    }
                  />
                  <label>Scrub</label>
                  <input
                    type="checkbox"
                    name="checkbox_eighty_nine"
                    checked={formData.checkbox_eighty_nine}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        checkbox_eighty_nine: e.target.checked,
                      })
                    }
                  />
                  <label>Solution</label>
                  <input
                    type="checkbox"
                    name="checkbox_seventy_four"
                    checked={formData.checkbox_seventy_four}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        checkbox_seventy_four: e.target.checked,
                      })
                    }
                  />
                  <label>Gel</label>
                  <input
                    type="checkbox"
                    name="checkbox_ninety"
                    checked={formData.checkbox_ninety}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        checkbox_ninety: e.target.checked,
                      })
                    }
                  />
                  <label>Other</label>
                  <input
                    type="text"
                    name="Text19"
                    value={formData.Text19}
                    onChange={(e) =>
                      setFormData({ ...formData, Text19: e.target.value })
                    }
                  />
                  <label>Site</label>
                  <input
                    type="text"
                    name="Text14"
                    value={formData.Text14}
                    onChange={(e) =>
                      setFormData({ ...formData, Text14: e.target.value })
                    }
                  />
                  <label>By</label>
                  <input
                    type="text"
                    name="Text18"
                    value={formData.Text18}
                    onChange={(e) =>
                      setFormData({ ...formData, Text18: e.target.value })
                    }
                  />{" "}
                </div>
                <div className="label-indent">
                  {" "}
                  <label>Implants</label>
                  <input
                    type="checkbox"
                    name="checkbox_eighty_seven"
                    checked={formData.checkbox_eighty_seven}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        checkbox_eighty_seven: e.target.checked,
                      })
                    }
                  />{" "}
                  <label>None</label>
                  <input
                    type="checkbox"
                    name="checkbox_eighty_six"
                    checked={formData.checkbox_eighty_six}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        checkbox_eighty_six: e.target.checked,
                      })
                    }
                  />{" "}
                  <label>Yes, see implant log</label>
                </div>
                <div className="label-indent">
                  {" "}
                  <label>Catheter Inserted</label>
                  <input
                    type="checkbox"
                    name="checkbox_eighty_three"
                    checked={formData.checkbox_eighty_three}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        checkbox_eighty_three: e.target.checked,
                      })
                    }
                  />
                  <label>No</label>
                  <input
                    type="checkbox"
                    name="checkbox_seventy_nine"
                    checked={formData.checkbox_seventy_nine}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        checkbox_seventy_nine: e.target.checked,
                      })
                    }
                  />
                  <label>Yes</label>
                  <label>Inserted by</label>{" "}
                  <input
                    type="text"
                    className="di-input"
                    name="Text20"
                    value={formData.Text20}
                    onChange={(e) =>
                      setFormData({ ...formData, Text20: e.target.value })
                    }
                  />
                  <label>Type</label>
                  <input
                    type="text"
                    className="di-input"
                    name="Text15"
                    value={formData.Text15}
                    onChange={(e) =>
                      setFormData({ ...formData, Text15: e.target.value })
                    }
                  />
                  <label>Size</label>
                  <input
                    type="text"
                    className="di-input"
                    name="SiteByRow1"
                    value={formData.SiteByRow1}
                    onChange={(e) =>
                      setFormData({ ...formData, SiteByRow1: e.target.value })
                    }
                  />
                </div>
                <div className="label-indent">
                  {" "}
                  <label>Catheter Removed</label>
                  <input
                    type="checkbox"
                    name="checkbox_eighty_two"
                    checked={formData.checkbox_eighty_two}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        checkbox_eighty_two: e.target.checked,
                      })
                    }
                  />
                  <label>No</label>
                  <input
                    type="checkbox"
                    name="checkbox_eighty_eight"
                    checked={formData.checkbox_eighty_eight}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        checkbox_eighty_eight: e.target.checked,
                      })
                    }
                  />
                  <label>Yes</label>
                  <label>Output</label>
                  <input
                    type="text"
                    className="di-input"
                    name="Dressing"
                    value={formData.Dressing}
                    onChange={(e) =>
                      setFormData({ ...formData, Dressing: e.target.value })
                    }
                  />
                  <label>Dressing</label>
                  <input
                    type="text"
                    className="di-input"
                    name="Text16"
                    value={formData.Text16}
                    onChange={(e) =>
                      setFormData({ ...formData, Text16: e.target.value })
                    }
                  />
                </div>
                <div className="label-indent">
                  {" "}
                  <label>Packing/Drains/Site</label>
                  <input
                    type="text"
                    className="di-input"
                    name="Text35"
                    value={formData.Text35}
                    onChange={(e) =>
                      setFormData({ ...formData, Text35: e.target.value })
                    }
                  />
                </div>
              </div>
              <div>
                <h4>
                  III. OUTCOME-PATIENT FREE FROM S & S OF INJURY RELATED TO
                  POSITIONING, EXTRANEOUS OBJECTS AND EQUIPMENT
                </h4>
              </div>
              <div>
                <p className="label-heading header">ESU/ARGON</p>
              </div>
              <div className="label-indent">
                <label>ID#</label>
                <input
                  type="text"
                  className="di-input"
                  name="Text23"
                  value={formData.Text23}
                  onChange={(e) =>
                    setFormData({ ...formData, Text23: e.target.value })
                  }
                />
                <label>Pad Lot#</label>
                <input
                  type="text"
                  className="di-input"
                  name="Pad"
                  value={formData.Pad}
                  onChange={(e) =>
                    setFormData({ ...formData, Pad: e.target.value })
                  }
                />
                <input
                  type="checkbox"
                  name="checkbox_seventy_seven"
                  checked={formData.checkbox_seventy_seven}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      checkbox_seventy_seven: e.target.checked,
                    })
                  }
                />
                <label>Bipolar</label>
                <input
                  type="text"
                  className="di-input"
                  name="Bipolar"
                  value={formData.Bipolar}
                  onChange={(e) =>
                    setFormData({ ...formData, Bipolar: e.target.value })
                  }
                />
                <br />{" "}
                <input
                  type="checkbox"
                  name="checkbox_eighty_one"
                  checked={formData.checkbox_eighty_one}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      checkbox_eighty_one: e.target.checked,
                    })
                  }
                />
                <label>ABC</label>
                <input
                  type="text"
                  className="di-input"
                  name="Text24"
                  value={formData.Text24}
                  onChange={(e) =>
                    setFormData({ ...formData, Text24: e.target.value })
                  }
                />
                <input
                  type="checkbox"
                  name="checkbox_ninety_seven"
                  checked={formData.checkbox_ninety_seven}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      checkbox_ninety_seven: e.target.checked,
                    })
                  }
                />
                <label>APC</label>
                <input
                  type="text"
                  className="di-input"
                  name="APC"
                  value={formData.APC}
                  onChange={(e) =>
                    setFormData({ ...formData, APC: e.target.value })
                  }
                />
                <input
                  type="checkbox"
                  name="checkbox_eighty_five"
                  checked={formData.checkbox_eighty_five}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      checkbox_eighty_five: e.target.checked,
                    })
                  }
                />
                <label>Other</label>
                <input
                  type="text"
                  className="di-input"
                  name="Other_2"
                  value={formData.Other_2}
                  onChange={(e) =>
                    setFormData({ ...formData, Other_2: e.target.value })
                  }
                />
                <input
                  type="checkbox"
                  name="box_one"
                  checked={formData.box_one}
                  onChange={(e) =>
                    setFormData({ ...formData, box_one: e.target.checked })
                  }
                />
                <label>Monopolar</label>
                <br />{" "}
                <input
                  type="checkbox"
                  name="checkbox_ninety_six"
                  checked={formData.checkbox_ninety_six}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      checkbox_ninety_six: e.target.checked,
                    })
                  }
                />
                <label>Blend</label>
                <input
                  type="checkbox"
                  name="checkbox_one_hundred"
                  checked={formData.checkbox_one_hundred}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      checkbox_one_hundred: e.target.checked,
                    })
                  }
                />
                <label>Pure</label>
                <label>Coag Setting</label>
                <input
                  type="text"
                  className="di-input"
                  name="CoagSetting"
                  value={formData.CoagSetting}
                  onChange={(e) =>
                    setFormData({ ...formData, CoagSetting: e.target.value })
                  }
                />
                <label>Cut Setting</label>
                <input
                  type="text"
                  className="di-input"
                  name="CutSetting"
                  value={formData.CutSetting}
                  onChange={(e) =>
                    setFormData({ ...formData, CutSetting: e.target.value })
                  }
                />
                <br /> <label>Pad Applied By</label>
                <input
                  type="text"
                  className="di-input"
                  name="PadAppliedBy"
                  value={formData.PadAppliedBy}
                  onChange={(e) =>
                    setFormData({ ...formData, PadAppliedBy: e.target.value })
                  }
                />
                <label>Site</label>
                <input
                  type="text"
                  className="di-input"
                  name="Text22"
                  value={formData.Text22}
                  onChange={(e) =>
                    setFormData({ ...formData, Text22: e.target.value })
                  }
                />
                <br /> <label>Post Procedure Skin Condition at Pad Site</label>
                <input
                  type="checkbox"
                  name="box_two"
                  checked={formData.box_two}
                  onChange={(e) =>
                    setFormData({ ...formData, box_two: e.target.checked })
                  }
                />
                <label>Clear/Intact</label>
                <input
                  type="checkbox"
                  name="box_three"
                  checked={formData.box_three}
                  onChange={(e) =>
                    setFormData({ ...formData, box_three: e.target.checked })
                  }
                />
                <input
                  type="text"
                  className="di-input"
                  name="Text21"
                  value={formData.Text21}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      Text21: e.target.value,
                    })
                  }
                />
              </div>
              <div className="form-divider"></div>
              <div>
                <p className="label-heading header">POSITIONING</p>
              </div>
              <div className="label-indent">
                <input
                  type="checkbox"
                  name="checkbox_ninety_two"
                  checked={formData.checkbox_ninety_two}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      checkbox_ninety_two: e.target.checked,
                    })
                  }
                />
                <label>Supine</label>
                <input
                  type="checkbox"
                  name="checkbox_ninety_five"
                  checked={formData.checkbox_ninety_five}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      checkbox_ninety_five: e.target.checked,
                    })
                  }
                />
                <label>Prone</label>
                <input
                  type="checkbox"
                  name="box_forty_two"
                  checked={formData.box_forty_two}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      box_forty_two: e.target.checked,
                    })
                  }
                />
                <label>Lateral</label>
                <input
                  type="checkbox"
                  name="box_forty_one"
                  checked={formData.box_forty_one}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      box_forty_one: e.target.checked,
                    })
                  }
                />
                <label>R</label>
                <input
                  type="checkbox"
                  name="box_forty_three"
                  checked={formData.box_forty_three}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      box_forty_three: e.target.checked,
                    })
                  }
                />
                <label>L</label>
                <input
                  type="checkbox"
                  name="box_thirty_eight"
                  checked={formData.box_thirty_eight}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      box_thirty_eight: e.target.checked,
                    })
                  }
                />
                <label>Jacknife</label>
                <input
                  type="checkbox"
                  name="checkbox_ninety_eight"
                  checked={formData.checkbox_ninety_eight}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      checkbox_ninety_eight: e.target.checked,
                    })
                  }
                />
                <input
                  type="text"
                  className="di-input"
                  name="Text25"
                  value={formData.Text25}
                  onChange={(e) =>
                    setFormData({ ...formData, Text25: e.target.value })
                  }
                />
                <input
                  type="checkbox"
                  name="checkbox_ninety_three"
                  checked={formData.checkbox_ninety_three}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      checkbox_ninety_three: e.target.checked,
                    })
                  }
                />
                <label>Lithotomy:Stirrups/Site</label>
                <br />{" "}
                <input
                  type="checkbox"
                  name="box_thirty_three"
                  checked={formData.box_thirty_three}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      box_thirty_three: e.target.checked,
                    })
                  }
                />
                <label>Boot</label>
                <input
                  type="checkbox"
                  name="box_thirty_nine"
                  checked={formData.box_thirty_nine}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      box_thirty_nine: e.target.checked,
                    })
                  }
                />
                <label>Knee</label>
                <input
                  type="checkbox"
                  name="box_thirty_four"
                  checked={formData.box_thirty_four}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      box_thirty_four: e.target.checked,
                    })
                  }
                />
                <label>Sling</label>
                <input
                  type="checkbox"
                  name="checkbox_ninety_nine"
                  checked={formData.checkbox_ninety_nine}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      checkbox_ninety_nine: e.target.checked,
                    })
                  }
                />
                <label>Safety Strap/Site</label>
                <input
                  type="text"
                  className="di-input"
                  name="SafetytrapSite"
                  value={formData.SafetytrapSite}
                  onChange={(e) =>
                    setFormData({ ...formData, SafetytrapSite: e.target.value })
                  }
                />
                <input
                  type="checkbox"
                  name="box_thirty_seven"
                  checked={formData.box_thirty_seven}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      box_thirty_seven: e.target.checked,
                    })
                  }
                />
                <label>Warm Blanket</label>
                <br /> <label>Arm on Arm Board</label>
                <input
                  type="checkbox"
                  name="box_thirty_five"
                  checked={formData.box_thirty_five}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      box_thirty_five: e.target.checked,
                    })
                  }
                />
                <label>R</label>
                <input
                  type="checkbox"
                  name="box_thirty_two"
                  checked={formData.box_thirty_two}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      box_thirty_two: e.target.checked,
                    })
                  }
                />
                <label>L</label>
                <label>By</label>
                <input
                  type="text"
                  className="di-input"
                  name="Text28"
                  value={formData.Text28}
                  onChange={(e) =>
                    setFormData({ ...formData, Text28: e.target.value })
                  }
                />
                <br /> <label>Arms Tucked at Side</label>
                <input
                  type="checkbox"
                  name="box_forty"
                  checked={formData.box_forty}
                  onChange={(e) =>
                    setFormData({ ...formData, box_forty: e.target.checked })
                  }
                />
                <label>R</label>
                <input
                  type="checkbox"
                  name="box_thirty_six"
                  checked={formData.box_thirty_six}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      box_thirty_six: e.target.checked,
                    })
                  }
                />
                <label>L</label>
                <label>By</label>
                <input
                  type="text"
                  className="di-input"
                  name="Text26"
                  value={formData.Text26}
                  onChange={(e) =>
                    setFormData({ ...formData, Text26: e.target.value })
                  }
                />
                <br />{" "}
                <input
                  type="checkbox"
                  name="box_forty_four"
                  checked={formData.box_forty_four}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      box_forty_four: e.target.checked,
                    })
                  }
                />
                <label>Ulnar Nerves Padded </label>
                <input
                  type="checkbox"
                  name="checkbox_ninety_four"
                  checked={formData.checkbox_ninety_four}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      checkbox_ninety_four: e.target.checked,
                    })
                  }
                />
                <label>Body Alignment Maintained</label>
                <br />{" "}
                <input
                  type="text"
                  className="di-input"
                  name="text_positioning"
                  value={formData.text_positioning}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      text_positioning: e.target.value,
                    })
                  }
                />
                <label>Positioned By</label>
                <input
                  type="text"
                  className="di-input"
                  name="by_positioned"
                  value={formData.by_positioned}
                  onChange={(e) =>
                    setFormData({ ...formData, by_positioned: e.target.value })
                  }
                />
              </div>
              <div className="form-divider"></div>
              <div>
                <p className="label-heading header">OTHER</p>
              </div>
              <div className="label-indent">
                <label>Sequentials</label>
                <input
                  type="checkbox"
                  name="box_twenty_four"
                  checked={formData.box_twenty_four}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      box_twenty_four: e.target.checked,
                    })
                  }
                />
                <label>None</label>
                <input
                  type="checkbox"
                  name="   box_nineteen"
                  checked={formData.box_nineteen}
                  onChange={(e) =>
                    setFormData({ ...formData, box_nineteen: e.target.checked })
                  }
                />
                <label>Thigh-High</label>
                <input
                  type="checkbox"
                  name="box_thirty_one"
                  checked={formData.box_thirty_one}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      box_thirty_one: e.target.checked,
                    })
                  }
                />
                <label>Calf</label>
                <input
                  type="checkbox"
                  name="box_twenty_nine"
                  checked={formData.box_twenty_nine}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      box_twenty_nine: e.target.checked,
                    })
                  }
                />
                <label>Foot</label>
                <label>Settings</label>
                <input
                  type="text"
                  className="di-input"
                  name="Text27"
                  value={formData.Text27}
                  onChange={(e) =>
                    setFormData({ ...formData, Text27: e.target.value })
                  }
                />
              </div>
              <div className="label-indent">
                <label>Laser</label>
                <input
                  type="checkbox"
                  name="box_eighteen"
                  checked={formData.box_eighteen}
                  onChange={(e) =>
                    setFormData({ ...formData, box_eighteen: e.target.checked })
                  }
                />
                <label>N/A</label>
                <input
                  type="checkbox"
                  name="box_thirty"
                  checked={formData.box_thirty}
                  onChange={(e) =>
                    setFormData({ ...formData, box_thirty: e.target.checked })
                  }
                />
                <label>Argon</label>
                <input
                  type="checkbox"
                  name="box_twenty_five"
                  checked={formData.box_twenty_five}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      box_twenty_five: e.target.checked,
                    })
                  }
                />
                <label>CO2</label>
                <input
                  type="checkbox"
                  name="box_twenty_seven"
                  checked={formData.box_twenty_seven}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      box_twenty_seven: e.target.checked,
                    })
                  }
                />
                <label>Yag</label>
                <input
                  type="checkbox"
                  name="box_twenty_three"
                  checked={formData.box_twenty_three}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      box_twenty_three: e.target.checked,
                    })
                  }
                />
                <input
                  type="text"
                  className="di-input"
                  name="Text29"
                  value={formData.Text29}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      Text29: e.target.value,
                    })
                  }
                />
                <input
                  type="checkbox"
                  name="box_twenty_six"
                  checked={formData.box_twenty_six}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      box_twenty_six: e.target.checked,
                    })
                  }
                />
                <label>Laser Safety Protocol Implemented</label>
              </div>
              <div className="label-indent">
                <label>X-Ray</label>
                <input
                  type="checkbox"
                  name="box_twenty_two"
                  checked={formData.box_twenty_two}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      box_twenty_two: e.target.checked,
                    })
                  }
                />
                <label>None</label>
                <input
                  type="checkbox"
                  name="box_twenty"
                  checked={formData.box_twenty}
                  onChange={(e) =>
                    setFormData({ ...formData, box_twenty: e.target.checked })
                  }
                />
                <label>Portable</label>
                <input
                  type="checkbox"
                  name="box_twenty_one"
                  checked={formData.box_twenty_one}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      box_twenty_one: e.target.checked,
                    })
                  }
                />
                <label>Flouro Time</label>
                <input
                  type="text"
                  name="FluoroTime"
                  value={formData.FluoroTime}
                  onChange={(e) =>
                    setFormData({ ...formData, FluoroTime: e.target.value })
                  }
                />
              </div>
              <div className="label-indent">
                <label>Heating/Cooling</label>
                <input
                  type="checkbox"
                  name="box_twenty_eight"
                  checked={formData.box_twenty_eight}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      box_twenty_eight: e.target.checked,
                    })
                  }
                />
                <label>N/A</label>
                <input
                  type="checkbox"
                  name="box_fifteen"
                  checked={formData.box_fifteen}
                  onChange={(e) =>
                    setFormData({ ...formData, box_fifteen: e.target.checked })
                  }
                />
                <label>Water</label>
                <input
                  type="checkbox"
                  name="box_seventeen"
                  checked={formData.box_seventeen}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      box_seventeen: e.target.checked,
                    })
                  }
                />
                <label>Air ID#</label>
                <input
                  type="text"
                  className="di-input"
                  name="AirlDII"
                  checked={formData.AirlDII}
                  onChange={(e) =>
                    setFormData({ ...formData, AirlDII: e.target.checked })
                  }
                />
                <label>Setting</label>
                <input
                  type="text"
                  className="di-input"
                  name="Setting"
                  value={formData.Setting}
                  onChange={(e) =>
                    setFormData({ ...formData, Setting: e.target.value })
                  }
                />
                <input
                  type="checkbox"
                  name="box_sixteen"
                  checked={formData.box_sixteen}
                  onChange={(e) =>
                    setFormData({ ...formData, box_sixteen: e.target.checked })
                  }
                />
                <label>Pt Temps-See Anesthesia Record</label>
              </div>
              <div className="label-indent">
                <label className="header">COUNTS</label>
                <div className="counts-container">
                  <div className="counts-item">Type</div>
                  <div className="counts-item">Correct</div>
                  <div className="counts-item">Incorrect</div>
                  <div className="counts-item">Correct</div>
                  <div className="counts-item">Incorrect</div>
                  <div className="counts-item">Correct</div>
                  <div className="counts-item">Incorrect</div>
                  <div className="counts-item">N/A</div>
                  <div className="counts-item">Sponge</div>
                  <div className="counts-item">
                    <input
                      className="counts-input"
                      name="CorrectSponge"
                      value={formData.CorrectSponge}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          CorrectSponge: e.target.value,
                        })
                      }
                    />
                  </div>
                  <div className="counts-item">
                    <input
                      className="counts-input"
                      name="IncorrectSponge"
                      value={formData.IncorrectSponge}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          IncorrectSponge: e.target.value,
                        })
                      }
                    />
                  </div>
                  <div className="counts-item">
                    <input
                      className="counts-input"
                      name="CorrectSponge_2"
                      value={formData.CorrectSponge_2}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          CorrectSponge_2: e.target.value,
                        })
                      }
                    />
                  </div>
                  <div className="counts-item">
                    <input
                      className="counts-input"
                      name="IncorrectSponge_2"
                      value={formData.IncorrectSponge_2}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          IncorrectSponge_2: e.target.value,
                        })
                      }
                    />
                  </div>
                  <div className="counts-item">
                    <input
                      className="counts-input"
                      name="CorrectSponge_3"
                      value={formData.CorrectSponge_3}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          CorrectSponge_3: e.target.value,
                        })
                      }
                    />
                  </div>
                  <div className="counts-item">
                    <input
                      className="counts-input"
                      name="IncorrectSponge_3"
                      value={formData.IncorrectSponge_3}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          IncorrectSponge_3: e.target.value,
                        })
                      }
                    />
                  </div>
                  <div className="counts-item">
                    <input
                      className="counts-input"
                      name="lJASponge"
                      value={formData.lJASponge}
                      onChange={(e) =>
                        setFormData({ ...formData, lJASponge: e.target.value })
                      }
                    />
                  </div>
                  <div className="counts-item">Needle</div>
                  <div className="counts-item">
                    <input
                      className="counts-input"
                      name="CorrectNeedle"
                      value={formData.CorrectNeedle}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          CorrectNeedle: e.target.value,
                        })
                      }
                    />
                  </div>
                  <div className="counts-item">
                    <input
                      className="counts-input"
                      name="IncorrectNeedle"
                      value={formData.IncorrectNeedle}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          IncorrectNeedle: e.target.value,
                        })
                      }
                    />
                  </div>
                  <div className="counts-item">
                    <input
                      className="counts-input"
                      name="CorrectNeedle_2"
                      value={formData.CorrectNeedle_2}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          CorrectNeedle_2: e.target.value,
                        })
                      }
                    />
                  </div>
                  <div className="counts-item">
                    <input
                      className="counts-input"
                      name="IncorrectNeedle_2"
                      value={formData.IncorrectNeedle_2}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          IncorrectNeedle_2: e.target.value,
                        })
                      }
                    />
                  </div>
                  <div className="counts-item">
                    <input
                      className="counts-input"
                      name="CorrectNeedle_3"
                      value={formData.CorrectNeedle_3}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          CorrectNeedle_3: e.target.value,
                        })
                      }
                    />
                  </div>
                  <div className="counts-item">
                    <input
                      className="counts-input"
                      name="IncorrectNeedle_3"
                      value={formData.IncorrectNeedle_3}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          IncorrectNeedle_3: e.target.value,
                        })
                      }
                    />
                  </div>
                  <div className="counts-item">
                    <input
                      className="counts-input"
                      name="lJANeedle"
                      value={formData.lJANeedle}
                      onChange={(e) =>
                        setFormData({ ...formData, lJANeedle: e.target.value })
                      }
                    />
                  </div>
                  <div className="counts-item">Instrument</div>
                  <div className="counts-item">
                    <input
                      className="counts-input"
                      name="CorrectInstrument"
                      value={formData.CorrectInstrument}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          CorrectInstrument: e.target.value,
                        })
                      }
                    />
                  </div>
                  <div className="counts-item">
                    <input
                      className="counts-input"
                      name="IncorrectInstrument"
                      value={formData.IncorrectInstrument}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          IncorrectInstrument: e.target.value,
                        })
                      }
                    />
                  </div>
                  <div className="counts-item">
                    <input
                      className="counts-input"
                      name="CorrectInstrument_2"
                      value={formData.CorrectInstrument_2}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          CorrectInstrument_2: e.target.value,
                        })
                      }
                    />
                  </div>
                  <div className="counts-item">
                    <input
                      className="counts-input"
                      name="IncorrectInstrument_2"
                      value={formData.IncorrectInstrument_2}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          IncorrectInstrument_2: e.target.value,
                        })
                      }
                    />
                  </div>
                  <div className="counts-item">
                    <input
                      className="counts-input"
                      name="CorrectInstrument_3"
                      value={formData.CorrectInstrument_3}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          CorrectInstrument_3: e.target.value,
                        })
                      }
                    />
                  </div>
                  <div className="counts-item">
                    <input
                      className="counts-input"
                      name="IncorrectInstrument_3"
                      value={formData.IncorrectInstrument_3}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          IncorrectInstrument_3: e.target.value,
                        })
                      }
                    />
                  </div>
                  <div className="counts-item">
                    <input
                      className="counts-input"
                      name="lJAInstrument"
                      value={formData.lJAInstrument}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          lJAInstrument: e.target.value,
                        })
                      }
                    />
                  </div>
                </div>
              </div>
              <div className="label-indent">
                <label>Circulator(s)</label>
                <textarea
                  name="Circulators"
                  value={formData.Circulators}
                  onChange={(e) =>
                    setFormData({ ...formData, Circulators: e.target.value })
                  }
                />
              </div>
              <div className="label-indent">
                <label>Scrub(s)</label>
                <textarea
                  name="Scrubs"
                  value={formData.Scrubs}
                  onChange={(e) =>
                    setFormData({ ...formData, Scrubs: e.target.value })
                  }
                />
              </div>
              <div className="label-indent">
                <label>Surgeon Notified of Count</label>
                <input
                  type="checkbox"
                  name="box_six"
                  checked={formData.box_six}
                  onChange={(e) =>
                    setFormData({ ...formData, box_six: e.target.checked })
                  }
                />
                <label>Yes</label>
                <input
                  type="checkbox"
                  name="box__four"
                  checked={formData.box__four}
                  onChange={(e) =>
                    setFormData({ ...formData, box__four: e.target.checked })
                  }
                />
                <label>No</label>
              </div>
              <div className="label-indent">
                <label>Action if Incorrect</label>
                <input
                  type="checkbox"
                  name="box_seven"
                  checked={formData.box_seven}
                  onChange={(e) =>
                    setFormData({ ...formData, box_seven: e.target.checked })
                  }
                />
                <label>N/A</label>
                <input
                  type="checkbox"
                  name="box_five"
                  checked={formData.box_five}
                  onChange={(e) =>
                    setFormData({ ...formData, box_five: e.target.checked })
                  }
                />
                <label>X-Ray Taken</label>
              </div>
              <div>
                <p className="label-heading header">TOURNIQUET</p>
              </div>
              <div className="label-indent">
                <input
                  type="checkbox"
                  name="box_fourteen"
                  checked={formData.box_fourteen}
                  onChange={(e) =>
                    setFormData({ ...formData, box_fourteen: e.target.checked })
                  }
                />
                <label>Tested PreOp</label>
                <label>ID#</label>
                <input
                  type="text"
                  className="di-input"
                  name="Text30"
                  value={formData.Text30}
                  onChange={(e) =>
                    setFormData({ ...formData, Text30: e.target.value })
                  }
                />
                <label>Checked By</label>
                <input
                  type="text"
                  className="di-input"
                  name="Text31"
                  value={formData.Text31}
                  onChange={(e) =>
                    setFormData({ ...formData, Text31: e.target.value })
                  }
                />
                <br /> <label>Applied By</label>
                <input
                  type="text"
                  className="di-input"
                  name="Text32"
                  value={formData.Text32}
                  onChange={(e) =>
                    setFormData({ ...formData, Text32: e.target.value })
                  }
                />
                <label>Site</label>
                <input
                  type="text"
                  className="di-input"
                  name="Text33"
                  value={formData.Text33}
                  onChange={(e) =>
                    setFormData({ ...formData, Text33: e.target.value })
                  }
                />
                <label>mmHg</label>
                <input
                  type="text"
                  className="di-input"
                  name="Text34"
                  value={formData.Text34}
                  onChange={(e) =>
                    setFormData({ ...formData, Text34: e.target.value })
                  }
                />
              </div>
              <div className="label-input">
                <div className="arrow-container">
                  <Icon icon="majesticons:arrow-up" width="24" height="24" />
                  <Icon icon="majesticons:arrow-down" width="24" height="24" />
                  <Icon icon="majesticons:arrow-up" width="24" height="24" />
                  <Icon icon="majesticons:arrow-down" width="24" height="24" />
                </div>
                <div className="form-divider"></div>
                <div>
                  <p className="label-heading header">VISUALS</p>
                </div>
                <div className="label-indent">
                  <input
                    type="checkbox"
                    name="box_twelve"
                    checked={formData.box_twelve}
                    onChange={(e) =>
                      setFormData({ ...formData, box_twelve: e.target.checked })
                    }
                  />
                  <label>None</label>
                  <input
                    type="checkbox"
                    name="box_eleven"
                    checked={formData.box_eleven}
                    onChange={(e) =>
                      setFormData({ ...formData, box_eleven: e.target.checked })
                    }
                  />
                  <label>Photographs</label>
                  <input
                    type="checkbox"
                    name="box_thirteen"
                    checked={formData.box_thirteen}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        box_thirteen: e.target.checked,
                      })
                    }
                  />
                  <label>Video Tape</label>
                  <label>Video/Photos to:</label>
                  <input
                    type="checkbox"
                    name="box_eight"
                    checked={formData.box_eight}
                    onChange={(e) =>
                      setFormData({ ...formData, box_eight: e.target.checked })
                    }
                  />
                  <label>MD</label>
                  <input
                    type="checkbox"
                    name="box_nine"
                    checked={formData.box_nine}
                    onChange={(e) =>
                      setFormData({ ...formData, box_nine: e.target.checked })
                    }
                  />
                  <label>Patient</label>
                  <input
                    type="checkbox"
                    name="box_ten"
                    checked={formData.box_ten}
                    onChange={(e) =>
                      setFormData({ ...formData, box_ten: e.target.checked })
                    }
                  />
                  <label>Chart</label>
                </div>
                <div className="label-indent">
                  <label>OR #</label>
                  <input
                    type="text"
                    className="di-input"
                    name="OR"
                    value={formData.OR}
                    onChange={(e) =>
                      setFormData({ ...formData, OR: e.target.value })
                    }
                  />
                  <label>Time In Room</label>
                  <input
                    type="text"
                    className="di-input"
                    name="time_in"
                    value={formData.time_in}
                    onChange={(e) =>
                      setFormData({ ...formData, time_in: e.target.value })
                    }
                  />
                  <label>Anesthesia Start</label>
                  <input
                    type="text"
                    className="di-input"
                    name="anesthesia_start"
                    value={formData.anesthesia_start}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        anesthesia_start: e.target.value,
                      })
                    }
                  />
                  <label>Surgery Start</label>
                  <input
                    type="text"
                    className="di-input"
                    name="surgery_start"
                    value={formData.surgery_start}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        surgery_start: e.target.value,
                      })
                    }
                  />
                  <label>Surgery End</label>
                  <input
                    type="text"
                    className="di-input"
                    name="surgery_end"
                    value={formData.surgery_end}
                    onChange={(e) =>
                      setFormData({ ...formData, surgery_end: e.target.value })
                    }
                  />
                  <label>Anethesia End</label>
                  <input
                    type="text"
                    className="di-input"
                    name="anesthesia_end"
                    value={formData.anesthesia_end}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        anesthesia_end: e.target.value,
                      })
                    }
                  />
                  <label>Time Out of Room</label>
                  <input
                    type="text"
                    className="di-input"
                    name="time_out"
                    value={formData.time_out}
                    onChange={(e) =>
                      setFormData({ ...formData, time_out: e.target.value })
                    }
                  />
                </div>
                <div className="label-indent">
                  <label>Pre-Op Diagnosis</label>
                  <textarea
                    name="preop_diagnosis_text"
                    value={formData.preop_diagnosis_text}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        preop_diagnosis_text: e.target.value,
                      })
                    }
                  />
                </div>
                <div className="label-indent">
                  <label>Procedure</label>
                  <textarea
                    name="procedure"
                    value={formData.procedure}
                    onChange={(e) =>
                      setFormData({ ...formData, procedure: e.target.value })
                    }
                  />
                </div>
                <div className="label-indent">
                  <div className="flex-text">
                    <div>
                      <label>Post-Op Diagnosis</label>
                    </div>{" "}
                    <div>
                      <input
                        type="checkbox"
                        name="pending_pathology"
                        checked={formData.pending_pathology}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            pending_pathology: e.target.checked,
                          })
                        }
                      />
                      <label>Pending Pathology Report</label>
                    </div>{" "}
                  </div>
                  <textarea
                    name="preop_diagnosis"
                    value={formData.preop_diagnosis}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        preop_diagnosis: e.target.value,
                      })
                    }
                  />
                </div>
              </div>{" "}
              <div>
                <p className="label-heading header">ANESTHESIA</p>
              </div>
              <div className="label-indent">
                <input
                  type="checkbox"
                  name="anesthesia_gen"
                  checked={formData.anesthesia_gen}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      anesthesia_gen: e.target.checked,
                    })
                  }
                />
                <label>Gen'l</label>
                <input
                  type="checkbox"
                  name="anesthesia_mac"
                  checked={formData.anesthesia_mac}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      anesthesia_mac: e.target.checked,
                    })
                  }
                />
                <label>MAC</label>
                <input
                  type="checkbox"
                  name="anesthesia_local"
                  checked={formData.anesthesia_local}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      anesthesia_local: e.target.checked,
                    })
                  }
                />
                <label>Local</label>
                <input
                  type="checkbox"
                  name="anesthesia_block"
                  checked={formData.anesthesia_block}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      anesthesia_block: e.target.checked,
                    })
                  }
                />
                <label>Block</label>
                <label>Type</label>
                <input
                  type="text"
                  className="di-input"
                  name="block_type"
                  value={formData.block_type}
                  onChange={(e) =>
                    setFormData({ ...formData, block_type: e.target.value })
                  }
                />
                <input
                  type="checkbox"
                  name="intubated"
                  checked={formData.intubated}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      intubated: e.target.checked,
                    })
                  }
                />
                <label>Intubated</label>
              </div>
              <div>
                <p className="label-heading header">ANESTHESIA AGENTS</p>
              </div>
              <div className="label-indent">
                <label>See Anesthesia Record</label>
                <input
                  type="text"
                  name="anesthesia_record"
                  value={formData.anesthesia_record}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      anesthesia_record: e.target.value,
                    })
                  }
                />
                <input
                  type="checkbox"
                  name="anesthesia_lma"
                  checked={formData.anesthesia_lma}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      anesthesia_lma: e.target.checked,
                    })
                  }
                />
                <label>LMA</label>
                <input
                  type="checkbox"
                  name="masked"
                  checked={formData.masked}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      masked: e.target.checked,
                    })
                  }
                />
                <label>Masked</label>
              </div>
              <div>
                <p className="label-heading header">COMPLICATIONS</p>
              </div>
              <div className="label-indent">
                <input
                  type="checkbox"
                  name="complications_none"
                  checked={formData.complications_none}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      complications_none: e.target.checked,
                    })
                  }
                />
                <label>None</label>
                <input
                  type="checkbox"
                  name="complications_yes"
                  checked={formData.complications_yes}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      complications_yes: e.target.checked,
                    })
                  }
                />
                <label>Yes</label>
                <input
                  type="text"
                  className="di-input"
                  name="complications_text"
                  value={formData.complications_text}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      complications_text: e.target.value,
                    })
                  }
                />
              </div>
              <p className="label-heading">ANESTHESIOLOGIST</p>
              <input
                className="di-input"
                type="text"
                name="anesthesiologist"
                value={formData.anesthesiologist}
                onChange={(e) =>
                  setFormData({ ...formData, anesthesiologist: e.target.value })
                }
              />
              <div>
                <p className="label-heading header">PROCEDURE 1</p>
              </div>
              <div></div>
              <div className="label-indent">
                <label>SURGEON</label>
                <input
                  type="text"
                  className="di-input"
                  name="procedure_one_surgeon"
                  value={formData.procedure_one_surgeon}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      procedure_one_surgeon: e.target.value,
                    })
                  }
                />
                <label>1ST ASSIST</label>
                <input
                  type="text"
                  className="di-input"
                  name="procedure_one"
                  value={formData.procedure_one}
                  onChange={(e) =>
                    setFormData({ ...formData, procedure_one: e.target.value })
                  }
                />
              </div>
              <div>
                <p className="label-heading">OTHERS</p>{" "}
                <input
                  type="text"
                  className="di-input"
                  name="others"
                  value={formData.others}
                  onChange={(e) =>
                    setFormData({ ...formData, others: e.target.value })
                  }
                />
                <p className="label-heading header">PROCEDURE 2</p>
              </div>
              <div></div>
              <div className="label-indent">
                <label>SURGEON</label>
                <input
                  type="text"
                  className="di-input"
                  name="procedure_two_surgeon"
                  value={formData.procedure_two_surgeon}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      procedure_two_surgeon: e.target.value,
                    })
                  }
                />
                <label>1ST ASSIST</label>
                <input
                  type="text"
                  className="di-input"
                  name="procedure_two"
                  value={formData.procedure_two}
                  onChange={(e) =>
                    setFormData({ ...formData, procedure_two: e.target.value })
                  }
                />
              </div>
            </div>
          </div>
          <div className="label-indent">
            <div className="counts-container">
              <div className="counts-item">CIRCULATING RN</div>
              <div className="counts-item">IN/OUT</div>
              <div className="counts-item">IN/OUT</div>
              <div className="counts-item">IN/OUT</div>
              <div className="counts-item">SCRUB RN/LVN/ORT</div>
              <div className="counts-item">IN/OUT</div>
              <div className="counts-item">IN/OUT</div>
              <div className="counts-item">IN/OUT</div>

              <div className="counts-item">
                <input
                  className="counts-input"
                  name="circulating_rn_one"
                  value={formData.circulating_rn_one}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      circulating_rn_one: e.target.value,
                    })
                  }
                />
              </div>
              <div className="counts-item">
                <input
                  className="counts-input"
                  name="inout_a"
                  value={formData.inout_a}
                  onChange={(e) =>
                    setFormData({ ...formData, inout_a: e.target.value })
                  }
                />
              </div>
              <div className="counts-item">
                <input
                  className="counts-input"
                  name="inout_b_one"
                  value={formData.inout_b_one}
                  onChange={(e) =>
                    setFormData({ ...formData, inout_b_one: e.target.value })
                  }
                />
              </div>
              <div className="counts-item">
                <input
                  className="counts-input"
                  name="inout_c_one"
                  value={formData.inout_c_one}
                  onChange={(e) =>
                    setFormData({ ...formData, inout_c_one: e.target.value })
                  }
                />
              </div>
              <div className="counts-item">
                <input
                  className="counts-input"
                  name="scrub_rn_one"
                  value={formData.scrub_rn_one}
                  onChange={(e) =>
                    setFormData({ ...formData, scrub_rn_one: e.target.value })
                  }
                />
              </div>
              <div className="counts-item">
                <input
                  className="counts-input"
                  name="inout_d_one"
                  value={formData.inout_d_one}
                  onChange={(e) =>
                    setFormData({ ...formData, inout_d_one: e.target.value })
                  }
                />
              </div>
              <div className="counts-item">
                <input
                  className="counts-input"
                  name="inout_e_one"
                  value={formData.inout_e_one}
                  onChange={(e) =>
                    setFormData({ ...formData, inout_e_one: e.target.value })
                  }
                />
              </div>
              <div className="counts-item">
                {" "}
                <input
                  className="counts-input"
                  name="inout_f_one"
                  value={formData.inout_f_one}
                  onChange={(e) =>
                    setFormData({ ...formData, inout_f_one: e.target.value })
                  }
                />
              </div>
              <div className="counts-item">
                <input
                  className="counts-input"
                  name="circulating_rn_two"
                  value={formData.circulating_rn_two}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      circulating_rn_two: e.target.value,
                    })
                  }
                />
              </div>
              <div className="counts-item">
                <input
                  className="counts-input"
                  name="inout_a_one"
                  value={formData.inout_a_one}
                  onChange={(e) =>
                    setFormData({ ...formData, inout_a_one: e.target.value })
                  }
                />
              </div>
              <div className="counts-item">
                <input
                  className="counts-input"
                  name="inout_b_two"
                  value={formData.inout_b_two}
                  onChange={(e) =>
                    setFormData({ ...formData, inout_b_two: e.target.value })
                  }
                />
              </div>
              <div className="counts-item">
                <input
                  className="counts-input"
                  name="inout_c_two"
                  value={formData.inout_c_two}
                  onChange={(e) =>
                    setFormData({ ...formData, inout_c_two: e.target.value })
                  }
                />
              </div>
              <div className="counts-item">
                <input
                  className="counts-input"
                  name="scrub_rn_two"
                  value={formData.scrub_rn_two}
                  onChange={(e) =>
                    setFormData({ ...formData, scrub_rn_two: e.target.value })
                  }
                />
              </div>
              <div className="counts-item">
                <input
                  className="counts-input"
                  name="inout_d_two"
                  value={formData.inout_d_two}
                  onChange={(e) =>
                    setFormData({ ...formData, inout_d_two: e.target.value })
                  }
                />
              </div>
              <div className="counts-item">
                <input
                  className="counts-input"
                  name="inout_e_two"
                  value={formData.inout_e_two}
                  onChange={(e) =>
                    setFormData({ ...formData, inout_e_two: e.target.value })
                  }
                />
              </div>
              <div className="counts-item">
                {" "}
                <input
                  className="counts-input"
                  name="inout_f_two"
                  value={formData.inout_f_two}
                  onChange={(e) =>
                    setFormData({ ...formData, inout_f_two: e.target.value })
                  }
                />
              </div>
              <div className="counts-item">
                <input
                  className="counts-input"
                  name="circulating_rn_three"
                  value={formData.circulating_rn_three}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      circulating_rn_three: e.target.value,
                    })
                  }
                />
              </div>
              <div className="counts-item">
                <input
                  className="counts-input"
                  name="inout_a_three"
                  value={formData.inout_a_three}
                  onChange={(e) =>
                    setFormData({ ...formData, inout_a_three: e.target.value })
                  }
                />
              </div>
              <div className="counts-item">
                <input
                  className="counts-input"
                  name="inout_b_three"
                  value={formData.inout_b_three}
                  onChange={(e) =>
                    setFormData({ ...formData, inout_b_three: e.target.value })
                  }
                />
              </div>
              <div className="counts-item">
                <input
                  className="counts-input"
                  name="inout_c_three"
                  value={formData.inout_c_three}
                  onChange={(e) =>
                    setFormData({ ...formData, inout_c_three: e.target.value })
                  }
                />
              </div>
              <div className="counts-item">
                <input
                  className="counts-input"
                  name="scrub_rn_three"
                  value={formData.scrub_rn_three}
                  onChange={(e) =>
                    setFormData({ ...formData, scrub_rn_three: e.target.value })
                  }
                />
              </div>
              <div className="counts-item">
                <input
                  className="counts-input"
                  name="inout_d_three"
                  value={formData.inout_d_three}
                  onChange={(e) =>
                    setFormData({ ...formData, inout_d_three: e.target.value })
                  }
                />
              </div>
              <div className="counts-item">
                <input
                  className="counts-input"
                  name="inout_e_three"
                  value={formData.inout_e_three}
                  onChange={(e) =>
                    setFormData({ ...formData, inout_e_three: e.target.value })
                  }
                />
              </div>
              <div className="counts-item">
                <input
                  className="counts-input"
                  name="inout_f_three"
                  value={formData.inout_f_three}
                  onChange={(e) =>
                    setFormData({ ...formData, inout_f_three: e.target.value })
                  }
                />
              </div>
            </div>
            <div className="pir-container">
              <div className="pi-item">IRRIGATION/MEDICATION</div>
              <div className="pi-item">AMOUNT</div>
              <div className="pi-item">METHOD</div>
              <div className="pi-item">
                {" "}
                <input
                  className="counts-input"
                  name="irrigation_one"
                  value={formData.irrigation_one}
                  onChange={(e) =>
                    setFormData({ ...formData, irrigation_one: e.target.value })
                  }
                />
              </div>
              <div className="pi-item">
                {" "}
                <input
                  className="counts-input"
                  name="amount_one"
                  value={formData.amount_one}
                  onChange={(e) =>
                    setFormData({ ...formData, amount_one: e.target.value })
                  }
                />
              </div>
              <div className="pi-item">
                {" "}
                <input
                  className="counts-input"
                  name="method_one"
                  value={formData.method_one}
                  onChange={(e) =>
                    setFormData({ ...formData, method_one: e.target.value })
                  }
                />
              </div>
              <div className="pi-item">
                {" "}
                <input
                  className="counts-input"
                  name="irrigation_two"
                  value={formData.irrigation_two}
                  onChange={(e) =>
                    setFormData({ ...formData, irrigation_two: e.target.value })
                  }
                />
              </div>
              <div className="pi-item">
                {" "}
                <input
                  className="counts-input"
                  name="amount_two"
                  value={formData.amount_two}
                  onChange={(e) =>
                    setFormData({ ...formData, amount_two: e.target.value })
                  }
                />
              </div>
              <div className="pi-item">
                {" "}
                <input
                  className="counts-input"
                  name="method_two"
                  value={formData.method_two}
                  onChange={(e) =>
                    setFormData({ ...formData, method_two: e.target.value })
                  }
                />
              </div>
              <div className="pi-item">
                {" "}
                <input
                  className="counts-input"
                  name="irrigation_three"
                  value={formData.irrigation_three}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      irrigation_three: e.target.value,
                    })
                  }
                />
              </div>
              <div className="pi-item">
                {" "}
                <input
                  className="counts-input"
                  name="amount_three"
                  value={formData.amount_three}
                  onChange={(e) =>
                    setFormData({ ...formData, amount_three: e.target.value })
                  }
                />
              </div>
              <div className="pi-item">
                {" "}
                <input
                  className="counts-input"
                  name="method_three"
                  value={formData.method_three}
                  onChange={(e) =>
                    setFormData({ ...formData, method_three: e.target.value })
                  }
                />
              </div>
              <div className="pi-item">
                {" "}
                <input
                  className="counts-input"
                  name="irrigation_four"
                  value={formData.irrigation_four}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      irrigation_four: e.target.value,
                    })
                  }
                />
              </div>
              <div className="pi-item">
                {" "}
                <input
                  className="counts-input"
                  name="amount_four"
                  value={formData.amount_four}
                  onChange={(e) =>
                    setFormData({ ...formData, amount_four: e.target.value })
                  }
                />
              </div>
              <div className="pi-item">
                {" "}
                <input
                  className="counts-input"
                  name="method_four"
                  value={formData.method_four}
                  onChange={(e) =>
                    setFormData({ ...formData, method_four: e.target.value })
                  }
                />
              </div>
              <div className="pi-item">
                {" "}
                <input
                  className="counts-input"
                  name="irrigation_five"
                  value={formData.irrigation_five}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      irrigation_five: e.target.value,
                    })
                  }
                />
              </div>
              <div className="pi-item">
                {" "}
                <input
                  className="counts-input"
                  name="amount_five"
                  value={formData.amount_five}
                  onChange={(e) =>
                    setFormData({ ...formData, amount_five: e.target.value })
                  }
                />
              </div>
              <div className="pi-item">
                {" "}
                <input
                  className="counts-input"
                  name="method_five"
                  value={formData.method_five}
                  onChange={(e) =>
                    setFormData({ ...formData, method_five: e.target.value })
                  }
                />
              </div>
            </div>
            <div>
              <div className="flex-text">
                <div>
                  <label className="header">SPECIMENS SENT TO PATHOLOGY</label>
                  <br />
                  <input
                    type="checkbox"
                    name="specimens_none"
                    checked={formData.specimens_none}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        specimens_none: e.target.checked,
                      })
                    }
                  />
                  <label>None</label>
                  <input
                    type="checkbox"
                    name="specimens_yes"
                    checked={formData.specimens_yes}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        specimens_yes: e.target.checked,
                      })
                    }
                  />
                  <label>Yes</label>
                  <input
                    type="checkbox"
                    name="specimens_fs"
                    checked={formData.specimens_fs}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        specimens_fs: e.target.checked,
                      })
                    }
                  />
                  <label>FS</label>
                  <input
                    type="checkbox"
                    name="specimens_xray"
                    checked={formData.specimens_xray}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        specimens_xray: e.target.checked,
                      })
                    }
                  />
                  <label>X-Ray</label>
                </div>{" "}
                <div>
                  <label className="header">CULTURES</label>
                  <br />
                  <input
                    type="checkbox"
                    name="cultures_none"
                    checked={formData.cultures_none}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        cultures_none: e.target.checked,
                      })
                    }
                  />
                  <label>None</label>
                  <input
                    type="checkbox"
                    name="cultures_aerobic"
                    checked={formData.cultures_aerobic}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        cultures_aerobic: e.target.checked,
                      })
                    }
                  />
                  <label>Aerobic</label>
                  <input
                    type="checkbox"
                    name="cultures_anaerobic"
                    checked={formData.cultures_anaerobic}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        cultures_anaerobic: e.target.checked,
                      })
                    }
                  />
                  <label>Anaerobic</label>
                </div>{" "}
              </div>
              <textarea
                name="specimens_sent"
                value={formData.specimens_sent}
                onChange={(e) =>
                  setFormData({ ...formData, specimens_sent: e.target.value })
                }
              />
              <div>
                <p className="title-text-h3">EVALUATION</p>
              </div>
              <div>
                <h4>OUTCOMES </h4>
                <div className="label-indent"></div>
              </div>
            </div>
            <div className="outcome-container">
              <div className="outcome-item">
                I. Patient verbalized/indicated understanding of procedure and
                sequence of events to expect before and immediately after
                surgery
              </div>
              <div className="outcome-item">
                <input
                  type="checkbox"
                  name="outcomes_one_met"
                  checked={formData.outcomes_one_met}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      outcomes_one_met: e.target.checked,
                    })
                  }
                />
                <label>Met</label>
              </div>
              <div className="outcome-item">
                <input
                  type="checkbox"
                  name="outcomes_one_notmet"
                  checked={formData.outcomes_one_notmet}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      outcomes_one_notmet: e.target.checked,
                    })
                  }
                />
                <label>Not Met</label>
              </div>
              <div className="outcome-item">
                II. Patient's surgery performed using aseptic practices in a
                manner to prevent cross contamination
              </div>
              <div className="outcome-item">
                {" "}
                <input
                  type="checkbox"
                  name="outcomes_two_met"
                  checked={formData.outcomes_two_met}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      outcomes_two_met: e.target.checked,
                    })
                  }
                />
                <label>Met</label>
              </div>
              <div className="outcome-item">
                {" "}
                <input
                  type="checkbox"
                  name="outcomes_two_notmet"
                  checked={formData.outcomes_two_notmet}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      outcomes_two_notmet: e.target.checked,
                    })
                  }
                />
                <label>Not Met</label>
              </div>
              <div className="outcome-item">
                III. Patient's skin remained smooth, intact, non-reddened,
                nonirritated and free or bruising. Sensation, motion and
                functions maintained/improved from baseline.{" "}
              </div>
              <div className="outcome-item">
                {" "}
                <input
                  type="checkbox"
                  name="outcomes_three_met"
                  checked={formData.outcomes_three_met}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      outcomes_three_met: e.target.checked,
                    })
                  }
                />
                <label>Met</label>
              </div>
              <div className="outcome-item">
                {" "}
                <input
                  type="checkbox"
                  name="outcomes_three_notmet"
                  checked={formData.outcomes_three_notmet}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      outcomes_three_notmet: e.target.checked,
                    })
                  }
                />
                <label>Not Met</label>
              </div>
            </div>
            <div className="label-indent">
              <label>Discharged to PACU Via</label>
              <input
                type="checkbox"
                name="discharged_gurney"
                checked={formData.discharged_gurney}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    discharged_gurney: e.target.checked,
                  })
                }
              />
              <label>Gurney</label>
              <input
                type="checkbox"
                name="discharged_wheelchair"
                checked={formData.discharged_wheelchair}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    discharged_wheelchair: e.target.checked,
                  })
                }
              />
              <label>Wheelchair</label>
              <label>Report Given to</label>
              <input
                type="text"
                className="di-input"
                name="discharged_report_given"
                value={formData.discharged_report_given}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    discharged_report_given: e.target.value,
                  })
                }
              />
            </div>
            <div className="label-indent">
              <label>NOTES</label>
              <textarea
                name="discharged_notes"
                value={formData.discharged_notes}
                onChange={(e) =>
                  setFormData({ ...formData, discharged_notes: e.target.value })
                }
              />
              <div className="grid-2">
                <div className="signature-container mt-50">
                  {/* <SignatureButton
                    individualSignature={notPatientSignature}
                    setIndividualSignature={(file) => {
                      console.log("Captured signature:", file);
                      setNotPatientSignature(file);
                    }}
                  /> */}
                  {/* <hr className="signature-line"></hr>
                  <p>R.N. Signature</p> */}
                </div>
              </div>
            </div>
          </div>
          <div className="center mt-50">
            <button className="btn-primary hover-pointer" type="submit">
              Submit
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default IntraOpNursingRecord;
