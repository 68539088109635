import React, { useState, useEffect } from "react";
import { Icon } from "@iconify/react";
import "../Forms/styles.css";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import moment from "moment";
import {
  addFile,
  getPreandPostOpAE,
  updatePreandPostOpAE,
} from "../../../utils/api";
import SignatureButton from "../../../components/SignatureButton";

const PreandPostOpAE = () => {
  const navigate = useNavigate();
  const { form_id } = useParams();
  const [loading, setLoading] = useState(true);
  const [individualSignature, setIndividualSignature] = useState("");
  const [notPatientSignature, setNotPatientSignature] = useState("");

  const [formData, setFormData] = useState({
    healthy: false,
    meds_none: false,
    no_known: false,
    asa_iii: false,
    plan_ga: false,
    plan_mac: false,
    plan_epidural: false,
    plan_spinal: false,
    past_anesthesia: false,
    procedures: false,
    patient_stable: false,
    patient_intubated: false,
    anesthesia_yes: false,
    anesthesia_no: false,
    nasal: false,
    mask: false,
    tpiece: false,
    oral_nasal: false,
    other_two: false,
    proposed: "",
    past_med: "",
    meds: "",
    allergies: "",
    past_surgical: "",
    cardiac: "",
    lungs: "",
    other: "",
    height: "",
    weight: "",
    age_two: "",
    vital_yes: false,
    vital_no: false,
    airway_normal: false,
    asa_i: false,
    asa_ii: false,
    asa_iv: false,
    patient_drowsy: false,
    patient_unstable: false,
    patient_somnolent: false,
    patient_unarousable: false,
    bp: "",
    p: "",
    r: "",
    sp0_two: "",
    temp: "",
    other_text_two: "",
    post_note: "",
    anesthesiologist: "",
    date_two: "",
    time_two: "",
    date: "",
    time: "",
    labs: "",
    air_text: "",
    female: false,
    male: false,
    awake: false,
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    let patientFileName, formCompleterFileName;

    if (notPatientSignature !== "") {
      console.log("Uploading form completer signature:", notPatientSignature);
    }

    // Fetch 1: Upload Signature
    if (individualSignature !== "") {
      const response = await addFile(individualSignature);

      const data = await response.json();
      patientFileName = data.fileName;
    }
    if (notPatientSignature !== "") {
      const response = await addFile(notPatientSignature);
      const data = await response.json();
      console.log("Uploaded form completer signature:", data.fileName);
      formCompleterFileName = data.fileName || null;
    }
    const payload = {
      ...formData,
      patient_signature: patientFileName,
      form_completer_signature: formCompleterFileName,
      date: moment().format("MM/DD/YYYY"),
      time: moment().format("h:mm a"),
      date_two: moment().format("MM/DD/YYYY"),
      time_two: moment().format("h:mm a"),
      status: "In Progress",
    };

    console.log("Payload Sent to Backend:", payload);

    // Fetch 2: Create/Update the Disclosure of Ownerhsip Model

    updatePreandPostOpAE(form_id, payload)
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        console.log("complete");
        navigate(-1);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getPreandPostOpAE(form_id)
      .then((res) => res.json())
      .then((data) => {
        console.log(data);

        if (data.status !== "Not Started") {
          setFormData({
            healthy: data.healthy || false,
            meds_none: data.meds_none || false,
            no_known: data.no_known || false,
            asa_iii: data.asa_iii || false,
            plan_ga: data.plan_ga || false,
            plan_mac: data.plan_mac || false,
            plan_epidural: data.plan_epidural || false,
            plan_spinal: data.plan_spinal || false,
            past_anesthesia: data.past_anesthesia || false,
            procedures: data.procedures || false,
            patient_stable: data.patient_stable || false,
            patient_intubated: data.patient_intubated || false,
            anesthesia_yes: data.anesthesia_yes || false,
            anesthesia_no: data.anesthesia_no || false,
            nasal: data.nasal || false,
            mask: data.mask || false,
            tpiece: data.tpiece || false,
            oral_nasal: data.oral_nasal || false,
            other_two: data.other_two || false,
            proposed: data.proposed || "",
            past_med: data.past_med || "",
            meds: data.meds || "",
            allergies: data.allergies || "",
            past_surgical: data.past_surgical || "",
            cardiac: data.cardiac || "",
            lungs: data.lungs || "",
            other: data.other || "",
            height: data.height || "",
            weight: data.weight || "",
            age_two: data.age_two || "",
            vital_yes: data.vital_yes || false,
            vital_no: data.vital_no || false,
            airway_normal: data.airway_normal || false,
            asa_i: data.asa_i || false,
            asa_ii: data.asa_ii || false,
            asa_iv: data.asa_iv || false,
            patient_drowsy: data.patient_drowsy || false,
            patient_unstable: data.patient_unstable || false,
            patient_somnolent: data.patient_somnolent || false,
            patient_unarousable: data.patient_unarousable || false,
            bp: data.bp || "",
            air_text: data.air_text || "",
            p: data.p || "",
            r: data.r || "",
            sp0_two: data.sp0_two || "",
            temp: data.temp || "",
            other_text_two: data.other_text_two || "",
            post_note: data.post_note || "",
            anesthesiologist: data.anesthesiologist || "",
            date_two: data.date_two || "",
            time_two: data.time_two || "",
            date: data.date || "",
            time: data.time || "",
            labs: data.labs || "",
            female: data.female || false,
            male: data.male || false,
            awake: data.awake || false,
          });
          setIndividualSignature(data.patient_signature || "");
          setNotPatientSignature(data.form_completer_signature || "");
        }
        setLoading(false);
      })
      .catch((err) => console.log(err));
  }, []);
  if (loading) return <p>Loading...</p>;
  return (
    <>
      <div
        className="arrow-container"
        onClick={() => navigate(-1)}
        style={{ cursor: "pointer" }}
      >
        <p className="back-arrow">
          <Icon icon="ic:twotone-arrow-back" className="icon" /> Back to Forms
        </p>
      </div>{" "}
      <div className="form-content">
        <form onSubmit={handleSubmit}>
          {" "}
          <h2 className="form-title">Pre and Post Anesthetic Evaluation</h2>
          <div className="label-indent">
            <label>Proposed Operation:</label>
            <br />
            <textarea
              name="proposed"
              value={formData.proposed}
              onChange={(e) =>
                setFormData({ ...formData, proposed: e.target.value })
              }
            />
          </div>
          <div className="label-indent">
            <label>Past Medical History/Review of Systems:</label>
            <input
              type="checkbox"
              name="healthy"
              checked={formData.healthy}
              onChange={(e) =>
                setFormData({ ...formData, healthy: e.target.checked })
              }
            />
            <label>Healthy</label>
            <br />
            <textarea
              name="past_med"
              value={formData.past_med}
              onChange={(e) =>
                setFormData({ ...formData, past_med: e.target.value })
              }
            />
          </div>
          <div className="label-indent">
            <label>Medications:</label>
            <input
              type="checkbox"
              name="meds_none"
              checked={formData.meds_none}
              onChange={(e) =>
                setFormData({ ...formData, meds_none: e.target.checked })
              }
            />
            <label>None</label>
            <br />
            <textarea
              name="meds"
              value={formData.meds}
              onChange={(e) =>
                setFormData({ ...formData, meds: e.target.value })
              }
            />
          </div>
          <div className="label-indent">
            <label>Allergies:</label>
            <input
              type="checkbox"
              name="no_known"
              checked={formData.no_known}
              onChange={(e) =>
                setFormData({ ...formData, no_known: e.target.checked })
              }
            />
            <label>No known allergies, including latex</label>
            <br />
            <textarea
              name="allergies"
              value={formData.allergies}
              onChange={(e) =>
                setFormData({ ...formData, allergies: e.target.value })
              }
            />
          </div>
          <div className="label-indent">
            <label>Past Surgical History:</label>
            <input
              type="checkbox"
              name="past_anesthesia"
              checked={formData.past_anesthesia}
              onChange={(e) =>
                setFormData({ ...formData, past_anesthesia: e.target.checked })
              }
            />
            <label>Past Anesthesia, No complications</label>
            <br />
            <textarea
              name="past_surgical"
              value={formData.past_surgical}
              onChange={(e) =>
                setFormData({ ...formData, past_surgical: e.target.value })
              }
            />
          </div>
          <div className="label-indent">
            <label>Physical Exam</label>
            <br />
            <label>Height:</label>
            <input
              type="text"
              className="post-op-input"
              name="height"
              value={formData.height}
              onChange={(e) =>
                setFormData({ ...formData, height: e.target.value })
              }
            />
            <label>Weight:</label>
            <input
              type="text"
              className="post-op-input"
              name="weight"
              value={formData.weight}
              onChange={(e) =>
                setFormData({ ...formData, weight: e.target.value })
              }
            />
            <label>Age:</label>
            <input
              type="text"
              className="post-op-input"
              name="age_two"
              value={formData.age_two}
              onChange={(e) =>
                setFormData({ ...formData, age_two: e.target.value })
              }
            />
            <label>Sex</label>
            <input
              type="checkbox"
              name="female"
              checked={formData.female}
              onChange={(e) =>
                setFormData({ ...formData, female: e.target.checked })
              }
            />
            <label>F</label>
            <input
              type="checkbox"
              name="male"
              checked={formData.male}
              onChange={(e) =>
                setFormData({ ...formData, male: e.target.checked })
              }
            />
            <label>M</label>
            <br />
            <label>Airway:</label>
            <input
              type="checkbox"
              name="airway_normal"
              checked={formData.airway_normal}
              onChange={(e) =>
                setFormData({ ...formData, airway_normal: e.target.checked })
              }
            />
            <label>Normal Airway</label>{" "}
            <input
              type="text"
              className="di-input"
              name="air_text"
              value={formData.air_text}
              onChange={(e) =>
                setFormData({ ...formData, air_text: e.target.value })
              }
            />
            <br />
            <label>Cardiac:</label>{" "}
            <input
              type="text"
              className="bottom-border-input"
              name="cardiac"
              value={formData.cardiac}
              onChange={(e) =>
                setFormData({ ...formData, cardiac: e.target.value })
              }
            />
            <br />
            <label>Lungs:</label>{" "}
            <input
              type="text"
              className="bottom-border-input"
              name="lungs"
              value={formData.lungs}
              onChange={(e) =>
                setFormData({ ...formData, lungs: e.target.value })
              }
            />
            <br />
            <label>Other:</label>{" "}
            <input
              type="text"
              className="bottom-border-input"
              name="other"
              value={formData.other}
              onChange={(e) =>
                setFormData({ ...formData, other: e.target.value })
              }
            />
          </div>
          <div className="label-indent">
            <label>Labs/ECG</label>
          </div>
          <div className="label-indent">
            <label>ASA Status </label>
            <input
              type="checkbox"
              name="asa_i"
              checked={formData.asa_i}
              onChange={(e) =>
                setFormData({ ...formData, asa_i: e.target.checked })
              }
            />
            <label>I</label>
            <input
              type="checkbox"
              name="asa_ii"
              checked={formData.asa_ii}
              onChange={(e) =>
                setFormData({ ...formData, asa_ii: e.target.checked })
              }
            />
            <label>II</label>
            <input
              type="checkbox"
              name="asa_iii"
              checked={formData.asa_iii}
              onChange={(e) =>
                setFormData({ ...formData, asa_iii: e.target.checked })
              }
            />
            <label>III</label>
            <input
              type="checkbox"
              name="asa_iv"
              checked={formData.asa_iv}
              onChange={(e) =>
                setFormData({ ...formData, asa_iv: e.target.checked })
              }
            />
            <label>IV</label>
          </div>
          <div className="label-indent">
            <label>Plan:</label>
            <input
              type="checkbox"
              name="plan_ga"
              checked={formData.plan_ga}
              onChange={(e) =>
                setFormData({ ...formData, plan_ga: e.target.checked })
              }
            />
            <label>GA</label>
            <input
              type="checkbox"
              name="plan_mac"
              checked={formData.plan_mac}
              onChange={(e) =>
                setFormData({ ...formData, plan_mac: e.target.checked })
              }
            />
            <label>MAC</label>
            <input
              type="checkbox"
              name="plan_epidural"
              checked={formData.plan_epidural}
              onChange={(e) =>
                setFormData({ ...formData, plan_epidural: e.target.checked })
              }
            />
            <label>Epidural</label>
            <input
              type="checkbox"
              name="plan_spinal"
              checked={formData.plan_spinal}
              onChange={(e) =>
                setFormData({ ...formData, plan_spinal: e.target.checked })
              }
            />
            <label>Spinal</label>
            <br />
            <input
              type="checkbox"
              name="procedures"
              checked={formData.procedures}
              onChange={(e) =>
                setFormData({ ...formData, procedures: e.target.checked })
              }
            />
            <label>
              Procedures, risks,benefits and alternatives discussed with
              patient. Patient agrees to proceed
            </label>
            <div className="grievance-container">
              {" "}
              <div className="gc-item">
                {" "}
                <SignatureButton
                  individualSignature={individualSignature}
                  setIndividualSignature={setIndividualSignature}
                />
                <hr className="signature-line"></hr>
                <p>Surgeon Signature</p>
              </div>
              <div className="gc-item mt-50">
                {" "}
                <label htmlFor="name" className="hidden">
                  Surgeon Signature
                </label>
                <input
                  type="date"
                  id="name"
                  name="date_3"
                  className="bottom-border-input"
                />
                <p>Date</p>
              </div>
              <div className="gc-item mt-50">
                {" "}
                <label htmlFor="name" className="hidden">
                  Time
                </label>
                <input
                  type="time"
                  id="time"
                  name="time_2"
                  className="bottom-border-input"
                />
                <p>Time</p>
              </div>
            </div>
          </div>
          <div className="text-center">
            <p>
              <b>POST-ANESTHESIA EVALUATION</b>
            </p>
          </div>
          <div className="label-indent">
            <label>BP:</label>
            <input
              type="text"
              className="di-input"
              name="bp"
              value={formData.bp}
              onChange={(e) => setFormData({ ...formData, bp: e.target.value })}
            />

            <label>P:</label>
            <input
              type="text"
              className="di-input"
              name="p"
              value={formData.p}
              onChange={(e) => setFormData({ ...formData, p: e.target.value })}
            />

            <label>R:</label>
            <input
              type="text"
              className="di-input"
              name="r"
              value={formData.r}
              onChange={(e) => setFormData({ ...formData, r: e.target.value })}
            />

            <label>SPO2:</label>
            <input
              type="text"
              className="di-input"
              name="sp0_two"
              value={formData.sp0_two}
              onChange={(e) =>
                setFormData({ ...formData, sp0_two: e.target.value })
              }
            />

            <label>Temp:</label>
            <input
              type="text"
              className="di-input"
              name="temp"
              value={formData.temp}
              onChange={(e) =>
                setFormData({ ...formData, temp: e.target.value })
              }
            />
          </div>
          <div className="label-indent">
            <label>Patient is:</label>
            <input
              type="checkbox"
              name="awake"
              checked={formData.awake}
              onChange={(e) =>
                setFormData({ ...formData, awake: e.target.checked })
              }
            />
            <label>Awake</label>
            <input
              type="checkbox"
              name="patient_drowsy"
              checked={formData.patient_drowsy}
              onChange={(e) =>
                setFormData({ ...formData, patient_drowsy: e.target.checked })
              }
            />
            <label>Drowsy</label>
            <input
              type="checkbox"
              name="patient_somnolent"
              checked={formData.patient_somnolent}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  patient_somnolent: e.target.checked,
                })
              }
            />
            <label>Somnolent</label>
            <input
              type="checkbox"
              name="patient_unarousable"
              checked={formData.patient_unarousable}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  patient_unarousable: e.target.checked,
                })
              }
            />
            <label>Unarousable</label>
            <input
              type="checkbox"
              name="patient_stable"
              checked={formData.patient_stable}
              onChange={(e) =>
                setFormData({ ...formData, patient_stable: e.target.checked })
              }
            />
            <label>Stable</label>
            <input
              type="checkbox"
              name="patient_unstable"
              checked={formData.patient_unstable}
              onChange={(e) =>
                setFormData({ ...formData, patient_unstable: e.target.checked })
              }
            />
            <label>Unstable</label>
            <input
              type="checkbox"
              name="patient_intubated"
              checked={formData.patient_intubated}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  patient_intubated: e.target.checked,
                })
              }
            />
            <label>Intubated</label>
          </div>
          <div className="label-indent">
            <label>Patient has:</label>
            <input
              type="checkbox"
              name="nasal"
              checked={formData.nasal}
              onChange={(e) =>
                setFormData({ ...formData, nasal: e.target.checked })
              }
            />
            <label>Nasal O2</label>
            <input
              type="checkbox"
              name="mask"
              checked={formData.mask}
              onChange={(e) =>
                setFormData({ ...formData, mask: e.target.checked })
              }
            />
            <label>Mask O2</label>
            <input
              type="checkbox"
              name="tpiece"
              checked={formData.tpiece}
              onChange={(e) =>
                setFormData({ ...formData, tpiece: e.target.checked })
              }
            />
            <label>T-Piece O2</label>
            <input
              type="checkbox"
              name="oral_nasal"
              checked={formData.oral_nasal}
              onChange={(e) =>
                setFormData({ ...formData, oral_nasal: e.target.checked })
              }
            />
            <label>Oral/Nasal Airway</label>
            <input
              type="checkbox"
              name="other_two"
              checked={formData.other_two}
              onChange={(e) =>
                setFormData({ ...formData, other_two: e.target.checked })
              }
            />
            <label>Other:</label>
            <input
              type="text"
              className="di-input"
              name="other_text_two"
              value={formData.other_text_two}
              onChange={(e) =>
                setFormData({ ...formData, other_text_two: e.target.value })
              }
            />
          </div>
          <div className="label-indent">
            <label>Vital signs stable:</label>
            <input
              type="checkbox"
              name="vital_yes"
              checked={formData.vital_yes}
              onChange={(e) =>
                setFormData({ ...formData, vital_yes: e.target.checked })
              }
            />
            <label>Yes</label>
            <input
              type="checkbox"
              name="vital_no"
              checked={formData.vital_no}
              onChange={(e) =>
                setFormData({ ...formData, vital_no: e.target.checked })
              }
            />
            <label>No</label>
          </div>
          <div className="label-indent">
            <label>Anesthesia Complications:</label>
            <input
              type="checkbox"
              name="anesthesia_yes"
              checked={formData.anesthesia_yes}
              onChange={(e) =>
                setFormData({ ...formData, anesthesia_yes: e.target.checked })
              }
            />
            <label>Yes</label>
            <input
              type="checkbox"
              name="anesthesia_no"
              checked={formData.anesthesia_no}
              onChange={(e) =>
                setFormData({ ...formData, anesthesia_no: e.target.checked })
              }
            />
            <label>No</label>
          </div>
          <div className="label-indent">
            <label>
              Post Anesthesia Note(comments on above, if appropriate):
            </label>
            <textarea
              name="post_note"
              value={formData.post_note}
              onChange={(e) =>
                setFormData({ ...formData, post_note: e.target.value })
              }
            />
            <div className="grievance-container">
              {" "}
              <div className="gc-item">
                {" "}
                <SignatureButton
                  individualSignature={notPatientSignature}
                  setIndividualSignature={(file) => {
                    console.log("Captured signature:", file);
                    setNotPatientSignature(file);
                  }}
                />
                <hr className="signature-line"></hr>
                <p>Anesthesiologist</p>
              </div>
              <div className="gc-item mt-50">
                {" "}
                <label htmlFor="name" className="hidden">
                  Anesthesiologist
                </label>
                <input
                  type="date"
                  id="name"
                  className="bottom-border-input"
                  name="date_two"
                  value={formData.date_two}
                  onChange={(e) =>
                    setFormData({ ...formData, date_two: e.target.value })
                  }
                />
                <p>Date</p>
              </div>
              <div className="gc-item mt-50">
                {" "}
                <label htmlFor="name" className="hidden">
                  Time
                </label>
                <input
                  type="time"
                  id="time"
                  className="bottom-border-input"
                  name="time_two"
                  value={formData.time_two}
                  onChange={(e) =>
                    setFormData({ ...formData, time_two: e.target.value })
                  }
                />
                <p>Time</p>
              </div>
            </div>
          </div>
          <div className="center mt-50">
            <button className="btn-primary hover-pointer" type="submit">
              Submit
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default PreandPostOpAE;
