import React, { useState, useEffect } from "react";
import { Icon } from "@iconify/react";
import { useNavigate } from "react-router-dom";
import "./styles.css";
import SignatureButton from "../../../components/SignatureButton";
import { useParams } from "react-router-dom";
import moment from "moment";
import {
  addFile,
  getPrivacyPolicy,
  updatePrivacyPolicy,
} from "../../../utils/api";

const NoticeofPrivacy = () => {
  const navigate = useNavigate();
  const { form_id } = useParams();

  const [patientName, setPatientName] = useState();
  const [date, setDate] = useState();
  const [authorizedRep, setAuthorizedRep] = useState();
  const [relation, setRelation] = useState();
  const [individualSignature, setIndividualSignature] = useState("");
  const [loading, setLoading] = useState(true);

  const handleSubmit = async (e) => {
    e.preventDefault();

    let fileName;
    // Fetch 1: Upload Signature
    if (individualSignature !== "") {
      const response = await addFile(individualSignature);
      const data = await response.json();
      fileName = await data.fileName;
    }
    const payload = {
      patient_name: patientName,
      authorized_rep: authorizedRep,
      relation: relation,
      patient_signature: fileName,
      patient_signature_date: date,
      date: moment().format("MM/DD/YYYY"),
      time: moment().format("h:mm a"),
      status: "In Progress",
    };

    // console.log("Payload Sent to Backend:", payload);

    // Fetch 2: ≈ Notice of privacy Model
    updatePrivacyPolicy(form_id, payload)
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        console.log("complete");
        navigate(-1);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getPrivacyPolicy(form_id)
      .then((res) => res.json())
      .then((data) => {
        console.log(data);

        if (data.status !== "Not Started") {
          if (data.authorized_rep === false) {
            setAuthorizedRep(false);
          } else {
            setAuthorizedRep(data.authorized_rep || null);
          }
          if (data.relation === false) {
            setRelation(false);
          } else {
            setRelation(data.relation || null);
          }
        }

        setPatientName(data.patient_name || "");

        setLoading(false);
      })
      .catch((err) => console.log(err));
  }, []);

  if (loading) return <p>Loading...</p>;
  return (
    <>
      <div
        className="arrow-container"
        onClick={() => navigate(-1)}
        style={{ cursor: "pointer" }}
      >
        <p className="back-arrow">
          <Icon icon="ic:twotone-arrow-back" className="icon" /> Back to Forms
        </p>
      </div>
      <h2 className="form-title">NOTICE OF PRIVACY PRACTICES</h2>
      <div className="form-content">
        <form onSubmit={(e) => handleSubmit(e)}>
          <p className="text-center">
            <strong>ACKNOWLEDGEMENT OF RECEIPT</strong>
          </p>
          <p className="text-center">
            <strong>OF</strong>
          </p>
          <p className="text-center">
            <strong>NOTICE OF PRIVACY PRACTICES</strong>
          </p>
          <p className="mt-50">
            I acknowledge that I was provided a copy of the Notice of Privacy
            Practices and that I have read, or have had the opportunity to read,
            this Notice and I understand the notice.
          </p>
          <div className="privacy-container">
            <div className="privacy-item">
              {" "}
              <input
                type="text"
                id="name"
                name="name"
                className="name-input"
                value={patientName}
                onChange={(e) => setPatientName(e.target.value)}
              />
              <hr className="signature-line"></hr>
              <p>Patient Name(Please Print)</p>
            </div>
            <div className="privacy-item mt-50">
              {" "}
              <label htmlFor="name" className="hidden">
                Patient Name
              </label>
              <input
                type="date"
                id="name"
                name="name"
                className="bottom-border-input"
                
                onChange={(e) => setDate(e.target.value)}
              />
              <p>Date</p>
            </div>
            <div className="privacy-item">
              {" "}
              <SignatureButton
                individualSignature={individualSignature}
                setIndividualSignature={setIndividualSignature}
              />
              <hr className="signature-line"></hr>
              <p>Patient's or Authorized Representative's Signature</p>
            </div>
            <div className="privacy-item mt-50">
              {" "}
              <input
                className="name-input"
                name="authorized_rep"
                onChange={(e) => setAuthorizedRep(e.target.value)}
              />
              <hr className="signature-line"></hr>
              <p>Authorized Representative(Please print if applicable)</p>
            </div>
            <div className="privacy-item mt-50">
              {" "}
              <label htmlFor="name" className="hidden">
                Relationship to Patient
              </label>
              <input
                type="text"
                id="name"
                name="name"
                className="name-input"
                
                onChange={(e) => setRelation(e.target.value)}
              />
              <hr className="signature-line"></hr>
              <p>Relationship to Patient</p>
            </div>
          </div>
          <div className="center mt-50">
            <button className="btn-primary hover-pointer" type="submit">
              Submit
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default NoticeofPrivacy;
