import React, { useState, useEffect } from "react";
import { Icon } from "@iconify/react";
import "../Forms/styles.css";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import moment from "moment";
import {
  addFile,
  getPostAneCarePO,
  updatePostAneCarePO,
} from "../../../utils/api";
import SignatureButton from "../../../components/SignatureButton";

const PostAneCarePO = () => {
  const navigate = useNavigate();
  const { form_id } = useParams();
  const [loading, setLoading] = useState(true);
  const [individualSignature, setIndividualSignature] = useState("");

  const [formData, setFormData] = useState({
    IV_mlhr: "",
    demerol_mg: "",
    demerol_q: "",
    toradol_mg: "",
    fentanyl_mcg: "",
    fentanyl_q: "",
    fentanyl_x: "",
    vicodin_q: "",
    reglan_x: "",
    time: "",
    MD: "",
    rn_initial_one: "",
    time_one: "",
    rn_initial_two: "",
    time_two: "",
    rn_initial_three: "",
    time_three: "",
    admin_RN: "",
    admin_time: "",
    IV_RN: "",
    IV_time: "",
    DC_RN: "",
    DC_notes: "",
    demerol_RN: "",
    demerol_time: "",
    toradol_RN: "",
    toradol_time: "",
    fentanyl_RN: "",
    fentanyl_time: "",
    percocet_RN: "",
    percocet_time: "",
    vicodin_RN: "",
    vicodin_time: "",
    other_RN: "",
    other_time: "",
    reglan_RN: "",
    reglan_time: "",
    zofran_RN: "",
    zofran_time: "",
    nausea_other_RN: "",
    nausea_other_time: "",
    additional_RN: "",
    additional_time: "",
    other_note: "",
    other_vicodin: "",
    nausea_other_notes: "",
    additional: "",
    discharge_RN: "",
    discharge_time: "",
    MD_eval_RN: "",
    MD_eval_time: "",
    date2: "",
    signature_time: "",
    parent_RN: "",
    parent_time: "",
    toradol_ivp: false,
    toradol_IM: false,
    demerol_x: false,
    oral_RN: "",
    oral_time: "",
    ox_one: false,
    ox_two: false,
    ox_three: false,
    ox_four: false,
    IV_run: false,
    DC_IV: false,
    demerol: false,
    toradol: false,
    fentanyl: false,
    percocet: false,
    vicodin: false,
    other: false,
    reglan: false,
    zofran: false,
    nausea_other: false,
    discharge: false,
    MD_eval: false,
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    let fileName;

    // Fetch 1: Upload Signature
    if (individualSignature !== "") {
      const response = await addFile(individualSignature);

      const data = await response.json();
      fileName = await data.fileName;
    }
    const payload = {
      ...formData,
      date2: moment().format("MM/DD/YYYY"),

      date: moment().format("MM/DD/YYYY"),
      signature_time: moment().format("h:mm a"),
      patient_signature: fileName,

      time: moment().format("h:mm a"),
      status: "In Progress",
    };

    console.log("Payload Sent to Backend:", payload);

    // Fetch 2: Create/Update the Disclosure of Ownerhsip Model

    updatePostAneCarePO(form_id, payload)
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        console.log("complete");
        navigate(-1);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getPostAneCarePO(form_id)
      .then((res) => res.json())
      .then((data) => {
        console.log(data);

        if (data.status !== "Not Started") {
          setFormData({
            IV_mlhr: data.IV_mlhr || "",
            demerol_mg: data.demerol_mg || "",
            demerol_q: data.demerol_q || "",
            toradol_mg: data.toradol_mg || "",
            fentanyl_mcg: data.fentanyl_mcg || "",
            fentanyl_q: data.fentanyl_q || "",
            fentanyl_x: data.fentanyl_x || "",
            vicodin_q: data.vicodin_q || "",
            reglan_x: data.reglan_x || "",
            time: data.time || "",
            MD: data.MD || "",
            rn_initial_one: data.rn_initial_one || "",
            time_one: data.time_one || "",
            rn_initial_two: data.rn_initial_two || "",
            time_two: data.time_two || "",
            rn_initial_three: data.rn_initial_three || "",
            time_three: data.time_three || "",
            admin_RN: data.admin_RN || "",
            admin_time: data.admin_time || "",
            IV_RN: data.IV_RN || "",
            IV_time: data.IV_time || "",
            DC_RN: data.DC_RN || "",
            DC_notes: data.DC_notes || "",
            other_vicodin: data.other_vicodin || "",
            demerol_RN: data.demerol_RN || "",
            demerol_time: data.demerol_time || "",
            toradol_RN: data.toradol_RN || "",
            toradol_time: data.toradol_time || "",
            fentanyl_RN: data.fentanyl_RN || "",
            fentanyl_time: data.fentanyl_time || "",
            percocet_RN: data.percocet_RN || "",
            percocet_time: data.percocet_time || "",
            vicodin_RN: data.vicodin_RN || "",
            vicodin_time: data.vicodin_time || "",
            other_RN: data.other_RN || "",
            other_time: data.other_time || "",
            reglan_RN: data.reglan_RN || "",
            reglan_time: data.reglan_time || "",
            zofran_RN: data.zofran_RN || "",
            zofran_time: data.zofran_time || "",
            nausea_other_RN: data.nausea_other_RN || "",
            nausea_other_time: data.nausea_other_time || "",
            additional_RN: data.additional_RN || "",
            additional_time: data.additional_time || "",
            other_note: data.other_note || "",
            nausea_other_notes: data.nausea_other_notes || "",
            additional: data.additional || "",
            discharge_RN: data.discharge_RN || "",
            discharge_time: data.discharge_time || "",
            MD_eval_RN: data.MD_eval_RN || "",
            MD_eval_time: data.MD_eval_time || "",
            date2: data.date || "",
            signature_time: data.signature_time || "",
            parent_RN: data.parent_RN || "",
            parent_time: data.parent_time || "",
            toradol_ivp: data.toradol_ivp || false,
            toradol_IM: data.toradol_IM || false,
            demerol_x: data.demerol_x || false,
            oral_RN: data.oral_RN || "",
            oral_time: data.oral_time || "",
            ox_one: data.ox_one || false,
            ox_two: data.ox_two || false,
            ox_three: data.ox_three || false,
            ox_four: data.ox_four || false,
            IV_run: data.IV_run || false,
            DC_IV: data.DC_IV || false,
            demerol: data.demerol || false,
            toradol: data.toradol || false,
            fentanyl: data.fentanyl || false,
            percocet: data.percocet || false,
            vicodin: data.vicodin || false,
            other: data.other || false,
            reglan: data.reglan || false,
            zofran: data.zofran || false,
            nausea_other: data.nausea_other || false,
            discharge: data.discharge || false,
            MD_eval: data.MD_eval || false,
          });
        }
        setLoading(false);
      })
      .catch((err) => console.log(err));
  }, []);
  if (loading) return <p>Loading...</p>;

  return (
    <>
      <div>
        <div
          className="arrow-container"
          onClick={() => navigate(-1)}
          style={{ cursor: "pointer" }}
        >
          <p className="back-arrow">
            <Icon icon="ic:twotone-arrow-back" className="icon" /> Back to Forms
          </p>
        </div>
        <h3 className="form-title">Post Anesthesia Care Physician Orders</h3>
        <div className="form-content">
          <form onSubmit={handleSubmit}>
            <div className="papo-container">
              <div className="papo-row">
                <div className="papo-column"></div>
                <div className="papo-column papo-title-text">
                  <p>R.N. Initials</p>
                </div>
                <div className="papo-column papo-title-text">
                  <p>Time Noted</p>
                </div>
              </div>
              <div className="papo-row">
                <div className="papo-column">
                  <b>1. Oxygen</b>
                </div>
                <div className="papo-column">{/* <input type="text" /> */}</div>
                <div className="papo-column">{/* <input type="text" /> */}</div>
              </div>
              <div className="papo-row">
                <div className="papo-column">
                  <input
                    type="checkbox"
                    name="ox_one"
                    checked={formData.ox_one}
                    onChange={(e) =>
                      setFormData({ ...formData, ox_one: e.target.checked })
                    }
                  />
                  <label>3-10 liters per minute via facemask</label>
                </div>
                <div className="papo-column">
                  <input
                    type="text"
                    name="rn_initial_one"
                    value={formData.rn_initial_one}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        rn_initial_one: e.target.value,
                      })
                    }
                  />
                </div>
                <div className="papo-column">
                  <input
                    type="text"
                    name="time_one"
                    value={formData.time_one}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        time_one: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
              <div className="papo-row">
                <div className="papo-column">
                  <input
                    type="checkbox"
                    name="ox_two"
                    checked={formData.ox_two}
                    onChange={(e) =>
                      setFormData({ ...formData, ox_two: e.target.checked })
                    }
                  />
                  <label>2-4 liters per minute via nasal cannula</label>
                </div>
                <div className="papo-column">
                  <input
                    type="text"
                    name="rn_initial_two"
                    value={formData.rn_initial_two}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        rn_initial_two: e.target.value,
                      })
                    }
                  />
                </div>
                <div className="papo-column">
                  <input
                    type="text"
                    name="time_two"
                    value={formData.time_two}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        time_two: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
              <div className="papo-row">
                <div className="papo-column">
                  <input
                    type="checkbox"
                    name="ox_three"
                    checked={formData.ox_three}
                    onChange={(e) =>
                      setFormData({ ...formData, ox_three: e.target.checked })
                    }
                  />
                  <label>3-10 liters per minute via LMA</label>
                </div>
                <div className="papo-column">
                  <input
                    type="text"
                    name="rn_initial_three"
                    value={formData.rn_initial_three}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        rn_initial_three: e.target.value,
                      })
                    }
                  />
                </div>
                <div className="papo-column">
                  <input
                    type="text"
                    name="time_three"
                    value={formData.time_three}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        time_three: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
              <div className="papo-row">
                <div className="papo-column">
                  <input
                    type="checkbox"
                    name="ox_four"
                    checked={formData.ox_four}
                    onChange={(e) =>
                      setFormData({ ...formData, ox_four: e.target.checked })
                    }
                  />
                  <label>Administer 02 PRN SP02 &gt; 92%</label>
                </div>
                <div className="papo-column">
                  <input
                    type="text"
                    name="admin_RN"
                    value={formData.admin_RN}
                    onChange={(e) =>
                      setFormData({ ...formData, admin_RN: e.target.value })
                    }
                  />
                </div>
                <div className="papo-column">
                  <input
                    type="text"
                    name="admin_time"
                    value={formData.admin_time}
                    onChange={(e) =>
                      setFormData({ ...formData, admin_time: e.target.value })
                    }
                  />
                </div>
              </div>
              <div className="papo-row">
                <div className="papo-column">
                  <b>2. IV</b>
                </div>
                <div className="papo-column">{/* <input type="text" /> */}</div>
                <div className="papo-column">{/* <input type="text" /> */}</div>
              </div>
              <div className="papo-row">
                <div className="papo-column">
                  <input
                    type="checkbox"
                    name="IV_run"
                    checked={formData.IV_run}
                    onChange={(e) =>
                      setFormData({ ...formData, IV_run: e.target.checked })
                    }
                  />
                  <label>
                    Run current IV at{" "}
                    <input
                      type="text"
                      className="papo-form-input"
                      name="IV_mlhr"
                      value={formData.IV_mlhr}
                      onChange={(e) =>
                        setFormData({ ...formData, IV_mlhr: e.target.value })
                      }
                    />
                    ml/hr
                  </label>
                </div>
                <div className="papo-column">
                  <input
                    type="text"
                    name="IV_RN"
                    value={formData.IV_RN}
                    onChange={(e) =>
                      setFormData({ ...formData, IV_RN: e.target.value })
                    }
                  />
                </div>
                <div className="papo-column">
                  <input
                    type="text"
                    name="IV_time"
                    value={formData.IV_time}
                    onChange={(e) =>
                      setFormData({ ...formData, IV_time: e.target.value })
                    }
                  />
                </div>
              </div>
              <div className="papo-row">
                <div className="papo-column">
                  <input
                    type="checkbox"
                    name="DC_IV"
                    checked={formData.DC_IV}
                    onChange={(e) =>
                      setFormData({ ...formData, DC_IV: e.target.checked })
                    }
                  />
                  <label>D/C IV prior to discharge</label>
                </div>
                <div className="papo-column">
                  <input
                    type="text"
                    name="DC_RN"
                    value={formData.DC_RN}
                    onChange={(e) =>
                      setFormData({ ...formData, DC_RN: e.target.value })
                    }
                  />
                </div>
                <div className="papo-column">
                  <input
                    type="text"
                    name="DC_notes"
                    value={formData.DC_notes}
                    onChange={(e) =>
                      setFormData({ ...formData, DC_notes: e.target.value })
                    }
                  />
                </div>
              </div>
              <div className="papo-row">
                <div className="papo-column">
                  <b>3. Pain Management</b>
                </div>
                <div className="papo-column">{/* <input type="text" /> */}</div>
                <div className="papo-column">{/* <input type="text" /> */}</div>
              </div>
              <div className="papo-row">
                <div className="papo-column">
                  Parenteral, for moderate to severe pain and when unable to
                  take PO
                </div>
                <div className="papo-column">
                  <input
                    type="text"
                    name="parent_RN"
                    value={formData.parent_RN}
                    onChange={(e) =>
                      setFormData({ ...formData, parent_RN: e.target.value })
                    }
                  />
                </div>
                <div className="papo-column">
                  <input
                    type="text"
                    name="parent_time"
                    value={formData.parent_time}
                    onChange={(e) =>
                      setFormData({ ...formData, parent_time: e.target.value })
                    }
                  />
                </div>
              </div>
              <div className="papo-row">
                <div className="papo-column">
                  <input
                    type="checkbox"
                    name="demerol"
                    checked={formData.demerol}
                    onChange={(e) =>
                      setFormData({ ...formData, demerol: e.target.checked })
                    }
                  />
                  <label>
                    Demerol© (meperidine){" "}
                    <input
                      type="text"
                      className="papo-form-input"
                      name="demerol_mg"
                      value={formData.demerol_mg}
                      onChange={(e) =>
                        setFormData({ ...formData, demerol_mg: e.target.value })
                      }
                    />
                    mg IVP, may repeat q
                    <input
                      type="text"
                      className="papo-form-input"
                      name="demerol_q"
                      value={formData.demerol_q}
                      onChange={(e) =>
                        setFormData({ ...formData, demerol_q: e.target.value })
                      }
                    />
                    min X{" "}
                    <input
                      type="text"
                      className="papo-form-input"
                      name="demerol_x"
                      checked={formData.demerol_x}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          demerol_x: e.target.checked,
                        })
                      }
                    />
                  </label>
                </div>
                <div className="papo-column">
                  <input
                    type="text"
                    name="demoral_RN"
                    value={formData.demerol_RN}
                    onChange={(e) =>
                      setFormData({ ...formData, demerol_RN: e.target.value })
                    }
                  />
                </div>
                <div className="papo-column">
                  <input
                    type="text"
                    name="demerol_time"
                    value={formData.demerol_time}
                    onChange={(e) =>
                      setFormData({ ...formData, demerol_time: e.target.value })
                    }
                  />
                </div>
              </div>
              <div className="papo-row">
                <div className="papo-column">
                  {" "}
                  <input
                    type="checkbox"
                    name="toradol"
                    checked={formData.toradol}
                    onChange={(e) =>
                      setFormData({ ...formData, toradol: e.target.checked })
                    }
                  />
                  <label>
                    Toradol© (ketorolac){" "}
                    <input
                      type="text"
                      className="papo-form-input"
                      name="toradol_mg"
                      value={formData.toradol_mg}
                      onChange={(e) =>
                        setFormData({ ...formData, toradol_mg: e.target.value })
                      }
                    />
                    mg{" "}
                    <input
                      type="checkbox"
                      name="toradol_ivp"
                      checked={formData.toradol_ivp}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          toradol_ivp: e.target.checked,
                        })
                      }
                    />
                    IVP or{" "}
                    <input
                      type="checkbox"
                      name="toradol_IM"
                      checked={formData.toradol_IM}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          toradol_IM: e.target.checked,
                        })
                      }
                    />
                    IM
                  </label>
                </div>
                <div className="papo-column">
                  <input
                    type="text"
                    name="toradol_RN"
                    value={formData.toradol_RN}
                    onChange={(e) =>
                      setFormData({ ...formData, toradol_RN: e.target.value })
                    }
                  />
                </div>
                <div className="papo-column">
                  <input
                    type="text"
                    name="toradol_time"
                    value={formData.toradol_time}
                    onChange={(e) =>
                      setFormData({ ...formData, toradol_time: e.target.value })
                    }
                  />
                </div>
              </div>
              <div className="papo-row">
                <div className="papo-column">
                  {" "}
                  <input
                    type="checkbox"
                    name="fentanyl"
                    checked={formData.fentanyl}
                    onChange={(e) =>
                      setFormData({ ...formData, fentanyl: e.target.checked })
                    }
                  />
                  <label>
                    Fentanyl{" "}
                    <input
                      type="text"
                      className="papo-form-input"
                      name="fentanyl_mcg"
                      value={formData.fentanyl_mcg}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          fentanyl_mcg: e.target.value,
                        })
                      }
                    />
                    mcg IVP, may repeat q
                    <input
                      type="text"
                      name="fentanyl_q"
                      value={formData.fentanyl_q}
                      onChange={(e) =>
                        setFormData({ ...formData, fentanyl_q: e.target.value })
                      }
                    />
                    min X{" "}
                    <input
                      type="text"
                      name="fentanyl_x"
                      value={formData.fentanyl_x}
                      onChange={(e) =>
                        setFormData({ ...formData, fentanyl_x: e.target.value })
                      }
                    />{" "}
                    doses
                  </label>
                </div>
                <div className="papo-column">
                  <input
                    type="text"
                    name="fentanyl_RN"
                    value={formData.fentanyl_RN}
                    onChange={(e) =>
                      setFormData({ ...formData, fentanyl_RN: e.target.value })
                    }
                  />
                </div>
                <div className="papo-column">
                  <input
                    type="text"
                    name="fentanyl_time"
                    value={formData.fentanyl_time}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        fentanyl_time: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
              <div className="papo-row">
                <div className="papo-column">
                  Oral, for mild to moderate pain when tolerating PO
                </div>
                <div className="papo-column">
                  <input
                    type="text"
                    name="oral_RN"
                    value={formData.oral_RN}
                    onChange={(e) =>
                      setFormData({ ...formData, oral_RN: e.target.value })
                    }
                  />
                </div>
                <div className="papo-column">
                  <input
                    type="text"
                    name="oral_time"
                    value={formData.oral_time}
                    onChange={(e) =>
                      setFormData({ ...formData, oral_time: e.target.value })
                    }
                  />
                </div>
              </div>
              <div className="papo-row">
                <div className="papo-column">
                  <input
                    type="checkbox"
                    name="percocet"
                    checked={formData.percocet}
                    onChange={(e) =>
                      setFormData({ ...formData, percocet: e.target.checked })
                    }
                  />
                  <label>Percocet 5/325mg 1 tab PO may repeat</label>
                </div>
                <div className="papo-column">
                  <input
                    type="text"
                    name="percocet_RN"
                    value={formData.percocet_RN}
                    onChange={(e) =>
                      setFormData({ ...formData, percocet_RN: e.target.value })
                    }
                  />
                </div>
                <div className="papo-column">
                  <input
                    type="text"
                    name="percocet_time"
                    value={formData.percocet_time}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        percocet_time: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
              <div className="papo-row">
                <div className="papo-column">
                  {" "}
                  <input
                    type="checkbox"
                    name="vicodin"
                    checked={formData.vicodin}
                    onChange={(e) =>
                      setFormData({ ...formData, vicodin: e.target.checked })
                    }
                  />
                  <label>
                    Vicodin© (hydrocodone 5/mg/acetaminophen 500mg) 1 tab PO q
                    <input
                      type="text"
                      className="papo-form-input"
                      name="vicodin_q"
                      value={formData.vicodin_q}
                      onChange={(e) =>
                        setFormData({ ...formData, vicodin_q: e.target.value })
                      }
                    />
                  </label>
                </div>
                <div className="papo-column">
                  <input
                    type="text"
                    name="vicodin_RN
"
                    value={formData.vicodin_RN}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        vicodin_RN: e.target.value,
                      })
                    }
                  />
                </div>
                <div className="papo-column">
                  <input
                    type="text"
                    name="vicodin_time"
                    value={formData.vicodin_time}
                    onChange={(e) =>
                      setFormData({ ...formData, vicodin_time: e.target.value })
                    }
                  />
                </div>
              </div>
              <div className="papo-row">
                <div className="papo-column">
                  {" "}
                  <input
                    type="checkbox"
                    name="other"
                    checked={formData.other}
                    onChange={(e) =>
                      setFormData({ ...formData, other: e.target.checked })
                    }
                  />
                  <label>Other</label>
                </div>
                <div className="papo-column">
                  <input
                    type="text"
                    name="other_RN"
                    value={formData.other_RN}
                    onChange={(e) =>
                      setFormData({ ...formData, other_RN: e.target.value })
                    }
                  />
                </div>
                <div className="papo-column">
                  <input
                    type="text"
                    name="other_time"
                    value={formData.other_time}
                    onChange={(e) =>
                      setFormData({ ...formData, other_time: e.target.value })
                    }
                  />
                </div>
              </div>
              <div className="papo-row">
                <div className="papo-column">
                  <b>4. Nausea and Vomitting</b>
                </div>
                <div className="papo-column">{/* <input type="text" /> */}</div>
                <div className="papo-column">{/* <input type="text" /> */}</div>
              </div>
              <div className="papo-row">
                <div className="papo-column">
                  <input
                    type="checkbox"
                    name="reglan"
                    checked={formData.reglan}
                    onChange={(e) =>
                      setFormData({ ...formData, reglan: e.target.checked })
                    }
                  />
                  <label>
                    Reglan© (metoclopramide) 10mg IVP, may repeat X{" "}
                    <input
                      type="text"
                      className="papo-form-input"
                      name="reglan_x"
                      value={formData.reglan_x}
                      onChange={(e) =>
                        setFormData({ ...formData, reglan_x: e.target.value })
                      }
                    />
                  </label>
                </div>
                <div className="papo-column">
                  <input
                    type="text"
                    name="reglan_RN"
                    value={formData.reglan_RN}
                    onChange={(e) =>
                      setFormData({ ...formData, reglan_RN: e.target.value })
                    }
                  />
                </div>
                <div className="papo-column">
                  <input
                    type="text"
                    name="reglan_time"
                    value={formData.reglan_time}
                    onChange={(e) =>
                      setFormData({ ...formData, reglan_time: e.target.value })
                    }
                  />
                </div>
              </div>
              <div className="papo-row">
                <div className="papo-column">
                  <input
                    type="checkbox"
                    name="zofran"
                    checked={formData.zofran}
                    onChange={(e) =>
                      setFormData({ ...formData, zofran: e.target.checked })
                    }
                  />
                  <label>Zofran© 4mg IVPB, for nausea & vomitting</label>
                </div>
                <div className="papo-column">
                  <input
                    type="text"
                    name="zofran_RN"
                    value={formData.zofran_RN}
                    onChange={(e) =>
                      setFormData({ ...formData, zofran_RN: e.target.value })
                    }
                  />
                </div>
                <div className="papo-column">
                  <input
                    type="text"
                    name="zofran_time"
                    value={formData.zofran_time}
                    onChange={(e) =>
                      setFormData({ ...formData, zofran_time: e.target.value })
                    }
                  />
                </div>
              </div>
              <div className="papo-row">
                <div className="papo-column">
                  <input
                    type="checkbox"
                    name="nausea_other"
                    checked={formData.nausea_other}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        nausea_other: e.target.checked,
                      })
                    }
                  />
                  <label>Other</label>
                  <input
                    type="text"
                    name="nausea_other_notes"
                    value={formData.nausea_other_notes}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        nausea_other_notes: e.target.value,
                      })
                    }
                  />
                </div>
                <div className="papo-column">
                  <input
                    type="text"
                    name="nausea_other_RN"
                    value={formData.nausea_other_RN}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        nausea_other_RN: e.target.value,
                      })
                    }
                  />
                </div>
                <div className="papo-column">
                  <input
                    type="text"
                    name="nausea_other_time"
                    value={formData.nausea_other_time}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        nausea_other_time: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
              <div className="papo-row">
                <div className="papo-column">
                  <b>5. Additional Orders:</b>{" "}
                  <input
                    type="text"
                    className="papo-form-input-long"
                    name="additional"
                    value={formData.additional}
                    onChange={(e) =>
                      setFormData({ ...formData, additional: e.target.value })
                    }
                  />
                </div>
                <div className="papo-column">
                  <input
                    type="text"
                    name="additional_RN"
                    value={formData.additional_RN}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        additional_RN: e.target.value,
                      })
                    }
                  />
                </div>
                <div className="papo-column">
                  <input
                    type="text"
                    name="additional_time"
                    value={formData.additional_time}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        additional_time: e.target.value,
                      })
                    }
                  />
                </div>
              </div>

              <div className="papo-row">
                <div className="papo-column">
                  <b>
                    6.{" "}
                    <input
                      type="checkbox"
                      name="discharge"
                      checked={formData.discharge}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          discharge: e.target.checked,
                        })
                      }
                    />
                    <label>
                      {" "}
                      May discharge patient when alert, stable, and discharge
                    </label>
                    criteria met.
                  </b>
                </div>
                <div className="papo-column">
                  <input
                    type="text"
                    name="discharge_RN"
                    value={formData.discharge_RN}
                    onChange={(e) =>
                      setFormData({ ...formData, discharge_RN: e.target.value })
                    }
                  />
                </div>
                <div className="papo-column">
                  <input
                    type="text"
                    name="discharge_RN"
                    value={formData.discharge_RN}
                    onChange={(e) =>
                      setFormData({ ...formData, discharge_RN: e.target.value })
                    }
                  />
                </div>
              </div>
              <div className="papo-row">
                <div className="papo-column">
                  <input
                    type="checkbox"
                    name="MD_eval"
                    checked={formData.MD_eval}
                    onChange={(e) =>
                      setFormData({ ...formData, MD_eval: e.target.checked })
                    }
                  />
                  <label>
                    <b>
                      M.D. Evaluation: I have evaluated the patient and patient
                      is ready for discharge.
                    </b>
                  </label>
                </div>
                <div className="papo-column">
                  <input
                    type="text"
                    name="MD_eval_RN"
                    value={formData.MD_eval_RN}
                    onChange={(e) =>
                      setFormData({ ...formData, MD_eval_RN: e.target.value })
                    }
                  />
                </div>
                <div className="papo-column">
                  <input
                    type="text"
                    name="MD_eval_time"
                    value={formData.MD_eval_time}
                    onChange={(e) =>
                      setFormData({ ...formData, MD_eval_time: e.target.value })
                    }
                  />
                </div>
              </div>
            </div>
            <div className="papo-sig-container">
              <label>
                M.D.
                <input
                  type="text"
                  className="sig-input"
                  name="MD"
                  value={formData.MD}
                  onChange={(e) =>
                    setFormData({ ...formData, MD: e.target.value })
                  }
                />
              </label>
              <label>
                Time
                <input
                  type="time"
                  className="sig-input"
                  name="discharge_time"
                  value={formData.discharge_time}
                  onChange={(e) =>
                    setFormData({ ...formData, discharge_time: e.target.value })
                  }
                />
              </label>
            </div>

            <div className="grievance-container mt-50">
              <div className="gc-item">
                {" "}
                <SignatureButton
                  individualSignature={individualSignature}
                  setIndividualSignature={setIndividualSignature}
                />
                <hr className="signature-line"></hr>
                <p>Anesthesia Provider Signature</p>
              </div>
              <div className="gc-item mt-50">
                {" "}
                <label htmlFor="name" className="hidden">
                  Patient Signature
                </label>
                <input
                  type="date"
                  id="name"
                  className="bottom-border-input"
                  name="date2"
                  value={formData.date2}
                  onChange={(e) =>
                    setFormData({ ...formData, date2: e.target.value })
                  }
                />
                <p>Date</p>
              </div>
              <div className="gc-item mt-50">
                {" "}
                <label htmlFor="name" className="hidden">
                  Time
                </label>
                <input
                  type="time"
                  className="bottom-border-input"
                  name="signature_time"
                  value={formData.signature_time}
                  onChange={(e) =>
                    setFormData({ ...formData, signature_time: e.target.value })
                  }
                />
                <p>Time</p>
              </div>
            </div>
            <div className="center mt-50">
              <button className="btn-primary hover-pointer" type="submit">
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default PostAneCarePO;
