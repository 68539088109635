import React, { useState, useEffect } from "react";
import { Icon } from "@iconify/react";
import "../Forms/styles.css";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import moment from "moment";
import {
  addFile,
  getOperativeNote,
  updateOperativeNote,
} from "../../../utils/api";

import SignatureButton from "../../../components/SignatureButton";

const OperativeNote = () => {
  const navigate = useNavigate();
  const { form_id } = useParams();
  const [loading, setLoading] = useState(true);
  const [individualSignature, setIndividualSignature] = useState("");
  const [notPatientSignature, setNotPatientSignature] = useState("");
  const [formData, setFormData] = useState({
    post_op_diag: "",
    procedure: "",
    surgeon_one: "",
    anesthesiologist: "",
    comp_notes: "",
    cond_notes: "",
    date_two: "",
    postop_notes: "",
    implant: "",
    date_top: "",
    time_top: "",
    date_two: "",
    time_two: "",
    preop_diag: "",
    anesth_local: false,
    anesth_sed: false,
    anesth_gen: false,
    EBL_none: false,
    specimen_NA: false,
    comp_none: false,
    time_two: "",
    ebl_notes: "",
  });

  const handleSubmit = async (e) => {
    e.preventDefault();

    let patientFileName, formCompleterFileName;

    if (notPatientSignature !== "") {
      console.log("Uploading form completer signature:", notPatientSignature);
    }

    // Fetch 1: Upload Signature
    if (individualSignature !== "") {
      const response = await addFile(individualSignature);

      const data = await response.json();
      patientFileName = data.fileName;
    }
    if (notPatientSignature !== "") {
      const response = await addFile(notPatientSignature);
      const data = await response.json();
      console.log("Uploaded form completer signature:", data.fileName);
      formCompleterFileName = data.fileName || null;
    }

    const payload = {
      patient_signature: patientFileName,
      form_completer_signature: formCompleterFileName,
      ...formData,
      date: moment().format("MM/DD/YYYY"),
      time: moment().format("h:mm a"),
      date_top: moment().format("MM/DD/YYYY"),
      time_top: moment().format("h:mm a"),
      date_two: moment().format("MM/DD/YYYY"),
      time_two: moment().format("h:mm a"),
      status: "In Progress",
    };

    console.log("Payload Sent to Backend:", payload);

    // Fetch 2: Create/Update the Disclosure of Ownerhsip Model

    updateOperativeNote(form_id, payload)
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        console.log("complete");
        navigate(-1);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getOperativeNote(form_id)
      .then((res) => res.json())
      .then((data) => {
        console.log(data);

        if (data.status !== "Not Started") {
          setFormData({
            post_op_diag: data.post_op_diag || "",
            procedure: data.procedure || "",
            surgeon_one: data.surgeon_one || "",
            anesthesiologist: data.anesthesiologist || "",
            comp_notes: data.comp_notes || "",
            cond_notes: data.cond_notes || "",
            date_two: data.date_two || "",
            postop_notes: data.postop_notes || "",
            implant: data.implant || "",
            date_top: data.date || "",
            time_top: data.time || "",
            preop_diag: data.preop_diag || "",
            anesth_local: data.anesth_local || false,
            anesth_sed: data.anesth_sed || false,
            anesth_gen: data.anesth_gen || false,
            EBL_none: data.EBL_none || false,
            specimen_NA: data.specimen_NA || false,
            comp_none: data.comp_none || false,
            time_two: data.time_two || "",
            ebl_notes: data.time_two || "",
          });
        }
        setLoading(false);
      })
      .catch((err) => console.log(err));
  }, []);
  if (loading) return <p>Loading...</p>;

  return (
    <>
      <div
        className="arrow-container"
        onClick={() => navigate(-1)}
        style={{ cursor: "pointer" }}
      >
        <p className="back-arrow">
          <Icon icon="ic:twotone-arrow-back" className="icon" /> Back to Forms
        </p>
      </div>{" "}
      <div className="form-content">
        <form onSubmit={handleSubmit}>
          {" "}
          <h2 className="form-title">Operative Note</h2>
          <div className="label-indent">
            <label>Date:</label>
            <input
              type="date"
              className="di-input"
              name="date_top"
              value={formData.date_top}
              onChange={(e) =>
                setFormData({ ...formData, date_top: e.target.value })
              }
            />
            <label>Time</label>
            <input
              type="time"
              className="di-input"
              name="time_top"
              value={formData.time_top}
              onChange={(e) =>
                setFormData({ ...formData, time_top: e.target.value })
              }
            />
          </div>
          <div className="label-indent">
            <label>Pre-Op Diagnosis:</label>
            <textarea
              name="preop_diag"
              value={formData.preop_diag}
              onChange={(e) =>
                setFormData({ ...formData, preop_diag: e.target.value })
              }
            />
          </div>
          <div className="label-indent">
            <label>Post-Op Diagnosis:</label>
            <textarea
              name="post_op_diag"
              value={formData.post_op_diag}
              onChange={(e) =>
                setFormData({ ...formData, post_op_diag: e.target.value })
              }
            />
          </div>
          <div className="label-indent">
            <label>Procedure:</label>
            <textarea
              name="procedure"
              value={formData.procedure}
              onChange={(e) =>
                setFormData({ ...formData, procedure: e.target.value })
              }
            />
          </div>
          <div className="label-indent">
            <label>Surgeon:</label>
            <textarea
              name="surgeon_one"
              value={formData.surgeon_one}
              onChange={(e) =>
                setFormData({ ...formData, surgeon_one: e.target.value })
              }
            />
          </div>
          <div className="label-indent">
            <label>Anesthesiologist:</label>
            <textarea
              name="anesthesiologist"
              value={formData.anesthesiologist}
              onChange={(e) =>
                setFormData({ ...formData, anesthesiologist: e.target.value })
              }
            />
          </div>
          <div className="label-indent">
            <label>Anesthesia Type:</label>
            <input
              type="checkbox"
              name="anesth_local"
              checked={formData.anesth_local}
              onChange={(e) =>
                setFormData({ ...formData, anesth_local: e.target.checked })
              }
            />
            <label>Local</label>
            <input
              type="checkbox"
              name="anesth_sed"
              checked={formData.anesth_sed}
              onChange={(e) =>
                setFormData({ ...formData, anesth_sed: e.target.checked })
              }
            />
            <label>Sedation</label>
            <input
              type="checkbox"
              name="anesth_gen"
              checked={formData.anesth_gen}
              onChange={(e) =>
                setFormData({ ...formData, anesth_gen: e.target.checked })
              }
            />
            <label>General</label>
          </div>
          <div className="label-indent">
            <label>EBL</label>
            <input
              type="checkbox"
              name="EBL_none"
              checked={formData.EBL_none}
              onChange={(e) =>
                setFormData({ ...formData, EBL_none: e.target.checked })
              }
            />
            <label>None or</label>
            <input
              type="text"
              name="ebl_notes"
              className="di-input"
              value={formData.ebl_notes}
              onChange={(e) =>
                setFormData({ ...formData, ebl_notes: e.target.value })
              }
            />
          </div>
          <div className="label-indent">
            <label>Specimen</label>
            <input
              type="checkbox"
              name="specimen_NA"
              checked={formData.specimen_NA}
              onChange={(e) =>
                setFormData({ ...formData, specimen_NA: e.target.checked })
              }
            />
            <label>N/A</label>
          </div>
          <div className="label-indent">
            <label>Complications</label>
            <input
              type="checkbox"
              name="comp_none"
              checked={formData.comp_none}
              onChange={(e) =>
                setFormData({ ...formData, comp_none: e.target.checked })
              }
            />
            <label>None or</label>
            <input
              type="text"
              name="comp_notes"
              className="di-input"
              value={formData.comp_notes}
              onChange={(e) =>
                setFormData({ ...formData, comp_notes: e.target.value })
              }
            />
          </div>
          <div className="label-indent">
            <label>Condition to Recovery</label>
            <textarea
              name="cond_notes"
              value={formData.cond_notes}
              onChange={(e) =>
                setFormData({ ...formData, cond_notes: e.target.value })
              }
            />
          </div>
          <div className="label-indent">
            <div className="grievance-container">
              {" "}
              <div className="gc-item">
                {" "}
                <SignatureButton
                  individualSignature={individualSignature}
                  setIndividualSignature={setIndividualSignature}
                />
                <hr className="signature-line"></hr>
                <p>Surgeon's Signature</p>
              </div>
            </div>
          </div>
          <div className="label-indent">
            <label>Surgeon's Post-Op Notes</label>
            <textarea
              name="postop_notes"
              value={formData.postop_notes}
              onChange={(e) =>
                setFormData({ ...formData, postop_notes: e.target.value })
              }
            />
            <br />
            <label>Date:</label>
            <input
              type="date"
              className="di-input"
              name="date_two"
              value={formData.date_two}
              onChange={(e) =>
                setFormData({ ...formData, date_two: e.target.value })
              }
            />
            <label>Time</label>
            <input
              type="time"
              className="di-input"
              name="time_two"
              value={formData.time_two}
              onChange={(e) =>
                setFormData({ ...formData, time_two: e.target.value })
              }
            />
            <br />
          </div>
          <div className="label-indent">
            <div className="grievance-container">
              {" "}
              <div className="gc-item">
                {" "}
                <SignatureButton
                  individualSignature={notPatientSignature}
                  setIndividualSignature={(file) => {
                    console.log("Captured signature:", file);
                    setNotPatientSignature(file);
                  }}
                />
                <hr className="signature-line"></hr>
                <p>Surgeon's Signature</p>
              </div>
            </div>
          </div>
          <div className="label-indent">
            <label>Implant/Implant Sticker</label>
            <textarea
              name="implant"
              value={formData.implant}
              onChange={(e) =>
                setFormData({ ...formData, implant: e.target.value })
              }
            />
          </div>
          <div className="center mt-50">
            <button className="btn-primary hover-pointer" type="submit">
              Submit
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default OperativeNote;
