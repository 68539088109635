import React, { useState, useEffect } from "react";
import "./newpatientform.css";
import BackgroundImage from "../../../components/BackgroundImage";
import {
  postPatientInfo,
  createPatientInfoRecord,
  getDoctors,
  createBilling,
  createInsurance,
  createSurgery,
  createSurgeryInformation,
  createCommercial,
  createOop,
  createPip,
  createComp,
} from "../../../utils/api";
import { replaceSpecialChar, formatPhone } from "../../../utils/helpers";
const moment = require("moment");

const NewPatientForm = () => {
  /** Form States -  New Patient Information */
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [dob, setDob] = useState("");
  const [surgeon, setSurgeon] = useState("");
  const [doctors, setDoctors] = useState([]);
  const [manualInput, setManualInput] = useState(false);
  const [surgeonInput, setSurgeonInput] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [preferredLanguage, setPreferredLanguage] = useState("");

  /** Form States -  New Surgery */
  const [dos, setDos] = useState("");
  const [tos, setTos] = useState("");
  const [surgeryType, setSurgeryType] = useState("");

  /** Form States -  New Billing */
  const [billingType, setBillingType] = useState("Insurance");
  const [isInsuranceInput, setIsInsuranceInput] = useState(true);

  /** Form States -  Insurance, Out of Pocket & Commercial Insurance */
  const [provider, setProvider] = useState("");
  const [policyNumber, setPolicyNumber] = useState("");
  const [groupNumber, setGroupNumber] = useState("");
  const [employerPolicy, setEmployerPolicy] = useState("");
  const [insuranceName, setInsuranceName] = useState("");
  const [patientRelation, setPatientRelation] = useState("");
  const [insuranceAddress, setInsuranceAddress] = useState("");
  const [insurancePhone, setInsurancePhone] = useState("");
  const [insuranceId, setInsuranceId] = useState("");
  const [effectiveDate, setEffectiveDate] = useState("");

  /** Form States -  Personal Injury */
  const [caseNumber, setcaseNumber] = useState("");
  const [injuryDate, setinjuryDate] = useState("");
  const [attyName, setattyName] = useState("");
  const [attyPhone, setattyPhone] = useState("");
  const [attyFax, setattyFax] = useState("");
  const [cmName, setcmName] = useState("");
  const [cmPhone, setcmPhone] = useState("");
  const [cmFax, setcmFax] = useState("");
  const [cmEmail, setcmEmail] = useState("");
  const [cmAddress, setcmAddress] = useState("");
  const [accidentType, setaccidentType] = useState("");

  /** Form States -  Worker's Comp */
  const [compCaseNumber, setcompCaseNumber] = useState("");
  const [compInjuryDate, setcompInjuryDate] = useState("");
  const [adjName, setadjName] = useState("");
  const [adjPhone, setadjPhone] = useState("");
  const [adjFax, setadjFax] = useState("");
  const [adjEmail, setadjEmail] = useState("");
  const [billAddress, setbillAddress] = useState("");
  const [billPhone, setbillPhone] = useState("");

  /** Form States -  Conditionally Display Surgery or Billing Inputs */
  const [createNewSurgery, setcreateSurgery] = useState(false);
  const [createNewBilling, setcreateBilling] = useState(false);

  // State for loading and response message
  const [isLoading, setIsLoading] = useState(false);
  const [responseMessage, setResponseMessage] = useState("");

  /** Handle Form Submit */
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    /** Gather query payload - Patient Model */
    const newPatient = {
      first_name: firstName,
      last_name: lastName,
      birthday: dob,
      phone_number: phoneNumber,
      preferred_language: preferredLanguage,
    };
    if (email.trim() !== "") {
      newPatient.email = email;
    }

    /** FETCH 1: ADD PATIENT TO THE DB   */
    try {
      const response = await postPatientInfo(newPatient);
      if (response.ok) {
        const data = await response.json();
        const patient_id = data.newPatient.id;

        /** Add Patient Info, Surgery & Billing info if applicable */
        handleNewPatientInfo(patient_id);
      } else {
        const data = await response.json();

        if (data.message) {
          if (data.message === "Email already in use") {
            setResponseMessage(
              "This email is already in use for another patient. Please try a different email."
            );
          }
        } else {
          setResponseMessage("Failed to add patient. Please try again.");
        }
      }
    } catch (err) {
      console.log(err);
      setResponseMessage("An error occurred. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleNewPatientInfo = async (patient_id) => {
    /** FETCH 2: CREATE THE PATIENT INFORMATION RECORD AND ASSOCIATE TO NEW PATIENT  */
    try {
      const response = await createPatientInfoRecord({
        patient_id: patient_id,
      });
      if (response.ok) {
        const data = await response.json();

        /** Conditionally create the Surgery & Billing Records  */
        if (createNewSurgery === true && createNewBilling === true) {
          handleNewSurgery(patient_id, false).then(() => {
            handleNewBilling(data, true, patient_id);
          });
        } else if (createNewSurgery === true) {
          handleNewSurgery(patient_id, true);
        } else if (createNewBilling === true) {
          handleNewBilling(data, true, patient_id);
        } else {
          window.location.replace(`/patient/${patient_id}`);
        }

        return;
      } else {
        const data = await response.json();
        console.log(data);
        setResponseMessage(
          "An error had occured adding Information to this patient. Please contact the Admin for further assistance."
        );
      }
    } catch (error) {
      console.error(error);
      setResponseMessage(
        "An error had occured adding Information to this patient. Please contact the Admin for further assistance."
      );
    }
  };

  const handleNewSurgery = async (patient_id, shouldRedirect) => {
    /** Gather query payload - Surgery Model */
    const payload = {
      patient_id: patient_id,
      DOS: moment(dos).format("MM/DD/YYYY"),
      TOS: tos,
      type: surgeryType,
      physician: manualInput === true ? surgeonInput : surgeon,
    };

    /** FETCH: CREATE THE SURGERY RECORD AND ASSOCIATE TO THE NEW PATIENT  */
    try {
      const response = await createSurgery(payload);
      if (response.ok) {
        /** Gather query payload - Surgery Information Model */
        const data = await response.json();
        const payload2 = { surgery_id: data.newSurgery.id };

        /** FETCH: CREATE THE SURGERY INFORMATION RECORD AND ASSOCIATE TO THE NEW SURGERY  */
        const response2 = await createSurgeryInformation(payload2);
        if (response2.ok) {
          // Redirect if NO pending Fetch requests remain
          if (shouldRedirect === true) {
            window.location.replace(`/patient/${patient_id}`);
          }
        } else {
          const data = await response.json();
          console.log(data);
          setResponseMessage(
            "An error had occured adding Surgery Information to this Surgery. Please contact the Admin for further assistance."
          );
        }

        return;
      } else {
        const data = await response.json();
        console.log(data);
        setResponseMessage(
          "An error had occured adding a Surgery to this patient. Please contact the Admin for further assistance."
        );
      }
    } catch (error) {
      console.error(error);
      setResponseMessage(
        "An error had occured adding a Surgery to this patient. Please contact the Admin for further assistance."
      );
    }
  };

  const handleNewBilling = async (data, shouldRedirect, patient_id) => {
    /** Gather query payload - Billing Model */
    const payload = {
      patient_info_id: data.newPatientInformation.patient_info_id,
      type: billingType,
    };

    /** FETCH: CREATE THE BILLING RECORD AND ASSOCIATE TO THE PATIENT  */
    try {
      const response = await createBilling(payload);
      if (response.ok) {
        const data = await response.json();

        /** Conditionally add the type of billing record  */
        switch (billingType) {
          case "Insurance":
            /** FETCH: CREATE THE INSURANCE RECORD AND ASSOCIATE TO THE BILLING RECORD  */
            try {
              const response = await createInsurance({
                billing_id: data.newBilling.id,
                provider: provider,
                policy_number: policyNumber,
                group_number: groupNumber,
                policy_through_employer: employerPolicy,
                name_on_insurance: insuranceName,
                relation_to_patient: patientRelation,
                address: insuranceAddress,
                phone_number: insurancePhone,
                id_number: insuranceId,
                effective_date: moment(effectiveDate).format("MM/DD/YYYY"),
                date_created: moment().format("MM/DD/YYYY"),
              });
              if (response.ok) {
                //const data = await response.json();
                // Redirect if NO pending Fetch requests remain
                if (shouldRedirect === true) {
                  window.location.replace(`/patient/${patient_id}`);
                }

                return;
              } else {
                const data = await response.json();
                console.log(data);
                setResponseMessage(
                  "An error had occured adding Insurance to this patient. Please contact the Admin for further assistance."
                );
              }
            } catch (error) {
              console.error(error);
              setResponseMessage(
                "An error had occured adding Insurance to this patient. Please contact the Admin for further assistance."
              );
            }
            break;
          case "Commercial":
            /** FETCH: CREATE THE COMMERCIAL INSURANCE RECORD AND ASSOCIATE TO THE BILLING RECORD  */
            try {
              const response = await createCommercial({
                billing_id: data.newBilling.id,
                provider: provider,
                policy_number: policyNumber,
                group_number: groupNumber,
                policy_through_employer: employerPolicy,
                name_on_insurance: insuranceName,
                relation_to_patient: patientRelation,
                address: insuranceAddress,
                phone_number: insurancePhone,
                id_number: insuranceId,
                effective_date: moment(effectiveDate).format("MM/DD/YYYY"),
                date_created: moment().format("MM/DD/YYYY"),
              });
              if (response.ok) {
                //const data = await response.json();
                // Redirect if NO pending Fetch requests remain
                if (shouldRedirect === true) {
                  window.location.replace(`/patient/${patient_id}`);
                }

                return;
              } else {
                const data = await response.json();
                console.log(data);
                setResponseMessage(
                  "An error had occured adding Commercial Insurance to this patient. Please contact the Admin for further assistance."
                );
              }
            } catch (error) {
              console.error(error);
              setResponseMessage(
                "An error had occured adding Commercial Insurance to this patient. Please contact the Admin for further assistance."
              );
            }
            break;
          case "Oop":
            /** FETCH: CREATE THE OUT OF POCKET RECORD AND ASSOCIATE TO THE BILLING RECORD  */
            try {
              const response = await createOop({
                billing_id: data.newBilling.id,
                provider: provider,
                policy_number: policyNumber,
                group_number: groupNumber,
                policy_through_employer: employerPolicy,
                name_on_insurance: insuranceName,
                relation_to_patient: patientRelation,
                address: insuranceAddress,
                phone_number: insurancePhone,
                id_number: insuranceId,
                effective_date: moment(effectiveDate).format("MM/DD/YYYY"),
                date_created: moment().format("MM/DD/YYYY"),
              });
              if (response.ok) {
                //const data = await response.json();
                // Redirect if NO pending Fetch requests remain
                if (shouldRedirect === true) {
                  window.location.replace(`/patient/${patient_id}`);
                }

                return;
              } else {
                const data = await response.json();
                console.log(data);
                setResponseMessage(
                  "An error had occured adding Out Of Pocket to this patient. Please contact the Admin for further assistance."
                );
              }
            } catch (error) {
              console.error(error);
              setResponseMessage(
                "An error had occured adding Out Of Pocket to this patient. Please contact the Admin for further assistance."
              );
            }
            break;
          case "Pip":
            /** FETCH: CREATE THE PIP RECORD AND ASSOCIATE TO THE BILLING RECORD  */
            try {
              const response = await createPip({
                billing_id: data.newBilling.id,
                case_number: caseNumber,
                date_of_injury: moment(injuryDate).format("MM/DD/YYYY"),
                attorney_name: attyName,
                attorney_phone: attyPhone,
                attorney_fax: attyFax,
                case_manager_name: cmName,
                case_manager_phone: cmPhone,
                case_manager_fax: cmFax,
                case_manager_email: cmEmail,
                case_manager_address: cmAddress,
                type_of_accident: accidentType,
                date_created: moment().format("MM/DD/YYYY"),
              });
              if (response.ok) {
                //const data = await response.json();
                // Redirect if NO pending Fetch requests remain
                if (shouldRedirect === true) {
                  window.location.replace(`/patient/${patient_id}`);
                }

                return;
              } else {
                const data = await response.json();
                console.log(data);
                setResponseMessage(
                  "An error had occured adding Personal Injury to this patient. Please contact the Admin for further assistance."
                );
              }
            } catch (error) {
              console.error(error);
              setResponseMessage(
                "An error had occured adding Personal Injury to this patient. Please contact the Admin for further assistance."
              );
            }
            break;
          case "Comp":
            /** FETCH: CREATE THE COMP RECORD AND ASSOCIATE TO THE BILLING RECORD  */
            try {
              const response = await createComp({
                billing_id: data.newBilling.id,
                case_number: compCaseNumber,
                date_of_injury: moment(compInjuryDate).format("MM/DD/YYYY"),
                adjuster_name: adjName,
                adjuster_phone: adjPhone,
                adjuster_fax: adjFax,
                adjuster_email: adjEmail,
                bill_review_address: billAddress,
                bill_review_phone: billPhone,
                date_created: moment().format("MM/DD/YYYY"),
              });
              if (response.ok) {
                //const data = await response.json();
                // Redirect if NO pending Fetch requests remain
                if (shouldRedirect === true) {
                  window.location.replace(`/patient/${patient_id}`);
                }

                return;
              } else {
                const data = await response.json();
                console.log(data);
                setResponseMessage(
                  "An error had occured adding Worker's Comp to this patient. Please contact the Admin for further assistance."
                );
              }
            } catch (error) {
              console.error(error);
              setResponseMessage(
                "An error had occured adding Worker's Comp to this patient. Please contact the Admin for further assistance."
              );
            }
            break;
          default:
            return;
        }
      } else {
        const data = await response.json();
        console.log(data);
        setResponseMessage(
          "An error had occured adding Billing to this patient. Please contact the Admin for further assistance."
        );
      }
    } catch (error) {
      console.error(error);
      setResponseMessage(
        "An error had occured adding Billing to this patient. Please contact the Admin for further assistance."
      );
    }
  };

  /** Fetch Doctors from the db for Dropdown on page load */
  useEffect(() => {
    getDoctors()
      .then((res) => res.json())
      .then((data) => {
        setDoctors(data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <div className="create-grid-container">
      <div className="first-column"></div>
      <div className="second-column patient-form-media">
        <BackgroundImage />
        <div>
          <form className="new-patient-form" onSubmit={handleSubmit}>
            <h1>New Patient Information</h1>
            <div className="border-bottom"></div>
            <div className="patient-form-row">
              <div className="patient-form-group">
                <label htmlFor="first-name">
                  Name<span className="asterick"> *</span>
                </label>
                <input
                  type="text"
                  id="first-name"
                  
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                />
                <label className="bottom-label" htmlFor="first-name">
                  First Name
                </label>
              </div>
              <div className="patient-form-group">
                <label htmlFor="last-name">&nbsp;</label>
                <input
                  type="text"
                  id="last-name"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                />
                <label className="bottom-label" htmlFor="last-name">
                  Last Name
                </label>
              </div>
              <div className="patient-form-group">
                <label htmlFor="dob">
                  Date of Birth<span className="asterick"> *</span>
                </label>
                <input
                  type="date"
                  id="dob"
                  value={dob}
                  onChange={(e) => setDob(e.target.value)}
                  
                />
              </div>
            </div>

            {/* Doctors */}
            <div className="patient-form-row">
              <div className="patient-form-group">
                <label htmlFor="surgeon">
                  Surgeon<span className="asterick"> *</span>
                </label>
                <select
                  value={surgeon}
                  onChange={(e) => {
                    e.target.value === "Other"
                      ? setManualInput(true)
                      : setManualInput(false);

                    setSurgeon(e.target.value);
                  }}
                  id="surgeon"
                  
                  className="patient-dr"
                >
                  <option value="">Select Surgeon</option>
                  {/* Doctors from the DB */}
                  {doctors.length > 0 ? (
                    doctors.map((doctor) => (
                      <option
                        key={doctor.id}
                        value={`${doctor.first_name} ${doctor.last_name}`}
                      >
                        {doctor.first_name} {doctor.last_name}
                      </option>
                    ))
                  ) : (
                    <></>
                  )}
                  <option value="Other">Other</option>
                </select>
                {/* Manual Input */}
                {manualInput === true ? (
                  <input
                    type="tel"
                    id="patient-tel"
                    style={{ marginTop: "10px" }}
                    
                    value={surgeonInput}
                    placeholder="Enter Surgeon Name"
                    onChange={(e) => {
                      setSurgeonInput(e.target.value);
                    }}
                  />
                ) : (
                  <></>
                )}
              </div>
              <div className="patient-form-group">
                <label htmlFor="patient-tel">
                  Phone Number<span className="asterick"> *</span>
                </label>
                <input
                  type="tel"
                  id="patient-tel"
                  
                  value={phoneNumber}
                  onChange={(e) => {
                    let num = replaceSpecialChar(e.target.value);
                    const phone = formatPhone(num);
                    setPhoneNumber(phone);
                  }}
                />
              </div>
              <div className="patient-form-group">
                <label htmlFor="email">Email</label>
                <input
                  type="email"
                  id="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
            </div>
            <div className="patient-form-row">
              <div className="patient-form-group language">
                <label htmlFor="preferred-languages">Preferred Languages</label>
                <input
                  type="text"
                  id="preferred-languages"
                  placeholder="Preferred Languages"
                  value={preferredLanguage}
                  onChange={(e) => setPreferredLanguage(e.target.value)}
                />
              </div>
            </div>

            <hr
              style={{ marginTop: "30px", border: ".5px solid #83829266" }}
            ></hr>

            <div className="patient-form-row">
              <button
                type="button"
                className="add-patient secondary"
                onClick={() => {
                  createNewSurgery
                    ? setcreateSurgery(false)
                    : setcreateSurgery(true);
                }}
              >
                {createNewSurgery ? "Remove Surgery" : "Add Surgery"}
              </button>
            </div>
            <br></br>

            {/* Surgery */}
            {createNewSurgery ? (
              <div className="patient-form-row">
                <div className="patient-form-group">
                  <label htmlFor="date-of-service">
                    Date of Service <span className="asterick"> *</span>
                  </label>
                  <input
                    type="date"
                    id="date-of-service"
                    className="date-of-service"
                    
                    value={dos}
                    onChange={(e) => setDos(e.target.value)}
                  />
                </div>
                <div className="patient-form-group">
                  <label htmlFor="time-of-service">
                    Time of Service <span className="asterick"> *</span>
                  </label>
                  <input
                    type="time"
                    id="time-of-service"
                    className="time-of-service"
                    
                    value={tos}
                    onChange={(e) => setTos(e.target.value)}
                  />
                </div>
                <div className="patient-form-group">
                  <label htmlFor="surgery-type">
                    Surgery Type <span className="asterick"> *</span>
                  </label>
                  <input
                    type="text"
                    id="surgery-type"
                    value={surgeryType}
                    
                    onChange={(e) => setSurgeryType(e.target.value)}
                  />
                </div>
              </div>
            ) : (
              <></>
            )}

            <hr
              style={{ marginTop: "10px", border: ".5px solid #83829266" }}
            ></hr>

            {/* Billing Information */}
            <div className="patient-form-row">
              <button
                type="button"
                className="add-patient secondary"
                onClick={() => setcreateBilling(!createNewBilling)}
              >
                {createNewBilling ? "Remove Billing" : "Add Billing"}
              </button>
            </div>

            <br />

            {createNewBilling && (
              <>
                <div className="patient-form-row">
                  <div className="patient-form-group billing">
                    <label htmlFor="billing-type">Billing Type</label>
                    <select
                      id="billing-type"
                      className="billing-type"
                      value={billingType}
                      onChange={(e) => {
                        const input = e.target.value;
                        if (
                          input === "Insurance" ||
                          input === "Oop" ||
                          input === "Commercial"
                        ) {
                          setIsInsuranceInput(true);
                        } else {
                          setIsInsuranceInput(false);
                        }

                        setBillingType(input);
                      }}
                      name="billing-type"
                    >
                      <option value="Insurance">Insurance</option>
                      <option value="Oop">Out of Pocket</option>
                      <option value="Pip">Personal Injury</option>
                      <option value="Comp">Worker's Comp</option>
                      <option value="Commercial">Commercial</option>
                    </select>
                  </div>
                </div>

                {/* INSURANCE, OUT OF POCKET & COMMERCIAL INSURANCE TYPE INPUTS */}
                {isInsuranceInput === true && (
                  <>
                    <div className="patient-form-row insurance-row">
                      <div className="patient-form-group">
                        <label htmlFor="provider">Provider</label>
                        <input
                          id="provider"
                          name="provider"
                          type="text"
                          value={provider}
                          onChange={(e) => setProvider(e.target.value)}
                        />
                      </div>
                      <div className="patient-form-group">
                        <label htmlFor="effective-date">Effective Date</label>
                        <input
                          id="effective-date"
                          name="effective-date"
                          type="date"
                          value={effectiveDate}
                          onChange={(e) => setEffectiveDate(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="patient-form-row insurance-row">
                      <div className="patient-form-group">
                        <label htmlFor="policy-number">Policy Number</label>
                        <input
                          id="policy-number"
                          name="policy-number"
                          type="text"
                          value={policyNumber}
                          onChange={(e) => setPolicyNumber(e.target.value)}
                        />
                      </div>
                      <div className="patient-form-group">
                        <label htmlFor="group-number">Group Number</label>
                        <input
                          id="group-number"
                          name="group-number"
                          type="text"
                          value={groupNumber}
                          onChange={(e) => setGroupNumber(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="patient-form-row insurance-row">
                      <div className="patient-form-group">
                        <label htmlFor="employer-policy">
                          Policy through Employer
                        </label>
                        <input
                          id="employer-policy"
                          name="employer-policy"
                          type="text"
                          value={employerPolicy}
                          onChange={(e) => setEmployerPolicy(e.target.value)}
                        />
                      </div>
                      <div className="patient-form-group">
                        <label htmlFor="insurance-name">
                          Name on Insurance
                        </label>
                        <input
                          id="insurance-name"
                          name="insurance-name"
                          type="text"
                          value={insuranceName}
                          onChange={(e) => setInsuranceName(e.target.value)}
                        />
                      </div>
                      <div className="patient-form-group">
                        <label htmlFor="patient-relation">
                          Relationship to Patient
                        </label>
                        <input
                          id="patient-relation"
                          name="patient-relation"
                          type="text"
                          value={patientRelation}
                          onChange={(e) => setPatientRelation(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="patient-form-row insurance-row">
                      <div className="patient-form-group">
                        <label htmlFor="insurance-address">Address</label>
                        <input
                          id="insurance-address"
                          name="insurance-address"
                          type="text"
                          value={insuranceAddress}
                          onChange={(e) => setInsuranceAddress(e.target.value)}
                        />
                      </div>
                      <div className="patient-form-group">
                        <label htmlFor="insurance-phone">Phone Number</label>
                        <input
                          id="insurance-phone"
                          name="insurance-phone"
                          type="text"
                          value={insurancePhone}
                          onChange={(e) => {
                            let num = replaceSpecialChar(e.target.value);
                            const phone = formatPhone(num);
                            setInsurancePhone(phone);
                          }}
                        />
                      </div>
                      <div className="patient-form-group">
                        <label htmlFor="insurance-id">ID Number</label>
                        <input
                          id="insurance-id"
                          name="insurance-id"
                          type="text"
                          value={insuranceId}
                          onChange={(e) => setInsuranceId(e.target.value)}
                        />
                      </div>
                    </div>
                  </>
                )}

                {/* PERSONAL INJURY TYPE INPUTS */}
                {billingType === "Pip" && (
                  <>
                    <div className="patient-form-row insurance-row">
                      <div className="patient-form-group">
                        <label htmlFor="case-number">Case Number</label>
                        <input
                          id="case-number"
                          name="case-number"
                          type="text"
                          value={caseNumber}
                          onChange={(e) => setcaseNumber(e.target.value)}
                        />
                      </div>
                      <div className="patient-form-group">
                        <label htmlFor="injury-date">Date of Injury</label>
                        <input
                          id="injury-date"
                          name="injury-date"
                          type="date"
                          value={injuryDate}
                          onChange={(e) => setinjuryDate(e.target.value)}
                        />
                      </div>
                      <div className="patient-form-group">
                        <label htmlFor="accident-type">Accident Type</label>
                        <input
                          id="accident-type"
                          name="accident-type"
                          type="text"
                          value={accidentType}
                          onChange={(e) => setaccidentType(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="patient-form-row insurance-row">
                      <div className="patient-form-group">
                        <label htmlFor="atty-name">Attorney Name</label>
                        <input
                          id="atty-name"
                          name="atty-name"
                          type="text"
                          value={attyName}
                          onChange={(e) => setattyName(e.target.value)}
                        />
                      </div>
                      <div className="patient-form-group">
                        <label htmlFor="atty-phone">Attorney Phone</label>
                        <input
                          id="atty-phone"
                          phone="atty-phone"
                          type="text"
                          value={attyPhone}
                          onChange={(e) => {
                            let num = replaceSpecialChar(e.target.value);
                            const phone = formatPhone(num);
                            setattyPhone(phone);
                          }}
                        />
                      </div>
                      <div className="patient-form-group">
                        <label htmlFor="atty-fax">Attorney Fax</label>
                        <input
                          id="atty-fax"
                          name="atty-fax"
                          type="text"
                          value={attyFax}
                          onChange={(e) => setattyFax(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="patient-form-row insurance-row">
                      <div className="patient-form-group">
                        <label htmlFor="cm-name">Case Manager Name</label>
                        <input
                          id="cm-name"
                          name="cm-name"
                          type="text"
                          value={cmName}
                          onChange={(e) => setcmName(e.target.value)}
                        />
                      </div>
                      <div className="patient-form-group">
                        <label htmlFor="cm-phone">Case Manager Phone</label>
                        <input
                          id="cm-phone"
                          name="cm-phone"
                          type="text"
                          value={cmPhone}
                          onChange={(e) => {
                            let num = replaceSpecialChar(e.target.value);
                            const phone = formatPhone(num);
                            setcmPhone(phone);
                          }}
                        />
                      </div>
                      <div className="patient-form-group">
                        <label htmlFor="cm-fax">Case Manager Fax</label>
                        <input
                          id="cm-fax"
                          name="cm-fax"
                          type="text"
                          value={cmFax}
                          onChange={(e) => setcmFax(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="patient-form-row insurance-row">
                      <div className="patient-form-group">
                        <label htmlFor="cm-email">Case Manager Email</label>
                        <input
                          id="cm-email"
                          name="cm-email"
                          type="email"
                          value={cmEmail}
                          onChange={(e) => setcmEmail(e.target.value)}
                        />
                      </div>
                      <div className="patient-form-group">
                        <label htmlFor="cm-address">Case Manager Address</label>
                        <input
                          id="cm-address"
                          name="cm-address"
                          type="text"
                          value={cmAddress}
                          onChange={(e) => setcmAddress(e.target.value)}
                        />
                      </div>
                    </div>
                  </>
                )}

                {/* WORKERS COMP TYPE INPUTS */}
                {billingType === "Comp" && (
                  <>
                    <div className="patient-form-row insurance-row">
                      <div className="patient-form-group">
                        <label htmlFor="comp-case-number">Case Number</label>
                        <input
                          id="comp-case-number"
                          name="comp-case-number"
                          type="text"
                          value={compCaseNumber}
                          onChange={(e) => setcompCaseNumber(e.target.value)}
                        />
                      </div>
                      <div className="patient-form-group">
                        <label htmlFor="comp-injury-date">Date of Injury</label>
                        <input
                          id="comp-injury-date"
                          name="comp-injury-date"
                          type="date"
                          value={compInjuryDate}
                          onChange={(e) => setcompInjuryDate(e.target.value)}
                        />
                      </div>
                      <div className="patient-form-group">
                        <label htmlFor="adjuster-name">Adjuster Name</label>
                        <input
                          id="adjuster-name"
                          name="adjuster-name"
                          type="text"
                          value={adjName}
                          onChange={(e) => setadjName(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="patient-form-row insurance-row">
                      <div className="patient-form-group">
                        <label htmlFor="adjuster-phone">Adjuster Phone</label>
                        <input
                          id="adjuster-phone"
                          name="adjuster-phone"
                          type="text"
                          value={adjPhone}
                          onChange={(e) => {
                            let num = replaceSpecialChar(e.target.value);
                            const phone = formatPhone(num);
                            setadjPhone(phone);
                          }}
                        />
                      </div>
                      <div className="patient-form-group">
                        <label htmlFor="adjuster-fax">Adjuster Fax</label>
                        <input
                          id="adjuster-fax"
                          name="adjuster-fax"
                          type="text"
                          value={adjFax}
                          onChange={(e) => setadjFax(e.target.value)}
                        />
                      </div>
                      <div className="patient-form-group">
                        <label htmlFor="adjuster-email">Adjuster Email</label>
                        <input
                          id="adjuster-email"
                          name="adjuster-email"
                          type="email"
                          value={adjEmail}
                          onChange={(e) => setadjEmail(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="patient-form-row insurance-row">
                      <div className="patient-form-group">
                        <label htmlFor="bill-review-address">
                          Billing Review Address
                        </label>
                        <input
                          id="bill-review-address"
                          name="bill-review-address"
                          type="text"
                          value={billAddress}
                          onChange={(e) => setbillAddress(e.target.value)}
                        />
                      </div>
                      <div className="patient-form-group">
                        <label htmlFor="bill-review-phone">
                          Billing Review Phone
                        </label>
                        <input
                          id="bill-review-phone"
                          name="bill-review-phone"
                          type="text"
                          value={billPhone}
                          onChange={(e) => {
                            let num = replaceSpecialChar(e.target.value);
                            const phone = formatPhone(num);
                            setbillPhone(phone);
                          }}
                        />
                      </div>
                    </div>
                  </>
                )}
              </>
            )}

            <hr
              style={{ marginTop: "10px", border: ".5px solid #83829266" }}
            ></hr>

            <div className="patient-form-row">
              <button
                type="submit"
                className="add-patient"
                disabled={isLoading}
              >
                {isLoading ? "Adding Patient..." : "Add Patient"}
              </button>
            </div>
            {/*response message */}
            {responseMessage && (
              <p className="response-message">{responseMessage}</p>
            )}
          </form>
        </div>
      </div>
    </div>
  );
};

export default NewPatientForm;
