import React, { useState, useEffect } from "react";
import { Icon } from "@iconify/react";
import "../Forms/styles.css";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import moment from "moment";
import {
  addFile,
  getPhysiciansDischargeOrders,
  updatePhysiciansDischargeOrders,
} from "../../../utils/api";
import SignatureButton from "../../../components/SignatureButton";

const PhysiciansDischargeOrders = () => {
  const navigate = useNavigate();
  const { form_id } = useParams();
  const [loading, setLoading] = useState(true);
  const [individualSignature, setIndividualSignature] = useState("");
  const [notPatientSignature, setNotPatientSignature] = useState("");

  const [formData, setFormData] = useState({
    adult: false,
    cleared: false,
    followup: false,
    diet: false,
    meds: false,
    sheet: false,
    prescription: false,
    DC_IV: false,
    prescription_one_check: false,
    prescription_two_check: false,
    prescription_three_check: false,
    followup_with: "",
    followup_in: "",
    prescription_one_notes: "",
    prescription_two_notes: "",
    prescription_three_notes: "",
    date_one: "",
    date_two: "",
    time_one: "",
    time_two: "",
    individualSignature: "",
  });
  const handleSubmit = async (e) => {
    e.preventDefault();
    let mdFileName, surgeonFileName;

    // Fetch 1: Upload Signature
    if (individualSignature !== "") {
      const response = await addFile(individualSignature);

      const data = await response.json();
      mdFileName = data.fileName;
    }
    if (notPatientSignature !== "") {
      const response = await addFile(notPatientSignature);
      const data = await response.json();
      surgeonFileName = data.fileName || null;
    }

    const payload = {
      surgeon_signature: surgeonFileName,
      md_signature: mdFileName,
      ...formData,
      date: moment().format("MM/DD/YYYY"),
      time: moment().format("h:mm a"),
      date_one: moment().format("MM/DD/YYYY"),
      time_one: moment().format("h:mm a"),
      date_two: moment().format("MM/DD/YYYY"),
      time_two: moment().format("h:mm a"),
      status: "In Progress",
    };

    console.log("Payload Sent to Backend:", payload);

    // Fetch 2: Create/Update the Disclosure of Ownerhsip Model

    updatePhysiciansDischargeOrders(form_id, payload)
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        console.log("complete");
        navigate(-1);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getPhysiciansDischargeOrders(form_id)
      .then((res) => res.json())
      .then((data) => {
        console.log(data);

        if (data.status !== "Not Started") {
          setFormData({
            adult: data.adult || false,
            cleared: data.cleared || false,
            followup: data.followup || false,
            diet: data.diet || false,
            meds: data.meds || false,
            sheet: data.sheet || false,
            prescription: data.prescription || false,
            DC_IV: data.DC_IV || false,
            prescription_one_check: data.prescription_one_check || false,
            prescription_two_check: data.prescription_two_check || false,
            prescription_three_check: data.prescription_three_check || false,
            followup_with: data.followup_with || "",
            followup_in: data.followup_in || "",
            prescription_one_notes: data.prescription_one_notes || "",
            prescription_two_notes: data.prescription_two_notes || "",
            prescription_three_notes: data.prescription_three_notes || "",
            date_one: data.date_one || "",
            date_two: data.date_two || "",
            time_one: data.time_one || "",
            time_two: data.time_two || "",
          });
        }
        setLoading(false);
      })
      .catch((err) => console.log(err));
  }, []);
  if (loading) return <p>Loading...</p>;

  return (
    <>
      <div
        className="arrow-container"
        onClick={() => navigate(-1)}
        style={{ cursor: "pointer" }}
      >
        <p className="back-arrow">
          <Icon icon="ic:twotone-arrow-back" className="icon" /> Back to Forms
        </p>
      </div>{" "}
      <div className="form-content">
        <form onSubmit={handleSubmit}>
          {" "}
          <h2 className="form-title">Physician Discharge Orders</h2>
          <div className="label-indent">
            <input
              type="checkbox"
              name="DC_IV"
              checked={formData.DC_IV}
              onChange={(e) =>
                setFormData({ ...formData, DC_IV: e.target.checked })
              }
            />
            <label>DC IV prior to discharge</label>
          </div>
          <div className="label-indent">
            <input
              type="checkbox"
              name="adult"
              checked={formData.adult}
              onChange={(e) =>
                setFormData({ ...formData, adult: e.target.checked })
              }
            />
            <label>
              Discharge home when criteria is met with responsible adult
            </label>
          </div>
          <div className="label-indent">
            <input
              type="checkbox"
              name="cleared"
              checked={formData.cleared}
              onChange={(e) =>
                setFormData({ ...formData, cleared: e.target.checked })
              }
            />
            <label>Discharge home when cleared by anesthesia</label>
          </div>
          <div className="label-indent">
            <input
              type="checkbox"
              name="followup"
              checked={formData.followup}
              onChange={(e) =>
                setFormData({ ...formData, followup: e.target.checked })
              }
            />
            <label>Follow up with</label>
            <input
              type="text"
              className="di-input"
              name="followup_with"
              value={formData.followup_with}
              onChange={(e) =>
                setFormData({ ...formData, followup_with: e.target.value })
              }
            />
            <label>in</label>
            <input
              type="text"
              className="di-input"
              name="followup_in"
              value={formData.followup_in}
              onChange={(e) =>
                setFormData({ ...formData, followup_in: e.target.value })
              }
            />
            <label>weeks</label>
          </div>
          <div className="label-indent">
            <input
              type="checkbox"
              name="diet"
              checked={formData.diet}
              onChange={(e) =>
                setFormData({ ...formData, diet: e.target.checked })
              }
            />
            <label>Advance diet as tolerated</label>
          </div>
          <div className="label-indent">
            <input
              type="checkbox"
              name="meds"
              checked={formData.meds}
              onChange={(e) =>
                setFormData({ ...formData, meds: e.target.checked })
              }
            />
            <label>Resume medications as prescribed</label>
          </div>
          <div className="label-indent">
            <input
              type="checkbox"
              name="sheet"
              checked={formData.sheet}
              onChange={(e) =>
                setFormData({ ...formData, sheet: e.target.checked })
              }
            />
            <label>Review post-op instruction sheet with patient</label>
          </div>
          <div className="label-indent">
            <input
              type="checkbox"
              name="prescription"
              checked={formData.prescription}
              onChange={(e) =>
                setFormData({ ...formData, prescription: e.target.checked })
              }
            />
            <label>Prescription given:</label>
            <div className="label-indent">
              <input
                type="checkbox"
                name="prescription_one_check"
                checked={formData.prescription_one_check}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    prescription_one_check: e.target.checked,
                  })
                }
              />
              <input
                type="text"
                className="long-input"
                name="prescription_one_notes"
                value={formData.prescription_one_notes}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    prescription_one_notes: e.target.value,
                  })
                }
              />
            </div>
            <div className="label-indent">
              <input
                type="checkbox"
                name="prescription_two_check"
                checked={formData.prescription_two_check}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    prescription_two_check: e.target.checked,
                  })
                }
              />
              <input
                type="text"
                className="long-input"
                name="prescription_two_notes"
                value={formData.prescription_two_notes}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    prescription_two_notes: e.target.value,
                  })
                }
              />
            </div>
            <div className="label-indent">
              <input
                type="checkbox"
                name="prescription_three_check"
                checked={formData.prescription_three_check}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    prescription_three_check: e.target.checked,
                  })
                }
              />
              <input
                type="text"
                className="long-input"
                name="prescription_three_notes"
                value={formData.prescription_three_notes}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    prescription_three_notes: e.target.value,
                  })
                }
              />
            </div>
          </div>
          <div className="label-indent grievance-container">
            <div className="gc-item ">
              {" "}
              <SignatureButton
                individualSignature={individualSignature}
                setIndividualSignature={setIndividualSignature}
                required
              />
              <hr className="signature-line"></hr>
              <p>Physician's Signature</p>
            </div>
            <div className="gc-item mt-50">
              {" "}
              <label htmlFor="name" className="hidden">
                Physician's Signature
              </label>
              <input
                name="date_one"
                type="date"
                value={formData.date_one}
                onChange={(e) =>
                  setFormData({ ...formData, date_one: e.target.value })
                }
                className="bottom-border-input"
              />
              <p>Date</p>
            </div>
            <div className="gc-item mt-50">
              {" "}
              <label htmlFor="name" className="hidden">
                Time
              </label>
              <input
                className="bottom-border-input"
                type="time"
                name="time_one"
                value={formData.time_one}
                onChange={(e) =>
                  setFormData({ ...formData, time_one: e.target.value })
                }
              />
              <p>Time</p>
            </div>
          </div>
          <div className="text-center">
            <p>
              <b>
                I have evaluated the patient and the patient is stable and ready
                for discharge.
              </b>
            </p>
          </div>
          <div className="label-indent grievance-container">
            <div className="gc-item ">
              {" "}
              <SignatureButton
                individualSignature={notPatientSignature}
                setIndividualSignature={(file) => {
                  setNotPatientSignature(file);
                }}
               
              />
              <hr className="signature-line"></hr>
              <p>M.D.</p>
            </div>
            <div className="gc-item mt-50">
              {" "}
              <label htmlFor="name" className="hidden"></label>
              <input
                className="bottom-border-input"
                name="date_two"
                type="date"
                value={formData.date_two}
                onChange={(e) =>
                  setFormData({ ...formData, date_two: e.target.value })
                }
              />
              <p>Date</p>
            </div>
            <div className="gc-item mt-50">
              {" "}
              <label htmlFor="name" className="hidden">
                Time
              </label>
              <input
                className="bottom-border-input"
                name="time_two"
                type="time"
                value={formData.time_two}
                onChange={(e) =>
                  setFormData({ ...formData, time_two: e.target.value })
                }
              />
              <p>Time</p>
            </div>
          </div>
          <div className="center mt-50">
            <button className="btn-primary hover-pointer" type="submit">
              Submit
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default PhysiciansDischargeOrders;
