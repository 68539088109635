import React, { useState } from "react";
import { Icon } from "@iconify/react";
import "../Forms/styles.css";
import { useNavigate } from "react-router-dom";
import SignatureButton from "../../../components/SignatureButton";

const PatientSatisfactionQues = () => {
  const navigate = useNavigate();

  return (
    <>
      <div
        className="arrow-container"
        onClick={() => navigate(-1)}
        style={{ cursor: "pointer" }}
      >
        <p className="back-arrow">
          <Icon icon="ic:twotone-arrow-back" className="icon" /> Back to Forms
        </p>
      </div>
      <div className="form-content">
        <div>
          <form>
            <div className="satisfaction-container">
              <h2>Patient Satisfaction Questionnaire</h2>
              <p>
                We have tried to make your Ambulatory Surgery experience as
                comfortable as possible. We are striving for perfection and,
                therefore, need your assessment of our services, personnel, and
                facility. Please complete this questionnaire and return it to
                us.
              </p>

              <div className="survey">
                <div className="pa-question">
                  <div>
                    <label className="question-label">
                      1. Do you feel that your instructions prior to surgery
                      were adequate?
                    </label>
                  </div>
                  <div className="psq-inputs">
                    <input type="checkbox" />
                    <label>Excellent</label>
                    <input type="checkbox" />
                    <label>Good</label>
                    <input type="checkbox" />
                    <label>Fair</label>
                    <input type="checkbox" />
                    <label>Poor</label>
                  </div>
                </div>

                <div className="pa-question">
                  <div>
                    <label className="question-label">
                      2. Do you feel that the Surgical Center personnel were
                      <br /> interested in you as a person?
                    </label>
                  </div>
                  <div className="psq-inputs">
                    <input type="checkbox" />
                    <label>Excellent</label>
                    <input type="checkbox" />
                    <label>Good</label>
                    <input type="checkbox" />
                    <label>Fair</label>
                    <input type="checkbox" />
                    <label>Poor</label>
                  </div>
                </div>

                <div className="pa-question">
                  <div>
                    <label className="question-label">
                      3.Was the Surgery Center pleasant and comfortable?
                    </label>
                  </div>
                  <div className="psq-inputs">
                    <input type="checkbox" />
                    <label>Excellent</label>
                    <input type="checkbox" />
                    <label>Good</label>
                    <input type="checkbox" />
                    <label>Fair</label>
                    <input type="checkbox" />
                    <label>Poor</label>
                  </div>
                </div>

                <div className="pa-question">
                  <div>
                    <label className="question-label">
                      4. Do you feel that the separation from your family
                      <br /> member or friend was minimal?
                    </label>
                  </div>
                  <div className="psq-inputs">
                    <input type="checkbox" />
                    <label>Excellent</label>
                    <input type="checkbox" />
                    <label>Good</label>
                    <input type="checkbox" />
                    <label>Fair</label>
                    <input type="checkbox" />
                    <label>Poor</label>
                  </div>
                </div>
              </div>

              <div className="pa-question">
                <div>
                  <label className="question-label">
                    5. Do you feel that you had been given
                    <br /> adequate postoperative instructions?
                  </label>
                </div>
                <div className="psq-inputs">
                  <input type="checkbox" />
                  <label>Excellent</label>
                  <input type="checkbox" />
                  <label>Good</label>
                  <input type="checkbox" />
                  <label>Fair</label>
                  <input type="checkbox" />
                  <label>Poor</label>
                </div>
              </div>

              <div className="pa-question">
                <div>
                  <label className="question-label">
                    6. Was the staff available to answer questions and explain
                    procedures?
                  </label>
                </div>
                <div className="psq-inputs">
                  <input type="checkbox" />
                  <label>Excellent</label>
                  <input type="checkbox" />
                  <label>Good</label>
                  <input type="checkbox" />
                  <label>Fair</label>
                  <input type="checkbox" />
                  <label>Poor</label>
                </div>
              </div>
              <div className="pa-question">
                <div>
                  <label className="question-label">
                    7. Were you satisfied with the staff’s concern for your
                    privacy?
                  </label>
                </div>
                <div className="psq-inputs">
                  <input type="checkbox" />
                  <label>Excellent</label>
                  <input type="checkbox" />
                  <label>Good</label>
                  <input type="checkbox" />
                  <label>Fair</label>
                  <input type="checkbox" />
                  <label>Poor</label>
                </div>
              </div>
              <div className="pa-question">
                <div>
                  <label className="question-label">
                    8. If you would have surgery again, would you consider
                    <br />
                    returning to our facility?
                  </label>
                </div>
                <div className="psq-inputs">
                  <input type="checkbox" />
                  <label>Excellent</label>
                  <input type="checkbox" />
                  <label>Good</label>
                  <input type="checkbox" />
                  <label>Fair</label>
                  <input type="checkbox" />
                  <label>Poor</label>
                </div>
              </div>
              <div className="pa-question">
                <div>
                  <label className="question-label">
                    9. Would you tell others about the Center?
                  </label>
                </div>
                <div className="psq-inputs">
                  <input type="checkbox" />
                  <label>Excellent</label>
                  <input type="checkbox" />
                  <label>Good</label>
                  <input type="checkbox" />
                  <label>Fair</label>
                  <input type="checkbox" />
                  <label>Poor</label>
                </div>
              </div>
              <div className="pa-question">
                <div>
                  {" "}
                  <label className="question-label">
                    10. Were you satisfied with the anesthesia provider’s
                    explanations?
                  </label>
                </div>
                <div className="psq-inputs">
                  <input type="checkbox" />
                  <label>Excellent</label>
                  <input type="checkbox" />
                  <label>Good</label>
                  <input type="checkbox" />
                  <label>Fair</label>
                  <input type="checkbox" />
                  <label>Poor</label>
                </div>
              </div>
              <div className="pa-question">
                <div>
                  {" "}
                  <label className="question-label">
                    11. How would you rate your overall experience at our
                    facility?
                  </label>
                </div>
                <div className="psq-inputs">
                  <input type="checkbox" />
                  <label>Excellent</label>
                  <input type="checkbox" />
                  <label>Good</label>
                  <input type="checkbox" />
                  <label>Fair</label>
                  <input type="checkbox" />
                  <label>Poor</label>
                </div>
              </div>
              <div className="pa-question">
                <div>
                  {" "}
                  <label className="question-label">
                    12. Did your parent or loved one feel that they were
                    re-united
                    <br /> with you, as soon as possible?
                  </label>
                </div>
                <div className="psq-inputs">
                  <input type="checkbox" />
                  <label>Excellent</label>
                  <input type="checkbox" />
                  <label>Good</label>
                  <input type="checkbox" />
                  <label>Fair</label>
                  <input type="checkbox" />
                  <label>Poor</label>
                </div>
              </div>
              <div className="comments-section">
                <label>
                  Please list any general comments or suggestions you feel will
                  help us to improve the quality of patient care at our
                  facility. Comments/Suggestions
                </label>
                <textarea name="comments"></textarea>
              </div>
            </div>
            <div className="grid-2">
              <div className="signature-container mt-50">
                <SignatureButton />
                <hr className="signature-line"></hr>
                <p> Signature(optional)</p>
              </div>

              <div className="mt-50">
                <label htmlFor="name" className="hidden">
                  Signature(optional)
                </label>
                <input
                  type="date"
                  id="name"
                  name="name"
                  className="bottom-border-input"
                  required
                />

                <p>Date of Surgery</p>
              </div>
            </div>
            <div className="center mt-50">
              <button className="btn-primary hover-pointer" type="submit">
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default PatientSatisfactionQues;
