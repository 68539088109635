import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import "./App.css";
import Auth from "./utils/auth";

import Login from "./components/Login";
import Signup from "./components/Signup";
import Nav from "./components/Nav";
import PatientNav from "./components/Nav/PatientNav";
import SignupSuccess from "./pages/User/SignupSuccess";

// User Pages
import DashboardNav from "./pages/User/DashboardNav";
import Profile from "./pages/User/Profile";
import NewPatientForm from "./pages/User/NewPatientForm";
import PatientSearch from "./pages/User/PatientSearch";
import NewSurgeryForm from "./pages/User/NewSurgeryForm";
import PatientFolder from "./pages/User/PatientFolder";
import PatientInfo from "./pages/User/PatientInfo";
import NursingQuestionnaire from "./pages/User/NursingQuestionnaire";
import SurgeryView from "./pages/User/SurgeryView";
import PatientForms from "./pages/User/PatientForms";
import ThankYou from "./pages/User/ThankYou";
import PinModal from "./pages/User/PinModal";
// Admin Pages
import AdminSideNav from "./pages/Admin/AdminSideNav";
import AdminDashboard from "./pages/Admin/AdminDashboard";
import AdminUserSearch from "./pages/Admin/AdminUserSearch";
import AdminApproval from "./pages/Admin/AdminApproval";
import AdminCreate from "./pages/Admin/AdminCreate";
//Doctor Pages
import DrDashboard from "./pages/Doctor/Dashboard";
import DrSideNav from "./pages/Doctor/SideNav";
import PendingPaperwork from "./pages/Doctor/PendingPaperWork";
// Admin Pages
import AdminEditUser from "./pages/Admin/AdminEditUser";
import AdminViewUser from "./pages/Admin/AdminViewUser";
//Forgot Password Pages
import ForgotPassword from "./components/ForgotPassword";
import OTPValidation from "./components/OTP";
//Forms Folder (Patient View)
import PatientAnethesiaQuestionnaire from "./pages/User/Forms/AnethesiaQuestionnaire";
import PatientAssignmentofBenefits from "./pages/User/Forms/AssignmentofBenefits";
import PatientDischargeInstructions from "./pages/User/Forms/DischargeInstructions";
import PatientDisclosureofOwnership from "./pages/User/Forms/DisclosureofOwnership";
import PatientGrievance from "./pages/User/Forms/Grievance";
import PatientNoticeofPrivacy from "./pages/User/Forms/NoticeofPrivacy";
import PatientPatientConsentAnethesia from "./pages/User/Forms/PatientConsentAnethesia";
import PatientPatientConsentProcedure from "./pages/User/Forms/PatientConsentProcedure";
import PatientPatientHistoryandPhysical from "./pages/User/Forms/PatientHistoryandPhysical";
import PatientPatientRegistration from "./pages/User/Forms/PatientRegistration";
import PatientPatientSatisfaction from "./pages/User/Forms/PatientSatisfaction";
import PatientEducation from "./pages/User/Forms/PatientEducation";
//Nurse Forms
import SurgicalSafetyChecklist from "./pages/User/NurseForms/SurgicalSafetyChecklist";
import ChartChecklist from "./pages/User/NurseForms/ChartChecklist";
import IntraoperativeRecord from "./pages/User/NurseForms/IntraoperativeRecord";
import IntraOpNursingRecord from "./pages/User/NurseForms/IntraOpNursingRecord";
import MedicationReconciliation from "./pages/User/NurseForms/MedicationReconciliation";
import NursingPreopAssessment from "./pages/User/NurseForms/NursingPreOpAssessment";
import PostAnesthesiaPhysicianOrders from "./pages/User/NurseForms/PostAnesthesiaPhysicianOrders";
import PostOpPatientContact from "./pages/User/NurseForms/PostOpPatientContact";
import PreopPhonecall from "./pages/User/NurseForms/PreOpPhoneCall";
import PACUNursingNotes from "./pages/User/NurseForms/PACUNursingNotes";
// Admin Forms
import AdminFinancial from "./pages/User/AdminForms/AdminFinancial";
import BillingSupply from "./pages/User/AdminForms/BillingSupply";
import ChartChecklistAdmin from "./pages/User/AdminForms/ChartChecklistAdmin";
import PhysiciansDischargeOrders from "./pages/User/AdminForms/PhysiciansDischargeOrders";
// Surgeon Forms
import OperativeNote from "./pages/User/SurgeonForms/OperativeNote";
// Anesthesia Forms
import AnesthesiaRecord from "./pages/User/AnesthesiaForms/AnesthesiaRecord";
import PostAneCarePO from "./pages/User/AnesthesiaForms/PostAneCarePO";
import PreandPostOpAE from "./pages/User/AnesthesiaForms/PreandPostOpAE";
import PatientSatisfactionQues from "./pages/User/AdminForms/PatientSatisfactionQues";
import PatientSurveyBenchmark from "./pages/User/AdminForms/PatientSurveyBenchmark";
//Forms (Logged in) Folder
import AnethesiaQuestionnaire from "./pages/User/FormsLoggedIn/AnethesiaQuestionnaire";
import AssignmentofBenefits from "./pages/User/FormsLoggedIn/AssignmentofBenefits";
import DischargeInstructions from "./pages/User/FormsLoggedIn/DischargeInstructions";
import DisclosureofOwnership from "./pages/User/FormsLoggedIn/DisclosureofOwnership";
import Grievance from "./pages/User/FormsLoggedIn/Grievance";
import NoticeofPrivacy from "./pages/User/FormsLoggedIn/NoticeofPrivacy";
import PatientConsentAnethesia from "./pages/User/FormsLoggedIn/PatientConsentAnethesia";
import PatientConsentProcedure from "./pages/User/FormsLoggedIn/PatientConsentProcedure";
import PatientHistoryandPhysical from "./pages/User/FormsLoggedIn/PatientHistoryandPhysical";
import PatientRegistration from "./pages/User/FormsLoggedIn/PatientRegistration";
import PatientSatisfaction from "./pages/User/FormsLoggedIn/PatientSatisfaction";



const AppWrapper = () => {
  return (
    <Router>
      <App />
    </Router>
  );
};

const App = () => {
  let user;
  const location = useLocation();

  if (Auth.loggedIn() !== false) {
    user = Auth.getUser();
    // console.log(user);
  }

  // Allow users to access routes IF logged in
  function checkUser() {
    if (user !== undefined) {
      // Conditionally routes based on the user_type
      switch (user.role) {
        case "Doctor":
          //console.log("doctor");
          return doctorRoutes;
        case "Admin":
          //console.log("admin");
          return adminRoutes;
        default:
          //console.log("null");
          return null;
      }
    }
  }

  const adminRoutes = (
    <>
      {/* Admin */}
      <Route path="/admin-nav" element={<AdminSideNav />} />
      <Route path="/admin-dashboard" element={<AdminDashboard />} />
      <Route path="/admin-user-search" element={<AdminUserSearch />} />
      <Route path="/admin-approval" element={<AdminApproval />} />
      <Route path="/admin-create" element={<AdminCreate />} />
      <Route path="/admin-edit-user/:id" element={<AdminEditUser />} />
      <Route path="/admin-view-user/:id" element={<AdminViewUser />} />
    </>
  );

  const doctorRoutes = (
    <>
      {/* Doctor */}
      <Route path="/dr-dashboard" element={<DrDashboard />} />
      <Route path="/dr-sidenav" element={<DrSideNav />} />
      <Route path="/pending-paperwork" element={<PendingPaperwork />} />
    </>
  );

  const isLoggedIn = () => {
    if (user !== undefined) {
      return (
        <>
          {/* Logged In Routes (For all User types) */}
          <Route path="/dashboard" element={<DashboardNav />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/new-patient-form" element={<NewPatientForm />} />
          <Route path="/patient-search" element={<PatientSearch />} />
          <Route path="/new-surgery-form/:id" element={<NewSurgeryForm />} />
          <Route path="/patient/:id" element={<PatientFolder />} />
          <Route path="/patient-information/:id" element={<PatientInfo />} />
          <Route
            path="/surgery-view/:id/surgery/:surgery_id"
            element={<SurgeryView />}
          />
          <Route
            path="patient/:id/nursing-questionnaire/:surgeryInfo_id"
            element={<NursingQuestionnaire />}
          />
          <Route
            path="patient/:id/patient-forms/:surgery_id"
            element={<PatientForms />}
          />
          <Route
            path="patient/:id/patient-forms/:surgery_id/thank-you"
            element={<ThankYou />}
          />
          <Route
            path="patient/:id/patient-forms/:surgery_id/pin-modal"
            element={<PinModal />}
          />
          {/* Forms */}
          <Route
            path="/patient-form-anethesia-ques/:form_id"
            element={<PatientAnethesiaQuestionnaire />}
          />
          <Route
            path="/patient-form-assignment-benefits/:form_id"
            element={<PatientAssignmentofBenefits />}
          />
          <Route
            path="/patient-form-discharge-instructions/:form_id"
            element={<PatientDischargeInstructions />}
          />
          <Route
            path="/patient-form-disclosure-ownership/:form_id"
            element={<PatientDisclosureofOwnership />}
          />
          <Route
            path="/patient-form-grievance/:form_id"
            element={<PatientGrievance />}
          />
          <Route
            path="/patient-form-privacy/:form_id"
            element={<PatientNoticeofPrivacy />}
          />
          <Route
            path="/patient-form-consent-anethesia/:form_id"
            element={<PatientPatientConsentAnethesia />}
          />
          <Route
            path="/patient-form-procedure-consent/:form_id"
            element={<PatientPatientConsentProcedure />}
          />
          <Route
            path="/patient-form-patient-history/:form_id"
            element={<PatientPatientHistoryandPhysical />}
          />
          <Route
            path="/patient-form-registration/:form_id"
            element={<PatientPatientRegistration />}
          />
          <Route
            path="/patient-form-satisfaction/:form_id"
            element={<PatientPatientSatisfaction />}
          />
          <Route
            path="/patient-form-patient-education/:form_id"
            element={<PatientEducation />}
          />
          {/* Nurse Forms */}
          <Route
            path="/nurse-form-surgical-safety-checklist/:form_id"
            element={<SurgicalSafetyChecklist />}
          />
          <Route
            path="/nurse-form-post-op-patient-contact/:form_id"
            element={<PostOpPatientContact />}
          />
          <Route
            path="/nurse-form-chart-checklist/:form_id"
            element={<ChartChecklist />}
          />
          <Route
            path="/nurse-form-intraop-record/:form_id"
            element={<IntraoperativeRecord />}
          />
          <Route
            path="/nurse-form-intraop-nursing-record/:form_id"
            element={<IntraOpNursingRecord />}
          />
          <Route
            path="/nurse-form-medication-reconciliation/:form_id"
            element={<MedicationReconciliation />}
          />
          <Route
            path="/nurse-form-nursing-preop-assessment/:form_id"
            element={<NursingPreopAssessment />}
          />
          <Route
            path="/nurse-form-preop-phone-call/:form_id"
            element={<PreopPhonecall />}
          />
          <Route
            path="/nurse-form-pacu-nursing-notes/:form_id"
            element={<PACUNursingNotes />}
          />
          {/* Admin Forms */}
          <Route
            path="/admin-form-admin-financial/:form_id"
            element={<AdminFinancial />}
          />
          <Route
            path="/admin-form-billing-supply/:form_id"
            element={<BillingSupply />}
          />
          <Route
            path="/admin-form-chart-checklist-admin/:form_id"
            element={<ChartChecklistAdmin />}
          />
          <Route
            path="/admin-form-physicians-discharge-orders/:form_id"
            element={<PhysiciansDischargeOrders />}
          />
          {/* Surgeon Forms */}
          <Route
            path="/surgeon-form-operative-note/:form_id"
            element={<OperativeNote />}
          />
          {/* Anesthesia Forms */}
          <Route
            path="/anesthesia-form-anesthesia-record/:form_id"
            element={<AnesthesiaRecord />}
          />
          <Route
            path="/anesthesia-form-post-anesthesia-care/:form_id"
            element={<PostAneCarePO />}
          />
          <Route
            path="/anesthesia-form-pre-and-post-op/:form_id"
            element={<PreandPostOpAE />}
          />
          <Route
            path="/patient-form-satisfaction-ques/:form_id"
            element={<PatientSatisfactionQues />}
          />
          <Route
            path="/patient-form-survey-benchmark/:form_id"
            element={<PatientSurveyBenchmark />}
          />
          {/* Logged in Forms */}
          <Route
            path="/form-anethesia-ques/:form_id"
            element={<AnethesiaQuestionnaire />}
          />
          <Route
            path="/form-assignment-benefits/:form_id"
            element={<AssignmentofBenefits />}
          />
          <Route
            path="/form-discharge-instructions/:form_id"
            element={<DischargeInstructions />}
          />
          <Route
            path="/form-disclosure-ownership/:form_id"
            element={<DisclosureofOwnership />}
          />
          <Route path="/form-grievance/:form_id" element={<Grievance />} />
          <Route path="/form-privacy/:form_id" element={<NoticeofPrivacy />} />
          <Route
            path="/form-consent-anethesia/:form_id"
            element={<PatientConsentAnethesia />}
          />
          <Route
            path="/form-procedure-consent/:form_id"
            element={<PatientConsentProcedure />}
          />
          <Route
            path="/form-patient-history/:form_id"
            element={<PatientHistoryandPhysical />}
          />
          <Route
            path="/form-registration/:form_id"
            element={<PatientRegistration />}
          />
          <Route
            path="/form-satisfaction/:form_id"
            element={<PatientSatisfaction />}
          />
          <Route
            path="/form-surgical-safety/:form_id"
            element={<SurgicalSafetyChecklist />}
          />
          <Route
            path="/form-post-op-patient-contact/:form_id"
            element={<PostOpPatientContact />}
          />
          <Route
            path="/form-post-anesthesia-po/:form_id"
            element={<PostAnesthesiaPhysicianOrders />}
          />
          <Route
            path="/form-chart-checklist/:form_id"
            element={<ChartChecklist />}
          />
          <Route
            path="/form-intraop-nursing-record/:form_id"
            element={<IntraOpNursingRecord />}
          />
          <Route
            path="/form-intraop-record/:form_id"
            element={<IntraoperativeRecord />}
          />
          \
          <Route
            path="/form-medication-reconciliation/:form_id"
            element={<MedicationReconciliation />}
          />
          <Route
            path="/form-nursing-preop-assessment/:form_id"
            element={<NursingPreopAssessment />}
          />
          <Route
            path="/form-preop-phone-call/:form_id"
            element={<PreopPhonecall />}
          />
          <Route
            path="/form-pacu-nursing-notes/:form_id"
            element={<PACUNursingNotes />}
          />
          <Route
            path="/form-patient-education/:form_id"
            element={<PatientEducation />}
          />
          <Route
            path="/form-admin-financial/:form_id"
            element={<AdminFinancial />}
          />
          <Route
            path="/form-billing-supply/:form_id"
            element={<BillingSupply />}
          />
          <Route
            path="/form-chart-checklist-admin/:form_id"
            element={<ChartChecklistAdmin />}
          />
          <Route
            path="/form-physicians-discharge-orders/:form_id"
            element={<PhysiciansDischargeOrders />}
          />
          <Route
            path="/form-operative-note/:form_id"
            element={<OperativeNote />}
          />
          <Route
            path="/form-anesthesia-record/:form_id"
            element={<AnesthesiaRecord />}
          />
          <Route
            path="/form-post-anesthesia-care/:form_id"
            element={<PostAneCarePO />}
          />
          <Route
            path="/form-pre-and-post-op/:form_id"
            element={<PreandPostOpAE />}
          />
          <Route
            path="/form-patient-satisfaction-ques/:form_id"
            element={<PatientSatisfactionQues />}
          />
          <Route
            path="/form-patient-survey-benchmark/:form_id"
            element={<PatientSurveyBenchmark />}
          />
        </>
      );
    }
  };

  // Define an array of paths that should display showPatientNav
  const showPatientNav =
    [
      `/patient/:id/patient-forms/:nursing_id/pin-modal`,
      `/patient/:id/patient-forms/:nursing_id/thank-you`,
      `/patient/:id/patient-forms/:nursing_id`,
    ].some((path) =>
      // Check if the current location's pathname matches any of the paths in the array
      location.pathname.match(
        new RegExp(`^${path.replace(/:id|:nursing_id/g, "\\d+")}$`)
      )
    ) ||
    location.pathname.startsWith("/patient-form") ||
    location.pathname.startsWith("/form") ||
    location.pathname.startsWith("/surgeon") ||
    location.pathname.startsWith("/anesthesia") ||
    location.pathname.startsWith("/admin-form") ||
    location.pathname.startsWith("/form-admin") ||
    location.pathname.startsWith("/nurse");

  return (
    <>
      {showPatientNav ? <PatientNav /> : <Nav />}
      <Routes>
        {/* All User */}
        <Route path="/" element={<Login />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/signup-success" element={<SignupSuccess />} />

        {/* Forgot Password */}
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/otp" element={<OTPValidation />} />

        {/* Additional Routes */}
        {isLoggedIn()}
        {checkUser()}

        <Route path="*" element={<Login />} />
      </Routes>
    </>
  );
};

export default AppWrapper;
